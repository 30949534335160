import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Avatar_16,
  Avatar_02,
  Avatar_05,
  Avatar_09,
  Avatar_10,
} from "../../../Routes/ImagePath/index";

import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import React, { useState, useEffect } from "react";

import { startOfMonth, endOfMonth, format } from "date-fns";

import AuthUser from "../../AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../../Spinner";

import Swal from "sweetalert2";

const CaixaMovimentoModal = ({
  onSubmissionSuccess,
  movimento,
  contas,
  clientes,
  rotulos,
  bancos,
}) => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user, user_permissoes, verificarPermissao, getMissao } =
    AuthUser();

  const [banco_id, setBancoID] = useState("");
  const [rotulo_id, setRotuloID] = useState("");
  const [conta_id, setContaID] = useState("");
  const [conta_secundaria_id, setContaSecundariaID] = useState("");
  const [cliente_id, setClienteID] = useState("");
  const [metodo, setMetodo] = useState("");
  const [caixa, setCaixa] = useState("");

  const [nota, setNota] = useState("");
  const [valor, setvalor] = useState("");
  const [data, setdata] = useState("");
  const [tipo, setTipo] = useState(1);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState();

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event("click"));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {

        const currentDate = new Date();

        setInputs(movimento);

        setdata(movimento?.data ? movimento?.data : format(currentDate, "yyyy-MM-dd"));
        setClienteID(movimento?.cliente_id ? movimento?.cliente_id : -1);
        setContaID(movimento?.conta_id ? movimento?.conta_id : -1);
        setContaSecundariaID(
          movimento?.conta_secundaria_id ? movimento?.conta_secundaria_id : -1
        );
        setRotuloID(movimento?.rotulo_id ? movimento?.rotulo_id : -1);
        setMetodo(movimento?.metodo ? movimento?.metodo : -1);
        setTipo(movimento?.tipo ? movimento?.tipo : 1);
        setCaixa(movimento?.caixa ? movimento?.caixa : 1); //1 - sim caixa, 2 - não caixa, 3 - entre contas
        setimagemPreview(
          movimento?.comprovativo
            ? url_root + "/storage/" + movimento?.comprovativo
            : ""
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [movimento]);

  // REGISTO NOVO

  const submitFormNovo = async () => {
    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {
        toggleLoading(true);

        await registar();
        
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else {
          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      console.log("Clicou em submeter");

      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, movimentocte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const registar = async () => {
    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {
        const formData = new FormData();

        formData.append("rotulo_id", rotulo_id);
        formData.append("valor", valor);
        formData.append("nota", nota);
        formData.append("data", data);
        formData.append("registou", user?.id);
        formData.append("tipo", tipo);
        formData.append("conta_id", conta_id);
        formData.append(
          "conta_secundaria_id",
          caixa == 3 ? conta_secundaria_id : -1
        );
        formData.append("metodo", metodo);
        formData.append("caixa", caixa);
        formData.append("cliente_id", cliente_id);
        formData.append("comprovativo", imagem);
        formData.append('missao_id', getMissao()?.id);

        const response = await http.post("/movimento/register", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        setNota("");
        setvalor("");
        setdata("");

        triggerCloseButtonClick("closeButton30");

        onSubmissionSuccess(response.data.data);
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, movimentocte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  // ATUALIZAR INFORMACAO

  const submitUpdateForm = async () => {
    if (verificarPermissao("UTILIZADORES", "atualizar")) {
      try {
        toggleLoading(true);
        await updateInfo();
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else {
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, movimentocte o administrador do sistema",
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      });
    }
  };

  const updateInfo = async () => {
    if (verificarPermissao("UTILIZADORES", "atualizar")) {
      try {
        const formData = new FormData();
        formData.append("id", inputs?.id);

        formData.append("rotulo_id", rotulo_id);
        formData.append("valor", inputs?.valor);
        formData.append("nota", inputs?.nota);
        formData.append("data", inputs?.data);
        formData.append("registou", user?.id);
        formData.append("tipo", tipo);
        formData.append("conta_id", conta_id);
        formData.append("metodo", metodo);
        formData.append("caixa", caixa);
        formData.append("cliente_id", cliente_id);
        formData.append(
          "conta_secundaria_id",
          caixa == 3 ? conta_secundaria_id : -1
        );
        formData.append("comprovativo", imagem);

        const response = await http.post(`/movimento/update`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        triggerCloseButtonClick("closeButton40");
        onSubmissionSuccess(response.data.data);
      } catch (error) {
        console.error(error);
        toast.error(
          "Ocorreu um erro ao atualizar a informação pessoal. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, movimentocte o administrador do sistema",
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      });
    }
  };

  const renderOptionContas = (contas) => {
    return contas?.map((conta) => (
      <React.Fragment key={conta.id}>
        <option value={conta.id}>
          {encontrarBancoPorId(conta?.banco_id)?.nome} - {conta.conta}
        </option>
      </React.Fragment>
    ));
  };

  const renderOptionClientes = (clientes) => {
    return clientes?.map((cliente) => (
      <React.Fragment key={cliente.id}>
        <option value={cliente.id}>{cliente.nome}</option>
      </React.Fragment>
    ));
  };

  const renderOptionRotulos = (rotulos) => {
    return rotulos?.map((rotulo) => (
      <React.Fragment key={rotulo.id}>
        <option value={rotulo.id}>{rotulo.nome}</option>
      </React.Fragment>
    ));
  };

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const encontrarBancoPorId = (id) => {
    // Encontre o objeto com a ID correspondente
    const bancoEncontrado = bancos.find((lista) => lista.id == id);

    // Verifique se o objeto foi encontrado
    if (bancoEncontrado != undefined) {
      return bancoEncontrado;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }
  };

  /* Imagem do logotipo */

  const [imagem, setSelectedFile] = useState(null);
  const [imagemPreview, setimagemPreview] = useState();

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);

    setimagemPreview(newFileURL);

    console.log("New image url 1:");
    console.log(newFileURL);
  };

  return (
    <>
      <>
        {/* Add Task Modal */}
        <div
          id="add_movimento_modal"
          className="modal modal-lg custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">{t("Add Movimento Financeiro")}</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  id="closeButton30"
                >
                  ×
                </button>
              </div>
              <div className="modal-body">
                <div className="card">
                  <div className="card-body">
                    <hr className="mt-4"></hr>
                    <form>
                      <div className="row mt-4">
                        <div className="col-md-6 row">
                          <div className="col-md-12">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                type="date"
                                className="form-control"
                                name="data"
                                value={data}
                                onChange={(e) => setdata(e.target.value)}
                              />
                              <label className="focus-label">{t("Data")}</label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                type="number"
                                className="form-control"
                                name="valor"
                                value={valor}
                                onChange={(e) => setvalor(e.target.value)}
                              />
                              <label className="focus-label">
                                {t("Valor")}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="profile-img-wrap edit-img">
                            <img
                              className="inline-block"
                              src={imagemPreview}
                              alt={movimento?.comprovativo}
                            />
                            <div className="fileupload btn">
                              <span className="btn-text">
                                carregar comprovativo
                              </span>
                              <input
                                className="upload"
                                type="file"
                                onChange={handleFileChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <select
                              className="form-control select"
                              onChange={(e) => setRotuloID(e.target.value)}
                            >
                              {renderOptionRotulos(rotulos)}
                            </select>

                            <label className="focus-label">
                              {t("Despesa")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input-block mb-3 form-focus focused">
                            <label className="focus-label">
                              {t("Tipo de movimento")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control select"
                              value={tipo}
                              onChange={(e) => setTipo(e.target.value)}
                            >
                              <option value="1">Entrada</option>
                              <option value="2">Saida</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input-block mb-3 form-focus focused">
                            <label className="focus-label">
                              {t("Metodo")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control select"
                              value={metodo}
                              onChange={(e) => setMetodo(e.target.value)}
                            >
                              <option value="1">Dinheiro</option>
                              <option value="2">Transferencia</option>
                              <option value="3">TPA</option>
                              <option value="4">Outro</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input-block mb-3 form-focus focused">
                            <label className="focus-label">
                              {t("Movimento no caixa ?")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control select"
                              value={caixa}
                              onChange={(e) => setCaixa(e.target.value)}
                            >
                              <option value="1">Sim</option>
                              <option value="2">Não</option>
                              <option value="3">Entre contas bancaria</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <select
                              className="form-control select"
                              onChange={(e) => setContaID(e.target.value)}
                            >
                              <option value="-1">- Nenhuma -</option>
                              {renderOptionContas(contas)}
                            </select>

                            <label className="focus-label">
                              {tipo == 1
                                ? "Conta Em Que Recebe o valor"
                                : "Conta Em Que Sai o Valor "}
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                        </div>

                        {caixa == 3 ? (
                          <>
                            <div className="col-md-12">
                              <div className="input-block mb-3 mb-3 form-focus focused">
                                <select
                                  className="form-control select"
                                  value={conta_secundaria_id}
                                  onChange={(e) =>
                                    setContaSecundariaID(e.target.value)
                                  }
                                >
                                  <option value="-1">- Nenhuma -</option>
                                  {renderOptionContas(contas)}
                                </select>

                                <label className="focus-label">
                                  {tipo == 1
                                    ? "Conta Em Que Sai o Valor "
                                    : "Conta Em Que Recebe o valor"}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        <div className="col-md-12">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <select
                              className="form-control select"
                              onChange={(e) => setClienteID(e.target.value)}
                            >
                              <option value="-1">- Nenhum -</option>
                              {renderOptionClientes(clientes)}
                            </select>

                            <label className="focus-label">
                              {t("Parceiro")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="input-block mb-3 form-focus focused">
                            <label className="col-form-label focus-label">
                              {t("Nota")}
                            </label>
                            <div className="">
                              <textarea
                                name="nota"
                                className="form-control"
                                value={nota}
                                onChange={(e) => setNota(e.target.value)}
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        <hr className="mt-4"></hr>

                        <div className="submit-section">
                          <a
                            className="btn btn-primary submit-btn"
                            onClick={submitFormNovo}
                          >
                            {loading ? "Carregando" : t("Submit")}
                          </a>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Add Task Modal */}

        {/* Edit Task Modal */}
        <div
          id="edit_movimento_modal"
          className="modal modal-lg custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  {t("Editar Movimento Financeira")}
                </h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  id="closeButton40"
                >
                  ×
                </button>
              </div>
              <div className="modal-body">
                <div className="card">
                  <div className="card-body">
                    <hr className="mt-4"></hr>
                    <form>
                      <div className="row">
                        <div className="col-md-6 row">
                          <div className="col-md-12">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                type="date"
                                className="form-control"
                                name="data"
                                value={inputs?.data}
                                onChange={(e) => handleInputsValueChanged(e)}
                              />
                              <label className="focus-label">{t("Data")}</label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                type="number"
                                className="form-control"
                                name="valor"
                                value={inputs?.valor}
                                onChange={(e) => handleInputsValueChanged(e)}
                              />
                              <label className="focus-label">
                                {t("Valor")}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="profile-img-wrap edit-img">
                            <img
                              className="inline-block"
                              src={imagemPreview}
                              alt={movimento?.comprovativo}
                            />
                            <div className="fileupload btn">
                              <span className="btn-text">
                                carregar comprovativo
                              </span>
                              <input
                                className="upload"
                                type="file"
                                onChange={handleFileChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <select
                              className="form-control select"
                              onChange={(e) => setRotuloID(e.target.value)}
                            >
                              {renderOptionRotulos(rotulos)}
                            </select>

                            <label className="focus-label">
                              {t("Despesa")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input-block mb-3 form-focus focused">
                            <label className="focus-label">
                              {t("Tipo de movimento")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control select"
                              value={tipo}
                              onChange={(e) => setTipo(e.target.value)}
                            >
                              <option value="1">Entrada</option>
                              <option value="2">Saida</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input-block mb-3 form-focus focused">
                            <label className="focus-label">
                              {t("Metodo")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control select"
                              value={metodo}
                              onChange={(e) => setMetodo(e.target.value)}
                            >
                              <option value="1">Dinheiro</option>
                              <option value="2">Transferencia</option>
                              <option value="3">TPA</option>
                              <option value="4">Outro</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input-block mb-3 form-focus focused">
                            <label className="focus-label">
                              {t("Movimento no caixa ?")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control select"
                              value={caixa}
                              onChange={(e) => setCaixa(e.target.value)}
                            >
                              <option value="1">Sim</option>
                              <option value="2">Não</option>
                              <option value="3">Entre contas bancaria</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <select
                              className="form-control select"
                              value={conta_id}
                              onChange={(e) => setContaID(e.target.value)}
                            >
                              <option value="-1">- Nenhuma -</option>
                              {renderOptionContas(contas)}
                            </select>

                            <label className="focus-label">
                              {tipo == 1
                                ? "Conta Em Que Recebe o valor"
                                : "Conta Em Que Sai o Valor "}
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                        </div>

                        {caixa == 3 ? (
                          <>
                            <div className="col-md-12">
                              <div className="input-block mb-3 mb-3 form-focus focused">
                                <select
                                  className="form-control select"
                                  value={conta_secundaria_id}
                                  onChange={(e) =>
                                    setContaSecundariaID(e.target.value)
                                  }
                                >
                                  <option value="-1">- Nenhuma -</option>
                                  {renderOptionContas(contas)}
                                </select>

                                <label className="focus-label">
                                  {tipo == 1
                                    ? "Conta Em Que Sai o Valor "
                                    : "Conta Em Que Recebe o valor"}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        <div className="col-md-12">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <select
                              className="form-control select"
                              onChange={(e) => setClienteID(e.target.value)}
                            >
                              <option value="-1">- Nenhum -</option>
                              {renderOptionClientes(clientes)}
                            </select>

                            <label className="focus-label">
                              {t("Parceiro")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="input-block mb-3 form-focus focused">
                            <label className="col-form-label focus-label">
                              {t("Nota")}
                            </label>
                            <div className="">
                              <textarea
                                name="nota"
                                className="form-control"
                                value={inputs?.nota}
                                onChange={(e) => handleInputsValueChanged(e)}
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        <hr className="mt-4"></hr>

                        <div className="submit-section">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => submitUpdateForm()}
                          >
                            {t("Atualizar Informação")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Edit Task Modal */}
        <ToastContainer />
      </>
    </>
  );
};

export default CaixaMovimentoModal;
