/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { Avatar_09, Avatar_10, Avatar_16 } from "../../Routes/ImagePath";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import AuthUser from "../AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../Spinner";

const ColaboradorTicketModalPopup = ({ onSubmissionSuccess, ticket, tarefa, utilizadores }) => {

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user, user_permissoes, verificarPermissao } = AuthUser();

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {

    setUsers(utilizadores);

  }, [users]);

  const handleSearchTermChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    const filtered = users.filter(user =>
      user.name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredUsers(filtered);
  };

  const handleUserSelect = (selectedUser, tipo) => {
    // Here you can add the selected user to your project team or perform any other action
    console.log("Selected user:", selectedUser);

    if (selectedUser == null) {
      // Clear the search term and filtered users after selecting a user
      setSearchTerm("");
      setFilteredUsers([]);
    } else {
      addColaboradoresticket(selectedUser, tipo);
    }

  };

  const addColaboradoresticket = async (user, tipo) => {
    try {

      setLoading(true);

      const formData = new FormData();
      formData.append('user_id', user?.id);
      formData.append('ticket_id', ticket?.id);
      formData.append('tipo', tipo);

      const response = await http.post('/ticket/addColaborador', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setLoading(false);

      triggerCloseButtonClick('closeButton5');

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {

          setLoading(false);
          //Refresh colaboradores list
          onSubmissionSuccess();

        }
      });

    } catch (error) {

      setLoading(false);
      console.error(error);

      toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event('click'));
    }
  };

  return (
    <div>
      <div id="assign_colaborador" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t('Assign employee to this Ticket')}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeButton5"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group m-b-30">
                <input
                  placeholder={t('Search')}
                  className="form-control search-input"
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                />
                <button className="btn btn-primary">{t('Search')}</button>
              </div>
              <div>

                {loading?<Spinner></Spinner>: filteredUsers.length > 0 && (
                  <ul className="chat-user-list">
                    {filteredUsers.map(user => (

                      <li  key={user.id}>
                        <span onClick={() => handleUserSelect(user, 2)}>
                          <div className="chat-block d-flex">
                            <span className="avatar">
                              <img src={url_root + "/storage/" + user.fotografia} alt={user.name} title={user.name} />
                            </span>
                            <div className="media-body align-self-center text-nowrap">
                              <div className="user-name">{user.name}</div>
                              <span className="designation">{user.email}</span>
                            </div>
                          </div>
                        </span>
                      </li>

                    ))}
                  </ul>
                )}

               
              </div>


            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

    </div>
  );
};

export default ColaboradorTicketModalPopup;
