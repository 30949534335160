import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Avatar_16,
  Avatar_02,
  Avatar_05,
  Avatar_09,
  Avatar_10,
} from "../../Routes/ImagePath/index";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import React, { useState, useEffect } from "react";

import AuthUser from "../AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../Spinner";

import Swal from "sweetalert2";

const TaskboardModal = ({ onSubmissionSuccess, projeto, utilizadores, quadro, tarefa, defaultColor, defaultPrioridade, quadros }) => {

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user, user_permissoes, verificarPermissao } = AuthUser();
  const [nome, setName] = useState('');
  const [prazo, setPrazo] = useState();
  const [estado, setEstado] = useState();

  const [quadro_id, setQuadro] = useState(-1);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [inputsQuadro, setInputsQuadro] = useState(quadro);
  const [inputsTarefa, setInputsTarefa] = useState(tarefa);
  const [projetoSelecionado, setProjetoSelecionado] = useState(projeto);

  const [selectedColor, setSelectedColor] = useState(defaultColor); // Define o estado inicial
  const [selectedPrioridade, setSelectedPrioridade] = useState(defaultPrioridade); // Define o estado inicial

  const handleColorChange = (e) => {
    setSelectedColor(e.target.value); // Atualiza o estado com o valor selecionado
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event('click'));
    }
  };


  useEffect(() => {

    setUsers(utilizadores);

    setPrazo(quadro?.data);

    const fetchData = async () => {
      try {

        setInputsQuadro(quadro);
        setInputsTarefa(tarefa);
        setName(tarefa?.nome);
        setEstado(tarefa?.estado);
        setSelectedColor(quadro.cor);
        setSelectedPrioridade(defaultPrioridade);
        setProjetoSelecionado(projeto);

        setQuadro(quadro?quadro?.id:-1);

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

  }, [tarefa, quadro]);

  const getProjeto = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/projeto/' + inputsQuadro?.projeto_id);

      setLoading(false);

      onSubmissionSuccess(res.data.projeto);



    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleSearchTermChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    const filtered = users.filter(user =>
      user.name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredUsers(filtered);
  };

  const handleUserSelect = (selectedUser, tipo) => {
    // Here you can add the selected user to your project team or perform any other action
    console.log("Selected user:", selectedUser);

    if (selectedUser == null) {
      // Clear the search term and filtered users after selecting a user
      setSearchTerm("");
      setFilteredUsers([]);
    } else {
      //addColaboradoresProjeto(selectedUser, tipo);
    }

  };

  //registar quadro

  const submitFormQuadro = async () => {

    console.log('Clicou em submeter');

    if (verificarPermissao('PROJETOS', 'adicionar')) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (nome.length < 2) {
          setError('O nome deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O nome deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }


        await uploadProjetoQuadro();


      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {
          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }

    } else {

      console.log('Clicou em submeter');

      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const uploadProjetoQuadro = async () => {
    if (verificarPermissao('PROJETOS', 'adicionar')) {
      try {

        const formData = new FormData();
        formData.append('nome', nome);
        formData.append('cor', selectedColor);
        formData.append('projeto_id', projeto?.id);

        const response = await http.post('/projeto/register/quadro', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        console.log(response);

        // Show a success toast
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {

            setName('');

            triggerCloseButtonClick('closeButton10');

            console.log('to send : ');
            console.log(response.data.data);

            onSubmissionSuccess(response.data.data);

          },
        });

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  //Update quadro

  const submitFormUpdateQuadro = async () => {

    if (verificarPermissao('PROJETOS', 'atualizar')) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (inputsQuadro?.nome.length < 2) {
          setError('O nome deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O nome deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 1000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        await updateProjetoQuadro();

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {
          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const updateProjetoQuadro = async () => {
    try {

      const formData = new FormData();
      formData.append('id', inputsQuadro?.id);
      formData.append('nome', inputsQuadro?.nome);
      formData.append('cor', selectedColor);
      formData.append('projeto_id', inputsQuadro?.projeto_id);

      console.log('ID quadro');
      console.log(inputsQuadro?.id)

      const response = await http.post('/projeto/update/quadro', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {

          setName('');

          triggerCloseButtonClick('closeButton20');

          console.log('to send : ');
          console.log(response.data.data);

          getProjeto();

        }
      });

    } catch (error) {
      console.error(error);

      toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputsQuadro((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  //registar tarefa

  const submitFormTarefa = async () => {

    if (verificarPermissao('PROJETOS', 'adicionar')) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (nome.length < 2) {
          setError('O nome deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O nome deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }


        await uploadProjetoTarefa();


      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {
          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }

    } else {

      console.log('Clicou em submeter');

      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const uploadProjetoTarefa = async () => {
    if (verificarPermissao('PROJETOS', 'adicionar')) {
      try {

        const formData = new FormData();
        formData.append('nome', nome);
        formData.append('prioridade', selectedPrioridade);
        formData.append('prazo', prazo);
        formData.append('projeto_id', projeto?.id);
        formData.append('quadro_id', inputsQuadro?.id?inputsQuadro?.id:-1);
        formData.append('estado', 0);
        formData.append('criado_por', user?.id);
        formData.append('quadro_id', quadro_id);

        const response = await http.post('/projeto/register/tarefa', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        console.log(response);

        // Show a success toast
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {

            setName('');

            triggerCloseButtonClick('closeButton30');

            console.log('to send : ');
            console.log(response.data.data);

            onSubmissionSuccess(response.data.data);

          },
        });

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  //Update tarefa

  const submitFormUpdateTarefa = async () => {

    if (verificarPermissao('PROJETOS', 'atualizar')) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (inputsTarefa?.nome.length < 2) {
          setError('O nome deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O nome deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 1000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        await updateProjetoTarefa();

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {
          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const updateProjetoTarefa = async () => {
    try {

      const formData = new FormData();
      formData.append('id', inputsTarefa?.id);
      formData.append('nome', nome);
      formData.append('prioridade', selectedPrioridade);
      formData.append('prazo', inputsTarefa?.prazo);
      formData.append('projeto_id', inputsTarefa?.projeto_id);
      formData.append('quadro_id', quadro_id);
      formData.append('estado', estado);

      const response = await http.post('/projeto/update/tarefa', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {

          setName('');

          triggerCloseButtonClick('closeButton40');

          console.log('to send : ');
          console.log(response.data.data);

          getProjeto();

        }
      });

    } catch (error) {
      console.error(error);

      toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleInputsValueChangedTarefa = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputsTarefa((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };


 

 
  const renderOptionQuadros = (quadros) => {
    return quadros?.map((quadro) => (
      <React.Fragment key={quadro?.id}>
        <option value={quadro?.id}>{quadro?.nome}</option>
      </React.Fragment>
    ));
  };

 
  return (
    <>
      {/* Add Lista Modal */}
      <div
        id="add_task_board"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{t('Add Task Board')}</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="closeButton10"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="input-block mb-3">
                  <label className="col-form-label">{t('Task Board Name')}</label>
                  <input type="text" className="form-control" value={nome} onChange={e => setName(e.target.value)} />
                </div>
                <div className="input-block mb-3 task-board-color">
                  <label className="col-form-label">{t('Task Board Color')}</label>
                  <div className="board-color-list">
                    <label className="board-control board-primary">
                      <input
                        name="radio"
                        type="radio"
                        className="board-control-input"
                        value="primary"
                        onChange={handleColorChange}
                        checked={selectedColor === 'primary'}
                      />
                      <span className="board-indicator" />
                    </label>
                    <label className="board-control board-success">
                      <input
                        name="radio"
                        type="radio"
                        className="board-control-input"
                        value="success"
                        onChange={handleColorChange}
                        checked={selectedColor === 'success'}
                      />
                      <span className="board-indicator" />
                    </label>
                    <label className="board-control board-info">
                      <input
                        name="radio"
                        type="radio"
                        className="board-control-input"
                        value="info"
                        onChange={handleColorChange}
                        checked={selectedColor === 'info'}
                      />
                      <span className="board-indicator" />
                    </label>
                    <label className="board-control board-purple">
                      <input
                        name="radio"
                        type="radio"
                        className="board-control-input"
                        value="purple"
                        onChange={handleColorChange}
                        checked={selectedColor === 'purple'}
                      />
                      <span className="board-indicator" />
                    </label>
                    <label className="board-control board-warning">
                      <input
                        name="radio"
                        type="radio"
                        className="board-control-input"
                        value="warning"
                        onChange={handleColorChange}
                        checked={selectedColor === 'warning'}
                      />
                      <span className="board-indicator" />
                    </label>
                    <label className="board-control board-danger">
                      <input
                        name="radio"
                        type="radio"
                        className="board-control-input"
                        value="danger"
                        onChange={handleColorChange}
                        checked={selectedColor === 'danger'}
                      />
                      <span className="board-indicator" />
                    </label>
                  </div>
                </div>
                <div className="submit-section m-t-20 text-center">
                  {loading ? 'A processar' : <a
                    className="btn btn-primary submit-btn"
                    onClick={submitFormQuadro}>
                    {t('Submit')}
                  </a>}

                </div>
              </form>
            </div>
          </div>
        </div>

      </div>

      {/* Add Lista Modal */}

      {/* Edit Lista Modal */}
      <div
        id="edit_task_board"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{t('Edit Task Board')}</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="closeButton20"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="input-block mb-3">
                  <label className="col-form-label">{t('Task Board Name')}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="nome" value={inputsQuadro?.nome} onChange={handleInputsValueChanged}
                  />
                </div>
                <div className="input-block mb-3 task-board-color">
                  <label className="col-form-label">{t('Task Board Color')}</label>
                  <div className="board-color-list">
                    <label className="board-control board-primary">
                      <input
                        name="radio"
                        type="radio"
                        className="board-control-input"
                        value="primary"
                        onChange={handleColorChange}
                        checked={inputsQuadro?.color == 'primary'}
                      />
                      <span className="board-indicator" />
                    </label>
                    <label className="board-control board-success">
                      <input
                        name="radio"
                        type="radio"
                        className="board-control-input"
                        value="success"
                        onChange={handleColorChange}
                        checked={selectedColor == 'success'}
                      />
                      <span className="board-indicator" />
                    </label>
                    <label className="board-control board-info">
                      <input
                        name="radio"
                        type="radio"
                        className="board-control-input"
                        value="info"
                        onChange={handleColorChange}
                        checked={selectedColor == 'info'}
                      />
                      <span className="board-indicator" />
                    </label>
                    <label className="board-control board-purple">
                      <input
                        name="radio"
                        type="radio"
                        className="board-control-input"
                        value="purple"
                        onChange={handleColorChange}
                        checked={selectedColor == 'purple'}
                      />
                      <span className="board-indicator" />
                    </label>
                    <label className="board-control board-warning">
                      <input
                        name="radio"
                        type="radio"
                        className="board-control-input"
                        value="warning"
                        onChange={handleColorChange}
                        checked={selectedColor == 'warning'}
                      />
                      <span className="board-indicator" />
                    </label>
                    <label className="board-control board-danger">
                      <input
                        name="radio"
                        type="radio"
                        className="board-control-input"
                        value="danger"
                        onChange={handleColorChange}
                        checked={selectedColor == 'danger'}
                      />
                      <span className="board-indicator" />
                    </label>
                  </div>
                </div>
                <div className="submit-section m-t-20 text-center">
                  {loading ? 'A processar' : <a
                    className="btn btn-primary submit-btn"
                    onClick={submitFormUpdateQuadro}>
                    {t('Save')}
                  </a>}

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Edit Lista Modal */}



      {/* Add Task Modal */}
      <div
        id="add_task_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{t('Add Task')}</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="closeButton30"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="input-block mb-3">
                  <label className="col-form-label">{t('Task Name')}</label>
                  <input type="text" className="form-control" value={nome} onChange={e => setName(e.target.value)} />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">{t('Task Priority')}</label>
                  <select className="form-control select" value={selectedPrioridade} onChange={e => setSelectedPrioridade(e.target.value)} >
                    <option value="1">
                      {t('Low')}
                    </option>
                    <option value="2" >
                      {t('Medium')}
                    </option>
                    <option value="3" >
                      {t('High')}
                    </option>
                  </select>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">{t('Due Date')}</label>
                  <div className="">
                    <input type="date" className="form-control" value={prazo} onChange={e => setPrazo(e.target.value)} />
                  </div>
                </div>

                <div className="input-block mb-3">
                    <label className="col-form-label">{t('List')}</label>
                     <select
                      onChange={(e) => setQuadro(e.target.value)}
                      value={quadro_id}
                      className="form-control select"
                    >
                      <option key={0} value={-1}></option>
                      {renderOptionQuadros(quadros)}
                    </select>
                  </div>

                {/* <div className="input-block mb-3">
                  <label className="col-form-label">{t('Task Followers')}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t('Search to add')}
                  />
                  <div className="task-follower-list">
                    <span data-bs-toggle="tooltip" title="John Doe">
                      <img
                        src={Avatar_02}
                        className="avatar"
                        alt="John Doe"
                        width={20}
                        height={20}
                      />
                      <i className="fa fa-times" />
                    </span>
                    <span data-bs-toggle="tooltip" title="Richard Miles">
                      <img
                        src={Avatar_09}
                        className="avatar"
                        alt="Richard Miles"
                        width={20}
                        height={20}
                      />
                      <i className="fa fa-times" />
                    </span>
                    <span data-bs-toggle="tooltip" title="John Smith">
                      <img
                        src={Avatar_10}
                        className="avatar"
                        alt="John Smith"
                        width={20}
                        height={20}
                      />
                      <i className="fa fa-times" />
                    </span>
                    <span data-bs-toggle="tooltip" title="Miguel Luis">
                      <img
                        src={Avatar_05}
                        className="avatar"
                        alt="Miguel Luis"
                        width={20}
                        height={20}
                      />
                      <i className="fa fa-times" />
                    </span>
                  </div>
                </div> */}
                <div className="submit-section m-t-20 text-center">
                  {loading ? 'A processar' : <a
                    className="btn btn-primary submit-btn"
                    onClick={submitFormTarefa}>
                    {t('Submit')}
                  </a>}

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Task Modal */}


      {/* Edit Task Modal */}
      <div
        id="edit_task_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{t('Edit Task')}</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="closeButton40"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="input-block mb-3">
                  <label className="col-form-label">{t('Task Name')}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="nome" value={nome} onChange={e => setName(e.target.value)} />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">{t('Task Priority')}</label>
                  <select className="form-control select" value={selectedPrioridade} onChange={e => setSelectedPrioridade(e.target.value)} >
                    <option value="1" >
                      {t('Low')}
                    </option>
                    <option value="2" >
                      {t('Medium')}
                    </option>
                    <option value="3" >
                      {t('High')}
                    </option>
                  </select>
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">{t('Due Date')}</label>
                  <div className="">
                    <input
                      type="date"
                      className="form-control"
                      name="prazo" value={inputsTarefa?.prazo} onChange={handleInputsValueChangedTarefa}
                    />
                  </div>
                </div>

                 <div className="input-block mb-3">
                  <label className="col-form-label">{t('Status')}</label>
                  <select className="form-control select" value={estado} onChange={e => setEstado(e.target.value)} selected={estado === inputsTarefa?.estado} >
                    <option value="0">
                      {t('Opened')}
                    </option>
                    <option value="1" >
                      {t('Closed')}
                    </option>
                  </select>
                </div> 

                <div className="input-block mb-3">
                    <label className="col-form-label">{t('List')}</label>
                     <select
                      onChange={(e) => setQuadro(e.target.value)}
                      value={quadro_id}
                      className="form-control select"
                    >
                      <option key={0} value={-1}></option>
                      {renderOptionQuadros(quadros)}
                    </select>
                  </div>

                {/* <div className="input-block mb-3">
                  <label className="col-form-label">{t('Task Followers')}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t('Search to add')}
                  />
                  <div className="task-follower-list">
                    <span data-bs-toggle="tooltip" title="John Doe">
                      <img
                        src={Avatar_02}
                        className="avatar"
                        alt="John Doe"
                        width={20}
                        height={20}
                      />
                      <i className="fa fa-times" />
                    </span>
                    <span data-bs-toggle="tooltip" title="Richard Miles">
                      <img
                        src={Avatar_09}
                        className="avatar"
                        alt="Richard Miles"
                        width={20}
                        height={20}
                      />
                      <i className="fa fa-times" />
                    </span>
                    <span data-bs-toggle="tooltip" title="John Smith">
                      <img
                        src={Avatar_10}
                        className="avatar"
                        alt="John Smith"
                        width={20}
                        height={20}
                      />
                      <i className="fa fa-times" />
                    </span>
                    <span data-bs-toggle="tooltip" title="Miguel Luis">
                      <img
                        src={Avatar_05}
                        className="avatar"
                        alt="Miguel Luis"
                        width={20}
                        height={20}
                      />
                      <i className="fa fa-times" />
                    </span>
                  </div>
                </div> */}



                <div className="submit-section m-t-20 text-center">
                  {loading ? 'A processar' : <a
                    className="btn btn-primary submit-btn"
                    onClick={submitFormUpdateTarefa}>
                    {t('Save')}
                  </a>}

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Task Modal */}


      <ToastContainer />

    </>
  );
};

export default TaskboardModal;
