import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../../../components/Spinner";

const MissaoFilter = ({ onFilterSubmit }) => {

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao } = AuthUser();

  const [nomeFilter, setNomeFilter] = useState('');
  const [estadoFilter, setEstadoFilter] = useState(-1);

  const [itemFocus, setItemFocus] = useState(false);

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const inputFocus = () => {
    setItemFocus(true);
  };

  const inputBlur = () => {
    setItemFocus(false);
  };

  const handleFilterSubmit = async () => {
    try {

      console.log('consultar');

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append('designacao', nomeFilter);
      formData.append('estado', estadoFilter);

      const response = await http.post('/missao/pesquisar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setLoading(false);

       onFilterSubmit(response.data.data);

    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error('Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setLoading(false);

    }
  };



  return (
    <div className="row filter-row space">
      <div className="col-sm-7 col-md-8">
        <div
          className={`input-block mb-3 form-focus  ${itemFocus ? "focused" : ""
            } `}
        >
          <input
            type="text"
            name="nameFilter"
            className="form-control floating"
            onFocus={inputFocus}
            onBlur={inputBlur}
            value={nomeFilter}
            onChange={(e) => setNomeFilter(e.target.value)}
          />
          <label className="focus-label">{t('Name')}</label>
        </div>
      </div>

      <div className="col-sm-3 col-md-2">
        <div className="input-block form-focus select-focus">
          <select className="form-control" onChange={(e) => setEstadoFilter(e.target.value)}
          >
            <option value="-1">Todos estados</option>
            <option value="0">Desativado</option>
            <option value="1">Ativado</option>
          </select>
          <label className="focus-label">{t('Status')}</label>
        </div>
      </div>

      <div className="col-sm-6 col-md-2">
        <span  className="btn btn-success btn-block w-100" onClick={()=> handleFilterSubmit()}>
          {t('Search')}
        </span>
      </div>

      <ToastContainer />
      
    </div>
  );
};

export default MissaoFilter;
