import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import AuthUser from "../AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../Spinner";

import Swal from "sweetalert2";

const ExperienciaModal = ({ colaborador_id, onSubmitSuccess }) => {
  // eslint-disable-next-line no-unused-vars
  const {
    http,
    url_root,
    user,
    user_permissoes,
    verificarPermissao,
    getMissao,
  } = AuthUser();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [mostrarFormNovo, setMostrar] = useState(false);
  const [colaborador, setColaborador] = useState([]);
  const [registoSelecionado, setRegistoSelecionado] = useState(0);

  const [empresa, setEmpresa] = useState("");
  const [local, setLocal] = useState("");
  const [ocupacao, setOcupacao] = useState("");
  const [desde, setDesde] = useState("");
  const [ate, setAte] = useState("");

  const toggleLoading = (value) => {
    setLoading(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        getUtilizador(colaborador_id);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [colaborador_id]);

  const getUtilizador = async (colaborador_id) => {
    try {
      setLoading(true); // Set loading to true when the request starts

      if (colaborador_id != 0 && colaborador_id != null) {
        const res = await http.get(
          "/utilizador/" + (colaborador_id ? colaborador_id : 0)
        );

        setColaborador(res.data.utilizador);

        onSubmitSuccess(res.data.utilizador);
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const mostrar = () => {
    setMostrar(!mostrarFormNovo);
  };

  const chamarModalEliminar = (idSelecionado) => {
    setRegistoSelecionado(idSelecionado);

    // eslint-disable-next-line no-restricted-globals
    if (confirm("Tem a certeza que pretende eliminar este registo?")) {
      setLoading(true);
      handleEliminar(idSelecionado);
    }
  };

  const handleEliminar = async (idSelecionado) => {
    try {
      const formData = new FormData();
      formData.append("id", idSelecionado);

      const response = await http.post("/experiencias/eliminar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      getUtilizador(colaborador_id);

      // Atualize a lista de acessos após ativar/desativar
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  // REGISTO NOVO

  const submitFormNovo = async () => {
    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {

        toggleLoading(true);

        await registar();

      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else {
          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      console.log("Clicou em submeter");

      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const registar = async () => {
    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {
        const formData = new FormData();
        formData.append("user_id", colaborador_id);
        formData.append("empresa", empresa);
        formData.append("local", local);
        formData.append("ocupacao", ocupacao);
        formData.append("desde", desde);
        formData.append("ate", ate);

        const response = await http.post("/experiencias/register", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.message == "Registo feito com sucesso") {
          
          setEmpresa("");
          setLocal("");
          setOcupacao("");
          setDesde("");
          setAte("");

          mostrar();

          getUtilizador(colaborador_id);
        }
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  // ATUALIZAR INFORMACAO

  const submitUpdateForm = async (id, updatedData) => {
    if (verificarPermissao("UTILIZADORES", "atualizar")) {
      try {
        toggleLoading(true);
        await updateInfo(id, updatedData);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else {
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      });
    }
  };

  const updateInfo = async (id, updatedData) => {
    if (verificarPermissao("UTILIZADORES", "atualizar")) {
      try {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("empresa", updatedData.empresa);
        formData.append("local", updatedData.local);
        formData.append("ocupacao", updatedData.ocupacao);
        formData.append("desde", updatedData.desde);
        formData.append("ate", updatedData.ate);

        const response = await http.post(`/experiencias/update`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.message == "Registo atualizado com sucesso") {
          // Atualizar o estado local com os dados atualizados
          setColaborador((prevState) => ({
            ...prevState,
            experiencias: prevState.experiencias.map((item) =>
              item.id == id ? { ...item, ...updatedData } : item
            ),
          }));

          getUtilizador(colaborador_id);
        }
      } catch (error) {
        console.error(error);
        toast.error(
          "Ocorreu um erro ao atualizar a informação pessoal. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      });
    }
  };

  return (
    <>
      {/* Personal Info Modal */}
      <div
        id="experiencias_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Experências de [{colaborador?.name}]</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              {colaborador?.experiencias?.map((item, index) => (
                <>
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">
                        {t("Experiência Nº : ")} {index + 1}
                        <span
                          className="delete-icon"
                          onClick={() => chamarModalEliminar(item?.id)}
                        >
                          <i className="fa-regular fa-trash-can" />
                        </span>
                      </h3>

                      <form>
                        <div className="row">
                         
                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="text"
                                value={item.empresa?item.empresa:''}
                                onChange={(e) =>
                                  setColaborador((prevState) => {
                                    const updatedInfo = [
                                      ...prevState.experiencias,
                                    ];
                                    updatedInfo[index].empresa =
                                      e.target.value;
                                    return {
                                      ...prevState,
                                      experiencias: updatedInfo,
                                    };
                                  })
                                }
                              />

                              <label className="focus-label">
                                {t("Empresa")}
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="text"
                                value={item.local?item.local:''}
                                onChange={(e) =>
                                  setColaborador((prevState) => {
                                    const updatedInfo = [
                                      ...prevState.experiencias,
                                    ];
                                    updatedInfo[index].local =
                                      e.target.value;
                                    return {
                                      ...prevState,
                                      experiencias: updatedInfo,
                                    };
                                  })
                                }
                              />
                              <label className="focus-label">
                                {t("Local")}
                              </label>
                            </div>
                          </div>


                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="text"
                                value={item.ocupacao?item.ocupacao:''}
                                onChange={(e) =>
                                  setColaborador((prevState) => {
                                    const updatedInfo = [
                                      ...prevState.experiencias,
                                    ];
                                    updatedInfo[index].ocupacao =
                                      e.target.value;
                                    return {
                                      ...prevState,
                                      experiencias: updatedInfo,
                                    };
                                  })
                                }
                              />
                              <label className="focus-label">
                                {t("Ocupação")}
                              </label>
                            </div>
                          </div>


                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="date"
                                value={item.desde?item.desde:''}
                                onChange={(e) =>
                                  setColaborador((prevState) => {
                                    const updatedInfo = [
                                      ...prevState.experiencias,
                                    ];
                                    updatedInfo[index].desde =
                                      e.target.value;
                                    return {
                                      ...prevState,
                                      experiencias: updatedInfo,
                                    };
                                  })
                                }
                              />
                              <label className="focus-label">
                                {t("Desde")}
                              </label>
                            </div>
                          </div>


                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="date"
                                value={item.ate?item.ate:''}
                                onChange={(e) =>
                                  setColaborador((prevState) => {
                                    const updatedInfo = [
                                      ...prevState.experiencias,
                                    ];
                                    updatedInfo[index].ate =
                                      e.target.value;
                                    return {
                                      ...prevState,
                                      experiencias: updatedInfo,
                                    };
                                  })
                                }
                              />
                              <label className="focus-label">
                                {t("Até")}
                              </label>
                            </div>
                          </div>
                        
                        
                       

                          <div className="submit-section">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => submitUpdateForm(item.id, item)}
                            >
                              {t("Atualizar Informação")}
                            </button>
                          </div>

                          <hr className="mt-4"></hr>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              ))}

              <div className="add-more ml-2 mt-3 ">
                <span onClick={mostrar} className="mt-4 btn btn-primary">
                  <i className="fa-solid fa-plus-circle" />
                  {t("Add More")}
                </span>
              </div>

              <div className="card">
                <div className="card-body">
                  {mostrarFormNovo ? (
                    <>
                      {" "}
                      <form>
                        <div className="row mt-4">
                         
                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <div>
                                <input
                                  className="form-control"
                                  type="text"
                                  onChange={(e) =>
                                    setEmpresa(e.target.value)
                                  }
                                />
                                <label className="focus-label">
                                  {t("Empresa")}
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="text"
                                onChange={(e) =>
                                  setLocal(e.target.value)
                                }
                              />
                              <label className="focus-label">
                                {t("Local")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="text"
                                onChange={(e) =>
                                  setOcupacao(e.target.value)
                                }
                              />
                              <label className="focus-label">
                                {t("Ocupação")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>


                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="date"
                                onChange={(e) =>
                                  setDesde(e.target.value)
                                }
                              />
                              <label className="focus-label">
                                {t("Desde")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>


                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="date"
                                onChange={(e) =>
                                  setAte(e.target.value)
                                }
                              />
                              <label className="focus-label">
                                {t("Até")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>
                        
                        
                         
                          <div className="submit-section">
                            <a
                              className="btn btn-primary submit-btn"
                              onClick={submitFormNovo}
                            >
                              {loading ? "Carregando" : t("Submit")}
                            </a>
                          </div>

                          <hr className="mt-4"></hr>
                        </div>
                      </form>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Personal Info Modal */}

      <ToastContainer />

      {loading ? (
        <>
          <Spinner></Spinner>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ExperienciaModal;
