import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import AuthUser from "../../AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../Spinner";

import { Link, useHistory } from 'react-router-dom';

import Swal from "sweetalert2";

const EditUserModal = ({ onSubmissionSuccess, utilizador, nivelParam, generoParam, accoes, cargos, departamentos, missoes, imagePreview }) => {

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao, user, getMissao } = AuthUser();

  const [imagem, setSelectedFile] = useState(null);
  const [imagemPreview, setimagemPreview] = useState(imagePreview);

  const [cargo_id, setCargoID] = useState(-1);
  const [departamento_id, setDepartamentoID] = useState(-1);
  const [missao_id, setMissaoID] = useState(-1);

  const [nivelSelecionado, setNivelSelecionado] = useState(1);
  const [objectUtilizador, setUtilizador] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);

  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const [loading, setLoading] = useState(false);
  const [niveis, setNiveis] = useState([]);
  const [tipo, setTipoSelecionado] = useState(1);
  const [genero, setGenero] = useState(1);

  const [inputs, setInputs] = useState([]);

  const [permissoes_lista, setPermissoes] = useState([]);
  const [accoes_lista, setAccoes] = useState([]);

  const getNiveis = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/niveis/'+getMissao()?.id);

      console.log("Carregado...");
      console.log(res.data);

      setNiveis(res.data.niveis);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleSelectAcessoChange = (id) => {

    setNivelSelecionado(id);

    // Fetch new permissions after navigation
    getPermissoes(id);

  };

  const handleSelectGeneroChange = (id) => {

    setGenero(id);

  };


  const handleSelectTipoChange = (id) => {

    setTipoSelecionado(id);

  };

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
      nivel_id: name === 'nivel_id' ? value : prevInputs.nivel_id,
    }));
  };

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event('click'));
    }
  };


  useEffect(() => {

    //Para utilizador normal
    setAccoes(accoes);

    const fetchData = async () => {
      try {

        //console.log('Fetching niveis...');
        await getNiveis();
        console.log('Niveis:', niveis);

        setInputs(utilizador);

        setNivelSelecionado(nivelParam);
        handleSelectGeneroChange(generoParam);

        getPermissoes(utilizador.nivel_id);

        setCargoID(utilizador.cargo_id);
        setDepartamentoID(utilizador.departamento_id);
        setMissaoID(utilizador.missao_id);
        setTipoSelecionado(utilizador.tipo);

        setimagemPreview(imagePreview);


      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

  }, [utilizador]);

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const validateEmail = (email) => {
    // Use a regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };


  const submitFormUpdate = async () => {

    if (verificarPermissao('UTILIZADORES', 'atualizar')) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (inputs.name.length < 2) {
          setError('O nome deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O nome deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        if (!validateEmail(inputs.email)) {
          setError('O email deve ser válido.');
          toggleLoading(false);

          // Show a error toast
          toast.error('O email deve ser válido.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }


        // Basic form validation
        if (cargo_id == -1) {
          setError('deve definir um cargo para o utilizador.');
          setLoading(false);

          // Show a error toast
          toast.error('deve definir um cargo para o utilizador.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });

          return;
        }


        if (departamento_id == -1) {
          setError('deve definir um departamento para o utilizador.');
          setLoading(false);

          // Show a error toast
          toast.error('deve definir um departamento para o utilizador.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });

          return;
        }


        if (missao_id == -1) {
          setError('deve definir uma missão para o utilizador.');
          setLoading(false);

          // Show a error toast
          toast.error('deve definir uma missão para o utilizador.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });

          return;
        }



        await updateUtilizador();


        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {
          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  const updateUtilizador = async () => {
    try {
      const formData = new FormData();
      formData.append('id', utilizador?.id);
      formData.append('name', inputs.name);
      formData.append('email', inputs.email);
      formData.append('nivel_id', nivelSelecionado?nivelSelecionado:0);
      formData.append('tipo', tipo);
      formData.append('nif', inputs.nif);
      formData.append('telefone', inputs.telefone);
      formData.append('data_nasc', inputs.data_nasc);
      formData.append('genero', genero);
      formData.append('nivel_academico', inputs.nivel_academico);
      formData.append('endereco', inputs.endereco);
      formData.append("seg_social", inputs?.seg_social);
      formData.append('fotografia', imagem);

      formData.append('cargo_id', cargo_id);
      formData.append('departamento_id', departamento_id);
      formData.append('missao_id', missao_id);
      formData.append('nif_expira', inputs?.nif_expira?inputs?.nif_expira:'2020-08-01');
      formData.append('salario_coletivo', inputs?.salario_coletivo);
      formData.append('salario', inputs?.salario?inputs?.salario:0);
      formData.append('admissao', inputs?.admissao?inputs?.admissao:'2020-08-01');
      formData.append('fim_contrato', inputs?.fim_contrato?inputs?.fim_contrato:'2020-08-01');
      formData.append('expatriado', inputs?.expatriado?inputs?.expatriado:0);
      formData.append('inss', inputs?.inss?inputs?.inss:1);
      formData.append('irt', inputs?.irt?inputs?.irt:1);

      const response = await http.post('/update', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {

          triggerCloseButtonClick('closeButton20');

          onSubmissionSuccess();

        }
      });

    } catch (error) {
      console.error(error);

      toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };


  /* permissoes */
  const getPermissoes = async (nivelSelecionado) => {

    try {

      setLoading(true);

      const formData = new FormData();
      formData.append('nivel_id', nivelSelecionado);

      const res = await http.post('/permissoes', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setPermissoes(res.data.permissoes);

      console.log('Permissões :');
      console.log(res.data.permissoes);

    } catch (error) {
      console.error('Ocorreu um erro ao carregar os permissões:', error);
    } finally {
      setLoading(false);
    }

  };

  const handleCheckboxChange = async (nivelId, acaoId, action) => {

    if (verificarPermissao('ACESSO', 'atualizar')) {
      try {

        setLoading(true);

        const formData = new FormData();
        formData.append('nivel_id', nivelId);
        formData.append('accao_id', acaoId);
        formData.append('tipo', action);

        // Set the value based on the current state (toggle)
        const valor = permissoes_lista.some(obj => obj.nivel_id === nivelId && obj.accao_id === acaoId && obj[action] === 1) ? 0 : 1;
        formData.append('value', valor);

        console.log(nivelId, acaoId, action, valor);

        const res = await http.post('/update-permissao', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        console.log(res);

        if (res) {
          setPermissoes(res.data.permissoes);
        }

      } catch (error) {

        // Show a success toast
        toast.error('Erro ao atualizar permissão:', error, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });

      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleSelectRow = (index, accao_id) => {
    // Recupera o estado atual do checkbox "Selecionar tudo" da linha
    const selectAllChecked = document.querySelector(`.chk_selecionar_linhas_${index}`).checked;

    console.log(accao_id);

    // Atualiza o estado de todos os checkboxes da linha com base no estado do "Selecionar tudo"
    document.querySelectorAll(`.chk_linha_permissao_${index}`).forEach((checkbox) => {
      checkbox.checked = selectAllChecked;
      // Chame a função handleCheckboxChange para atualizar o estado no back-end, se necessário
      // Substitua os valores de acesso_id e cdata.id pelos valores corretos
    });

    const valor = selectAllChecked ? 1 : 0;

    console.log('Valor todos :');
    console.log(valor);

    handleAtivarDesativarLinha(nivelSelecionado, accao_id, valor);
  };

  const handleAtivarDesativarLinha = async (acesso_id, accao_id, valor) => {

    if (verificarPermissao('ACESSO', 'atualizar')) {
      try {

        setLoading(true);

        const formData = new FormData();
        formData.append('nivel_id', acesso_id);
        formData.append('accao_id', accao_id);
        formData.append('valor', valor);


        console.log(acesso_id, accao_id, valor);

        const res = await http.post('/update-permissao-multipla', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        console.log(res);

        if (res) {
          setPermissoes(res.data.permissoes);
        }

      } catch (error) {

        // Show a success toast
        toast.error('Erro ao atualizar permissão:', error, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });

      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  }


  const handleSelectedAll = async () => {

    if (verificarPermissao('ACESSO', 'atualizar')) {

      setSelectAllChecked(!selectAllChecked);

      const checkboxes = document.querySelectorAll('.chk_selecionar_linhas');

      try {
        setLoading(true);

        for (let i = 0; i < checkboxes.length; i++) {
          const checkbox = checkboxes[i];

          // Defina o valor para todos os checkboxes (verificar ou desmarcar)
          checkbox.checked = !selectAllChecked;

          const accao_id = checkbox.getAttribute('code');
          const valor = !selectAllChecked ? 1 : 0;

          const formData = new FormData();
          formData.append('nivel_id', utilizador?.nivel?.id);
          formData.append('accao_id', accao_id);
          formData.append('valor', valor);

          const res = await http.post('/update-permissao-multipla', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          // Atualize o estado apenas com a última resposta
          if (i === checkboxes.length - 1) {
            const ultima_permissao = res.data.permissoes;
            setPermissoes(ultima_permissao);
          }
        }
      } catch (error) {
        // Mostrar um toast de erro
        toast.error('Erro ao atualizar permissão:', error, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  /* fim get permissões */



  const renderOptionCargos = (cargos) => {
    return cargos?.map((cargo) => (
      <React.Fragment key={cargo.id}>
        <option value={cargo.id}>{cargo.designacao}</option>
      </React.Fragment>
    ));
  };

  const renderOptionDepartamentos = (departamentos) => {
    return departamentos?.map((departamento) => (
      <React.Fragment key={departamento.id}>
        <option value={departamento.id}>{departamento.designacao}</option>
      </React.Fragment>
    ));
  };

  const renderOptionMissoes = (missoes) => {
    return missoes?.map((missao) => (
      <React.Fragment key={missao.id}>
        <option value={missao.id}>{missao.designacao}</option>
      </React.Fragment>
    ));
  };

  const handleFileChange = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    //document.getElementById('imagem').src = newFileURL;

    setimagemPreview(newFileURL);

    console.log('New image url 1:');
    console.log(newFileURL);
    // Restante do código
  };


  return (
    <div id="edit_user" className="modal custom-modal fade" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{t('Edit User')}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeButton20"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">

                <div className="col-sm-12">
                  <div className="input-block mb-3">
                    <div className="profile-img-wrap edit-img">
                      <img
                        className="inline-block"
                        src={imagemPreview}
                        alt={utilizador?.name}
                      />
                      <div className="fileupload btn">
                        <span className="btn-text">edit</span>
                        <input className="upload" type="file" onChange={handleFileChange} />
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-sm-8">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      {t('Name')} <span className="text-danger">*</span>
                    </label>
                    <input className="form-control" type="text" name="name" id="nome" value={inputs.name} onChange={handleInputsValueChanged} />
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      {t('Gender')} <span className="text-danger">*</span>
                    </label>
                    <select className="form-control select" onChange={e => handleSelectGeneroChange(e.target.value)} value={genero} >
                      <option value="1" selected={inputs.genero == 1}>
                        Feminino
                      </option>
                      <option value="2" selected={inputs.genero == 2}>
                        Masculino
                      </option>
                    </select>
                  </div>
                </div>



                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input className="form-control" id="email" name="email" type="email" value={inputs.email} onChange={handleInputsValueChanged} />
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      {t('phone')} <span className="text-danger">*</span>
                    </label>
                    <input className="form-control" id="telefone" name="telefone" type="text" value={inputs.telefone} onChange={handleInputsValueChanged} />
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">{t('Birthday')} </label>
                    <input className="form-control" type="date" name="data_nasc" value={inputs.data_nasc} onChange={handleInputsValueChanged} />
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">{t('Role')} </label>
                    <select onChange={(e) => handleSelectAcessoChange(e.target.value)}
                      value={nivelSelecionado} className="form-control select">
                      {niveis.map((cdata) => (
                        <option key={cdata.id} value={cdata.id}>
                          {cdata.designacao}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>


                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">{t('Job Role')}</label>
                    <select
                      onChange={(e) => setCargoID(e.target.value)}
                      value={cargo_id}
                      className="form-control select"
                    >
                      <option key={0} value={-1}></option>
                      {renderOptionCargos(cargos)}
                    </select>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">{t('Department')}</label>
                    <select
                      onChange={(e) => setDepartamentoID(e.target.value)}
                      value={departamento_id}
                      className="form-control select"
                    >
                      <option key={0} value={-1}></option>
                      {renderOptionDepartamentos(departamentos)}
                    </select>
                  </div>
                </div>

                <div className="col-sm-8">
                  <div className="input-block mb-3">
                    <label className="col-form-label">{t('Mission')}</label>
                    <select
                      onChange={(e) => setMissaoID(e.target.value)}
                      value={missao_id}
                      className="form-control select"
                    >
                      <option key={0} value={-1}></option>
                      {renderOptionMissoes(missoes)}
                    </select>
                  </div>
                </div>

                { (( user.tipo == 1) || ( user.tipo == 2 && inputs.tipo > 1) ) ?
                <div className="col-sm-4">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      {t('User Type')} <span className="text-danger">*</span>
                    </label>
                    <select className="form-control select" onChange={e => handleSelectTipoChange(e.target.value)} value={tipo} >


                      {user.tipo == 1 ?
                      <option value="1" selected={inputs.tipo == 1}>
                        Super Utilizador
                      </option> : ''
                      }

                      <option value="2" selected={inputs.tipo == 2}>
                        Admininistrador
                      </option>
                      <option value="3" selected={inputs.tipo == 3} >
                        Colaborador
                      </option>
                    </select>
                  </div>
                </div>:''}

                <div className="col-sm-12">
                  <div className="input-block mb-3 mb-3 form-focus focused">
                    <label className="focus-label">
                      {t("Nº Segurança Social")}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="seg_social"
                      value={inputs.seg_social}
                      onChange={handleInputsValueChanged}
                    />
                  </div>
                </div>


                <div className="col-sm-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">{t('Degree')} </label>
                    <input className="form-control" type="text" name="nivel_academico" value={inputs.nivel_academico} onChange={handleInputsValueChanged} />
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      {t('Adress')} <span className="text-danger">*</span>
                    </label>
                    <input className="form-control" name="endereco" value={inputs.endereco} onChange={handleInputsValueChanged} />
                  </div>
                </div>

                <div className="submit-section mb-5">
                  <a
                    className="btn btn-primary submit-btn"
                    onClick={submitFormUpdate}>
                    {loading ? 'Carregando' : t('Save')}
                  </a>
                </div>


              </div>
              <div className="table-responsive m-t-15">

                <label className="inputcheck">
                  {nivelSelecionado?.designacao}
                </label>

                &nbsp;

                <label className="inputcheck">
                  <input type="checkbox" id="select-all" onChange={() => handleSelectedAll()} checked={selectAllChecked} />
                  &nbsp; Selecionar tudo
                </label>

                <table className="table table-striped custom-table">
                  <thead>
                    <tr>
                      <th>{t('Module Permission')}</th>
                      <th className="text-center">{t('Read')}</th>
                      <th className="text-center">{t('Write')}</th>
                      <th className="text-center">{t('Create')}</th>
                      <th className="text-center">{t('Delete')}</th>
                      <th className="text-center">{t('Active')}</th>
                      <th className="text-center">{t('All')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {

                      accoes_lista?.map((cdata, index) => {

                        // Find the corresponding object in your array
                        const matchingObject = permissoes_lista?.find(obj => {
                          return obj.accao_id == cdata.id && obj.nivel_id == nivelSelecionado;
                        });

                        return (
                          <tr key={index}>
                            <td>{cdata.designacao}</td>

                            <td className="text-center">
                              <label className="custom_check">
                                <input key={cdata.id + 'ver'} type="checkbox" checked={matchingObject && matchingObject.ver == 1} onChange={() => handleCheckboxChange(nivelSelecionado, cdata.id, 'ver')} className={'chk_linha_permissao_' + index} />
                                <span className="checkmark" />
                              </label>
                            </td>


                            <td className="text-center">
                              <label className="custom_check">
                                <input key={cdata.id + 'atualizar'} type="checkbox" checked={matchingObject && matchingObject.atualizar == 1} onChange={() => handleCheckboxChange(nivelSelecionado, cdata.id, 'atualizar')} className={'chk_linha_permissao_' + index} />
                                <span className="checkmark" />
                              </label>
                            </td>


                            <td className="text-center">
                              <label className="custom_check">
                                <input key={cdata.id + 'adicionar'} type="checkbox" checked={matchingObject && matchingObject.adicionar == 1} onChange={() => handleCheckboxChange(nivelSelecionado, cdata.id, 'adicionar')} className={'chk_linha_permissao_' + index} />
                                <span className="checkmark" />
                              </label>
                            </td>


                            <td className="text-center">
                              <label className="custom_check">
                                <input key={cdata.id + 'remover'} type="checkbox" checked={matchingObject && matchingObject.remover == 1} onChange={() => handleCheckboxChange(nivelSelecionado, cdata.id, 'remover')} className={'chk_linha_permissao_' + index} />
                                <span className="checkmark" />
                              </label>
                            </td>

                            <td className="text-center">
                              <label className="custom_check">
                                <input key={cdata.id + 'ativar'} type="checkbox" checked={matchingObject && matchingObject.ativar == 1} onChange={() => handleCheckboxChange(nivelSelecionado, cdata.id, 'ativar')} className={'chk_linha_permissao_' + index} />
                                <span className="checkmark" />
                              </label>
                            </td>

                            <td className="text-center">
                              <label className="custom_check">
                                <input type="checkbox" checked={matchingObject && matchingObject.ver == 1 && matchingObject.adicionar && matchingObject.atualizar && matchingObject.ativar && matchingObject.remover} key={cdata.id + 'select'} onChange={() => handleSelectRow(index, cdata.id)} className={'chk_selecionar_linhas_' + index + ' chk_selecionar_linhas'} code={cdata.id} />
                                <span className="checkmark" />
                              </label>
                            </td>


                          </tr>)


                      })}
                  </tbody>
                </table>


              </div>

            </form>
          </div>
        </div>
      </div>

      <ToastContainer />

    </div>
  );
};

export default EditUserModal;
