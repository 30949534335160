import React, { useState, useEffect } from "react";

import Breadcrumbs from "../../../components/Breadcrumbs";

import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";

import AuthUser from "../../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../components/Spinner";
import PostoModal from "../../../components/Administration/comercial/PostoModal";
import PostoFilter from "../Administration/comercial/PostoFilter";
import PostoTable from "../Administration/comercial/PostoTable";
import ClienteVinculoModal from "../../../components/Administration/comercial/ClienteVinculoModal";

const Posto = () => {
  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } =
    AuthUser();
  const [postos, setpostos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [zonas, setZonas] = useState([]);

  const [vinculos, setVinculos] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [postoselecionado, setPostoSelecionado] = useState([]);

  const [filteredpostos, setfilteredpostos] = useState([]);

  const { t } = useTranslation();

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  // Callback function to receive filtered users from UserFilter
  const handleFilterSubmit = (filteredpostos) => {
    console.log("received:");
    console.log(filteredpostos);

    setfilteredpostos(filteredpostos);
  };

  const handleSubmissionSuccess = (postos) => {
    // Refresh acessos state or trigger any necessary actions
    setpostos(postos);
  };

  const getpostos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/postos/" + getMissao()?.id);
      setpostos(res.data.postos);
      setfilteredpostos(res.data.postos);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getClientes = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts
      const res = await http.get("/clientes/" + getMissao()?.id);
      setClientes(res.data.clientes);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getZonas = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts
      const res = await http.get("/zonas/" + getMissao()?.id);
      setZonas(res.data.zonas);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Chama a função assíncrona getUtilizador()
        await getpostos();
        await getClientes();
        await getZonas();
        await getColaboradores();
      } catch (error) {
        console.error("Erro ao obter postos:", error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, []);

  const verVinculos = async (cliente, vinculosParam) => {
    try {
      setPostoSelecionado(cliente);
      setVinculos(vinculosParam);

      const editButton = document.getElementById(
        "triggerModalEditClienteVinculo"
      );

      if (editButton) {
        // Click the button to trigger the modal
        editButton.click();
      }
    } catch (error) {
      console.error("Erro ao obter vínculos:", error);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getColaboradores = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/utilizadores/ativados/" + getMissao()?.id);

      setColaboradores(res.data.utilizadores);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  if (verificarPermissao("CARGO", "ver"))
    if (loading)
      return (
        <>
          <Spinner></Spinner>
        </>
      );
    else
      return (
        <>
          (
          <>
            <div className="page-wrapper">
              <div className="content container-fluid">
                <Breadcrumbs
                  maintitle={t("Posto Cliente")}
                  title="Dashboard"
                  subtitle={t("Postos Dos Nossos clientes")}
                  modal="#add_posto_modal"
                  name={t("Add Posto")}
                />

                <PostoFilter
                  onFilterSubmit={handleFilterSubmit}
                  clientes={clientes}
                  zonas={zonas}
                />

                <PostoTable
                  postos={filteredpostos}
                  clientes={clientes}
                  zonas={zonas}
                  onVerVinculos={verVinculos}
                />

                <ClienteVinculoModal
                  cliente={postoselecionado}
                  vinculos={vinculos}
                  colaboradores={colaboradores}
                />

                <span
                  data-bs-toggle="modal"
                  data-bs-target="#cliente_colaborador_modal"
                  id="triggerModalEditClienteVinculo"
                ></span>
              </div>
            </div>{" "}
          </>
          );{" "}
        </>
      );
  else
    return (
      <>
        <div className="page-wrapper cardhead">
          <div className="content ">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">SEM PERMISSÃO</h3>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      <i className="fa fa-times-circle text-danger"></i>&nbsp;
                      OPERAÇÃO NÃO AUTORIZADA
                    </h4>
                  </div>
                  <div className="card-body">
                    <p>
                      Esta operação não é autorizada para o seu nivel de acesso
                      ao sistema, por favor contacte o administrador do sistema.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default Posto;
