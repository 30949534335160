import React, { useState, useEffect } from "react";
import { Table } from "antd";
import {
  User1,
  User2,
  User3,
  User4,
  User5,
  User6,
  User7,
  User8,
  User9,
  User10,
  User11,
  User12,
} from "../../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import CargoModal from "../../../../components/Administration/cargos/CargoModal";
import DeleteModal from "../../../../components/modelpopup/DeleteModal";

import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";

import AuthUser from "../../../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../../components/Spinner";
import SubsidioDoCargoModal from "../../../../components/Administration/Users/SubsidioDoCargoModal";

const CargoTable = ({ onLerVinculos, cargos, subsidios }) => {
  console.log("onVerVinculos:", onLerVinculos);

  const { t } = useTranslation();

  const [cargo, setCargo] = useState(cargos);
  const [cargoSelecionado, setCargoSelecionado] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } =
    AuthUser();

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [selectedUserIds, setSelectedUserIds] = useState([]);

  useEffect(() => {
    setCargo(cargos);
  }, [cargos]);

  const handleSubmissionSuccess = (cargos) => {
    setCargo(cargos);
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat("pt-PT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const chamarModalEliminar = (idSelecionado) => {
    setCargoSelecionado(idSelecionado);

    const editButton = document.getElementById("triggerModalEliminar");

    console.log("cargo selecionado:");
    console.log(cargoSelecionado);

    if (editButton) {
      editButton.click();
    }
  };

  const chamarModalEdit = (idSelecionado) => {
    setCargoSelecionado(idSelecionado);

    const editButton = document.getElementById("triggerModalEdit");

    console.log("Cargo selecionado:");
    console.log(cargoSelecionado);

    if (editButton) {
      editButton.click();
    }
  };

  const getCargo = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/funcaos/" + getMissao()?.id);

      setCargo(res.data.funcaos);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleAtivarDesativar = async (id, newState) => {
    //console.log('Clicou aqui para ativar e desativar');

    if (verificarPermissao("CARGO", "ativar")) {
      try {
        setLoading(true);

        const formData = new FormData();
        formData.append("id", id);
        formData.append("estado", newState);

        const response = await http.post("/funcao/activate", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        setLoading(false);

        setCargo(response.data.data);

        // Atualize a lista de utilizadores após ativar/desativar
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const chamarOnVerVinculos = async (idSelecionado) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("id", idSelecionado?.id);

    const response = await http.post("/cargo/vinculos", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    setLoading(false);

    onLerVinculos(idSelecionado, response?.data?.colaboradores);
  };

  const chamarModalSubsidiosCargo = (idSelecionado) => {

    setCargoSelecionado(idSelecionado);

    const editButton = document.getElementById("chamar_modal_subsidios_cargo");

    if (editButton) {
      editButton.click();
    }
  };

  const columns = [
    {
      title: t("Name"),
      dataIndex: "designacao",
      render: (text, record) => <div className="table-avatar">{text}</div>,
      sorter: (a, b) => a.designacao.length - b.designacao.length,
    },
    {
      title: "Colaboradores",
      dataIndex: "colaborador",
      render: (text, record) => (
        <>
          <span onClick={() => chamarOnVerVinculos(record)}>
            <i className="fa fa-user-tie"></i>
            {" [ "}
            {record?.colaboradores?.length} {" ] "}
          </span>
        </>
      ),
      sorter: (a, b) => a.estado.length - b.estado.length,
    },
    {
      title: "Sálario",
      dataIndex: "salario",
      render: (text, record) => (
        <>
          <span title={'Ver de subsidios do cargo : '+record?.designacao} onClick={() => chamarModalSubsidiosCargo(record)} >{formatNumber(text)}</span>
        </>
      ),
      sorter: (a, b) => a.salario.length - b.salario.length,
    },
    {
      title: "Descrição",
      dataIndex: "descricao",
      render: (text, record) => (
        <>
          <span title={text}>{text?.substring(0, 50)}</span>
        </>
      ),
      sorter: (a, b) => a.descricao.length - b.descricao.length,
    },
    {
      title: t("Status"),
      dataIndex: "estado",
      render: (text, record) => (
        <>
          <span
            className={
              text == 1 ? "badge bg-inverse-success" : "badge bg-inverse-danger"
            }
          >
            {text == 1 ? "Ativo" : "Desativado"}
          </span>
          &nbsp;
          <span
            onClick={() => handleAtivarDesativar(record.id, text == 1 ? 0 : 1)}
          >
            <i className="fa fa-recycle"></i>
          </span>
        </>
      ),
      sorter: (a, b) => a.nivel_id.length - b.nivel_id.length,
    },

    {
      title: t("Action"),
      render: (record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <span
              className="dropdown-item"
              title={record?.name}
              onClick={() => chamarModalEdit(record)}
            >
              <i className="fa fa-pencil m-r-5" /> {t("Edit")}
            </span>
            <span
              className="dropdown-item"
              title={record?.nome}
              onClick={() => chamarModalSubsidiosCargo(record)}
            >
              <i className="fa fa-arrow-up m-r-5" /> {t("Subsidios")}
            </span>
            <span
              className="dropdown-item"
              title={record?.nome}
              onClick={() => chamarOnVerVinculos(record)}
            >
              <i className="fa fa-link m-r-5" /> {t("Vinculos")}
            </span>
            <span
              className="dropdown-item"
              title={record?.designacao}
              onClick={() => chamarModalEliminar(record)}
            >
              <i className="fa fa-trash m-r-5" /> {t("Delete")}
            </span>
          </div>
        </div>
      ),
    },
  ];

  if (loading)
    return (
      <>
        <Spinner>
          <ToastContainer />
        </Spinner>
      </>
    );
  else
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <Table
                className="table-striped"
                style={{ overflowX: "auto" }}
                columns={columns}
                dataSource={cargo}
                rowKey={(record) => record.id}
              />
              <CargoModal
                onSubmissionSuccess={handleSubmissionSuccess}
                cargo={cargoSelecionado}
              />
              <DeleteModal
                objectoName={t("Job Role")}
                onSubmissionSuccess={handleSubmissionSuccess}
                id={cargoSelecionado?.id}
                endpoint={"funcao"}
              />

              <SubsidioDoCargoModal subsidios={subsidios} cargo={cargoSelecionado} />

              <span
                data-bs-toggle="modal"
                data-bs-target="#add_subsidio_cargo_modal"
                id="chamar_modal_subsidios_cargo"
              ></span>

              <span
                data-bs-toggle="modal"
                data-bs-target="#edit_cargo_modal"
                id="triggerModalEdit"
              ></span>

              <span
                data-bs-toggle="modal"
                data-bs-target="#delete"
                id="triggerModalEliminar"
              ></span>
            </div>
          </div>
        </div>

        <ToastContainer />
      </>
    );
};

export default CargoTable;
