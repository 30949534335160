import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Avatar_16,
  Avatar_02,
  Avatar_05,
  Avatar_09,
  Avatar_10,
} from "../../../Routes/ImagePath/index";

import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import React, { useState, useEffect } from "react";

import AuthUser from "../../AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../Spinner";

import Swal from "sweetalert2";

const BancoModal = ({ onSubmissionSuccess, banco }) => {

  const { t } = useTranslation(); 

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user, user_permissoes, verificarPermissao, getMissao } = AuthUser();
  const [nome, setName] = useState('');
  const [descricao, setDescricao] = useState('');
  const [sigla, setSigla] = useState();
  const [prazo, setPrazo] = useState();
  const [estado, setEstado] = useState();

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState(banco);

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event('click'));
    }
  };

  useEffect(() => {

    const fetchData = async () => {
      try {

        setInputs(banco);
        setimagemPreview(url_root+"/storage/"+banco?.logo);

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

  }, [banco]);


  //registar tarefa

  const submitFormBanco = async () => {

    if (verificarPermissao('CARGO', 'adicionar')) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (nome.length < 2) {
          setError('O nome deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O nome deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }


        await uploadBanco();


      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {
          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }

    } else {

      console.log('Clicou em submeter');

      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const uploadBanco = async () => {
    if (verificarPermissao('CARGO', 'adicionar')) {
      try {

        const formData = new FormData();
        formData.append('nome', nome);
        formData.append('descricao', descricao);
        formData.append('sigla', sigla);
        formData.append('estado', 0);
        formData.append('missao_id', getMissao()?.id);

        const response = await http.post('/banco/register', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        console.log(response);

        // Show a success toast
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {

            setName('');

            triggerCloseButtonClick('closeButton30');

            console.log('to send : ');
            console.log(response.data.data);

            onSubmissionSuccess(response.data.data);

          },
        });

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  //Update tarefa

  const submitFormUpdate = async () => {

    if (verificarPermissao('CARGO', 'atualizar')) {
      try {

        toggleLoading(true);

        // Basic form validation
        if (inputs?.nome.length < 2) {
          setError('O nome deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O nome deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 1000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        await updateBanco();

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {
          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const updateBanco = async () => {
    try {

      const formData = new FormData();
      formData.append('id', inputs?.id);
      formData.append('nome', inputs?.nome);
      formData.append('logo', imagem);
      formData.append('descricao', inputs?.descricao);
      formData.append('sigla', inputs?.sigla?inputs?.sigla:'NENHUMA');

      const response = await http.post('/banco/update', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });     

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {

          triggerCloseButtonClick('closeButton40');

          console.log('to send : ');
          onSubmissionSuccess(response.data.data);


        }
      });

    } catch (error) {
      console.error(error);

      toast.error('Ocorreu um erro ao carregar o bancos. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  /* Imagem do logotipo */  

  const [imagem, setSelectedFile] = useState(null);
  const [imagemPreview, setimagemPreview] = useState();

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    //document.getElementById('imagem').src = newFileURL;

    setimagemPreview(newFileURL);

    console.log("New image url 1:");
    console.log(newFileURL);
    // Restante do código
  };

  return (
    <>

      {/* Add Task Modal */}
      <div
        id="add_banco_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{t('Add Banco')}</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="closeButton30"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <form>
              <div className="input-block mb-3 form-focus focused">                
                  <label className="col-form-label focus-label">{t('Nome Do Banco')}</label>
                  <input type="text" className="form-control" value={nome} onChange={e => setName(e.target.value)} />
                </div>

                <div className="input-block mb-3 form-focus focused">  
                  <label className="col-form-label focus-label">{t('Sigla')}</label>
                  <input type="text" className="form-control" value={sigla} onChange={e => setSigla(e.target.value)} />
                </div>

                <div className="input-block mb-3 form-focus focused"> 
                  <label className="col-form-label focus-label">{t('Descrição')}</label>
                  <div className="">
                    <textarea className="form-control" value={descricao} onChange={e => setDescricao(e.target.value)}></textarea>
                  </div>
                </div>

                <div className="submit-section m-t-20 text-center">
                  {loading ? 'A processar' : <a
                    className="btn btn-primary submit-btn"
                    onClick={submitFormBanco}>
                    {t('Submit')}
                  </a>}

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Task Modal */}


      {/* Edit Task Modal */}
      <div
        id="edit_banco_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{t('Editar Banco')}</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="closeButton40"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <form>


              <div className="col-sm-12">
                  <div className="input-block mb-3 form-focus focused mb-4">
                    <div className="profile-img-wrap edit-img">
                      <img
                        className="inline-block"
                        src={imagemPreview}
                        alt={banco?.logo}
                      />
                      <div className="fileupload btn">
                        <span className="btn-text">editar</span>
                        <input
                          className="upload"
                          type="file"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

          

                <div className="input-block mb-3 mt-5 form-focus focused"> 
                  <label className="col-form-label focus-label">{t('Nome')}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="nome" value={inputs?.nome} onChange={e => handleInputsValueChanged(e)} />
                </div>

                <div className="input-block mb-3 form-focus focused"> 
                  <label className="col-form-label focus-label">{t('Sigla')}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="sigla" value={inputs?.sigla} onChange={e => handleInputsValueChanged(e)} />
                </div>

                <div className="input-block mb-3 form-focus focused"> 
                  <label className="col-form-label focus-label">{t('Descrição')}</label>
                  <div className="">
                    <textarea name="descricao" className="form-control" value={inputs?.descricao} onChange={e => handleInputsValueChanged(e)}></textarea>
                  </div>
                </div>

                <div className="submit-section m-t-20 text-center">
                  {loading ? 'A processar' : <a
                    className="btn btn-primary submit-btn"
                    onClick={submitFormUpdate}>
                    {t('Save')}
                  </a>}

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Task Modal */}


      <ToastContainer />

    </>
  );
};

export default BancoModal;
