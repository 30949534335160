import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { base_url } from "../../../../../base_urls";
import { t } from "i18next";

import { format } from 'date-fns';

import AuthUser from "../../../../../components/AuthUser";

import Swal from "sweetalert2";

import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../../../../components/Spinner";
import DiretorioFicheiroModal from "../../../../../components/modelpopup/DiretorioFicheiroModal";

const Files = ({ projeto, handleSubmission, onReceberNome, onFileNameEditSubmissionSuccess, onDiretorioSelecionado, arquivos }) => {

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getToken, user, getMissao } = AuthUser();

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);
  const [diretorio, setDiretorio] = useState([]);
  const [projetoSelecionado, setProjetoSelecionado] = useState(projeto);
  const [arquivosSelecionados, setArquivos] = useState(arquivos);
  const [descricao, setDescricao] = useState([]);

  useEffect(() => {
    //axios.get(base_url + "/api/files.json").then((res) => setValues(res.data));
    setProjetoSelecionado(projeto);
    setArquivos(arquivos);
  }, [arquivos]);

  const getProjeto = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/projeto/' + projeto?.id);

      setProjetoSelecionado(res.data?.projeto);
      setArquivos(res.data?.projeto?.arquivos);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getArquivos = async (para_id) => {
    try {

      setLoading(true);

      console.log('para_id',para_id)

      const formData = new FormData();
      formData.append('para_id', para_id);

      const response = await http.post('/arquivos/'+getMissao()?.id, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setArquivos(response.data?.arquivos);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const removerArquivoProjeto = async (arquivo) => {

    Swal.fire({
      title: "Remover arquivo?",
      text: "Tem a certeza que pretende remover este arquivo do projeto?",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(async function (t) {

      if (t.isConfirmed) {
        try {

          setLoading(true);

          setDiretorio(arquivo);

          const formData = new FormData();
          formData.append('arquivo_id', arquivo?.id);
          formData.append('projeto_id', projeto?.id);

          const response = await http.post('/projeto/removerArquivo', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          setLoading(false);

          getArquivos(arquivo?.para_id);  

        } catch (error) {

          setLoading(false);
          console.error(error);

          toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }

    });
  };


  const receber_nome = (nome) => {

    onFileNameEditSubmissionSuccess(nome);

  }

  const chamarModalEdit = (nome,accao,id) => {

    onReceberNome(nome,accao,id);

  }

  const chamarModalRename = (arquivo) => {

    setDescricao(arquivo?.descricao);

    enviar_nome_e_ID(arquivo);

    const editButton = document.getElementById('triggerModalEdit');

    if (editButton) {
      editButton.click();
    }

  }

  const enviar_nome_e_ID = (arquivo) => {

    onReceberNome(arquivo);

  }

  const abrir_diretorio = (arquivo) => {

    setDiretorio(arquivo);

    onDiretorioSelecionado(arquivo);

  }


  return (
    <div className="row">
      {arquivosSelecionados?.map((arquivo, index) => (

        <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4" key={index}>
          <div className="card card-file">
            <div className="dropdown-file">
              <Link to="#" className="dropdown-link" data-bs-toggle="dropdown">
                <i className="fa fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-right">

              {arquivo?.tipo != 2 ?
                <a title={arquivo?.ficheiro} className="dropdown-item" href={url_root + '/api/ler_anexo/' + arquivo?.ficheiro} target="_blank" rel="noreferrer">{t('View')}</a>:
                <span title={arquivo?.ficheiro} className="dropdown-item" onClick={() => abrir_diretorio(arquivo)}>
                  {t('View')}
                  </span>}

                {arquivo?.tipo != 2 ?
                  <a title={arquivo?.ficheiro} className="dropdown-item" href={url_root + '/api/ler_anexo/' + arquivo?.ficheiro} target="_blank" rel="noreferrer"> {t('Download')}</a> : ''}


                <span className="dropdown-item" onClick={() => chamarModalEdit(arquivo?.descricao,2,arquivo?.id)}>
                  {t('Rename')}
                </span>

                <span className="dropdown-item" onClick={() => removerArquivoProjeto(arquivo)}>
                  {t('Delete')}
                </span>

              </div>
            </div>
            <div className="card-file-thumb">

              {/* {
                arquivo.tipo_ficheiro != 'media' ? <img src={url_root + "/storage/uploads/icons/" + arquivo?.file_icon} width={20} height={20} /> : <img src={url_root + "/storage/uploads/" + arquivo?.ficheiro} alt={arquivo?.ficheiro} title={arquivo?.ficheiro} className="img-fluid" height={20} />
              } */}

              {arquivo?.tipo != 2 ?
                <img src={url_root + "/storage/uploads/icons/" + arquivo?.file_icon} width={40} height={40} /> :
                <img onDoubleClick={() => abrir_diretorio(arquivo)} src={url_root + "/storage/uploads/icons/" + arquivo?.file_icon} width={60} height={60} />}


              {/* <i className={`fa-regular ${file.icon}`} /> */}

            </div>
            <div className="card-body">
              <h6>
                <a title={arquivo?.ficheiro} className="text-primary" href={url_root + '/api/ler_anexo/' + arquivo?.ficheiro} target="_blank" rel="noreferrer">{arquivo?.descricao}</a>
              </h6>
              {arquivo?.tipo != 2 ?
              <span>{arquivo?.tamanho}Mb</span>:''}
            </div>
            <div className="card-footer">{format(new Date(arquivo?.updated_at), 'dd/MM/yyyy')}</div>
          </div>
        </div>

      ))}


      <div className="col-12">
        {'_________________________________________________________________________________________'}
      </div>


      <ToastContainer />


      {/* <DiretorioFicheiroModal onFileNameSubmissionSuccess={receber_nome} name={descricao} /> */}

      <span
        data-bs-toggle="modal"
        data-bs-target="#add_file"
        id="triggerModalEdit"
      >
      </span>

    </div>
  );
};

export default Files;
