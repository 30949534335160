/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import { Table } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar_01,
  Avatar_02,
  Avatar_05,
  Avatar_09,
  Avatar_10,
  Avatar_11,
  Avatar_12,
  Avatar_13,
  Avatar_16,
  eye,
  laptop,
} from "../../../Routes/ImagePath";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import { format } from 'date-fns';

import Swal from "sweetalert2";

import AuthUser from "../../../components/AuthUser";

import Spinner from "../../../components/Spinner";

import { toast, ToastContainer } from 'react-toastify';
import ProjectModelPopup from "../../../components/modelpopup/ProjectModelPopup";
import TodasAtividadesModalPopup from "../../../components/modelpopup/TodasAtividadesModalPopup";

export const ProjectDetails = ({ projetos, tarefas, utilizadores, missoes, utilizador, onSubmissionSuccess, atividades }) => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getToken, user, getMissao } = AuthUser();

  const [loading, setLoading] = useState(false);

  const [projetoSelecionado, setProjetoSelecionado] = useState();

  const [tarefasFiltradas, setTarefasFiltradas] = useState(tarefas);

  useEffect(() => {

    setTarefasFiltradas(filterTasks(tarefas));

  }, [tarefas]);

  const handleSubmissionSuccessProjeto = () => {
    onSubmissionSuccess();
  };

  const chamarModalEliminar = (idSelecionado) => {

    setProjetoSelecionado(idSelecionado);

    const editButton = document.getElementById('triggerModalEliminar');

    console.log('Projeto selecionado:')
    console.log(projetoSelecionado);

    if (editButton) {
      editButton.click();
    }

  }

  const chamarModalEdit = (idSelecionado) => {

    setProjetoSelecionado(idSelecionado);

    const editButton = document.getElementById('triggerModalEdit');

    console.log('Projeto selecionado:')
    console.log(projetoSelecionado);

    if (editButton) {
      editButton.click();
    }

  }

  const stripHtmlTags = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const aprovarProjeto = async (projeto_id, estado) => {

    if (user?.tipo == 1) {

      Swal.fire({
        title: estado == 1 ? 'Aprovar projeto' : 'Reprovar projeto',
        text: "Tem a certeza que pretende " + (estado == 1 ? 'aprovar' : 'reprovar') + " este projeto ?",
        type: "confirm",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (t) {

        if (t.isConfirmed) {
          try {

            setLoading(true);

            const formData = new FormData();
            formData.append('id', projeto_id);
            formData.append('estado', estado);

            const response = await http.post('/projeto/aprovar', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });

            setLoading(false);
            //Refresh colaboradores list
            handleSubmissionSuccessProjeto();

            // Show a success toast
            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              onClose: () => {

                setLoading(false);
                //Refresh colaboradores list
                //getProjetos();

              }
            });

          } catch (error) {

            setLoading(false);
            console.error(error);

            toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        }

      });

    } else {

      toast.error('Seu utilizador não tem permissão para aprovar ou negar aprovação de projeto', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });

    }




  };

  const arquivarProjeto = async (projeto_id, estado) => {

    if (user?.tipo == 1) {

      Swal.fire({
        title: estado == 2 ? 'Arquivar projeto' : 'Desarquivar projeto',
        text: "Tem a certeza que pretende " + (estado == 2 ? 'arquivar' : 'desarquivar') + " este projeto ?",
        type: "confirm",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (t) {

        if (t.isConfirmed) {
          try {

            setLoading(true);

            const formData = new FormData();
            formData.append('id', projeto_id);
            formData.append('estado', estado);

            const response = await http.post('/projeto/arquivar', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });

            setLoading(false);
            //Refresh colaboradores list
            handleSubmissionSuccessProjeto();

            // Show a success toast
            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              onClose: () => {

                setLoading(false);
                //Refresh colaboradores list
                //getProjetos();

              }
            });

          } catch (error) {

            setLoading(false);
            console.error(error);

            toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        }

      });

    } else {

      toast.error('Seu utilizador não tem permissão para aprovar ou negar aprovação de projeto', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });

    }




  };

  // Function to filter tasks based on specified conditions
  const filterTasks = (tasks) => {
    return tasks?.filter((task) => {
      // Excluir se a missão do projeto é diferente da missão na sessão
      if (getMissao()?.id != task?.projeto?.missao_id) {
        return false;
      }

      // Excluir se o projeto está arquivado
      if (task?.projeto?.estado == 2) {
        return false;
      }

      // Se o tipo de usuário for 1, retorna true para todos os projetos
      if (utilizador.tipo == 1) {
        return true;
      }

      // Se o projeto foi criado pelo usuário
      if (task?.projeto?.criado_por == utilizador.id) {
        return true;
      }

      // Se o usuário é participante do projeto
      const participante = task?.projeto?.colaboradores.find(
        (colaborador) => colaborador.colaborador_id == utilizador.id
      );
      if (participante) {
        return true;
      }

      return false;
    });
  };

  const projectsData = [
    {
      id: 1,
      title: "Office Management",
      openTasks: 1,
      completedTasks: 9,
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
      deadline: "17 Apr 2019",
      projectLeader: { name: "João Lauriano", avatar: "Avatar_16" },
      teamMembers: [
        { id: 1, name: "John Doe", avatar: "Avatar_02" },
        { id: 2, name: "Richard Miles", avatar: "Avatar_09" },
        { id: 3, name: "John Smith", avatar: "Avatar_10" },
        { id: 4, name: "Miguel Luis", avatar: "Avatar_05" },
      ],
    },
    {
      id: 2,
      title: "Project Management",
      openTasks: 2,
      completedTasks: 5,
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
      deadline: "17 Apr 2019",
      projectLeader: { name: "João Lauriano", avatar: "Avatar_16" },
      teamMembers: [
        { id: 1, name: "John Doe", avatar: "Avatar_02" },
        { id: 2, name: "Richard Miles", avatar: "Avatar_09" },
        { id: 3, name: "John Smith", avatar: "Avatar_10" },
        { id: 4, name: "Miguel Luis", avatar: "Avatar_05" },
      ],
    },
    {
      id: 3,
      title: "Video Calling App",
      openTasks: 3,
      completedTasks: 3,
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
      deadline: "17 Apr 2019",
      projectLeader: { name: "João Lauriano", avatar: "Avatar_16" },
      teamMembers: [
        { id: 1, name: "John Doe", avatar: "Avatar_02" },
        { id: 2, name: "Richard Miles", avatar: "Avatar_09" },
        { id: 3, name: "John Smith", avatar: "Avatar_10" },
        { id: 4, name: "Miguel Luis", avatar: "Avatar_05" },
      ],
    },
    {
      id: 4,
      title: "Hospital Administration",
      openTasks: 12,
      completedTasks: 4,
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
      deadline: "17 Apr 2019",
      projectLeader: { name: "João Lauriano", avatar: "Avatar_16" },
      teamMembers: [
        { id: 1, name: "John Doe", avatar: "Avatar_02" },
        { id: 2, name: "Richard Miles", avatar: "Avatar_09" },
        { id: 3, name: "John Smith", avatar: "Avatar_10" },
        { id: 4, name: "Miguel Luis", avatar: "Avatar_05" },
      ],
    },
  ];

  const assests = [
    {
      id: 1,
      image: laptop,
      Name: "Laptop",
      AssetID: "AST - 001",
      AssignedDate: "22 Nov, 2022 10:32AM",
      img: Avatar_01,
      Assignee: "John Paul Raj\r\n  john@dreamguystech.com",
      Action: eye,
    },
    {
      id: 2,
      image: laptop,
      Name: "Laptop",
      AssetID: "AST - 002",
      AssignedDate: "22 Nov, 2022 10:32AM",
      img: Avatar_02,
      Assignee: "Vinod Selvaraj\r\n   vinod.s@dreamguystech.com",
      Action: eye,
    },
    {
      id: 3,
      image: laptop,
      Name: "Dell Keyboard",
      AssetID: "AST - 003",
      AssignedDate: "22 Nov, 2022 10:32AM",
      img: Avatar_05,
      Assignee: "Harika \r\n          harika.v@dreamguystech.com",
      Action: eye,
    },
    {
      id: 4,
      image: laptop,
      Name: "Logitech Mouse",
      AssetID: "AST - 0024",
      AssignedDate: "22 Nov, 2022 10:32AM",
      img: Avatar_09,
      Assignee: "Mythili\r\n            mythili@dreamguystech.com",
      Action: eye,
    },
    {
      id: 5,
      image: laptop,
      Name: "Laptop",
      AssetID: "AST - 005",
      AssignedDate: "22 Nov, 2022 10:32AM",
      img: Avatar_11,
      Assignee: "John Paul Raj\r\n         john@dreamguystech.com",
      Action: eye,
    },
    {
      id: 6,
      image: laptop,
      Name: "Laptop",
      AssetID: "AST - 006",
      AssignedDate: "22 Nov, 2022 10:32AM",
      img: Avatar_13,
      Assignee: "Vinod Selvaraj\r\n               vinod.s@dreamguystech.com",
      Action: eye,
    },
  ];
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id.length - b.id.length,
      width: "10%",
    },

    {
      title: t('Name'),
      dataIndex: "Name",
      render: (text, record) => (
        <span className="table-avatar">
          <Link to="/profile" className="avatar">
            <img alt="" src={record.image} />
          </Link>
          {text}
        </span>
      ),
      sorter: (a, b) => a.Name.length - b.Name.length,
    },

    {
      title: " AssetID",
      dataIndex: "AssetID",
      sorter: (a, b) => a.AssetID.length - b.AssetID.length,
    },
    {
      title: " AssignedDate",
      dataIndex: "AssignedDate",
      sorter: (a, b) => a.AssignedDate.length - b.AssignedDate.length,
    },

    {
      title: " Assignee",
      dataIndex: "Assignee",
      render: (text, record) => (
        <span className="table-avatar">
          <Link to="/profile" className="avatar">
            <img alt="" src={record.img} />
          </Link>
          {text}
        </span>
      ),
      sorter: (a, b) => a.Assignee.length - b.Assignee.length,
    },

    {
      title: t('Action'),
      dataIndex: "Action",
      render: (record) => (
        <span className="delete-table me-2">
          <Link to="/user-asset-details" className="table-actions d-flex">
            <img alt="" src={eye} />
          </Link>
        </span>
      ),
      sorter: (a, b) => a.Action.length - b.Action.length,
    },
  ];

  const salerytype = [
    { value: 1, label: "Weekly  Type" },
    { value: 2, label: "Hourly Type" },
    { value: 3, label: "Daily Type" },
    { value: 4, label: "Monthly Type" },
  ];
  const bank = [
    { value: 1, label: "Bank transfer" },
    { value: 2, label: "Check" },
    { value: 3, label: "Cash" },
  ];
  const pf = [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ];
  const esi = [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ];
  const rate = [
    { value: 1, label: "1%" },
    { value: 2, label: "2%" },
    { value: 3, label: "3%" },
    { value: 4, label: "4%" },
    { value: 5, label: "5%" },
    { value: 6, label: "6%" },
    { value: 7, label: "7%" },
    { value: 8, label: "8%" },
    { value: 9, label: "9%" },
    { value: 10, label: "10%" },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#00b0f0" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#00b0f0",
      },
    }),
  };

  const navegar_na_tarefa = (tarefa_id) => {

    handleNavegarParaTarefa(tarefa_id);
  }

  const handleNavegarParaTarefa = (tarefa_id) => {

    navigate('/task/tasks/' + tarefa_id);

  };

  if (loading) return (<><Spinner><ToastContainer /></Spinner></>); else return (
    <>
      <div className="tab-content">
        {/* Projects Tab */}
        <div className="tab-pane fade" id="emp_projects">
          <div className="row">
            {projetos?.filter(project => {

              // Excluir se a missão do projeto é diferente da missão na sessão
              if (getMissao()?.id != project.missao_id) {
                return false;
              }

              // Excluir se o projeto esta arquivado
              if (project.estado == 2) {
                return false;
              }

              // Se o tipo de usuário for 1, retorna true para todos os projetos
              if (utilizador?.tipo == 1) {
                return true;
              }

              // Se o projeto foi criado pelo usuário
              if (project.criado_por == utilizador?.id) {
                return true;
              }

              // Se o usuário é participante do projeto
              const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == utilizador?.id);
              if (participante) {
                return true;
              }

              return false;

            })?.map((project) => (
              <div
                className="col-lg-4 col-sm-6 col-md-4 col-xl-3 d-flex"
                key={project?.id}
              >
                <div className="card w-100">
                  <div className="card-body">
                    <div className="dropdown dropdown-action profile-action">
                      <Link
                        to="#"
                        className="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="material-icons">more_vert</i>
                      </Link>
                      <div className="dropdown-menu dropdown-menu-right">
                        <span
                          className="dropdown-item"
                          title={project?.nome}
                          onClick={() => aprovarProjeto(project?.id, 1)}
                        >
                          <i className="fa fa-thumbs-up text-success m-r-5" /> {t('Approve')}
                        </span>
                        <span
                          className="dropdown-item"
                          title={project?.nome}
                          onClick={() => aprovarProjeto(project?.id, 2)}
                        >
                          <i className="fa fa-thumbs-down text-danger m-r-5" /> {t('Negate')}
                        </span>
                        <span
                          className="dropdown-item"
                          title={project?.nome}
                          onClick={() => chamarModalEdit(project)}
                        >
                          <i className="fa fa-pencil m-r-5" /> {t('Edit')}
                        </span>
                        <span
                          className="dropdown-item"
                          title={project?.nome}
                          onClick={() => arquivarProjeto(project?.id, 2)}
                        >
                          <i className="fa fa-trash m-r-5" /> {t('Arquive')}
                        </span>
                      </div>
                    </div>

                    <h4 className="project-title">

                      {
                        project?.aprovado == 0 ? <><img width={24}
                          height={24} title={t('Pending to approve')} src={url_root + "/storage/uploads/icons/siren.png"} /></> : project?.aprovado == 1 ? <span title={t('Approved')}><i className="fa fa-thumbs-up text-success"></i></span> : <span title={t('Negated')}><i className="fa fa-thumbs-down text-danger"></i></span>
                      }

                      {
                        (project?.aprovado == 0 || project?.aprovado == 2) ? <></> :
                          project?.estado == 1 ? <><span
                            className={"badge bg-inverse-success"}
                          >
                            <i className="fa fa-check"></i>&nbsp;&nbsp; <span>{t('Closed')}</span>
                          </span>&nbsp;&nbsp;</> : ''
                      }

                      {
                        (project?.aprovado == 0 || project?.aprovado == 2) ? <></> : ((new Date(project?.data_fim) < new Date()) && project?.estado != 1) ? (<><span
                          className={"badge bg-inverse-danger"}
                        >
                          <i className="fa fa-clock"></i>&nbsp;&nbsp; <span>{t('Pending')}</span>
                        </span>&nbsp;&nbsp;</>) : (project?.estado != 1 ? (<span
                          className={"badge bg-inverse-warning"}
                        >
                          <i className="fa fa-list"></i>&nbsp;&nbsp; <span>{t('Opened')}</span>
                        </span>) : '')
                      }
                    </h4>
                    <h4 className="project-title">
                      <Link to={'/project-view/' + project?.id}>{project?.projecto_code + ' - ' + project?.nome}</Link>
                    </h4>
                    <h4 className="project-title">
                      <Link to={'/task-board/' + project?.id} title="Quadro de tarefas" className={"badge bg-inverse-info"}>
                        <i className="fa fa-tasks"></i>&nbsp;{t('View Task-board')}
                      </Link>
                    </h4>
                    <small className="block text-ellipsis m-b-15">

                      <span className="text-xs">{project?.tarefas?.filter(tarefa => tarefa.estado == 0).length}</span>{" "}
                      <span className="text-warning">{t('open tasks')}, </span>

                      <span className="text-xs">
                        {project?.tarefas?.filter(tarefa => tarefa.estado == 1).length}
                      </span>{" "}
                      <span className="text-success">{t('tasks completed')}</span>

                    </small>
                    <p className="text-muted">{stripHtmlTags(project?.descricao).length > 140 ? stripHtmlTags(project?.descricao).substring(0, 140) + '...' : stripHtmlTags(project?.descricao)}</p>

                    <div className="pro-deadline m-b-15">
                      <div className="sub-title">{t('Deadline')}:</div>
                      <div className="text-muted">{format(new Date(project?.data_fim), 'dd/MM/yyyy')}</div>
                    </div>
                    <div className="project-members m-b-15">
                      <div>{t('Project Leader')}:</div>
                      <ul className="team-members">
                        {project?.colaboradores?.filter(user => user.tipo == 1).map(user => (
                          <li>
                            <Link
                              to="#"
                              data-bs-toggle="tooltip"
                              title={user?.colaborador?.name}
                            >
                              <img src={url_root + "/storage/" + user?.colaborador?.fotografia} alt={user?.colaborador?.name} title={user?.colaborador?.name} />
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="project-members m-b-15">
                      <div>{t('Team')} :</div>
                      <ul className="team-members">
                        {project?.colaboradores?.filter(user => user.tipo == 2).slice(0, 4).map(user => (
                          <li>
                            <Link
                              to="#"
                              data-bs-toggle="tooltip"
                              title={user?.colaborador?.name}
                            >
                              <img src={url_root + "/storage/" + user?.colaborador?.fotografia} alt={user?.colaborador?.name} title={user?.colaborador?.name} />
                            </Link>
                          </li>
                        ))}

                        <li className="dropdown avatar-dropdown">



                          {
                            project?.colaboradores?.filter(colaborador => colaborador.tipo == 2).length > 4 ? <Link
                              to="#"
                              className="all-users dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >

                              <span>+{project?.colaboradores?.filter(colaborador => colaborador.tipo == 2).length - 4}</span>

                            </Link> : ''
                          }


                          <div className="dropdown-menu dropdown-menu-right">
                            <div className="avatar-group">
                              {project?.colaboradores?.filter(user => user.tipo == 2).map(user => (
                                <Link className="avatar avatar-xs" to="#">
                                  <img src={url_root + "/storage/" + user?.colaborador?.fotografia} alt={user?.colaborador?.name} title={user?.colaborador?.name} />
                                </Link>

                              ))}
                            </div>
                            <div className="avatar-pagination">
                              <ul className="pagination">
                                <li className="page-item">
                                  <Link
                                    className="page-link"
                                    to="#"
                                    aria-label="Previous"
                                  >
                                    <span aria-hidden="true">«</span>
                                    <span className="sr-only">{t('Previous')}</span>
                                  </Link>
                                </li>
                                <li className="page-item">
                                  <Link className="page-link" to="#">
                                    1
                                  </Link>
                                </li>
                                <li className="page-item">
                                  <Link className="page-link" to="#">
                                    2
                                  </Link>
                                </li>
                                <li className="page-item">
                                  <Link
                                    className="page-link"
                                    to="#"
                                    aria-label="Next"
                                  >
                                    <span aria-hidden="true">»</span>
                                    <span className="sr-only">{t('Next')}</span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <p className="m-b-5">
                      {t('Progress')}
                      <span className="text-success float-end">
                        {Math.round(((project?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / project?.tarefas?.length) * 100) ? Math.round(((project?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / project?.tarefas?.length) * 100) : 0}%</span>
                    </p>

                    <div className="progress progress-xs mb-0">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        data-bs-toggle="tooltip"
                        title={(Math.round(((project?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / project?.tarefas?.length) * 100)) + '%'}
                        style={{ width: `${Math.round(((project?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / project?.tarefas?.length) * 100)}%` }}
                      />
                    </div>

                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* /Assests Tab */}
        <div className="tab-pane fade" id="emp_assets">
          <div className="table-responsive table-newdatatable">
            <Table
              columns={columns}
              dataSource={assests}
              className="table-striped"
              rowKey={(record) => record.id}
            />
          </div>
        </div>


        {/* Atividades */}

        <div className="tab-pane fade" id="tab_activities">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title"> {t('Activities')}</h3>

              <span
                data-bs-toggle="modal"
                data-bs-target="#all_activities_modal"
                className="btn btn-secondary mb-2 ml-2"
              >
                <i className="fa fa-list"></i>{' '}
                {t('See All Activities')}
              </span>


              <div className="activity">
                <div className="activity-box">
                  <ul className="activity-list">
                    {atividades?.slice(0, 10)?.map((activity, index) => (
                      <li key={index}>
                        <div className="activity-user">
                          <Link
                            to={"/profile/" + activity?.user?.id}
                            title={activity?.user?.name}
                            data-bs-toggle="tooltip"
                            className="avatar"
                          >
                            <img src={url_root + "/storage/" + activity?.user?.fotografia} alt={activity?.user?.name} title={activity?.user?.name} />
                          </Link>
                        </div>
                        <div className="activity-content">
                          <div className="timeline-content">

                            <Link to={"/profile/" + activity?.user?.id} className="name">
                              {activity?.user?.name}
                            </Link>{" "}

                            {/* PROJETOS */}

                            {/* Adicionou projeto */}
                            {(activity?.tipo == 1 && activity?.accao == 1 && activity?.receptor_id == 0) ? ' adicionou novo projeto, ' : ''}

                            {/* editou projeto */}
                            {(activity?.tipo == 1 && activity?.accao == 2) ? ' editou a informação do projeto ' + activity?.mensagem + '  ' : ''}

                            {/* fechou projeto */}
                            {(activity?.tipo == 1 && activity?.accao == 9) ? ' fechou o projeto, ' + activity?.mensagem + '  ' : ''}

                            {/* abriu projeto */}
                            {(activity?.tipo == 1 && activity?.accao == 10) ? ' voltou a abrir o projeto para execução, ' + activity?.mensagem + '  ' : ''}

                            {/* prazo projeto */}
                            {(activity?.tipo == 1 && activity?.accao == 11) ? ' Alterou o prazo ( deadline ) do projeto, ' + activity?.mensagem + '  ' : ''}

                            {/* adicionou colaborador ao projeto */}
                            {
                              (activity?.tipo == 1 && activity?.accao == 1 && activity?.receptor_id != 0) &&
                              <span>
                                adicionou o colaborador <Link to={"/profile/" + activity?.receptor?.id} className="name">
                                  {activity?.receptor?.name}
                                </Link> ao projeto, {activity?.mensagem}{', '}
                              </span>
                            }


                            {/* removeu colaborador do projeto */}
                            {
                              (activity?.tipo == 1 && activity?.accao == 3 && activity?.receptor_id != 0) &&
                              <span>
                                removeu o colaborador <Link to={"/profile/" + activity?.receptor?.id} className="name">
                                  {activity?.receptor?.name}
                                </Link> do projeto, {activity?.mensagem}{', '}
                              </span>
                            }

                            {/* Aprovar, reprovar projeto */}
                            {
                              (activity?.tipo == 1 && activity?.accao == 4 && activity?.receptor_id == 0) &&
                              <span>
                                {activity?.mensagem} a execução do projeto{', '}
                              </span>
                            }

                            {/* Arquivar, desarquivar projeto */}
                            {
                              (activity?.tipo == 1 && activity?.accao == 6 && activity?.receptor_id == 0) &&
                              <span>
                                {activity?.mensagem} o projeto{', '}
                              </span>
                            }


                            {/* eliminar projeto */}
                            {
                              (activity?.tipo == 1 && activity?.accao == 3 && activity?.receptor_id == 0) &&
                              <span>
                                {'removeu o projeto ' + activity?.mensagem}{' '}
                              </span>
                            }

                            {/* TAREFAS */}

                            {/* adicionou tarefa ao projeto */}
                            {
                              (activity?.tipo == 2 && activity?.accao == 1 && activity?.receptor_id == 0) &&
                              <span>
                                adicionou a tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                  {' '}{activity?.mensagem}
                                </Link> ao projeto {', '}
                              </span>
                            }

                            {/* editou a tarefa do projeto */}
                            {
                              (activity?.tipo == 2 && activity?.accao == 2 && activity?.receptor_id == 0) &&
                              <span>
                                editou a tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                  {' '}{activity?.mensagem}
                                </Link> no projeto {', '}
                              </span>
                            }


                            {/* fechou tarefa */}
                            {(activity?.tipo == 2 && activity?.accao == 9) ? ' fechou a tarefa, ' + activity?.tarefa?.nome + ' no projeto,  ' : ''}

                            {/* abriu tarefa */}
                            {(activity?.tipo == 2 && activity?.accao == 10) ? ' voltou a abrir a tarefa ' + activity?.tarefa?.nome + ' no projeto,  ' : ''}

                            {/* prazo tarefa */}
                            {(activity?.tipo == 2 && activity?.accao == 11) ? ' Alterou o prazo ( deadline ) da tarefa ' + activity?.tarefa?.nome + ' no projeto,  ' : ''}

                            {/* eliminar tarefa */}
                            {
                              (activity?.tipo == 2 && activity?.accao == 3 && activity?.receptor_id == 0) &&
                              <span>
                                {'removeu a tarefa ' + activity?.mensagem}{' no projeto, '}
                              </span>
                            }


                            {/* adicionou colaborador na tarefa */}
                            {
                              (activity?.tipo == 2 && activity?.accao == 1 && activity?.receptor_id != 0) &&
                              <span>
                                adicionou o colaborador <Link to={"/profile/" + activity?.receptor?.id} className="name">
                                  {activity?.receptor?.name}
                                </Link> a tarefa, <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">{activity?.tarefa?.nome}</Link>{', no projeto '}
                              </span>
                            }


                            {/* removeu colaborador da tarefa */}
                            {
                              (activity?.tipo == 2 && activity?.accao == 3 && activity?.receptor_id != 0) &&
                              <span>
                                removeu o colaborador <Link to={"/profile/" + activity?.receptor?.id} className="name">
                                  {activity?.receptor?.name}
                                </Link> da tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">{activity?.tarefa?.nome}</Link>{', no projeto '}
                              </span>
                            }



                            {/* ITEMS DE TAREFA */}

                            {/* adicionou item de tarefa a tarefa */}
                            {
                              (activity?.tipo == 3 && activity?.accao == 1 && activity?.receptor_id == 0) &&
                              <span>
                                adicionou item de tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                  {' '}{activity?.item?.nome}
                                </Link> a tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                  {' '}{activity?.tarefa?.nome}
                                </Link>  no projeto,
                              </span>
                            }

                            {/* editou a tarefa do projeto */}
                            {
                              (activity?.tipo == 3 && activity?.accao == 2 && activity?.receptor_id == 0) &&
                              <span>
                                editou a informação do item de tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                  {' '}{activity?.item?.nome}
                                </Link> a tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                  {' '}{activity?.tarefa?.nome}
                                </Link>  no projeto,
                              </span>
                            }

                            {/* fechou item de tarefa */}
                            {(activity?.tipo == 3 && activity?.accao == 9) ? <span>fechou o item de tarefa, {activity?.item?.nome} na tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                              {' ' + activity?.tarefa?.nome}</Link> do projeto </span> : ''}

                            {/* abriu item de tarefa */}
                            {(activity?.tipo == 3 && activity?.accao == 10) ? <span>voltou a abrir o item de tarefa, {activity?.item?.nome} para execução na tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                              {' ' + activity?.tarefa?.nome}</Link> do projeto </span> : ''}


                            {/* eliminar item de tarefa */}
                            {
                              (activity?.tipo == 3 && activity?.accao == 3 && activity?.receptor_id == 0) &&
                              <span>
                                removeu item de tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                  {' '}{activity?.mensagem}
                                </Link> na a tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                  {' '}{activity?.tarefa?.nome}
                                </Link>  do projeto,
                              </span>
                            }

                            {
                              (activity?.tipo == 6 && activity?.accao == 2 && activity?.projeto_id != 0) &&
                              <span>
                                Adicionou ficheiros ao projeto <Link to={"/project-view/" + activity?.projeto?.id}>{activity?.projeto?.nome}</Link>
                              </span>
                            }

                            {
                              (activity?.tipo == 6 && activity?.accao == 3 && activity?.projeto_id != 0) &&
                              <span>
                                Removeu ficheiros ao projeto <Link to={"/project-view/" + activity?.projeto?.id}>{activity?.projeto?.nome}</Link>
                              </span>
                            }


                            {/* TICKETS */}

                            {/* Adicionou ticket */}
                            {(activity?.tipo == 4 && activity?.accao == 1 && activity?.receptor_id == 0) ? ' adicionou novo ticket, ' + <span><Link to={"/task/ticket-view/" + activity?.tipo_id} className="name">
                              {activity?.mensagem}{', '}
                            </Link></span> : ''}

                            {/* editou ticket */}
                            {(activity?.tipo == 4 && activity?.accao == 2) ? <span> editou a informação do ticket <Link to={"/task/ticket-view/" + activity?.tipo_id} className="name">
                              {activity?.mensagem}{', '}
                            </Link></span> : ''}

                            {/* fechou ticket */}
                            {(activity?.tipo == 4 && activity?.accao == 9) ? <span> fechou o ticket, <Link to={"/task/ticket-view/" + activity?.tipo_id} className="name">
                              {activity?.mensagem}{', '}
                            </Link></span> : ''}

                            {/* abriu ticket */}
                            {(activity?.tipo == 4 && activity?.accao == 10) ? ' voltou a abrir o ticket para execução, ' + activity?.mensagem + '  ' : ''}

                            {/* prazo ticket */}
                            {(activity?.tipo == 4 && activity?.accao == 11) ? <span> Alterou o prazo ( deadline ) do ticket, <Link to={"/task/ticket-view/" + activity?.tipo_id} className="name">
                              {activity?.mensagem}{', '}
                            </Link></span> : ''}

                            {/* adicionou colaborador ao ticket */}
                            {
                              (activity?.tipo == 4 && activity?.accao == 1 && activity?.receptor_id != 0) &&
                              <span>
                                adicionou o colaborador <Link to={"/profile/" + activity?.receptor?.id} className="name">
                                  {activity?.receptor?.name}
                                </Link> ao ticket, <Link to={"/task/ticket-view/" + activity?.tipo_id} className="name">
                                  {activity?.mensagem}{', '}
                                </Link>
                              </span>
                            }


                            {/* removeu colaborador do ticket */}
                            {
                              (activity?.tipo == 4 && activity?.accao == 3 && activity?.receptor_id != 0) &&
                              <span>
                                removeu o colaborador <Link to={"/profile/" + activity?.receptor?.id} className="name">
                                  {activity?.receptor?.name}
                                </Link> do ticket, <Link to={"/task/ticket-view/" + activity?.tipo_id} className="name">
                                  {activity?.mensagem}{', '}
                                </Link>
                              </span>
                            }

                            {/* Aprovar, reprovar ticket */}
                            {
                              (activity?.tipo == 4 && activity?.accao == 4 && activity?.receptor_id == 0) &&
                              <span>
                                {activity?.mensagem} a execução do ticket <Link to={"/task/ticket-view/" + activity?.tipo_id} className="name">
                                  {activity?.mensagem}{', '}
                                </Link>
                              </span>
                            }

                            {/* Arquivar, desarquivar ticket */}
                            {
                              (activity?.tipo == 4 && activity?.accao == 6 && activity?.receptor_id == 0) &&
                              <span>
                                {activity?.mensagem} o ticket <Link to={"/task/ticket-view/" + activity?.tipo_id} className="name">
                                  {activity?.mensagem}{', '}
                                </Link>
                              </span>
                            }


                            {/* eliminar ticket */}
                            {
                              (activity?.tipo == 4 && activity?.accao == 3 && activity?.receptor_id == 0) &&
                              <span>
                                {'removeu o ticket ' + activity?.mensagem}{' '}
                              </span>
                            }


                            {/* CHATS */}

                            {/* chat no direto */}

                            {
                              (activity?.tipo == 7 && activity?.accao == 1 && activity?.chat_para == 1) &&
                              <span>
                                deixou mensagem para <Link to={"/call/chat/1/" + activity?.receptor_id}>{activity?.receptor?.name}</Link>
                              </span>
                            }

                            {/* chat na tarefa */}

                            {
                              (activity?.tipo == 7 && activity?.accao == 1 && activity?.chat_para == 2) &&
                              <span>
                                conversou no chat da tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">{activity?.tarefa?.nome}
                                </Link>  do projeto <Link to={"/project-view/" + activity?.projeto?.id}>{activity?.projeto?.nome}</Link>
                              </span>
                            }

                            {/* chat no ticket */}

                            {
                              (activity?.tipo == 7 && activity?.accao == 1 && activity?.chat_para == 3) &&
                              <span>
                                conversou no chat do ticket <Link to={"/ticket-view/" + activity?.tipo_id}>{activity?.mensagem}</Link>
                              </span>
                            }


                            {/* NOME E LINK DO PROJETO, DATA E HORA */}

                            {activity?.tipo == 1 || activity?.tipo == 2 || activity?.tipo == 3 ?
                              <Link to={"/project-view/" + activity?.projeto?.id}>{activity?.projeto?.nome}</Link>
                              : ''}

                            <span className="time">{format(new Date(activity.data), 'dd/MM/yyyy') + ' ' + activity.hora}</span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>


            </div>
          </div>
        </div>


        {/* /Bank Tab */}

        <div className="tab-pane fade" id="bank_statutory">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title"> {t('Tasks')}</h3>


              <div className="project-task text-start">

                <ul className="nav nav-tabs nav-tabs-top nav-justified mb-0">

                  <li className="nav-item">
                    <Link
                      className="nav-link active"
                      to="#tarefas_todas"
                      data-bs-toggle="tab"
                      aria-expanded="true">
                      {t('All Tasks')}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="#tarefas_abertas"
                      data-bs-toggle="tab"
                      aria-expanded="false">
                      {t('Progress')}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="#tarefas_pendentes"
                      data-bs-toggle="tab"
                      aria-expanded="false">
                      {t('Pending Tasks')}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="#tarefas_concluidas"
                      data-bs-toggle="tab"
                      aria-expanded="false">
                      {t('Completed Tasks')}
                    </Link>
                  </li>

                </ul>
                <div className="tab-content">
                  <div className="tab-pane show active" id="tarefas_todas">
                    <div className="task-wrapper">
                      <div className="task-list-container text-start">
                        <div className="task-list-body text-start">

                          <table className="table table-bordered" width="100%">
                            <tbody>

                              {tarefasFiltradas?.map(task => (

                                <tr key={task?.id} onClick={() => navegar_na_tarefa(task?.id)}>
                                  <td>
                                    <span className={"task-action-btn task-check " + (
                                      task?.estado == 1
                                        ? " text-success "
                                        : ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? " text-danger " : " text-warning "
                                    )}



                                    >
                                      <span
                                        className="action-circle large complete-btn"
                                        title="Mark Complete"
                                      >
                                        <i className="material-icons">check</i>
                                      </span>
                                    </span>
                                  </td>
                                  <td>
                                    <span className="task-label text-start" >
                                      {format(new Date(task?.prazo), 'dd/MM/yyyy')}
                                    </span>
                                  </td>
                                  <td>
                                    {
                                      task?.estado == 1 ? <>
                                        <span
                                          className={"badge bg-inverse-success"}
                                        >
                                          <i className="fa fa-check"></i> <span>{t('Closed')}</span>
                                        </span></> : ''
                                    }

                                    {

                                      ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? (<><span
                                        className={"badge bg-inverse-danger"}
                                      >
                                        <i className="fa fa-clock"></i> <span>{t('Pending')}</span>
                                      </span></>) : (task?.estado != 1 ? (<span
                                        className={"badge bg-inverse-warning"}
                                      >
                                        <i className="fa fa-list"></i> <span>{t('Opened')}</span>
                                      </span>) : '')
                                    }

                                  </td>
                                  <td>
                                    <span
                                      className={
                                        task?.prioridade == 1
                                          ? "badge bg-inverse-success"
                                          : task?.prioridade == 2 ? "badge bg-inverse-warning" : "badge bg-inverse-danger"
                                      }
                                    >{t('Priority')}{' : '}
                                      {task?.prioridade == 1 ? t('Low') : task?.prioridade == 2 ? t('Medium') : t('High')}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={"task-label text-start"}
                                    >[{(Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) ? (Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) : 0}%]
                                    </span>
                                  </td>
                                  <td>

                                    <span className="task-label" >{task?.nome}
                                    </span>
                                  </td>
                                  <td>

                                    <span className="task-action-btn task-btn-right">
                                      <span className="action-circle large delete-btn" title="Tarefas" onClick={() => navegar_na_tarefa(task?.id)} >
                                        <i className="fa fa-link"></i>
                                      </span>
                                    </span>

                                  </td>
                                </tr>

                              ))}

                            </tbody>
                          </table>

                        </div>

                      </div>

                    </div>
                  </div>
                  <div className="tab-pane" id="tarefas_abertas">
                    <div className="task-wrapper">
                      <div className="task-list-container">
                        <div className="task-list-body">


                          <table className="table table-bordered" width="100%">
                            <tbody>

                              {tarefasFiltradas
                                // Filtrar apenas tarefas pendentes
                                ?.filter(task => task.estado == 0)
                                .map(task => (

                                  <tr key={task?.id} onClick={() => navegar_na_tarefa(task?.id)}>
                                    <td>
                                      <span className={"task-action-btn task-check " + (
                                        task?.estado == 1
                                          ? " text-success "
                                          : ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? " text-danger " : " text-warning "
                                      )}



                                      >
                                        <span
                                          className="action-circle large complete-btn"
                                          title="Mark Complete"
                                        >
                                          <i className="material-icons">check</i>
                                        </span>
                                      </span>
                                    </td>
                                    <td>
                                      <span className="task-label text-start" >
                                        {format(new Date(task?.prazo), 'dd/MM/yyyy')}
                                      </span>
                                    </td>
                                    <td>
                                      {
                                        task?.estado == 1 ? <>
                                          <span
                                            className={"badge bg-inverse-success"}
                                          >
                                            <i className="fa fa-check"></i> <span>{t('Closed')}</span>
                                          </span></> : ''
                                      }

                                      {

                                        ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? (<><span
                                          className={"badge bg-inverse-danger"}
                                        >
                                          <i className="fa fa-clock"></i> <span>{t('Pending')}</span>
                                        </span></>) : (task?.estado != 1 ? (<span
                                          className={"badge bg-inverse-warning"}
                                        >
                                          <i className="fa fa-list"></i> <span>{t('Opened')}</span>
                                        </span>) : '')
                                      }

                                    </td>
                                    <td>
                                      <span
                                        className={
                                          task?.prioridade == 1
                                            ? "badge bg-inverse-success"
                                            : task?.prioridade == 2 ? "badge bg-inverse-warning" : "badge bg-inverse-danger"
                                        }
                                      >{t('Priority')}{' : '}
                                        {task?.prioridade == 1 ? t('Low') : task?.prioridade == 2 ? t('Medium') : t('High')}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={"task-label text-start"}
                                      >[{(Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) ? (Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) : 0}%]
                                      </span>
                                    </td>
                                    <td>

                                      <span className="task-label" >{task?.nome}
                                      </span>
                                    </td>
                                    <td>

                                      <span className="task-action-btn task-btn-right">
                                        <span className="action-circle large delete-btn" title="Tarefas" onClick={() => navegar_na_tarefa(task?.id)} >
                                          <i className="fa fa-link"></i>
                                        </span>
                                      </span>

                                    </td>
                                  </tr>

                                ))}

                            </tbody>
                          </table>




                        </div>

                      </div>

                    </div>
                  </div>
                  <div className="tab-pane" id="tarefas_pendentes">
                    <div className="task-wrapper">
                      <div className="task-list-container">
                        <div className="task-list-body">

                          <table className="table table-bordered" width="100%">
                            <tbody>

                              {tarefasFiltradas
                                // Filtrar apenas tarefas pendentes
                                ?.filter(task => task.estado == 0 && new Date(task.prazo) < new Date())
                                .map(task => (

                                  <tr key={task?.id} onClick={() => navegar_na_tarefa(task?.id)}>
                                    <td>
                                      <span className={"task-action-btn task-check " + (
                                        task?.estado == 1
                                          ? " text-success "
                                          : ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? " text-danger " : " text-warning "
                                      )}



                                      >
                                        <span
                                          className="action-circle large complete-btn"
                                          title="Mark Complete"
                                        >
                                          <i className="material-icons">check</i>
                                        </span>
                                      </span>
                                    </td>
                                    <td>
                                      <span className="task-label text-start" >
                                        {format(new Date(task?.prazo), 'dd/MM/yyyy')}
                                      </span>
                                    </td>
                                    <td>
                                      {
                                        task?.estado == 1 ? <>
                                          <span
                                            className={"badge bg-inverse-success"}
                                          >
                                            <i className="fa fa-check"></i> <span>{t('Closed')}</span>
                                          </span></> : ''
                                      }

                                      {

                                        ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? (<><span
                                          className={"badge bg-inverse-danger"}
                                        >
                                          <i className="fa fa-clock"></i> <span>{t('Pending')}</span>
                                        </span></>) : (task?.estado != 1 ? (<span
                                          className={"badge bg-inverse-warning"}
                                        >
                                          <i className="fa fa-list"></i> <span>{t('Opened')}</span>
                                        </span>) : '')
                                      }

                                    </td>
                                    <td>
                                      <span
                                        className={
                                          task?.prioridade == 1
                                            ? "badge bg-inverse-success"
                                            : task?.prioridade == 2 ? "badge bg-inverse-warning" : "badge bg-inverse-danger"
                                        }
                                      >{t('Priority')}{' : '}
                                        {task?.prioridade == 1 ? t('Low') : task?.prioridade == 2 ? t('Medium') : t('High')}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={"task-label text-start"}
                                      >[{(Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) ? (Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) : 0}%]
                                      </span>
                                    </td>
                                    <td>

                                      <span className="task-label" >{task?.nome}
                                      </span>
                                    </td>
                                    <td>

                                      <span className="task-action-btn task-btn-right">
                                        <span className="action-circle large delete-btn" title="Tarefas" onClick={() => navegar_na_tarefa(task?.id)} >
                                          <i className="fa fa-link"></i>
                                        </span>
                                      </span>

                                    </td>
                                  </tr>

                                ))}

                            </tbody>
                          </table>

                        </div>

                      </div>

                    </div>
                  </div>
                  <div className="tab-pane" id="tarefas_concluidas">
                    <div className="task-wrapper">
                      <div className="task-list-container">
                        <div className="task-list-body">

                          <table className="table table-bordered" width="100%">
                            <tbody>

                              {tarefasFiltradas
                                // Filtrar apenas tarefas pendentes
                                ?.filter(task => task.estado == 1)
                                .map(task => (

                                  <tr key={task?.id} onClick={() => navegar_na_tarefa(task?.id)}>
                                    <td>
                                      <span className={"task-action-btn task-check " + (
                                        task?.estado == 1
                                          ? " text-success "
                                          : ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? " text-danger " : " text-warning "
                                      )}



                                      >
                                        <span
                                          className="action-circle large complete-btn"
                                          title="Mark Complete"
                                        >
                                          <i className="material-icons">check</i>
                                        </span>
                                      </span>
                                    </td>
                                    <td>
                                      <span className="task-label text-start" >
                                        {format(new Date(task?.prazo), 'dd/MM/yyyy')}
                                      </span>
                                    </td>
                                    <td>
                                      {
                                        task?.estado == 1 ? <>
                                          <span
                                            className={"badge bg-inverse-success"}
                                          >
                                            <i className="fa fa-check"></i> <span>{t('Closed')}</span>
                                          </span></> : ''
                                      }

                                      {

                                        ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? (<><span
                                          className={"badge bg-inverse-danger"}
                                        >
                                          <i className="fa fa-clock"></i> <span>{t('Pending')}</span>
                                        </span></>) : (task?.estado != 1 ? (<span
                                          className={"badge bg-inverse-warning"}
                                        >
                                          <i className="fa fa-list"></i> <span>{t('Opened')}</span>
                                        </span>) : '')
                                      }

                                    </td>
                                    <td>
                                      <span
                                        className={
                                          task?.prioridade == 1
                                            ? "badge bg-inverse-success"
                                            : task?.prioridade == 2 ? "badge bg-inverse-warning" : "badge bg-inverse-danger"
                                        }
                                      >{t('Priority')}{' : '}
                                        {task?.prioridade == 1 ? t('Low') : task?.prioridade == 2 ? t('Medium') : t('High')}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={"task-label text-start"}
                                      >[{(Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) ? (Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) : 0}%]
                                      </span>
                                    </td>
                                    <td>

                                      <span className="task-label" >{task?.nome}
                                      </span>
                                    </td>
                                    <td>

                                      <span className="task-action-btn task-btn-right">
                                        <span className="action-circle large delete-btn" title="Tarefas" onClick={() => navegar_na_tarefa(task?.id)} >
                                          <i className="fa fa-link"></i>
                                        </span>
                                      </span>

                                    </td>
                                  </tr>

                                ))}

                            </tbody>
                          </table>


                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>


      </div>

      <TodasAtividadesModalPopup atividades={atividades} />

      <ProjectModelPopup onSubmissionSuccess={handleSubmissionSuccessProjeto} projeto={projetoSelecionado} prioridadeParam={projetoSelecionado?.prioridade} tempoParam={projetoSelecionado?.tempo} utilizadores={utilizadores} missoes={missoes} />


      <span
        data-bs-toggle="modal"
        data-bs-target="#edit_project"
        id="triggerModalEdit"
      >
      </span>

      <span
        data-bs-toggle="modal"
        data-bs-target="#delete"
        id="triggerModalEliminar"
      >
      </span>



      <ToastContainer />


    </>
  );
};

export const ListItem = ({ title, text }) => (
  <li>
    <div className="title">{title}</div>
    <div className="text">{text}</div>
  </li>
);
