import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AuthUser from "../AuthUser";

import { toast, ToastContainer } from 'react-toastify';

const DeleteModal = ({ objectoName, onSubmissionSuccess, id, endpoint }) => {

  const { t } = useTranslation();

  const { http, url_root, user_permissoes, verificarPermissao } = AuthUser();

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event('click'));
    }
  };

  const handleEliminar = async () => {

    try {

      const formData = new FormData();
      formData.append('id', id);

      const response = await http.post('/' + endpoint + '/eliminar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          // Redirecionar para a lista de Acessos após o cadastro bem-sucedido

          triggerCloseButtonClick('closeButton3');

          onSubmissionSuccess(response.data?.data);

        },
      });

      // Atualize a lista de acessos após ativar/desativar

    } catch (error) {
      console.error(error);

      toast.error('Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Delete Performance Indicator Modal */}
      <div className="modal custom-modal fade" id="delete" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-header">
                <h3>{objectoName}</h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="closeButton3"

                >
                  <span aria-hidden="true">×</span>
                </button>
                <p>{t('Are you sure want to delete?')}</p>
              </div>
              <div className="modal-btn">
                <div className="row">
                  <div className="col-6">
                    <span className="btn btn-primary continue-btn" onClick={() => handleEliminar()}>
                      {loading ? 'Carregando' : t('Delete')}
                    </span>
                  </div>
                  <div className="col-6">
                    <Link
                      to="#"
                      data-bs-dismiss="modal"
                      className="btn btn-primary cancel-btn"
                    >
                      {t('Cancel')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Delete Performance Indicator Modal */}

      <ToastContainer />

    </>
  );
};

export default DeleteModal;
