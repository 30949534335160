import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import AuthUser from "../../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../components/Spinner";
import BancoFilter from "../Administration/financas/BancoFilter";
import BancoTable from "../Administration/financas/BancoTable";
import BancoModal from "../../../components/Administration/financas/BancoModal";
import { startOfMonth, endOfMonth, format } from "date-fns";
import { Link } from "react-router-dom";
import CaixaMovimentoModal from "../../../components/Administration/financas/CaixaMovimentoModal";
import DeleteModal from "../../../components/modelpopup/DeleteModal";

const UnderConstruction = () => {
    
    return (
      <>
        <div className="page-wrapper cardhead">
          <div className="content ">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">FUNCIONALIDADE EM DESENVOLVIMENTO</h3>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      <i className="fa fa-times-circle text-danger"></i>&nbsp;
                      ESTA FUNCIONALIDADE AINDA NÃO ESTA DISPONIVEL
                    </h4>
                  </div>
                  <div className="card-body">
                    <p>
                      Esta operação não é autorizada para o seu nivel de acesso
                      ao sistema, por favor contacte o administrador do sistema.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default UnderConstruction;
