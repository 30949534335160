
import React, { useState, useEffect } from "react";
import { Table } from "antd";
import {
  User1,
  User2,
  User3,
  User4,
  User5,
  User6,
  User7,
  User8,
  User9,
  User10,
  User11,
  User12,
} from "../../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import DeleteModal from "../../../../components/modelpopup/DeleteModal";

import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";

import AuthUser from "../../../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../../../components/Spinner";
import BancoModal from "../../../../components/Administration/financas/BancoModal";

const BancoTable = ({ bancos }) => {

  const { t } = useTranslation();

  const [cargo, setBanco] = useState(bancos);
  const [bancoselecionado, setBancoSelecionado] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } = AuthUser();

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [selectedUserIds, setSelectedUserIds] = useState([]);

  useEffect(() => {
    setBanco(bancos);
  }, [bancos]);

  const handleSubmissionSuccess = (bancos) => {   
    setBanco(bancos);
  };

  const chamarModalEliminar = (idSelecionado) => {

    setBancoSelecionado(idSelecionado);

    const editButton = document.getElementById('triggerModalEliminar');

    console.log('banco selecionado:')
    console.log(bancoselecionado);

    if (editButton) {
      editButton.click();
    }

  }

  const chamarModalEdit = (idSelecionado) => {

    setBancoSelecionado(idSelecionado);

    const editButton = document.getElementById('triggerModalEdit');

    console.log('Banco selecionado:')
    console.log(bancoselecionado);

    if (editButton) {
      editButton.click();
    }

  }

  

  const getBancos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/banco/'+getMissao()?.id);

      setBanco(res.data.funcaos);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };



  const handleAtivarDesativar = async (id, newState) => {

    //console.log('Clicou aqui para ativar e desativar');

    if (verificarPermissao('CARGO', 'ativar')) {
      try {

        setLoading(true); 

        const formData = new FormData();
        formData.append('id', id);
        formData.append('estado', newState);

        const response = await http.post('/banco/activate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        setLoading(false); 

        setBanco(response.data.data);

        // Atualize a lista de utilizadores após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };



  const columns = [
    {
      title: t('Name'),
      dataIndex: "name",
      render: (text, record) => (
        <div className="table-avatar">

        
          <Link to="#" className="avatar">
            <img src={url_root + "/storage/" + record.logo} alt="logo" />
          </Link>
          <Link to="#">
            {record?.nome}
          </Link>
          

        </div>
      ),
      sorter: (a, b) => a.nome.length - b.nome.length,
    },
    {
      title: "Sigla",
      dataIndex: "sigla",
      sorter: (a, b) => a.sigla.length - b.sigla.length,
    },
    {
      title: "Descrição",
      dataIndex: "descricao",
      render: (text, record) => (
        <>
          <span title={record?.descricao}>
            {record?.descricao?.substring(0, 50)}
          </span>
        </>
      ),
      sorter: (a, b) => a.descricao.length - b.descricao.length,     
    },
    {
      title: t('Status'),
      dataIndex: "estado",
      render: (text, record) => (
        <>
          <span
            className={
              record?.estado == 1
                ? "badge bg-inverse-success"
                : "badge bg-inverse-danger"
            }
          >
            {record?.estado == 1 ? 'Ativo' : 'Desativado'}
          </span>&nbsp;
          <span
            onClick={() => handleAtivarDesativar(record.id, text == 1 ? 0 : 1)}
          >
            <i className="fa fa-recycle"></i>
          </span>
        </>
      ),
      sorter: (a, b) => a.estado.length - b.estado.length,
    },

    {
      title: t('Action'),
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <span
              className="dropdown-item"
              title={record?.sigla}
              onClick={() => chamarModalEdit(record)}
            >
              <i className="fa fa-pencil m-r-5" /> {t('Edit')}
            </span>
            <span
              className="dropdown-item"
              title={record?.sigla}
              onClick={() => chamarModalEliminar(record)}
            >
              <i className="fa fa-trash m-r-5" /> {t('Delete')}
            </span>
          </div>
        </div>
      ),
    },
  ];

  if (loading) return (<><Spinner><ToastContainer /></Spinner></>); else return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">


            <Table
              className="table-striped"
              style={{ overflowX: "auto" }}
              columns={columns}
              dataSource={cargo}
              rowKey={(record) => record.id}
            />
            <BancoModal onSubmissionSuccess={handleSubmissionSuccess} banco={bancoselecionado} imagePreview={bancoselecionado?.logo} />
            <DeleteModal objectoName={t('Banco')} onSubmissionSuccess={handleSubmissionSuccess} id={bancoselecionado?.id} endpoint={'banco'} />


            <span
              data-bs-toggle="modal"
              data-bs-target="#edit_banco_modal"
              id="triggerModalEdit"
            >
            </span>

            <span
              data-bs-toggle="modal"
              data-bs-target="#delete"
              id="triggerModalEliminar"
            >
            </span>

          </div>
        </div>
      </div>

      <ToastContainer />

    </>
  );
};

export default BancoTable;
