import { User1, User2, User3, User4, User5 } from "../../Routes/ImagePath";

const ActivitiesData = {
    ActivitiesList: [
    {
        "user": "Leila Gavião",
        "avatar": User1,
        "activity": "adicionou novo(a) tarefa administração de ficheiros",
        "timestamp": "4 mins passados"
    },
    {
        "user": "João Lauriano",
        "avatar": User5,
        "activity": "adicionou Lucas Gaivião e Miguel ao projeto de cargas",
        "timestamp": "6 mins passados"
    },
    {
        "user": "Catarina Manuel",
        "avatar": User3,
        "activity": "concluio a tarefa limpar as viaturas",
        "timestamp": "12 mins passados"
    },
    {
        "user": "Bernardo Gaspar",
        "avatar": User4,
        "activity": "alterou o nome da tarefa Disponibilidade do doctor",
        "timestamp": "1 dias passados"
    },
    {
        "user": "Miguel Luis",
        "avatar": User2,
        "activity": "adicionou novo(a) tarefa video conferencia",
        "timestamp": "2 days passados"
    },
    {
        "user": "João Lauriano",
        "avatar": User5,
        "activity": "adicionou Josue and Bernardo Gaspar a terfa chat privado",
        "timestamp": "7 days passados"
    }
]
}

export default ActivitiesData;