import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import RecentFiles from "./recentFiles";
import Files from "./files";

import { format } from 'date-fns';

import Swal from "sweetalert2";

import AuthUser from "../../../../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../../../../components/Spinner";
import { useTranslation } from "react-i18next";
import DiretorioFicheiroModal from "../../../../../components/modelpopup/DiretorioFicheiroModal";

const FileManager = () => {

  const { t } = useTranslation();

  const [projetos, setProjetos] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getToken, user, getMissao, logout } = AuthUser();
  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [projetoSelecionado, setProjetoSelecionado] = useState(null);
  const [arquivosSelecionados, setArquivos] = useState(null);
  const [arquivoSelecionado, setArquivo] = useState(null);

  const [accao, setAccao] = useState(1);
  const [id, setID] = useState(0);
  const [nome, setNome] = useState(0);

  const navigate = useNavigate();

  const [ficheiros, setSelectedFile] = useState(null);
  const [anterior, setAnterior] = useState(-1);
  const [descricao, setDescricao] = useState();
  const [diretorioSelecionado, setDiretorio] = useState(-1);//para_id
  const [tipoSelecionado, setTipo] = useState(2); //1-root, 2 - folder, 3 - document


  // Function to filter tasks based on specified conditions
  const filterProjetos = (projetos) => {
    return projetos?.filter((project) => {
      // Excluir se a missão do projeto é diferente da missão na sessão
      if (getMissao()?.id != project.missao_id) {
        return false;
      }

      // Excluir se o projeto esta arquivado
      if (project.estado == 2) {
        return false;
      }

      // Se o tipo de usuário for 1, retorna true para todos os projetos
      if (user.tipo == 1) {
        return true;
      }

      // Se o projeto foi criado pelo usuário
      if (project.criado_por == user.id) {
        return true;
      }

      // Se o usuário é participante do projeto
      const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
      if (participante) {
        return true;
      }

      return false;

    });
  };

  useEffect(() => {

    setDiretorio(-1);
    setDescricao('Arquivos carregados no sistema');
    setTipo(2);

    if (getToken() == undefined) {
      logout();
      //return <Redirect to="/signIn" />;
      //props.history.push('/trainning/system/signIn');
      //history.push('');
      navigate("/");
    } else {

      const fetchData = async () => {
        try {
          // Chama a função assíncrona getUtilizador()

          await getProjetos();
          await getArquivos(-1);

        } catch (error) {
          console.error('Erro ao obter utilizadores:', error);
        }
      };

      // Chama a função fetchData() para iniciar o processo
      fetchData();

    }

  }, []);

  const getProjetos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/projetos/'+getMissao()?.id);

      setProjetos(filterProjetos(res.data.projetos));

      if (projetoSelecionado != null) {

        setProjetoSelecionado(null);

        // Encontrar o projeto selecionado
        const projetoEncontrado = projetos.find(project => project.id == projetoSelecionado?.id);
        setProjetoSelecionado(projetoEncontrado || null);

        setArquivos(projetoSelecionado?.arquivos);

      }


      console.log('projetos :');
      console.log(projetos);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getArquivos = async (para_id) => {
    try {

      setLoading(true);

      console.log('para_id', para_id)

      const formData = new FormData();
      formData.append('para_id', para_id);

      const response = await http.post('/arquivos/'+getMissao()?.id, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setArquivos(response.data?.arquivos);
      setAnterior(response.data?.anterior);


      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getProjetoArquivos = async (para_id) => {
    try {

      setLoading(true);

      const formData = new FormData();
      formData.append('para_id', para_id);

      const response = await http.post('/projetos/arquivos/'+getMissao()?.id, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setArquivos(response.data?.arquivos);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getProjeto = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/projeto/' + projetoSelecionado?.id);

      setProjetoSelecionado(res.data.projeto);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleFileChange = (e) => {
    const newFile = e.target.files;

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Chamar uploadFiles() apenas se houver arquivos selecionados
    if (newFile && newFile.length > 0) {
      if (projetoSelecionado)
        uploadFiles(newFile, projetoSelecionado?.id, 3);
      else
        uploadFilesGeral(newFile, diretorioSelecionado);
    }
  };

  const uploadFiles = async (ficheiros, para_id, tipo) => {
    try {

      const formData = new FormData();
      formData.append('missao_id', getMissao()?.id);
      formData.append('descricao', descricao);
      formData.append('para_id', para_id);
      formData.append('tipo', tipo);

      // Append each file separately
      if (ficheiros)
        for (let i = 0; i < ficheiros.length; i++) {
          formData.append('files[]', ficheiros[i]);
        }

      const response = await http.post('/projeto/uploadFiles', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setProjetoSelecionado(response?.data?.projeto);

      getArquivos(response?.data?.projeto?.id);

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {

          getProjetos();

        }
      });

    } catch (error) {
      console.error(error);

      toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const uploadFilesGeral = async (ficheiros, para_id) => {
    try {

      setLoading(true);

      console.log('para_id', para_id);

      const formData = new FormData();
      formData.append('missao_id', getMissao()?.id);
      formData.append('descricao', descricao);
      formData.append('para_id', para_id);
      formData.append('tipo', 3);

      // Append each file separately
      if (ficheiros)
        for (let i = 0; i < ficheiros.length; i++) {
          formData.append('files[]', ficheiros[i]);
        }

      const response = await http.post('/criar-arquivo', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setArquivos(response?.data?.arquivos);

      console.log(response.data.message);

      setLoading(false);

    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const diretorio_selecionado = (dir) => {

    //setDiretorio(dir);
    console.log('dir', dir);

    setArquivo(dir);
    setDiretorio(dir?.id);
    setTipo(3);
    setDescricao(dir?.descricao);

    getArquivos(dir?.id);

  }

  const chamarModalNovo = () => {

    setTipo(2);
    setAccao(1)
    setDescricao('');

    const editButton = document.getElementById('triggerModalEdit');

    if (editButton) {
      editButton.click();
    }

  }

  const setProjetoArquivos = (project) => {

    setProjetoSelecionado(project);
    setArquivos(projetoSelecionado?.arquivos);

    setDescricao(project?.projecto_code + ' - ' + project?.nome);

    getProjetoArquivos(project?.id);

  }

  const setArquivoUploaded = () => {

    setDescricao('Arquivos carregados no sistema');
    setDiretorio(-1);
    setTipo(2);
    setArquivo(null);
    setProjetoSelecionado(null);

    getArquivos(-1);

  }

  const setAccaoEid = (nome, accao, id) => {

    setNome(nome);
    setAccao(accao);
    setID(id);

    const editButton = document.getElementById('triggerModalEdit');

    if (editButton) {
      editButton.click();
    }

  }

  const irParaAnterior = () => {

    setDescricao(anterior ? anterior?.descricao : 'Arquivos carregados no sistema');

    setDiretorio(anterior ? anterior?.id : -1);
    setArquivo(anterior ? anterior : null);

    getArquivos(anterior ? anterior?.id : -1);

  }

  const diretorioCriado = (para_id) => {

    setDescricao(arquivoSelecionado?arquivoSelecionado?.descricao:'Arquivos carregados no sistema');

    getArquivos(para_id);

  }


  if (loading) return (<><Spinner><ToastContainer /></Spinner></>); else return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="file-wrap">
                <div className="file-sidebar">
                  <div className="file-header justify-content-center">
                    <span>{t('Projects')}</span>
                    <Link to="#" className="file-side-close">
                      <i className="fa-solid fa-xmark" />
                    </Link>
                  </div>
                  <form className="file-search">
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="fa-solid fa-magnifying-glass" />
                      </div>
                      <input
                        type="text"
                        className="form-control rounded-pill"
                        placeholder={t('Search')}
                      />
                    </div>
                  </form>
                  <div className="file-pro-list">
                    <div className="file-scroll">
                      <ul className="file-menu">

                        <li className="active mb-3" onClick={() => setArquivoUploaded()}>
                          <span ><i className="fa fa-folder"></i> &nbsp; Uploads diretos</span>
                        </li>

                        <li className="active mb-2">
                          <Link to="#">{t('All Projects')}</Link>
                        </li>

                        {projetos.map((project) => (
                          <li key={project?.id} className="mb-1 p-2" onClick={() => setProjetoArquivos(project)}>
                            <span>
                              {
                                project?.aprovado == 0 ? <><img width={24}
                                  height={24} title={t('Pending to approve')} src={url_root + "/storage/uploads/icons/siren.png"} /></> : project?.aprovado == 1 ? <span title={t('Approved')}><i className="fa fa-thumbs-up text-success"></i></span> : <span title={t('Negated')}><i className="fa fa-thumbs-down text-danger"></i></span>
                              }

                              {
                                (project?.aprovado == 0 || project?.aprovado == 2) ? <></> :
                                  project?.estado == 1 ? <><span
                                    className={"badge bg-inverse-success"}
                                  >
                                    <i className="fa fa-check"></i>&nbsp;&nbsp; <span>{t('Closed')}</span>
                                  </span>&nbsp;&nbsp;</> : ''
                              }

                              {
                                (project?.aprovado == 0 || project?.aprovado == 2) ? <></> : ((new Date(project?.data_fim) < new Date()) && project?.estado != 1) ? (<><span
                                  className={"badge bg-inverse-danger"}
                                >
                                  <i className="fa fa-clock"></i>&nbsp;&nbsp; <span>{t('Pending')}</span>
                                </span>&nbsp;&nbsp;</>) : (project?.estado != 1 ? (<span
                                  className={"badge bg-inverse-warning"}
                                >
                                  <i className="fa fa-list"></i>&nbsp;&nbsp; <span>{t('Opened')}</span>
                                </span>) : '')
                              }

                              <br></br>

                              {project?.projecto_code + ' - ' + project?.nome}{' '}[{format(new Date(project?.data_fim), 'dd/MM/yyyy')}]

                            </span>

                            <hr></hr>
                          </li>
                        ))}

                      </ul>
                      <div className="show-more">
                        <Link to="#">{t('Show More')}</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="file-cont-wrap">
                  <div className="file-cont-inner">
                    <div className="file-cont-header">

                      {(arquivoSelecionado != null) && (projetoSelecionado == null) ?
                        <div className="file-options">
                          <span className="btn-file" onClick={() => irParaAnterior()}>
                            <i className="fa fa-arrow-left" />
                          </span>{' '}
                        </div> : ''}

                      <div className="file-options">
                        <Link
                          to="#"
                          id="file_sidebar_toggle"
                          className="file-sidebar-toggle"
                        >
                          <i className="fa-solid fa-bars" />
                        </Link>
                      </div>
                      <span>{t('File Manager')}</span>

                      {projetoSelecionado == null ?
                        <div className="file-options">
                          <span className="btn-file" onClick={() => chamarModalNovo()}>
                            <i className="fa fa-folder" />
                          </span>{' '}
                        </div> : ''}

                      <div className="file-options">
                        <span className="btn-file">
                          <input type="file" className="upload" name="files[]" onChange={handleFileChange} id="files_edit" multiple="multiple" />
                          <i className="fa-solid fa-upload" />
                        </span>
                      </div>
                    </div>
                    <div className="file-content">
                      <form className="file-search">
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="fa-solid fa-magnifying-glass" />
                          </div>
                          <input
                            type="text"
                            className="form-control rounded-pill"
                            placeholder={t('Search')}
                          />
                        </div>
                      </form>
                      <div className="file-body">
                        <div className="file-scroll">
                          <div className="file-content-inner">

                            <h4><i className="fa fa-info-circle" onClick={() => getArquivos(diretorioSelecionado)}></i>{' '} {descricao}</h4>

                            <Files projeto={projetoSelecionado} handleSubmission={getProjetos} onFileNameEditSubmissionSuccess={getArquivos} onDiretorioSelecionado={diretorio_selecionado} arquivos={arquivosSelecionados} onReceberNome={setAccaoEid} />

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>


      <DiretorioFicheiroModal onFileNameSubmissionSuccess={diretorioCriado} name={descricao} tipo={tipoSelecionado} para_id={diretorioSelecionado} accao={accao} id={id} nomeEdit={nome} />


      <span
        data-bs-toggle="modal"
        data-bs-target="#add_file"
        id="triggerModalEdit"
      >
      </span>


    </>
  );
};

export default FileManager;
