import React, { useEffect, useState } from "react";
import { Attachment, User3 } from "../../../../../Routes/ImagePath";
import ChatContent from "./chatContent";
import { Link, useParams, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../../../components/AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Swal from "sweetalert2";


const ChatView = ({utilizador}) => {

  const [isActive, setIsActive] = useState(false);

  const { t } = useTranslation();  

  let { id } = useParams();
  let { tipo } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, user, getMissao } = AuthUser();

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [mensagem, setMensagem] = useState('');
  const [mensagem_tipo, setTipoMensagem] = useState(1);  

   const [conversa, setConversa] = useState([]);  

  const getConversa = async () => {
    try {

      const formData = new FormData();
      formData.append('userIdA', user?.id);
      formData.append('userIdB', id);

      const response = await http.post('/chat-direto', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setConversa(response.data.chat);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const enviar_mensagem = async () => {

    if(id!=0){

      if (verificarPermissao('PROJETOS', 'adicionar')) {
        try {
  
          if (mensagem.length > 0) {
  
            console.log('mensagem', mensagem);
  
            /*
    
           - para //1-direto, 2-grupo, 3-tarefa de projeto, 4-solicitações
           - mensagem_tipo // 1-texto, 2-ficheiro, 3-nota
           - nota_tipo // 1-criou tarefa, 2-editou a tarefa, 3-removeu a tarefa, 4-adicionou membro, 5-removeu membro, 6-conclui tarefa, 7-desmarcou conclusão da tarefa
    
          */
            const formData = new FormData();
            formData.append('missao_id', getMissao()?.id);
            formData.append('mensagem', mensagem);
            formData.append('emissor_id', user.id);
            formData.append('receptor_id', id);
            formData.append('projeto_id', 0);
            formData.append('para', 1);
            formData.append('para_id', id);
            formData.append('mensagem_tipo', mensagem_tipo);
            formData.append('ficheiro', '');
            formData.append('tipo_ficheiro', 0);
            formData.append('icon', '');
            formData.append('nota_tipo', 0);
            
             // Obter data e hora atuais
             const dataAtual = new Date();
             const dataFormatada = `${dataAtual.getFullYear()}-${String(dataAtual.getMonth() + 1).padStart(2, '0')}-${String(dataAtual.getDate()).padStart(2, '0')}`;
  
             const horaFormatada = `${String(dataAtual.getHours()).padStart(2, '0')}:${String(dataAtual.getMinutes()).padStart(2, '0')}`;
  
             formData.append('data', dataFormatada);
             formData.append('hora', horaFormatada);
            
  
            const response = await http.post('/register-chat', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });
  
            //console.log('conversa', response);
  
            setMensagem('');
  
            setConversa(response.data.data);
  
          }
  
        } catch (error) {
          console.error(error);
  
          toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      } else {
        Swal.fire({
          title: "SEM PERMISSÃO",
          text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
          type: "error",
          showCancelButton: !0,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Certo, entendi!",
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-danger ml-1",
          buttonsStyling: !1,
        });
      }


    }else {

      toast.error('Selecione primeiro um utilizador na bara lateral.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

   
  };


  const toggleClass = () => {
    setIsActive(!isActive);
  };

  const toggleClose = () => {
    setIsActive(false);
  };  

  useEffect(() => {

    const fetchData = async () => {

      try {
        // Chama a função assíncrona getUtilizador()       
        await getConversa();
      } catch (error) {
        console.error('Erro ao obter utilizadores:', error);
      }

    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();

  }, [utilizador]);


  const fetchMensagens = async () => {
    try {
      const formData = new FormData();
      formData.append('userIdA', user?.id);
      formData.append('userIdB', id);
  
      const response = await http.post('/chat-direto', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
  
      setConversa(response.data.chat);
      
    } catch (error) {
      console.error('Erro ao buscar mensagens:', error);
    }
  };

  // useEffect para buscar as mensagens inicialmente e então configurar a atualização periódica
  useEffect(() => {
    fetchMensagens(); // Busca as mensagens inicialmente

    // Configura a atualização periódica a cada 5 segundos (5000ms)
    const intervalId = setInterval(() => {
      fetchMensagens(); // Busca as mensagens periodicamente
    }, 5000); // Ajuste o intervalo conforme necessário

    // Retorna uma função de limpeza para limpar o intervalo quando o componente é desmontado ou o useEffect é re-executado
    return () => clearInterval(intervalId);
  }, [id]); // O array vazio indica que o useEffect só será executado uma vez após a montagem inicial do componente



  return (
    <>
      <div className="col-lg-9 message-view task-view">
        <div className="chat-window">
          <div className="fixed-header">
            <div className="navbar">
              <div className="user-details me-auto">
                <div className="float-start user-img">
                  <Link
                    className="avatar"
                    to={"/profile/"+utilizador?.id}
                    title={utilizador?.name}>
                    <img src={url_root + "/storage/" + utilizador?.fotografia} alt={utilizador?.name} title={utilizador?.name}  className="rounded-circle" />
                    <span className="status online" />
                  </Link>
                </div>
                <div className="user-info float-start">
                  <Link to={"/profile/"+utilizador?.id} title={utilizador?.name}>
                    <span>{utilizador?.name}</span>{" "}
                    <i className="typing-text"></i>
                  </Link>
                  {/* <span className="last-seen">{t('Last seen today at')}7:50</span> */}
                </div>
              </div>
              <div className="search-box">
                <div className="input-group input-group-sm">
                  <input
                    type="text"
                    placeholder={t('Search')}
                    className="form-control"
                  />
                  <button type="button" className="btn">
                    <i className="fa fa-search" />
                  </button>
                </div>
              </div>
              <ul className="nav custom-menu">
                <li className="nav-item">
                  <Link
                    className="nav-link task-chat profile-rightbar float-end"
                    id="task_chat"
                    to="#task_window"
                    onClick={toggleClass}>
                    <i className="fa fa-user" />
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    onClick={() => localStorage.setItem("minheight", "true")}
                    to="/call/voice-call"
                    className="nav-link">
                    <i className="fa fa-phone" />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/call/video-call" className="nav-link">
                    <i className="fa fa-video-camera" />
                  </Link>
                </li> */}
                <li className="nav-item dropdown dropdown-action">
                  <Link
                    aria-expanded="false"
                    data-bs-toggle="dropdown"
                    className="nav-link dropdown-toggle"
                    to="#">
                    <i className="fa fa-cog" />
                  </Link>
                  <div className="dropdown-menu dropdown-menu-right">
                    <Link to="#" className="dropdown-item">
                      {t('Delete Conversations')}
                    </Link>
                    <Link to="#" className="dropdown-item">
                    {t('Settings')}
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="chat-contents" onClick={toggleClose}>
            <div className="chat-content-wrap">
              <div className="chat-wrap-inner">
                <div className="chat-box">
                  <ChatContent utilizador={utilizador} conversa={conversa} />
                </div>
              </div>
            </div>
          </div>
          <div className="chat-footer">
            <div className="message-bar">
              <div className="message-inner">
                {/* <Link
                  className="link attach-icon"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#drag_files">
                  <img src={Attachment} alt="" />
                </Link> */}
                <div className="message-area">
                  <div className="input-group">
                    <textarea
                      className="form-control"
                      placeholder={t('Type message...')}
                      value={mensagem}
                      onChange={e => setMensagem(e.target.value)}
                    />
                    <span className="input-group-append">
                      <span className="btn btn-custom" onClick={() => enviar_mensagem()}>
                        <i className="fa-solid fa-paper-plane" />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

     
    </>
  );
};

export default ChatView;
