/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React from "react";

import { Link } from "react-router-dom";
import {
  Avatar_02,
  Avatar_04,
  Avatar_05,
  Avatar_07,
  Avatar_08,
  Avatar_09,
  User1,
  User10,
  User11,
  User12,
  User13,
  User2,
  User5,
  User8,
  User9,
} from "../../../../../Routes/ImagePath";

import { useTranslation } from "react-i18next";

const EmployeeDashboard = () => {
  // const [menu, setMenu] = useState(false);

  // const toggleMobileMenu = () => {
  //   setMenu(!menu);
  // };

  const { t } = useTranslation();

  return (
    <>
      <div className="page-wrapper">

        {/* Page Content */}
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="welcome-box">
                <div className="welcome-img">
                  <img alt="" src={User9} />
                </div>
                <div className="welcome-det">
                  <h3>{t('Welcome')}, Diogo Luís</h3>
                  <p>Monday, 20 May 2023</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <section className="dash-section">
                <h1 className="dash-sec-title">{t('Today')}</h1>
                <div className="dash-sec-content">
                  <div className="dash-info-list">
                    <Link to="#" className="dash-card text-danger">
                      <div className="dash-card-container">
                        <div className="dash-card-icon">
                          <i className="fa-regular fa-hourglass" />
                        </div>
                        <div className="dash-card-content">
                          <p>Richard Miles {t('is off sick today')}</p>
                        </div>
                        <div className="dash-card-avatars">
                          <div className="e-avatar">
                            <img src={User9} alt="" />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="dash-info-list">
                    <Link to="#" className="dash-card">
                      <div className="dash-card-container">
                        <div className="dash-card-icon">
                          <i className="fa fa-suitcase" />
                        </div>
                        <div className="dash-card-content">
                          <p>{t('You are away today')}</p>
                        </div>
                        <div className="dash-card-avatars">
                          <div className="e-avatar">
                            <img src={User5} alt="" />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="dash-info-list">
                    <Link to="#" className="dash-card">
                      <div className="dash-card-container">
                        <div className="dash-card-icon">
                          <i className="fa-regular fa-building" />
                        </div>
                        <div className="dash-card-content">
                          <p>{t('You are working from home today')}</p>
                        </div>
                        <div className="dash-card-avatars">
                          <div className="e-avatar">
                            <img src={User5} alt="" />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </section>
              <section className="dash-section">
                <h1 className="dash-sec-title">{t('Tomorrow')}</h1>
                <div className="dash-sec-content">
                  <div className="dash-info-list">
                    <div className="dash-card">
                      <div className="dash-card-container">
                        <div className="dash-card-icon">
                          <i className="fa fa-suitcase" />
                        </div>
                        <div className="dash-card-content">
                          <p>2 {t('people will be away tomorrow')}</p>
                        </div>
                        <div className="dash-card-avatars">
                          <Link to="#" className="e-avatar">
                            <img src={User10} alt="" />
                          </Link>
                          <Link to="#" className="e-avatar">
                            <img src={User11} alt="" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="dash-section">
                <h1 className="dash-sec-title">{t('Next seven days')}</h1>
                <div className="dash-sec-content">
                  <div className="dash-info-list">
                    <div className="dash-card">
                      <div className="dash-card-container">
                        <div className="dash-card-icon">
                          <i className="fa fa-suitcase" />
                        </div>
                        <div className="dash-card-content">
                          <p>2 {t('people are going to be away')}</p>
                        </div>
                        <div className="dash-card-avatars">
                          <Link to="#" className="e-avatar">
                            <img src={User12} alt="" />
                          </Link>
                          <Link to="#" className="e-avatar">
                            <img src={User13} alt="" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dash-info-list">
                    <div className="dash-card">
                      <div className="dash-card-container">
                        <div className="dash-card-icon">
                          <i className="fa fa-user-plus" />
                        </div>
                        <div className="dash-card-content">
                          <p>{t('Your first day is going to be ')} segunda-feira</p>
                        </div>
                        <div className="dash-card-avatars">
                          <div className="e-avatar">
                            <img src={User2} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dash-info-list">
                    <Link to="#" className="dash-card">
                      <div className="dash-card-container">
                        <div className="dash-card-icon">
                          <i className="fa fa-calendar" />
                        </div>
                        <div className="dash-card-content">
                          <p>{t('Its Holiday on ')} Terca-feira</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </section>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="dash-sidebar">
                <section>
                  <h5 className="dash-title">{t('Projects')}</h5>
                  <div className="card">
                    <div className="card-body">
                      <div className="time-list">
                        <div className="dash-stats-list">
                          <h4>71</h4>
                          <p>{t('Total Tasks')}</p>
                        </div>
                        <div className="dash-stats-list">
                          <h4>14</h4>
                          <p>{t('Pending Tasks')}</p>
                        </div>
                      </div>
                      <div className="request-btn">
                        <div className="dash-stats-list">
                          <h4>2</h4>
                          <p>{t('Total Projects')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <h5 className="dash-title">{t('Your Leave')}</h5>
                  <div className="card">
                    <div className="card-body">
                      <div className="time-list">
                        <div className="dash-stats-list">
                          <h4>4.5</h4>
                          <p>{t('Leave Taken')}</p>
                        </div>
                        <div className="dash-stats-list">
                          <h4>12</h4>
                          <p>{t('Remaining')}</p>
                        </div>
                      </div>
                      <div className="request-btn">
                        <Link className="btn btn-primary" to="#">
                        {t('Apply Leave')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <h5 className="dash-title">{t('Your time off allowance')}</h5>
                  <div className="card">
                    <div className="card-body">
                      <div className="time-list">
                        <div className="dash-stats-list">
                          <h4>5.0 {t('Hours')}</h4>
                          <p>{t('Approved')}</p>
                        </div>
                        <div className="dash-stats-list">
                          <h4>15 {t('Hours')}</h4>
                          <p>{t('Remaining')}</p>
                        </div>
                      </div>
                      <div className="request-btn">
                        <Link className="btn btn-primary" to="#">
                        {t('Apply Time Off')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <h5 className="dash-title">{t('Upcoming Holiday')}</h5>
                  <div className="card">
                    <div className="card-body text-center">
                      <h4 className="holiday-title mb-0">
                        Mon 20 May 2023 - Ramzan
                      </h4>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </>
  );
};

export default EmployeeDashboard;
