import React from "react";

import Breadcrumbs from "../../../../../components/Breadcrumbs";
import ManageJobResumesTable from "./ManageResumeTable";

import { useTranslation } from "react-i18next";

const ManageJobResumes = () => {

  const { t } = useTranslation();

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle={t('Manage Resumes')}
            title="Dashboard"
            subtitle={t('Jobs')+' / '+t('Manage Resumes')}
          />
          <ManageJobResumesTable />
        </div>
      </div>
    </>
  );
};

export default ManageJobResumes;
