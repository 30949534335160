import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  User4,
  User5,
  User6,
  User7,
  User8,
} from "../../Routes/ImagePath";

import { useTranslation } from "react-i18next";

import AuthUser from "../AuthUser";

const ChatSidebar = () => {

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, user, getMissao } = AuthUser();

  const [utilizadores, setUtilizadores] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const getUtilizadores = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/utilizadores/ativados/'+getMissao().id);

      setUtilizadores(res.data.utilizadores);

      console.log('Utilizadores : ');
      console.log(res.data.utilizadores);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  useEffect(() => {

    const fetchData = async () => {
      try {
        // Chama a função assíncrona getUtilizador()

        await getUtilizadores();

      } catch (error) {
        console.error('Erro ao obter utilizadores:', error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, []);

  return (
    <div>
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <nav className="greedy">
              <ul className="link-item">
                <li>
                  <Link to="/admin-dashboard">
                    <i className="la la-home" /> <span>{t('Back to Home')}</span>
                  </Link>
                </li>
                {/* <li className="menu-title">
                  <span>{t('Chat Groups')}</span>
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#add_group"
                  >
                    <i className="fa-solid fa-plus" />
                  </Link>
                </li>
                <li>
                  <Link to="/call/chat/1/0">
                    <span className="chat-avatar-sm user-img">
                      <img
                        className="rounded-circle"
                        src={User7}
                        alt="UserImage"
                      />
                    </span>
                    <span className="chat-user">#Geral</span>
                  </Link>
                </li>
                <li>
                  <Link to="/call/chat/1/0">
                    <span className="chat-avatar-sm user-img">
                      <img
                        className="rounded-circle"
                        src={User7}
                        alt="UserImage"
                      />
                    </span>
                    <span className="chat-user">#Grafica</span>
                  </Link>
                </li>
                <li>
                  <Link to="/call/chat/1/0">
                    <span className="chat-avatar-sm user-img">
                      <img
                        className="rounded-circle"
                        src={User7}
                        alt="UserImage"
                      />
                    </span>
                    <span className="chat-user">#operacoes</span>
                  </Link>
                </li>
                <li>
                  <Link to="/call/chat/1/0">
                    <span className="chat-avatar-sm user-img">
                      <img
                        className="rounded-circle"
                        src={User7}
                        alt="UserImage"
                      />
                    </span>
                    <span className="chat-user">#marketing</span>
                  </Link>
                </li> */}
                <li className="menu-title">
                  {t('Direct Chats')}
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#add_chat_user"
                  >
                    <i className="fa-solid fa-plus" />
                  </Link>
                </li>

                {utilizadores?.filter(utilizador => utilizador.id != user.id).map(user => (

                  <li key={user?.id}>

                    <Link to={"/call/chat/1/"+user?.id}>
                      <span className="chat-avatar-sm user-img">
                        <img
                          className="rounded-circle"
                          src={url_root + "/storage/" + user?.fotografia} alt={user?.name} title={user?.name}
                        />
                        <span className="status online" />
                      </span>
                      <span className="chat-user">{user?.name}</span>{" "}
                      {/* <span className="badge rounded-pill bg-danger">1</span> */}
                    </Link>

                  </li>

                ))}

              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatSidebar;
