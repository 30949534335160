import React from "react";

import Breadcrumbs from "../../../../../components/Breadcrumbs";
import OfferApprovalTable from "./OfferApprovalTable";

import { useTranslation } from "react-i18next";

const OfferApprovals = () => {

  const { t } = useTranslation();

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle={t('Offer Approvals')}
            title="Dashboard"
            subtitle={t('Jobs')+' / '+t('Offer Approvals')}
          />

          <OfferApprovalTable />
        </div>
      </div>
    </>
  );
};

export default OfferApprovals;
