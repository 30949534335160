import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import AuthUser from "../../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../components/Spinner";
import BancoFilter from "../Administration/financas/BancoFilter";
import BancoTable from "../Administration/financas/BancoTable";
import BancoModal from "../../../components/Administration/financas/BancoModal";
import { startOfMonth, endOfMonth, format } from "date-fns";
import { Link } from "react-router-dom";
import CaixaMovimentoModal from "../../../components/Administration/financas/CaixaMovimentoModal";
import DeleteModal from "../../../components/modelpopup/DeleteModal";

const Caixa = () => {
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } = AuthUser();

  const [data1, setData1] = useState();
  const [data2, setData2] = useState();

  const [metodo, setMetodo] = useState([]);
  const [conta_id, setContaID] = useState([]);
  const [caixa, setCaixa] = useState([]);
  const [tipo, setTipo] = useState([]);
  const [rotulo_id, setRotuloID] = useState([]);
  const [cliente_id, setClienteID] = useState([]);

  const [bancos, setBancos] = useState([]);
  const [contas, setContas] = useState([]);
  const [rotulos, setRotulos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [movimentos, setMovimentos] = useState([]);
  const [movimentoSelecionado, setMovimentoSelecionado] = useState([]);
  const [filteredBancos, setfilteredBancos] = useState([]);
  const [banco_id, setBancoID] = useState(-1);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible((prevVisible) => !prevVisible);
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat("pt-PT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const handleFilterSubmit = (filteredBancos) => {
    setfilteredBancos(filteredBancos);
  };

  const handleSubmissionSuccess = (movimentos) => {
    setMovimentos(movimentos);
  };

  const renderOptionBancos = (bancos) => {
    return bancos?.map((banco) => (
      <React.Fragment key={banco.id}>
        <option value={banco.id}>{banco.nome}</option>
      </React.Fragment>
    ));
  };

  const renderOptionRotulos = (rotulos) => {
    return rotulos?.map((rotulo) => (
      <React.Fragment key={rotulo.id}>
        <option value={rotulo.id}>{rotulo.nome}</option>
      </React.Fragment>
    ));
  };

  const renderOptionClientes = (clientes) => {
    return clientes?.map((cliente) => (
      <React.Fragment key={cliente.id}>
        <option value={cliente.id}>{cliente.nome}</option>
      </React.Fragment>
    ));
  };

  const getBancos = async () => {
    try {
      setLoading(true);
      const res = await http.get("/bancos");
      setBancos(res.data.bancos);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const pesquisar = async () => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("rotulo_id", rotulo_id);
      formData.append("metodo", metodo);
      formData.append("tipo", tipo);
      formData.append("caixa", caixa);
      formData.append("conta_id", conta_id);
      formData.append("cliente_id", cliente_id);
      formData.append("data1", data1);
      formData.append("data2", data2);
      formData.append("missao_id", getMissao()?.id);

      const response = await http.post("/movimento/pesquisar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setMovimentos(response.data.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        }
      );
    }
  };

  const exportar_pdf = async () => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("rotulo_id", rotulo_id);
      formData.append("metodo", metodo);
      formData.append("tipo", tipo);
      formData.append("caixa", caixa);
      formData.append("conta_id", conta_id);
      formData.append("cliente_id", cliente_id);
      formData.append("data1", data1);
      formData.append("data2", data2);
      formData.append("missao_id", getMissao()?.id);

      const response = await http.post("/movimento-financeiro/pdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setLoading(false);

      //console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      if(response?.data?.url)
        window.open(response?.data?.url, "_blank");

    } catch (error) {
      setLoading(false);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        }
      );
    }
  };

  const encontrarBancoPorId = (id) => {
    // Encontre o objeto com a ID correspondente
    const bancoEncontrado = bancos.find((lista) => lista.id == id);

    // Verifique se o objeto foi encontrado
    if (bancoEncontrado != undefined) {
      return bancoEncontrado;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }
  };

  const renderOptionContas = (contas) => {
    return contas?.map((conta) => (
      <React.Fragment key={conta.id}>
        <option value={conta.id}>
          {encontrarBancoPorId(conta?.banco_id)?.nome} - {conta.conta}
        </option>
      </React.Fragment>
    ));
  };

  const getMovimentos = async () => {
    try {
      setLoading(true);
      const res = await http.get("/movimentos/"+getMissao()?.id);
      setMovimentos(res.data.movimentos);
      setContas(res.data.contas);
      setClientes(res.data.clientes);
      setRotulos(res.data.rotulos);
      setBancos(res?.data?.bancos);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const currentDate = new Date();

    setData1(format(startOfMonth(currentDate), "yyyy-MM-dd"));
    setData2(format(endOfMonth(currentDate), "yyyy-MM-dd"));

    const fetchData = async () => {
      try {
        await getMovimentos();
      } catch (error) {
        console.error("Erro ao obter bancos:", error);
      }
    };
    fetchData();
  }, []);

  const chamarModalEdit = (idSelecionado) => {
    setMovimentoSelecionado(idSelecionado);
    const editButton = document.getElementById("triggerModalEdit");
    if (editButton) {
      editButton.click();
    }
  };

  const chamarModalEliminar = (idSelecionado) => {
    setMovimentoSelecionado(idSelecionado);
    const editButton = document.getElementById("triggerModalEliminar");
    if (editButton) {
      editButton.click();
    }
  };

  const getTotalValor = () => {
    return getTotalEntrada() - getTotalSaida();
  };

  const getTotalSaida = () => {
    return movimentos.reduce((total, movimento) => {
      return movimento.tipo == 2 ? total + parseFloat(movimento.valor) : total;
    }, 0);
  };

  const getTotalEntrada = () => {
    return movimentos.reduce((total, movimento) => {
      return movimento.tipo == 1 ? total + parseFloat(movimento.valor) : total;
    }, 0);
  };

  if (verificarPermissao("CARGO", "ver"))
    return (
      <>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <Breadcrumbs
              maintitle={t("Movimentos Financeiros")}
              title="Dashboard"
              subtitle={t("Movimentos Fincaceiros")}
              modal="#add_movimento_modal"
              name={t("Add Movimento")}
            />

            <div className="row">
              <div className="col-md-12">
                <div className="card-group m-b-30">
                  {/* Total */}
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between mb-3">
                        <div>
                          <span className="d-block">{t("Saldo do periodo")}</span>
                        </div>
                        <div>
                          <span className={"text-primary"}>
                            {Math.round(
                              (100 * getTotalValor()) / getTotalValor()
                            )}
                            %
                          </span>
                        </div>
                      </div>
                      <h3 className="mb-3">{formatNumber(getTotalValor())}</h3>
                      <div className="progress mb-2" style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-primary"
                          role="progressbar"
                          style={{
                            width: `${Math.round(
                              (100 * getTotalEntrada()) / getTotalEntrada()
                            )}%`,
                          }}
                          aria-valuenow={40}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Entrada */}
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between mb-3">
                        <div>
                          <span className="d-block">{t("Entradas")}</span>
                        </div>
                        <div>
                          <span className={"text-success"}>
                            {Math.round(
                              (100 * getTotalEntrada()) / getTotalValor()
                            )
                              ? Math.round(
                                  (100 * getTotalEntrada()) / getTotalValor()
                                )
                              : 0}
                            %
                          </span>
                        </div>
                      </div>

                      <h3 className="mb-3">
                        {formatNumber(getTotalEntrada())}
                      </h3>

                      <div className="progress mb-2" style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{
                            width: `${Math.round(
                              (100 * getTotalEntrada()) / getTotalValor()
                            )}%`,
                          }}
                          aria-valuenow={40}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Saidas */}
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between mb-3">
                        <div>
                          <span className="d-block">{t("Saidas")}</span>
                        </div>
                        <div>
                          <span className={"text-danger"}>
                            {Math.round(
                              (100 * getTotalSaida()) / getTotalValor()
                            )
                              ? Math.round(
                                  (100 * getTotalSaida()) / getTotalValor()
                                )
                              : 0}
                            %
                          </span>
                        </div>
                      </div>
                      <h3 className="mb-3">{formatNumber(getTotalSaida())}</h3>
                      <div className="progress mb-2" style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-danger"
                          role="progressbar"
                          style={{
                            width: `${Math.round(
                              (100 * getTotalSaida()) / getTotalValor()
                            )}%`,
                          }}
                          aria-valuenow={40}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row filter-row space">
              <div className="col-sm-6 col-md-2">
                <div className="input-block form-focus select-focus">
                  <label className="focus-label">{t("Data 1")}</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => setData1(e.target.value)}
                    value={data1}
                  />
                </div>
              </div>

              <div className="col-sm-6 col-md-2">
                <div className="input-block form-focus select-focus">
                  <label className="focus-label">{t("Data 2")}</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => setData2(e.target.value)}
                    value={data2}
                  />
                </div>
              </div>

              <div className="col-sm-12 col-md-2">
                <div className="input-block form-focus select-focus">
                  <label className="focus-label">{t("Conta Bancaria")}</label>
                  <select
                    onChange={(e) => setContaID(e.target.value)}
                    value={conta_id}
                    className="form-control select"
                  >
                    <option key={0} value={-1}>
                      -
                    </option>
                    {renderOptionContas(contas)}
                  </select>
                </div>
              </div>

              <div className="col-sm-6 col-md-2">
                <div className="input-block form-focus select-focus">
                  <select
                    className="form-control"
                    onChange={(e) => setCaixa(e.target.value)}
                    value={caixa}
                  >
                    <option value="-1">- </option>
                    <option value="1">Sim</option>
                    <option value="2">Não</option>
                  </select>
                  <label className="focus-label">
                    {t("Movimento De Caixa")}
                  </label>
                </div>
              </div>

              <div className="col-sm-6 col-md-2">
                <div className="input-block form-focus select-focus">
                  <select
                    className="form-control"
                    onChange={(e) => setTipo(e.target.value)}
                    value={tipo}
                  >
                    <option value="-1">-</option>
                    <option value="2">Despesa</option>
                    <option value="1">Receita</option>
                  </select>
                  <label className="focus-label">
                    {t("Despesa ou Receita")}
                  </label>
                </div>
              </div>

              <div className="col-sm-6 col-md-1">
                {!loading ? (
                  <>
                    <span
                      className="btn btn-success btn-block w-100"
                      onClick={() => pesquisar()}
                    >
                      <i className="fa fa-search"></i>
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="col-sm-6 col-md-1">
                <span
                  className="btn btn-dark btn-block w-100"
                  onClick={toggleDropdown}
                >
                  <i className="fa fa-cogs"></i>
                </span>
              </div>

              <div className={`${dropdownVisible ? "d-block" : "d-none"}`}>
                <div className="row">
                  <div className="col-sm-12 col-md-3">
                    <div className="input-block form-focus select-focus">
                      <label className="focus-label">
                        {t("Tipos de movimentos")}
                      </label>
                      <select
                        onChange={(e) => setRotuloID(e.target.value)}
                        value={rotulo_id}
                        className="form-control select"
                      >
                        <option key={0} value={-1}>
                          -
                        </option>
                        {renderOptionRotulos(rotulos)}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="input-block form-focus select-focus">
                      <label className="focus-label">{t("Cliente")}</label>
                      <select
                        onChange={(e) => setClienteID(e.target.value)}
                        value={cliente_id}
                        className="form-control select"
                      >
                        <option key={0} value={-1}>
                          -
                        </option>
                        {renderOptionClientes(clientes)}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="input-block form-focus select-focus">
                      <label className="focus-label">{t("Metodo")}</label>
                      <select
                        onChange={(e) => setMetodo(e.target.value)}
                        value={metodo}
                        className="form-control select"
                      >
                        <option value="-1">-</option>
                        <option value="1">Dinheiro</option>
                        <option value="2">Transferencia</option>
                        <option value="3">TPA</option>
                        <option value="4">Outro</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-1">
                    {!loading ? (
                      <>
                        <span
                          className="btn btn-danger btn-block w-100"
                          onClick={() => exportar_pdf()}
                        >
                          <i className="fa fa-file-pdf"></i>
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <span className="">
                      <i className="fa fa-trash" /> {t("Eliminar")}
                    </span>
                  </div>
                </div>
                <hr></hr>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="table-responsive">
                  <table className="table table-striped table-responsive">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Data</th>
                        <th>Tipo</th>
                        <th>Valor(kz)</th>
                        <th>Caixa</th>
                        <th>Conta 1</th>
                        <th>Conta 2</th>
                        <th>Descrição</th>
                        <th>Nota</th>
                        <th>Cliente/Fornecedor</th>
                        <th>
                          <i className="fa fa-cogs"></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {movimentos?.slice(0, 356).map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{format(new Date(item?.data), "dd/MM/yyyy")}</td>
                          <td>
                            {item?.tipo == 2 ? (
                              <span className="btn btn-white btn-sm btn-rounded">
                                <i className="far fa-dot-circle text-danger" />{" "}
                                {t("Saida")}
                              </span>
                            ) : (
                              <span className="btn btn-white btn-sm btn-rounded">
                                <i className="far fa-dot-circle text-success" />{" "}
                                {t("Entrada")}
                              </span>
                            )}
                          </td>
                          <td>
                            {formatNumber(item?.valor)}
                            <br></br> {item?.metodo == 1 ? "[Dinheiro]" : ""}
                            {item?.metodo == 2 ? "[Transferencia]" : ""}
                            {item?.metodo == 3 ? "[TPA]" : ""}
                            {item?.metodo == 4 ? "[Outro]" : ""}
                          </td>
                          <td>{item?.caixa == 1 ? "Sim" : "Não"}</td>
                          <td>
                            <div className="table-avatar">
                              {item?.conta_id == null ||
                              item?.conta_id == -1 ? (
                                " - "
                              ) : (
                                <>
                                  <Link to="#" className="avatar">
                                    <img
                                      src={
                                        url_root +
                                        "/storage/" +
                                        item?.conta?.banco?.logo
                                      }
                                    />
                                  </Link>
                                  <Link to="#">
                                    <span>{item?.conta?.conta}</span>||
                                    <span>{item?.conta?.IBAN}</span>
                                  </Link>
                                  <br></br>
                                  <span>
                                    {item?.tipo == 1 ? "[Entrou]" : "[Saiu]"}
                                  </span>
                                </>
                              )}
                            </div>
                          </td>

                          <td>
                            <div className="table-avatar">
                              {item?.conta_secundaria_id == null ||
                              item?.conta_secundaria_id == -1 ? (
                                " - "
                              ) : (
                                <>
                                  <Link to="#" className="avatar">
                                    <img
                                      src={
                                        url_root +
                                        "/storage/" +
                                        item?.conta_secundaria?.banco?.logo
                                      }
                                    />
                                  </Link>
                                  <Link to="#">
                                    <span>{item?.conta_secundaria?.conta}</span>
                                    ||
                                    <span>{item?.conta_secundaria?.IBAN}</span>
                                  </Link>
                                  <br></br>
                                  <span>
                                    {item?.tipo == 1 ? "[Saiu]" : "[Entrou]"}
                                  </span>
                                </>
                              )}
                            </div>
                          </td>

                          <td>{item?.rotulo?.nome}</td>
                          <td>{item?.nota}</td>
                          <td>{item?.cliente?.nome}</td>
                          <td>
                            <div className="dropdown dropdown-action text-end">
                              <Link
                                to="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="material-icons">more_vert</i>
                              </Link>

                              <div className="dropdown-menu dropdown-menu-right">
                                <span
                                  className="dropdown-item"
                                  title={item?.nome}
                                  onClick={() => chamarModalEdit(item)}
                                >
                                  <i className="fa fa-pencil m-r-5" />{" "}
                                  {t("Edit")}
                                </span>

                                <span
                                  className="dropdown-item"
                                  onClick={() => chamarModalEliminar(item)}
                                >
                                  <i className="fa fa-trash m-r-5" />{" "}
                                  {t("Delete")}
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {loading ? (
              <>
                <Spinner></Spinner>
              </>
            ) : (
              <></>
            )}

            <CaixaMovimentoModal
              onSubmissionSuccess={handleSubmissionSuccess}
              rotulos={rotulos}
              clientes={clientes}
              contas={contas}
              movimento={movimentoSelecionado}
              bancos={bancos}
            />

            <DeleteModal
              objectoName={t("Movimento")}
              onSubmissionSuccess={handleSubmissionSuccess}
              id={movimentoSelecionado?.id}
              endpoint={"movimento"}
            />

            <span
              data-bs-toggle="modal"
              data-bs-target="#edit_movimento_modal"
              id="triggerModalEdit"
            ></span>

            <span
              data-bs-toggle="modal"
              data-bs-target="#delete"
              id="triggerModalEliminar"
            ></span>
          </div>
        </div>{" "}
      </>
    );
  else
    return (
      <>
        <div className="page-wrapper cardhead">
          <div className="content ">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">SEM PERMISSÃO</h3>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      <i className="fa fa-times-circle text-danger"></i>&nbsp;
                      OPERAÇÃO NÃO AUTORIZADA
                    </h4>
                  </div>
                  <div className="card-body">
                    <p>
                      Esta operação não é autorizada para o seu nivel de acesso
                      ao sistema, por favor contacte o administrador do sistema.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default Caixa;
