import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Avatar_16,
  Avatar_02,
  Avatar_05,
  Avatar_09,
  Avatar_10,
} from "../../../Routes/ImagePath/index";

import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import React, { useState, useEffect } from "react";

import { startOfMonth, endOfMonth, format } from "date-fns";

import AuthUser from "../../AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../../Spinner";

import Swal from "sweetalert2";

const PostoDoColaboradorModal = ({ clientes, colaborador }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const {
    http,
    url_root,
    user,
    user_permissoes,
    verificarPermissao,
    getMissao,
  } = AuthUser();

  const regimeMap = {
    0: "Normal",
    1: "12/12",
    2: "12/24",
    3: "12/48",
    4: "24/12",
    5: "24/24",
    6: "24/48",
    7: "48/24",
    8: "48/48",
  };

  const [cliente_id, setClienteID] = useState("");
  const [colaborador_postos, setColaboradorPostos] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event("click"));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getPostosDoColaborador(colaborador);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [colaborador]);

  const getPostosDoColaborador = async (colaborador) => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("colaborador_id", colaborador?.id);

      const response = await http.post("/postos/do-colaborador", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      // Converte o objeto em um array
      const postosArray = Object.values(response.data.postos);
      console.log("Dados dos postos como array:", postosArray); // Log para verificar

      setColaboradorPostos(postosArray);

      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // REGISTO NOVO

  const submitFormNovo = async () => {
    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {
        toggleLoading(true);

        await registar();
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else {
          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      console.log("Clicou em submeter");

      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, movimentocte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const registar = async () => {
    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {
        const formData = new FormData();

        formData.append("colaborador_id", colaborador?.id);
        formData.append("cliente_id", cliente_id);
        formData.append("missao_id", getMissao()?.id);

        const response = await http.post(
          "vincular/colaborador/posto",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        // Converte o objeto em um array
        const postosArray = Object.values(response.data.postos);
        console.log("Dados dos postos como array:", postosArray); // Log para verificar

        setColaboradorPostos(postosArray);
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, movimentocte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const submitFormEliminarVinculo = async (id) => {
    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {
        Swal.fire({
          title: "Remover vinculo entre o posto e colaborador",
          text: "Tem a certeza que pretende remover este colaborador deste posto ?",
          type: "confirm",
          showCancelButton: !0,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!",
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-danger ml-1",
          buttonsStyling: !1,
        }).then(async function (t) {
          if (t.isConfirmed) {
            toggleLoading(true);

            await removerVinculo(id);
          }
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else {
          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      console.log("Clicou em submeter");

      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, movimentocte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const removerVinculo = async (id) => {
    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {
        const formData = new FormData();

        formData.append("id", id);

        const response = await http.post(
          "/remover/colaborador/posto",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        // Converte o objeto em um array
        const postosArray = Object.values(response.data.postos);
        console.log("Dados dos postos como array:", postosArray); // Log para verificar

        setColaboradorPostos(postosArray);
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, movimentocte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const renderOptionPostos = (postos) => {
    const regimeMap = {
      0: "Normal",
      1: "12/12",
      2: "12/24",
      3: "12/48",
      4: "24/12",
      5: "24/24",
      6: "24/48",
      7: "48/24",
      8: "48/48",
    };

    return postos?.map((posto) => (
      <React.Fragment key={posto.id}>
        <option value={posto.id}>
          {posto?.cliente?.nome} - {posto.codigo} {posto.nome} -{" "}
          {regimeMap[posto.regime]} - {posto?.zona?.nome}
        </option>
      </React.Fragment>
    ));
  };

  return (
    <>
      <>
        {/* Add Posto Colaborador Modal */}
        <div
          id="add_posto_colaborador_modal"
          className="modal modal-lg custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  {t("Postos Do Colaborador : " + colaborador?.name)}
                </h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  id="closeButton30"
                >
                  ×
                </button>
              </div>
              <div className="modal-body">
                <div className="card">
                  <div className="card-body">
                    <hr className="mt-4"></hr>
                    <form>
                      <div className="row mt-4">
                        <div className="col-md-8">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <select
                              className="form-control select"
                              onChange={(e) => setClienteID(e.target.value)}
                            >
                              <option value="-1">- Nenhum -</option>
                              {renderOptionPostos(clientes)}
                            </select>

                            <label className="focus-label">
                              {t("Posto Do Cliente")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <a
                            className="btn btn-primary submit-btn"
                            onClick={submitFormNovo}
                          >
                            {loading ? "Carregando" : t("Vincular")}
                          </a>
                        </div>

                        <hr className="mt-2"></hr>

                        <div>
                          <h2>
                            <i
                              onClick={() =>
                                getPostosDoColaborador(colaborador)
                              }
                              className="fa fa-recycle"
                              title="Atualizar a lista"
                            ></i>{" "}
                            Lista de Postos do Colaborador
                          </h2>
                          <div className="table-responsive">
                            <table className="table table-nowrap">
                              <thead>
                                <tr>
                                  <th>
                                    <i className="fa fa-cogs"></i>
                                  </th>
                                  <th>Nome do Cliente</th>
                                  <th>Nome do Posto</th>
                                  <th>Código do Posto</th>
                                  <th>Regime</th>
                                  <th>Zona</th>
                                </tr>
                              </thead>
                              <tbody>
                                {colaborador_postos &&
                                colaborador_postos.length > 0 ? (
                                  colaborador_postos.map((posto, index) => (
                                    <tr key={index}>
                                      <td>
                                        <i
                                          onClick={() =>
                                            submitFormEliminarVinculo(
                                              posto?.id_relacao
                                            )
                                          }
                                          title="Remover vinculo"
                                          className="fa fa-trash text-danger"
                                        ></i>
                                      </td>
                                      <td>{posto.nome_cliente}</td>
                                      <td>{posto.nome_posto}</td>
                                      <td>{posto.codigo_posto}</td>
                                      <td>{regimeMap[posto.regime]}</td>
                                      <td>{posto.zona}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="6">Nenhum posto encontrado</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Add Posto Colaborador Modal */}

        <ToastContainer />
      </>
    </>
  );
};

export default PostoDoColaboradorModal;
