import { Avatar_01, Avatar_02, Avatar_05, Avatar_09, Avatar_10, Avatar_11, Avatar_12, Avatar_13, Avatar_16 } from "../../Routes/ImagePath";

const contactsData = {
  Contacts: [
    {
        "name": "John Doe",
        "jobTitle": "Web Developer",
        "imageSrc": Avatar_02
      },
      {
        "name": "Richard Miles",
        "jobTitle": "Web Developer",
        "imageSrc": Avatar_09
      },
      {
        "name": "John Smith",
        "jobTitle": "Android Developer",
        "imageSrc": Avatar_10
      },
      {
        "name": "Miguel Luis",
        "jobTitle": "IOS Developer",
        "imageSrc": Avatar_05
      },
      {
        "name": "Wilmer Deluna",
        "jobTitle": "Team Leader",
        "imageSrc": Avatar_11
      },
      {
        "name": "Jeffrey Warden",
        "jobTitle": "Web Developer",
        "imageSrc": Avatar_12
      },
      {
        "name": "Bernardo Gaspar",
        "jobTitle": "Web Developer",
        "imageSrc": Avatar_13
      },
      {
        "name": "Leila Gavião",
        "jobTitle": "Team Leader",
        "imageSrc": Avatar_01
      },
      {
        "name": "João Lauriano",
        "jobTitle": "Team Leader",
        "imageSrc": Avatar_16
      },
      {
        "name": "Lucas Gaivião",
        "jobTitle": "Android Developer",
        "imageSrc": Avatar_16
      }
  ],
};

export default contactsData;
