/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";

import Breadcrumbs from "../../../../components/Breadcrumbs";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddSalaryModal from "../../../../components/modelpopup/AddSalaryModal";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../../components/AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../../../../components/Spinner";

import { startOfMonth, endOfMonth, format } from "date-fns";

import { Link, useParams, useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import RegistarPagamentoFolhaSalarialUnicaModal from "../../../../components/modelpopup/RegistarPagamentoFolhaSalarialUnicaModal";
import RegistarPagamentoFolhaSalarialConjuntaModal from "../../../../components/modelpopup/RegistarPagamentoFolhaSalarialConjuntaModal";
import AlterarBancoFolhaSalarialModal from "../../../../components/modelpopup/AlterarBancoFolhaSalarialModal";
import AddColaboradorFolhaSalarialModal from "../../../../components/modelpopup/AddColaboradorFolhaSalarialModal";
import AddColaboradorEscalaModal from "../../../../components/modelpopup/AddColaboradorEscalaModal";
import ColaboradorAlterarDataInicioNaEscalaModal from "../../../../components/modelpopup/ColaboradorAlterarDataInicioNaEscalaModal";
import RegistarFecharAbrirEscalaModal from "../../../../components/modelpopup/RegistarFecharAbrirEscalaModal";
import ColaboradorDobraNaEscalaModal from "../../../../components/modelpopup/ColaboradorDobraNaEscalaModal";

const Efetividade = () => {
  const navigate = useNavigate();
  // navigate('/admin-dashboard');

  let { escalaID } = useParams();

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao, user } = AuthUser();
  const [loading, setLoading] = useState(false);

  const [processados, setProcessados] = useState([]);
  const [salarios, setSalarios] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [escala, setescala] = useState([]);
  const [colaborador_escolhido, setColaboradorEscolhido] = useState([]);
  const [dia_escolhido, setDiaEscolhido] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedescalas, setSelectedescalas] = useState([]);

  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const [efetivos, setEfetivos] = useState([]);

  const [setSelectedOption] = useState(null);
  const [setSelecttwo] = useState(null);

  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [utilizadores, setUtilizador] = useState([]);

  const formatNumber = (number) => {
    return new Intl.NumberFormat("pt-PT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getColaboradoresDaEscala();
      } catch (error) {
        console.error("Erro ao obter utilizadores:", error);
      }
    };

    fetchData();
  }, []);

  // Método para adicionar ou remover o colaborador do array selectedEmployees
  const handleRowClick = (colaboradorId) => {
    setSelectedEmployees(
      (prevSelected) =>
        prevSelected.includes(colaboradorId)
          ? prevSelected.filter((id) => id !== colaboradorId) // Remove se já estiver selecionado
          : [...prevSelected, colaboradorId] // Adiciona se não estiver selecionado
    );
  };

  const submitescalaSlarialExcelreport = async (escala_id) => {
    try {
      setLoading(true);

      await getReportescalaExcel(escala_id);

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const getReportescalaExcel = async (escala_id) => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("escala_id", escala_id);

      const response = await http.post("/escala_salarial/excel", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(url_root + "" + response?.data?.url, "_blank");
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const adicionarescala = () => {
    if (escala) {
      setSelectedescalas([...selectedescalas, escala]);
    }
  };

  const getColaboradoresDaEscala = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/escala/colaboradores/" + escalaID);

      console.log("resposta escala : ", res);

      // Use Object.values() para garantir que seja um array válido
      const efetivosArray = Array.isArray(res.data.efetivos)
        ? res.data.efetivos
        : Object.values(res.data.efetivos);

      setEfetivos(efetivosArray); // Agora será um array válido
      setBancos(Array.isArray(res?.data?.bancos) ? res.data.bancos : []);
      setProcessados(
        Array.isArray(res?.data?.processados) ? res.data.processados : []
      );
      setescala(res?.data?.escala || {});
      setColaboradores(
        Array.isArray(res?.data?.colaboradores) ? res.data.colaboradores : []
      );

      console.log("Efetivos:", efetivosArray); // Verifique se agora é um array correto

      adicionarescala(res?.data?.escala);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao obter colaboradores da escala: ", error);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleOnSubmitSuccess = (res) => {
    setescala(res?.data?.escala || {});

    const editButton = document.getElementById("buttonClose7");

    if (editButton) {
      editButton.click();
    }

    /*toast.success("Estado da escala atualizado com sucesso.", {
      position: "top-right",
      autoClose: 2000, // Close the toast after 2 seconds
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
    });*/

    //getColaboradoresDaEscala();
  };

  const definir_assets = (res) => {
    try {
      //setSalarios(res?.data?.salarios);
      setBancos(res?.data?.bancos);
      setProcessados(res?.data?.processados);
      setescala(res?.data?.escala);
      setColaboradores(res?.data?.colaboradores);

      // Use Object.values() para garantir que seja um array válido
      const efetivosArray = Array.isArray(res.data.efetivos)
        ? res.data.efetivos
        : Object.values(res.data.efetivos);

      setEfetivos(efetivosArray); // Agora será um array válido

      adicionarescala(res?.data?.escala);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const chamarModalEliminarSelecionados = () => {
    handleEliminarSelecionados();
  };

  const handleEliminarSelecionados = async () => {
    if (selectedEmployees.length > 0) {
      Swal.fire({
        title: "Remover colaborador(es) selecionado(s) da escala?",
        text: "Tem a certeza que pretende remover este(s) colaborador(es) desta escala?",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (t) {
        if (t.isConfirmed) {
          try {
            setLoading(true);

            try {
              const formData = new FormData();
              formData.append(
                "colaboradores",
                JSON.stringify(selectedEmployees)
              );
              formData.append("escala_id", escala?.id);

              const res = await http.post(
                "/escala/colaborador/eliminar/conjunto",
                formData,
                {
                  headers: { "Content-Type": "multipart/form-data" },
                }
              );

              setBancos(res?.data?.bancos);
              setProcessados(res?.data?.processados);
              setescala(res?.data?.escala);
              setColaboradores(res?.data?.colaboradores);

              // Use Object.values() para garantir que seja um array válido
              const efetivosArray = Array.isArray(res.data.efetivos)
                ? res.data.efetivos
                : Object.values(res.data.efetivos);

              setEfetivos(efetivosArray); // Agora será um array válido

              adicionarescala(res?.data?.escala);

              setLoading(false);

              // Atualize a lista de acessos após ativar/desativar
            } catch (error) {
              setLoading(false);
              console.error(error);

              toast.error(
                "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
                {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                }
              );
            } finally {
              setLoading(false);
            }

            setLoading(false);
          } catch (error) {
            setLoading(false);

            toast.error(
              "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
              {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          }
        }
      });
    } else {
      toast.error("Deve selecionar pelo menos um colaborador.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const chamarModalEliminar = (colaborador_id) => {
    handleEliminar(colaborador_id);
  };

  const handleEliminar = async (colaborador_id) => {
    Swal.fire({
      title: "Remover colaborador da escala?",
      text: "Tem a certeza que pretende remover este colaborador desta escala?",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(async function (t) {
      if (t.isConfirmed) {
        try {
          setLoading(true);

          try {
            const formData = new FormData();
            formData.append("colaborador_id", colaborador_id);
            formData.append("escala_id", escala?.id);

            const res = await http.post(
              "/escala/colaborador/eliminar",
              formData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );

            setBancos(res?.data?.bancos);
            setProcessados(res?.data?.processados);
            setescala(res?.data?.escala);
            setColaboradores(res?.data?.colaboradores);

            // Use Object.values() para garantir que seja um array válido
            const efetivosArray = Array.isArray(res.data.efetivos)
              ? res.data.efetivos
              : Object.values(res.data.efetivos);

            setEfetivos(efetivosArray); // Agora será um array válido

            adicionarescala(res?.data?.escala);

            setLoading(false);

            // Atualize a lista de acessos após ativar/desativar
          } catch (error) {
            setLoading(false);
            console.error(error);

            toast.error(
              "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          } finally {
            setLoading(false);
          }

          setLoading(false);
        } catch (error) {
          setLoading(false);

          toast.error(
            "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      }
    });
  };

  const chamarModalRegistarFecharAbrir = () => {
    const editButton = document.getElementById("triggerAbrirFecharEscala");

    if (editButton) {
      editButton.click();
    }
  };

  const chamarModalAlterarDataInicioColaboradorNaEscala = (colaborador) => {
    setColaboradorEscolhido(colaborador);

    const editButton = document.getElementById("triggerAlterarData");

    if (editButton) {
      editButton.click();
    }
  };

  const chamarModalAlterarDobraNaEscala = (colaborador, dia) => {
    setColaboradorEscolhido(colaborador);
    setDiaEscolhido(dia);

    const editButton = document.getElementById("triggerAlterarDobra");

    if (editButton) {
      editButton.click();
    }
  };

  const chamarModalAlterarBanco = () => {
    const editButton = document.getElementById("triggerModalAlterarBanco");

    if (editButton) {
      editButton.click();
    }
  };

  const submitEfetividadePDFreport = async () => {
    try {
      setLoading(true);

      await getReportescala();

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Utilizador não autenticado.");

        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        setError(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado"
        );

        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const getReportescala = async () => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("escala_id", escala?.id);
      formData.append("missao_id", escala?.missao_id);

      const response = await http.post("/efetividade/pdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const submitReciboPDFreport = async (recibo_id) => {
    try {
      setLoading(true);

      await getReportRecibo(recibo_id);

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const getReportRecibo = async (recibo_id) => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("recibo_id", recibo_id);

      const response = await http.post("/recibo_salarial/pdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const handleToggleDiaEscala = async (colaborador_id, dia, escala_id) => {
    if (escala?.estado != 1) {
      try {
        setLoading(true);

        const formData = new FormData();
        formData.append("colaborador_id", colaborador_id);
        formData.append("dia", dia);
        formData.append("escala_id", escala_id);

        const res = await http.post(
          "/escala/colaborador/alternar-dia",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        definir_assets(res);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);

        toast.error(
          "Ocorreu um erro ao alternar o dia da escala. Tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      toast.error(
        "Esta escala ja esta fechada, deve abrir para edição se pretender altera-la.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const handleToggleDiaFalta = async (id, escala_id, estado) => {
    if (escala?.estado != 1) {
      if (id != 0) {
        try {
          setLoading(true);

          const formData = new FormData();
          formData.append("id", id);
          formData.append("escala_id", escala_id);
          formData.append("estado", estado);

          const res = await http.post(
            "/escala/colaborador/marcar-desmarcar-falta",
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );

          definir_assets(res);

          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error(error);

          toast.error(
            "Ocorreu um erro ao alternar o dia da escala. Tente novamente mais tarde.",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      } else {
        //Nothing to do because it is an off day
      }
    } else {
      toast.error(
        "Esta escala ja esta fechada, deve abrir para edição se pretender altera-la.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };


  //Ciclo da escala

  const dayAbbreviations = ["D", "S", "T", "Q", "Q", "S", "S"]; // Abreviações dos dias da semana

  // Converte as datas de escala para objetos de data válidos
  const dataInicio = escala?.data1 ? new Date(escala.data1) : null;
  const dataFim = escala?.data2 ? new Date(escala.data2) : null;

  // Calcula o número de dias na escala, com validação para garantir que dataInicio e dataFim sejam válidos
  const diasNaEscala =
    dataInicio && dataFim && dataInicio <= dataFim
      ? Math.ceil((dataFim - dataInicio) / (1000 * 60 * 60 * 24) + 1)
      : 0;

  // Verifica se diasNaEscala é válido para evitar erros
  if (diasNaEscala <= 0) {
    console.error("Intervalo de datas inválido ou datas faltando.");
    return <p>Intervalo de datas inválido ou não definido.</p>;
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle={t("Efetividade da Escala")}
            title="Escala"
            subtitle={t("Colaboradores")}
            modal="#add_colaborador_escala"
            name={t("Colaboradores")}
          />

          <div className="row mb-4">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-2">
              <Link
                title={"Ver " + escala?.designacao}
                className="btn btn-primary"
                to={"/escala-colaboradores/" + escala?.id}
              >
                <i className="fa fa-calendar"></i>&nbsp;Ver Escala
              </Link>
              {/* escala-colaboradores/ */}
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-2">
              <span
                title={escala?.designacao}
                className="btn btn-danger"
                onClick={() => submitEfetividadePDFreport()}
              >
                <i className="fa fa-file-pdf"></i>&nbsp;Exportar PDF
              </span>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-2">
              <span
                title={escala?.designacao}
                className="btn btn-danger"
                onClick={() => chamarModalEliminarSelecionados()}
              >
                <i className="fa fa-trash"></i>&nbsp;Remover Selecionados
              </span>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-2">
              {escala?.estado != 1 ? (
                <>
                  <span
                    className="btn btn-white btn-rounded"
                    onClick={() => chamarModalRegistarFecharAbrir()}
                  >
                    <i className="far fa-dot-circle text-danger" />{" "}
                    {t("Aberta")}
                  </span>
                </>
              ) : (
                <>
                  <span
                    className="btn btn-white btn-rounded"
                    onClick={() => chamarModalRegistarFecharAbrir()}
                    title={format(new Date(escala?.data_fecho), "dd/MM/yyyy")}
                  >
                    <i className="far fa-dot-circle text-success" />{" "}
                    {t("Fechada")} - Em{" "}
                    {format(new Date(escala?.data_fecho), "dd/MM/yyyy")}
                  </span>
                </>
              )}
            </div>
          </div>

          <table
            className="table table-striped table-responsive"
            style={{ overflowX: "auto" }}
          >
            <thead>
              <tr>
                <th colSpan={7}>
                  EFETIVIDADE : {escala?.designacao?.toUpperCase()} - [{" "}
                  {escala?.zona?.nome?.toUpperCase()} ]
                </th>
              </tr>
            </thead>
          </table>

          {/* começa a escala */}

          <div className="row">
            <div className="col-sm-12">
              <div className="table-responsive">
                <table className="table table-responsive table-striped mb-0">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                       {/* Linha de abreviações dos dias da semana */}
                       {[...Array(diasNaEscala).keys()].map((i) => {
                        const currentDate = new Date(dataInicio);
                        currentDate.setDate(dataInicio.getDate() + i);
                        const dayOfWeek = currentDate.getDay();
                        const abbreviation = dayAbbreviations[dayOfWeek];

                        return <th key={`day-abbrev-${i}`}>{abbreviation}</th>;
                      })}
                    </tr>
                    <tr>
                      <th>
                        <i className="fa fa-cogs"></i>{" "}
                      </th>
                      <th>
                        {t("Employee")}
                        {" [ "}
                        {selectedEmployees?.length}
                        {" ]"}
                      </th>
                      <th>Categoria</th>
                      <th>Telefone</th>
                      <th>Enquadrado</th>
                      <th>T</th>
                      <th>F</th>
                      <th>D</th>
                       {/* Números dos dias no intervalo */}
                       {[...Array(diasNaEscala).keys()].map((i) => {
                        const day = new Date(dataInicio);
                        day.setDate(dataInicio.getDate() + i);
                        return <th key={day.getDate()}>{day.getDate()}</th>;
                      })}
                    </tr>
                  </thead>

                  <tbody>
                    {efetivos?.length > 0 ? (
                      efetivos?.map(({ colaborador, efetividade }) => {
                        
                        const totalDiasTrabalhados = efetividade?.reduce(
                          (total, day) =>
                            day.estado == 1 ? total + 1 : total,
                          0
                        );

                        const totalFaltas = efetividade?.reduce(
                          (total, day) =>
                            day.estado == 1 && day.faltou == 1
                              ? total + 1
                              : total,
                          0
                        );

                        const totalDobras = efetividade?.reduce(
                          (total, day) =>
                            day.estado == 1 && day.dobra == 1
                              ? total + 1
                              : total,
                          0
                        );

                        return (
                          <tr
                            key={efetividade?.num}
                            className={`selectable ${
                              selectedEmployees.includes(colaborador?.id)
                                ? "selected"
                                : ""
                            }  ${colaborador?.estado != 1 ? "desativado" : ""}`}
                            onClick={() => handleRowClick(colaborador?.id)}

                            title={colaborador?.name}
                          >
                           

                            <td>
                              <i
                                className="fa fa-times fa-lg text-danger"
                                onClick={() =>
                                  chamarModalEliminar(colaborador?.id)
                                }
                              ></i>{" "}
                              <i
                                className="fa fa-calendar fa-lg text-primary"
                                onClick={() =>
                                  chamarModalAlterarDataInicioColaboradorNaEscala(
                                    colaborador
                                  )
                                }
                              ></i>
                            </td>
                            <td style={{ backgroundColor: "transparent" }}>
                              <h2 className="table-avatar">
                                <Link
                                  className="avatar avatar-xs"
                                  to={`/profile/${colaborador?.id}`}
                                >
                                  <img
                                    alt=""
                                    src={
                                      url_root +
                                      "/storage/" +
                                      colaborador?.fotografia
                                    }
                                  />
                                </Link>
                                <Link to={`/profile/${colaborador?.id}`}>
                                  {colaborador?.name}
                                </Link>
                              </h2>
                            </td>
                            <td style={{ backgroundColor: "transparent" }}>
                              {colaborador?.cargo?.designacao}
                            </td>
                            <td style={{ backgroundColor: "transparent" }}>
                              {colaborador?.telefone}
                            </td>
                            <td style={{ backgroundColor: "transparent" }}>
                              {colaborador?.admissao
                                ? format(
                                    new Date(colaborador?.admissao),
                                    "dd/MM/yyyy"
                                  )
                                : colaborador?.admissao}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#000",
                                color: "#fff",
                                textAlign: "center",
                              }}
                            >
                              {totalDiasTrabalhados}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#f8d7da",
                                color: "#721c24",
                                textAlign: "center",
                              }}
                            >
                              {totalFaltas}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#d4edda",
                                color: "#155724",
                                textAlign: "center",
                              }}
                            >
                              {totalDobras}
                            </td>

                            {efetividade?.length > 0 ? (
                              efetividade.map((day, index) => (
                                <td key={index}>
                                  {day.estado == 1 && (
                                    <span
                                      style={{
                                        color:
                                          day.estado == 1 && day?.faltou != 1
                                            ? "#007bff"
                                            : day?.faltou == 1
                                            ? "#fd7e14"
                                            : "#007bff",
                                      }}
                                    >
                                      {day.estado == 1 && day?.faltou != 1 ? (
                                        <span
                                          onClick={() =>
                                            handleToggleDiaFalta(
                                              day?.id,
                                              escala?.id,
                                              day?.faltou == 1 ? 0 : 1
                                            )
                                          }
                                        >
                                          P
                                        </span>
                                      ) : day?.faltou == 1 ? (
                                        <>
                                          <span
                                            onClick={() =>
                                              handleToggleDiaFalta(
                                                day?.id,
                                                escala?.id,
                                                day?.faltou == 1 ? 0 : 1
                                              )
                                            }

                                            title={day?.dobra==1?'Dobrante : '+day?.dobrante?.name+(day?.sem_remuneracao==1?' - Sem remuneração ao ausente':' - Com remuneração ao ausente')+' - '+day?.nota:'Não tem dobra registada'}
                                          >
                                            F
                                          </span>
                                          <span
                                            title="Registar ou alterar dobra"
                                            onClick={() =>
                                              chamarModalAlterarDobraNaEscala(
                                                colaborador,
                                                day
                                              )
                                            }
                                          >
                                            {" "}
                                            <i 
                                            
                                            style={{
                                              color:
                                                day.dobra == 1
                                                  ? "#007bff"
                                                  : "#fd7e14",
                                            }}

                                            className="fa fa-user"></i>
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                        
                                    </span>
                                  )}
                                </td>
                              ))
                            ) : (
                              <td colSpan="31">N/D</td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="39">Nenhum dado encontrado</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* termina a escala */}

          {loading ? (
            <>
              <Spinner></Spinner>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      <AddColaboradorEscalaModal
        utilizadores={colaboradores}
        salarios={salarios}
        processados={processados}
        escala={escala}
        onSubmissionSuccess={definir_assets}
      />

      <span
        data-bs-toggle="modal"
        data-bs-target="#marcar_falta_conjunta_modal"
        id="triggerMarcarFaltaConjunta"
      ></span>

      <span
        data-bs-toggle="modal"
        data-bs-target="#abrir_fecha_escala_conjunta_modal"
        id="triggerAbrirFecharEscala"
      ></span>

      <span
        data-bs-toggle="modal"
        data-bs-target="#alterar_banco_modal"
        id="triggerModalAlterarBanco"
      ></span>

      <span
        data-bs-toggle="modal"
        data-bs-target="#escala_alterar_data_inicio_colaborador_modal"
        id="triggerAlterarData"
      ></span>

      <span
        data-bs-toggle="modal"
        data-bs-target="#escala_dobra_colaborador_modal"
        id="triggerAlterarDobra"
      ></span>

      <RegistarFecharAbrirEscalaModal
        escala_id={escala?.id}
        onSubmitSuccess={handleOnSubmitSuccess}
      />

      <ColaboradorAlterarDataInicioNaEscalaModal
        escala_id={escala?.id}
        colaborador={colaborador_escolhido}
        onSubmissionSuccess={definir_assets}
      />

      <ColaboradorDobraNaEscalaModal
        colaboradores={colaboradores}
        escala_id={escala?.id}
        colaborador={colaborador_escolhido}
        dia={dia_escolhido}
        onSubmissionSuccess={definir_assets}
      />

      <AlterarBancoFolhaSalarialModal
        escala_id={escala?.id}
        bancos={bancos}
        onSubmitSuccess={handleOnSubmitSuccess}
      />
    </>
  );
};

export default Efetividade;
