/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { TimePicker } from "antd";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { startOfMonth, endOfMonth, format } from "date-fns";

import AuthUser from "../AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../Spinner";

import { Link, useHistory } from "react-router-dom";

import Swal from "sweetalert2";

dayjs.extend(customParseFormat);
const onChange = (time, timeString) => {
  // console.log(time, timeString);
};

const AddEscalaModal = ({
  departamentos,
  cargos,
  postos,
  bancos,
  onSubmissionSuccess,
}) => {
  // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao, user, getMissao } = AuthUser();

  const [selectedDate1, setSelectedDate1] = useState(null);
  const [cargo_id, setCargoID] = useState(-1);
  const [banco_id, setBancoID] = useState(-1);
  const [posto_id, setPostoID] = useState(-1);
  const [departamento_id, setDepartamentoID] = useState(-1);

  const [nota, setNota] = useState("");
  const [ano, setAno] = useState(2024);
  const [mes, setMes] = useState(1);
  const [subNatal, setSubNatal] = useState(0);

  const [data1, setData1] = useState("");
  const [data2, setData2] = useState("");

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const [grupo, handleGrupoDaDFolha] = useState(6);

  useEffect(() => {
    const currentDate = new Date();
    setData1(format(startOfMonth(currentDate), "yyyy-MM-dd"));
    setData2(format(endOfMonth(currentDate), "yyyy-MM-dd"));
  }, []);

  const employee = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ];
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#00b0f0" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#00b0f0",
      },
    }),
  };

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event("click"));
    }
  };

  const submitForm = async () => {
    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {
        setLoading(true);

        // Basic form validation
        if (grupo == 2 && cargo_id == -1) {
          setLoading(false);

          // Show a error toast
          toast.error("Deve definir um cargo.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });

          return;
        }

        if (grupo == 1 && departamento_id == -1) {
          setLoading(false);

          // Show a error toast
          toast.error("Deve definir um departamento.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });

          return;
        }

        if (grupo == 3 && posto_id == -1) {
          setLoading(false);

          // Show a error toast
          toast.error("Deve definir um posto de cliente.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });

          return;
        }

        await uploadSalario();

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Utilizador não autenticado.");

          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else {
          setError(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado"
          );

          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );
        }
      } finally {
        setLoading(false);
      }
    } else {
      console.log("Clicou em submeter");

      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const uploadSalario = async () => {
    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {
        const formData = new FormData();

        console.log("tipo :");
        console.log(grupo);

        formData.append("tipo", grupo);
        formData.append("missao_id", getMissao()?.id);
        formData.append("banco_id", banco_id);
        formData.append("posto_id", posto_id);
        formData.append("cargo_id", cargo_id);
        formData.append("departamento_id", departamento_id);
        formData.append("registou", user?.id);
        formData.append("atualizou", user?.id);
        formData.append("data1", data1);
        formData.append("data2", data2);

        const response = await http.post(
          "/escala/coletivo/processar",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        setLoading(false);

        triggerCloseButtonClick("closeButton1");

        onSubmissionSuccess(response?.data?.escala_id);

        //console.log("folha_salarial_id");
        //console.log(response?.data?.folha_salarial_id);
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const renderOptionCargos = (cargos) => {
    return cargos?.map((cargo) => (
      <React.Fragment key={cargo.id}>
        <option value={cargo.id}>{cargo.designacao}</option>
      </React.Fragment>
    ));
  };

  const renderOptionDepartamentos = (departamentos) => {
    return departamentos?.map((departamento) => (
      <React.Fragment key={departamento.id}>
        <option value={departamento.id}>{departamento.designacao}</option>
      </React.Fragment>
    ));
  };

  const renderOptionPostos = (postos) => {
    return postos?.map((posto) => (
      <React.Fragment key={posto.id}>
        <option value={posto.id}>
          {posto?.cliente?.nome} - {posto.codigo} {posto.nome}
        </option>
      </React.Fragment>
    ));
  };

  const renderOptionBancos = (bancos) => {
    return bancos?.map((banco) => (
      <React.Fragment key={banco.id}>
        <option value={banco.id}>{banco.nome}</option>
      </React.Fragment>
    ));
  };

  return (
    <>
      <div
        id="add_group_escala"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">CRIAR ESCALA DE TRABALHO</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeButton1"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <hr></hr>

              <form>
                <div className="row">
                  <div className="col-md-4">
                    <div className="input-block mb-3 form-focus focused">
                      <label className="focus-label">
                        {t("Escolha o tipo de grupo")}
                      </label>
                      <select
                        className="form-control"
                        value={grupo}
                        onChange={(e) => handleGrupoDaDFolha(e.target.value)}
                      >
                        <option value="1">Por Departamento</option>
                        <option value="2">Por Cargo</option>
                        <option value="3">Por Posto</option>
                        <option value="4">Expatriados</option>
                        <option value="5">Todos os colaboradores</option>
                        <option value="6">Por Projeto</option>
                      </select>
                    </div>
                  </div>

                  {grupo == 1 ? (
                    <>
                      <div className="col-md-8">
                        <div className="input-block mb-3 form-focus focused">
                          <label className="focus-label">
                            {t("Department")}
                          </label>
                          <select
                            onChange={(e) => setDepartamentoID(e.target.value)}
                            value={departamento_id}
                            className="form-control select"
                          >
                            <option key={0} value={-1}></option>
                            {renderOptionDepartamentos(departamentos)}
                          </select>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {grupo == 2 ? (
                    <>
                      <div className="col-md-8">
                        <div className="input-block mb-3 form-focus focused">
                          <label className="focus-label">{t("Job Role")}</label>
                          <select
                            onChange={(e) => setCargoID(e.target.value)}
                            value={cargo_id}
                            className="form-control select"
                          >
                            <option key={0} value={-1}></option>
                            {renderOptionCargos(cargos)}
                          </select>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {grupo == 3 ? (
                    <>
                      <div className="col-md-8">
                        <div className="input-block mb-3 form-focus focused">
                          <label className="focus-label">
                            {t("Postos De Clientes")}
                          </label>
                          <select
                            onChange={(e) => setPostoID(e.target.value)}
                            value={posto_id}
                            className="form-control select"
                          >
                            <option key={0} value={-1}></option>
                            {renderOptionPostos(postos)}
                          </select>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="col-md-4">
                    <div className="input-block mb-3 mb-3 form-focus focused">
                      <div>
                        <input
                          className="form-control"
                          type="date"
                          value={data1}
                          onChange={(e) => setData1(e.target.value)}
                        />
                        <label className="focus-label">{t("Desde")}</label>
                      </div>
                    </div>
                  </div>

                  {grupo != 1 && grupo != 2 && grupo != 3 ? (
                    <>
                      <div className="col-md-4">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <div>
                            <input
                              className="form-control"
                              type="date"
                              value={data2}
                              onChange={(e) => setData2(e.target.value)}
                            />
                            <label className="focus-label">{t("Ate")}</label>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {grupo == 1 || grupo == 2 || grupo == 3 ? (
                    <>
                      <div className="col-md-8">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <div>
                            <input
                              className="form-control"
                              type="date"
                              value={data2}
                              onChange={(e) => setData2(e.target.value)}
                            />
                            <label className="focus-label">{t("Ate")}</label>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="submit-section mb-5">
                  <a
                    className="btn btn-primary submit-btn"
                    onClick={submitForm}
                  >
                    {loading ? "..." : t("Submit")}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <>
          <Spinner></Spinner>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AddEscalaModal;
