import React, { useState, useEffect } from "react";

import Breadcrumbs from "../../../components/Breadcrumbs";

import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";

import AuthUser from "../../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../components/Spinner";
import BancoFilter from "../Administration/financas/BancoFilter";
import BancoTable from "../Administration/financas/BancoTable";
import BancoModal from "../../../components/Administration/financas/BancoModal";

import { Link } from "react-router-dom";
import ContaBancariaEmpresaModal from "../../../components/Administration/financas/ContaBancariaEmpresaModal";
import DeleteModal from "../../../components/modelpopup/DeleteModal";

const ContasEmpresa = () => {
  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } = AuthUser();
  const [bancos, setBancos] = useState([]);
  const [contas, setContas] = useState([]);
  const [contaSelecionada, setContaSelecionada] = useState();

  const [filteredBancos, setfilteredBancos] = useState([]);
  const [banco_id, setBancoID] = useState(-1);

  const { t } = useTranslation();

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible((prevVisible) => !prevVisible);
  };

  // Callback function to receive filtered users from UserFilter
  const handleFilterSubmit = (filteredBancos) => {
    console.log("received:");
    console.log(filteredBancos);

    setfilteredBancos(filteredBancos);
  };

  const handleSubmissionSuccess = (bancos) => {
    // Refresh acessos state or trigger any necessary actions
    getContasEmpresa();
  };

  const renderOptionBancos = (bancos) => {
    return bancos?.map((banco) => (
      <React.Fragment key={banco.id}>
        <option value={banco.id}>{banco.nome}</option>
      </React.Fragment>
    ));
  };

  const getBancos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/bancos/"+getMissao()?.id);

      setBancos(res.data.bancos);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getContasEmpresa = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts
      const res = await http.get("/contas/empresa/"+getMissao()?.id);
      setContas(res.data.contas);
      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Chama a função assíncrona getUtilizador()
        await getBancos();
        await getContasEmpresa();
      } catch (error) {
        console.error("Erro ao obter contas:", error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, []);

  const chamarModalEdit = (idSelecionado) => {
    setContaSelecionada(idSelecionado);

    const editButton = document.getElementById("triggerModalEdit");

    if (editButton) {
      editButton.click();
    }
  };

  const chamarModalEliminar = (idSelecionado) => {
    setContaSelecionada(idSelecionado);

    const editButton = document.getElementById("triggerModalEliminar");

    if (editButton) {
      editButton.click();
    }
  };

  const handleAtivarDesativar = async (id, newState) => {
    //console.log('Clicou aqui para ativar e desativar');

    if (verificarPermissao("CARGO", "ativar")) {
      try {
        setLoading(true);

        const formData = new FormData();
        formData.append("id", id);
        formData.append("estado", newState);

        const response = await http.post("/conta/activate", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        setLoading(false);

        getContasEmpresa();

        // Atualize a lista de utilizadores após ativar/desativar
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  if (verificarPermissao("CARGO", "ver"))
    if (loading)
      return (
        <>
          <Spinner></Spinner>
        </>
      );
    else
      return (
        <>
          (
          <>
            <div className="page-wrapper">
              <div className="content container-fluid">
                <Breadcrumbs
                  maintitle={t("Contas Bancarias")}
                  title="Dashboard"
                  subtitle={t("Contas da empresa")}
                  modal="#add_conta_modal"
                  name={t("Add Conta Bancaria")}
                />

                {/* inicio filtro */}

                <div className="row filter-row space">
                  <div className="col-sm-6 col-md-3">
                    <div className="input-block form-focus select-focus">
                      <label className="focus-label">
                        {t("Digite a Conta, IBAN ou NIB")}
                      </label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-3">
                    <div className="input-block form-focus select-focus">
                      <label className="focus-label">{t("Banco")}</label>
                      <select
                        onChange={(e) => setBancoID(e.target.value)}
                        value={banco_id}
                        className="form-control select"
                      >
                        <option key={0} value={-1}></option>
                        {renderOptionBancos(bancos)}
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-2">
                    <div className="input-block form-focus select-focus">
                      <select className="form-control">
                        <option value="-1">Todos estados</option>
                        <option value="0">Ativada</option>
                        <option value="1">Não Ativada</option>
                      </select>
                      <label className="focus-label">{t("Pagamento")}</label>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-2">
                    {!loading ? (
                      <>
                        <span className="btn btn-success btn-block w-100">
                          {t("Search")}
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="col-sm-6 col-md-2">
                    <span
                      className="btn btn-dark btn-block w-100"
                      onClick={toggleDropdown}
                    >
                      <i className="fa fa-cogs"></i>
                      {" Opções"}
                    </span>
                  </div>

                  <div className={`${dropdownVisible ? "d-block" : "d-none"}`}>
                    <span className=" mt-2">
                      <i className="la la-money" /> {t("Alterar o banco")}
                    </span>
                    {"  "} | {"    "}
                    <span className="">
                      <i className="fa fa-trash" /> {t("Eliminar a conta")}
                    </span>
                    <hr></hr>
                  </div>
                </div>

                {/* fim filtro */}

                <table
                  className="table table-striped table-responsive"
                  style={{ overflowX: "auto" }}
                >
                  <thead>
                    <tr>
                      <th>Banco</th>
                      <th>Conta</th>
                      <th>IBAN</th>
                      <th>NIB</th>
                      <th>Estado</th>
                      <th>
                        <i className="fa fa-cogs"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {contas?.slice(0, 356).map((item, index) => (
                      <tr key={index} title={item?.nome}>
                        <td>
                          <div className="table-avatar">
                            <Link to="#" className="avatar">
                              <img
                                src={url_root + "/storage/" + item?.banco?.logo}
                                alt="logo"
                              />
                            </Link>
                            <span>{item?.banco?.nome}</span>
                          </div>
                        </td>
                        <td>{item?.conta}</td>
                        <td>{item?.IBAN}</td>
                        <td>{item?.NIB}</td>
                        <td>
                          <span
                            className={
                              item?.estado == 1
                                ? "badge bg-inverse-success"
                                : "badge bg-inverse-danger"
                            }
                          >
                            {item?.estado == 1 ? "Ativo" : "Desativado"}
                          </span>
                          &nbsp;
                          <span
                            onClick={() =>
                              handleAtivarDesativar(
                                item.id,
                                item?.estado == 1 ? 0 : 1
                              )
                            }
                          >
                            <i className="fa fa-recycle"></i>
                          </span>
                        </td>
                        <td>
                          <div className="dropdown dropdown-action text-end">
                            <Link
                              to="#"
                              className="action-icon dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="material-icons">more_vert</i>
                            </Link>

                            <div className="dropdown-menu dropdown-menu-right">
                              <span
                                className="dropdown-item"
                                title={item?.banco?.nome + " " + item?.conta}
                                onClick={() => chamarModalEdit(item)}
                              >
                                <i className="fa fa-pencil m-r-5" /> {t("Edit")}
                              </span>

                              <span
                                className="dropdown-item"
                                title={item?.nome}
                                onClick={() => chamarModalEliminar(item)}
                              >
                                <i className="fa fa-trash m-r-5" />{" "}
                                {t("Delete")}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <ContaBancariaEmpresaModal
                  onSubmissionSuccess={handleSubmissionSuccess}
                  contaP={contaSelecionada}
                  bancos={bancos}
                />

                <span
                  data-bs-toggle="modal"
                  data-bs-target="#edit_conta_modal"
                  id="triggerModalEdit"
                ></span>

                <span
                  data-bs-toggle="modal"
                  data-bs-target="#delete"
                  id="triggerModalEliminar"
                ></span>

                <DeleteModal
                  objectoName={t("Conta Bancaria")}
                  onSubmissionSuccess={handleSubmissionSuccess}
                  id={contaSelecionada?.id}
                  endpoint={"conta"}
                />
              </div>
            </div>{" "}
          </>
          );{" "}
        </>
      );
  else
    return (
      <>
        <div className="page-wrapper cardhead">
          <div className="content ">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">SEM PERMISSÃO</h3>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      <i className="fa fa-times-circle text-danger"></i>&nbsp;
                      OPERAÇÃO NÃO AUTORIZADA
                    </h4>
                  </div>
                  <div className="card-body">
                    <p>
                      Esta operação não é autorizada para o seu nivel de acesso
                      ao sistema, por favor contacte o administrador do sistema.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default ContasEmpresa;
