import React, { useState } from 'react';
import axios from 'axios';

const ChatGPTcomponent = () => { 
    const [messages, setMessages] = useState([]);

    const sendMessageToOpenAI = async (message) => {
        try {
            const response = await axios.post(
                'https://api.openai.com/v1/chat/completions',
                {
                    model: "gpt-3.5-turbo",
                    messages: [
                        {
                            role: "system",
                            content: "You are a helpful assistant."
                        },
                        {
                            role: "user",
                            content: message
                        }
                    ]
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer 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` // Assuming you have the API key stored in an environment variable
                    }
                }
            );

            const botMessage = response.data.choices[0].message.content;
            setMessages([...messages, { role: 'bot', content: botMessage }]);
        } catch (error) {
            console.error('Error sending message to OpenAI:', error);
        }
    };

    const handleSendMessage = (userMessage) => {
        setMessages([...messages, { role: 'user', content: userMessage }]);
        sendMessageToOpenAI(userMessage);
    };

    return (

        <div className="page-wrapper">
            {/* Page Content */}
            <div className="content container-fluid">
                <div className="row">
                    <div className="col-md-12"></div>

                    
                    <div>
                        {messages.map((message, index) => (
                            <div key={index} style={{ textAlign: message.role === 'bot' ? 'left' : 'right' }}>
                                <span style={{ fontWeight: 'bold' }}>{message.role === 'bot' ? 'Bot:' : 'You:'}</span> {message.content}
                            </div>
                        ))}
                        <input type="text" className='form-control' onKeyDown={(e) => e.key === 'Enter' && handleSendMessage(e.target.value)} />
                    </div>


                </div>
            </div>
        </div>
    );
};

export default ChatGPTcomponent;
