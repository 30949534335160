import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";

import AuthUser from "../../../../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../../../components/Spinner";

import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { Link } from "react-router-dom";
import DeductionTable from "./DeductionTable";
import DeleteModal from "../../../../../components/modelpopup/deletePopup";

import { useTranslation } from "react-i18next";

import SubsidioModal from "../../../../../components/Administration/financas/SubsidioModal";
import SubsidioTable from "../../../Administration/financas/SubsidioTable";
import DescontoTable from "../../../Administration/financas/DescontoTable";
import SubsidioVinculoModal from "../../../../../components/Administration/financas/SubsidioVinculoModal";

const PayrollItems = () => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } = AuthUser();
  const [subsidios, setsubsidios] = useState([]);

  const [subsidioSelecionado, setSubsidioSelecionado] = useState([]);
  const [vinculos, setVinculos] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [postos, setPostos] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);

  const [filteredsubsidios, setfilteredsubsidios] = useState([]);

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  // Callback function to receive filtered users from UserFilter
  const handleFilterSubmit = (filteredsubsidios) => {
    setfilteredsubsidios(filteredsubsidios);
  };

  const handleSubmissionSuccess = (subsidios) => {
    // Refresh acessos state or trigger any necessary actions
    setsubsidios(subsidios);
  };

  const onVerPermissoes = (vinculos, subsidio) => {
    setVinculos(vinculos);
    setSubsidioSelecionado(subsidio);
  };

  const getVinculos = async (subsidio) => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const formData = new FormData();
      formData.append("id", subsidio?.id);

      const response = await http.post("/subsidio/vinculos", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setVinculos(response?.data);
      setSubsidioSelecionado(subsidio);

      if (response?.data) {
        const editButton = document.getElementById(
          "triggerModalEditSubsidioVinculo"
        );

        if (editButton) {
          editButton.click();
        }
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getCargosDepartamentosPostos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/cargos-departamentos-postos/"+getMissao()?.id);
      setCargos(res.data.cargos);
      setDepartamentos(res.data.departamentos);
      setPostos(res.data.postos);
      setColaboradores(res.data.colaboradores);
      setClientes(res.data.clientes);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Chama a função assíncrona getUtilizador()
        await getCargosDepartamentosPostos();
      } catch (error) {
        console.error("Erro ao obter postos:", error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, []);

  if (verificarPermissao("CARGO", "ver"))
    if (loading)
      return (
        <>
          <Spinner></Spinner>
        </>
      );
    else
      return (
        <>
          (
          <>
            <div className="page-wrapper">
              <div className="content container-fluid">
                <Breadcrumbs
                  maintitle={t("Payroll Items")}
                  title="Dashboard"
                  subtitle={t("Payroll Items")}
                />

                <div className="page-menu">
                  <div className="row">
                    <div className="col-sm-12">
                      <ul className="nav nav-tabs nav-tabs-bottom">
                        <li className="nav-item">
                          <Link
                            className="nav-link active"
                            data-bs-toggle="tab"
                            to="#tab_additions"
                          >
                            {t("Subsidios")}
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            data-bs-toggle="tab"
                            to="#tab_deductions"
                          >
                            {t("Deductions")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="tab-content">
                  <div className="tab-pane show active" id="tab_additions">
                    <SubsidioTable
                      subsidios={subsidios}
                      verPemissoes={onVerPermissoes}
                    />
                  </div>

                  <div className="tab-pane" id="tab_deductions">
                    <DescontoTable
                      subsidios={subsidios}
                      verPemissoes={onVerPermissoes}
                    />
                  </div>
                </div>

                <span
                  data-bs-toggle="modal"
                  data-bs-target="#vinculo_subsidio_modal"
                  id="triggerModalEditSubsidioVinculo"
                ></span>
                
              </div>
            </div>

            <SubsidioVinculoModal
              clientes={clientes}
              cargos={cargos}
              departamentos={departamentos}
              postos={postos}
              colaboradores={colaboradores}
              subsidio={subsidioSelecionado}
              vinculos={vinculos}
            />

            <DeleteModal Name={t("Delete")} />

          </>
          );{" "}
        </>
      );
  else
    return (
      <>
        <div className="page-wrapper cardhead">
          <div className="content ">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">SEM PERMISSÃO</h3>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      <i className="fa fa-times-circle text-danger"></i>&nbsp;
                      OPERAÇÃO NÃO AUTORIZADA
                    </h4>
                  </div>
                  <div className="card-body">
                    <p>
                      Esta operação não é autorizada para o seu nivel de acesso
                      ao sistema, por favor contacte o administrador do sistema.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default PayrollItems;
