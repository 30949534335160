import {
  Avatar_02,
  Avatar_05,
  Avatar_09,
  Avatar_10,
  Avatar_16,
} from "../../Routes/ImagePath";
import DatePicker from "react-datepicker";
import DefaultEditor from "react-simple-wysiwyg";

import Select from "react-select";
import { useTranslation } from "react-i18next";

import React, { useState, useEffect } from "react";
import AuthUser from "../AuthUser";
import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../Spinner";
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";

const ProjectModelPopup = ({ onSubmissionSuccess, projeto, prioridadeParam, tempoParam, semanalParam, utilizadores, missoes }) => {

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user, user_permissoes, verificarPermissao, multipla_visibilidade } = AuthUser();
  const [nome, setName] = useState('');
  const [estado, setEstado] = useState();
  const [semanal, setSemanal] = useState(1);
  //eslint-disable-next-line no-unused-vars
  const [descricao, setDescricao] = useState('');
  const [data_inicio, setDataInicio] = useState('');
  const [data_fim, setDataFim] = useState(new Date());
  const [orcamento, setOrcamento] = useState(1);
  const [custo, setCusto] = useState(1);
  const [tempo, setTempo] = useState(1);
  const [prioridade, setPrioridade] = useState(1);

  const [missao_id, setMissaoID] = useState(-1);

  const [tempoSelecionado, setTempoSelecionado] = useState(1);
  const [semanalSelecionado, setSemanalSelecionado] = useState(1);
  const [prioridadeSelecionada, setPrioridadeSelecionada] = useState(1);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredLiders, setFilteredLiders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermLider, setSearchTermLider] = useState("");

  const [ficheiros, setSelectedFile] = useState(null);


  // Defina o estado inicial do array
  const [lideresSelecionados, setLideresSelecionados] = useState([]);
  const [colaboradoresSelecionados, setColaboradoresSelecionados] = useState([]);


  const [lideresIDs, setLideresIDs] = useState([]);
  const [colaboradoresIDs, setColaboradoresIDs] = useState([]);

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event('click'));
    }
  };

  const { t } = useTranslation();

  const [inputs, setInputs] = useState([]);

  useEffect(() => {

    setPrioridadeSelecionada(prioridadeParam);
    setTempoSelecionado(tempoParam);
    setSemanalSelecionado(semanalParam);

    setUsers(utilizadores);
    setMissaoID(user?.missao_id);

    const fetchData = async () => {
      try {

        setInputs(projeto);
        setEstado(projeto?.estado);

        setMissaoID(projeto?.missao_id);

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

  }, [projeto]);

  const handleSelectTempoChange = (id) => {
    setTempoSelecionado(id);
  };

  const handleSelectSemanalChange = (id) => {
    setSemanalSelecionado(id);
  };

  const handleSelectPrioridadeChange = (id) => {
    setPrioridadeSelecionada(id);
  };

  const submitForm = async () => {

    console.log('Clicou em submeter');

    if (verificarPermissao('PROJETOS', 'adicionar')) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (nome.length < 2) {
          setError('O nome deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O nome deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }


        if (missao_id == -1) {
          setError('deve definir uma missão para o projeto.');
          setLoading(false);

          // Show a error toast
          toast.error('deve definir uma missão para o projeto.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });

          return;
        }


        await uploadProjeto();


      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {
          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }

    } else {

      console.log('Clicou em submeter');

      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const uploadProjeto = async () => {
    if (verificarPermissao('PROJETOS', 'adicionar')) {
      try {

        const formData = new FormData();
        formData.append('nome', nome);
        formData.append('descricao', descricao);
        formData.append('data_inicio', data_inicio);
        formData.append('data_fim', data_fim);
        formData.append('orcamento', orcamento);
        formData.append('custo', custo);
        formData.append('tempo', tempo);
        formData.append('semanal', semanal);
        formData.append('aprovado', 0);
        formData.append('prioridade', prioridade);
        formData.append('lideres', JSON.stringify(lideresIDs));
        formData.append('colaboradores', JSON.stringify(colaboradoresIDs));

        formData.append('missao_id', missao_id?missao_id:user?.missao_id);

        // Append each file separately
        if (ficheiros)
          for (let i = 0; i < ficheiros.length; i++) {
            formData.append('files[]', ficheiros[i]);
          }

        console.log('lideres', lideresIDs);
        console.log('colaboradores', colaboradoresIDs);

        formData.append('estado', 0);
        formData.append('empresa_id', user.empresa_id);

        const response = await http.post('/projeto/register', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        console.log(response);

        // Show a success toast
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {

            setName('');
            setDataInicio('');
            setDataFim('');
            setOrcamento(0);
            setCusto(0);

            triggerCloseButtonClick('closeButton1');

            console.log('to send : ');
            console.log(response.data.data);

            onSubmissionSuccess(response.data.data);

          },
        });

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const getProjeto = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/projeto/' + inputs?.id);

      console.log('Projeto :');
      console.log(res.data.projeto);
      setInputs(res.data.projeto);

      setLoading(false);

      onSubmissionSuccess(res.data.projeto);



    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleSearchTermChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    const filtered = users.filter(user =>
      user.name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredUsers(filtered);
  };

  const handleSearchTermChangeLider = (event) => {
    const term = event.target.value;
    setSearchTermLider(term);
    const filtered = users.filter(user =>
      user.name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredLiders(filtered);
  };

  const handleUserSelect = (selectedUser, tipo) => {
    // Here you can add the selected user to your project team or perform any other action
    console.log("Selected user:", selectedUser);

    if (selectedUser == null) {
      // Clear the search term and filtered users after selecting a user
      setSearchTerm("");
      setSearchTermLider("");
      setFilteredUsers([]);
      setFilteredLiders([]);
    } else {
      addColaboradoresProjeto(selectedUser, tipo);
    }

  };


  //Update project

  const submitFormUpdate = async () => {

    if (verificarPermissao('PROJETOS', 'atualizar')) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (inputs.nome.length < 2) {
          setError('O nome deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O nome deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 1000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        if (missao_id == -1) {
          setError('deve definir uma missão para o projeto.');
          setLoading(false);

          // Show a error toast
          toast.error('deve definir uma missão para o projeto.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });

          return;
        }

        await updateProjeto();

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {
          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  const updateProjeto = async () => {
    try {

      const formData = new FormData();
      formData.append('id', inputs?.id);
      formData.append('nome', inputs?.nome);
      formData.append('descricao', inputs?.descricao);
      formData.append('data_inicio', inputs?.data_inicio);
      formData.append('data_fim', inputs?.data_fim);
      formData.append('orcamento', inputs?.orcamento);
      formData.append('custo', inputs?.custo);
      formData.append('tempo', tempoSelecionado);
      formData.append('prioridade', prioridadeSelecionada);
      formData.append('estado', estado);
      formData.append('semanal', semanalSelecionado);

      formData.append('missao_id', missao_id);

      // Append each file separately
      if (ficheiros)
        for (let i = 0; i < ficheiros.length; i++) {
          formData.append('files[]', ficheiros[i]);
        }

      const response = await http.post('/projeto/update', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });



      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {

          setName('');
          setDataInicio('');
          setDataFim('');
          setOrcamento(0);
          setCusto(0);

          triggerCloseButtonClick('closeButton2');

          console.log('to send : ');
          console.log(response.data.data);

          getProjeto();

        }
      });

    } catch (error) {
      console.error(error);

      toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };


  const addColaboradoresProjeto = async (user, tipo) => {
    try {

      setLoading(true);

      const formData = new FormData();
      formData.append('user_id', user?.id);
      formData.append('projeto_id', inputs?.id);
      formData.append('tipo', tipo);

      const response = await http.post('/projeto/addColaborador', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {

          setLoading(false);
          //Refresh colaboradores list
          getProjeto();

        }
      });

    } catch (error) {

      setLoading(false);
      console.error(error);

      toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const removerColaboradoresProjeto = async (user) => {

    Swal.fire({
      title: "Remover colaborador?",
      text: "Tem a certeza que pretende remover este colaborador do projeto?",
      type: "confirm",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(async function (t) {

      if (t.isConfirmed) {
        try {

          setLoading(true);

          const formData = new FormData();
          formData.append('user_id', user?.id);
          formData.append('projeto_id', inputs?.id);

          const response = await http.post('/projeto/removerColaborador', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          // Show a success toast
          toast.success(response.data.message, {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => {

              setLoading(false);
              //Refresh colaboradores list
              getProjeto();

            }
          });

        } catch (error) {

          setLoading(false);
          console.error(error);

          toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }

    });
  };


  /* Fim update projeto */


  const handleLiderNovoSelect = (selectedUser, tipo) => {


    if (selectedUser == null) {
      // Clear the search term and filtered users after selecting a user
      setSearchTerm("");
      setSearchTermLider("");
      setFilteredUsers([]);
      setFilteredLiders([]);

    } else {

      // Verifique se o usuário já existe no array
      const usuarioExistente = lideresSelecionados.find(usuario => usuario.id === selectedUser.id);

      // Se o usuário não existir, adicione-o ao array
      if (!usuarioExistente && !colaboradoresSelecionados.find(usuario => usuario.id === selectedUser.id)) {
        // Crie um novo objeto contendo o usuário e seu tipo
        const novoUsuarioSelecionado = { ...selectedUser, tipo };

        if (tipo == 1) {// Adicione o novo usuário ao array de usuários selecionados
          setLideresSelecionados([...lideresSelecionados, novoUsuarioSelecionado]);
          setLideresIDs([...lideresIDs, novoUsuarioSelecionado.id]);
          console.log('Lider adicionado:');
          console.log(lideresSelecionados);
        }
        else {// Adicione o novo usuário ao array de usuários selecionados
          setColaboradoresSelecionados([...colaboradoresSelecionados, novoUsuarioSelecionado]);
          setColaboradoresIDs([...colaboradoresIDs, novoUsuarioSelecionado.id]);
          console.log('Colaborador adicionado:');
          console.log(lideresSelecionados);
        }


      } else {
        toast.error('Ja esta adicionado.', {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

    }

  };


  const handleLiderRemove = (userToRemove) => {
    // Filtrar o array de usuários selecionados para excluir o usuário que deseja remover
    const novosLideresSelecionados = lideresSelecionados.filter(usuario => usuario.id !== userToRemove.id);

    const novosUsuariosSelecionados = colaboradoresSelecionados.filter(usuario => usuario.id !== userToRemove.id);

    // Atualizar o estado com o novo array de usuários selecionados
    setLideresSelecionados(novosLideresSelecionados);
    setColaboradoresSelecionados(novosUsuariosSelecionados);
  };

  const handleFileChange = (e) => {

    const newFile = e.target.files;

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Restante do código
  };

  const renderOptionMissoes = (missoes) => {
    return missoes?.map((missao) => (
      <React.Fragment key={missao.id}>
        <option value={missao.id}>{missao.designacao}</option>
      </React.Fragment>
    ));
  };

  return (
    <>
      <div
        id="create_project"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t('Create Project')}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeButton1"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">

                  <div className="col-sm-9">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Project Name')}</label>
                      <input className="form-control" type="text" value={nome} onChange={e => setName(e.target.value)} />
                    </div>
                  </div>

                  <div className="col-sm-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Programa semanal ?</label>
                      <select className="form-control select" value={semanal} onChange={e => setSemanal(e.target.value)} >
                        <option value="1">
                          {t('Yes')}
                        </option>
                        <option value="2" >
                          {t('No')}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Start Date')}</label>
                      <input className="form-control" type="date" value={data_inicio} onChange={e => setDataInicio(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('End Date')}</label>
                      <input className="form-control" type="date" value={data_fim} onChange={e => setDataFim(e.target.value)} />
                    </div>
                  </div>
                </div>

                {semanal != 1 ? 
                <div className="row">
                  <div className="col-sm-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Rate')}</label>
                      <input
                        placeholder="0"
                        className="form-control"
                        type="number"
                        value={orcamento} onChange={e => setOrcamento(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">&nbsp;</label>
                      <select className="form-control select" value={tempo} onChange={e => setTempo(e.target.value)} >
                        <option value="1">
                          {t('Fixed')}
                        </option>
                        <option value="2" >
                          {t('Hourly')}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Real Cost')}</label>
                      <input
                        placeholder="Kz 50"
                        className="form-control"
                        type="number"
                        value={custo} onChange={e => setCusto(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Priority')}</label>
                      <select className="form-control select" value={prioridade} onChange={e => setPrioridade(e.target.value)} >
                        <option value="1">
                          {t('Low')}
                        </option>
                        <option value="2" >
                          {t('Medium')}
                        </option>
                        <option value="3">
                          {t('High')}
                        </option>
                      </select>
                    </div>
                  </div>
                </div> : ''}


                {multipla_visibilidade ?
                <div className="col-sm-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">{t('Mission')}</label>
                    <select
                      onChange={(e) => setMissaoID(e.target.value)}
                      value={missao_id}
                      className="form-control select"
                    >
                      <option key={0} value={-1}></option>
                      {renderOptionMissoes(missoes)}
                    </select>
                  </div>
                </div> : ''}


                {semanal != 1 ?<>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        {t('Add Project Leader')}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="autoCompleteUsers"
                        value={searchTermLider}
                        onChange={handleSearchTermChangeLider}
                      />
                      {filteredLiders.length > 0 && (
                        <ul className="user-list">
                          <li key={0 * 10}>
                            <span onClick={() => handleLiderNovoSelect(null, 0)}>

                              <i title="Adicionar" className="fa fa-times text-danger"></i>
                              &nbsp;&nbsp;
                              {'Fechar a lista'}

                            </span>
                          </li>
                          {filteredLiders.map(user => (
                            <li key={user.id * 10}>
                              <span onClick={() => handleLiderNovoSelect(user, 1)}>

                                <i title="Adicionar" className="fa fa-plus-circle text-primary"></i>
                                &nbsp;&nbsp;<img src={url_root + "/storage/" + user.fotografia} alt={user.name} title={user.name} className="avatar" />
                                {user.name}

                              </span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Team Leader')}</label>

                      <div className="task-follower-list">

                        {lideresSelecionados?.slice(0, 4).map(user => (
                          <span data-bs-toggle="tooltip" title={user?.name} key={user?.id}>
                            <img
                              src={url_root + "/storage/" + user?.fotografia} alt={user?.name} title={user?.name} className="avatar"
                              width={20}
                              height={20}
                            />
                            <i className="fa fa-times"
                              onClick={() => handleLiderRemove(user)}
                            />
                          </span>
                        ))}

                        {
                          lideresSelecionados?.length > 4 ?
                            <span className="all-team">+{lideresSelecionados?.length - 4}</span> :
                            ''
                        }


                      </div>


                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Add Team')}</label>
                      <input
                        className="form-control"
                        type="text"
                        id="autoCompleteUsers"
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                      />
                      {filteredUsers.length > 0 && (
                        <ul className="user-list">
                          <li key={0}>
                            <span onClick={() => handleLiderNovoSelect(null, 0)}>

                              <i title="Adicionar" className="fa fa-times text-danger"></i>
                              &nbsp;&nbsp;
                              {'Fechar a lista'}

                            </span>
                          </li>
                          {filteredUsers.map(user => (
                            <li key={user.id}>
                              <span onClick={() => handleLiderNovoSelect(user, 2)}>

                                <i title="Adicionar" className="fa fa-plus-circle text-primary"></i>
                                &nbsp;&nbsp;<img src={url_root + "/storage/" + user.fotografia} alt={user.name} title={user.name} className="avatar" />
                                {user.name}

                              </span>
                            </li>
                          ))}
                        </ul>
                      )}

                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Team Members')}</label>
                      <div className="project-members">


                        <div className="task-follower-list">

                          {colaboradoresSelecionados?.slice(0, 4).map(user => (
                            <span data-bs-toggle="tooltip" title={user?.name} key={user?.id}>
                              <img
                                src={url_root + "/storage/" + user?.fotografia} alt={user?.name} title={user?.name} className="avatar"
                                width={20}
                                height={20}
                              />
                              <i className="fa fa-times"
                                onClick={() => handleLiderRemove(user)}
                              />
                            </span>
                          ))}

                          {
                            colaboradoresSelecionados?.filter(colaborador => colaborador.tipo == 2).length > 4 ?
                              <span className="all-team">+{colaboradoresSelecionados?.length - 4}</span> :
                              ''
                          }

                        </div>



                      </div>
                    </div>
                  </div>
                </div> </>: ''}


                <div className="input-block mb-3">
                  <label className="col-form-label">{t('Description')}</label>
                  <DefaultEditor value={descricao} onChange={e => setDescricao(e.target.value)} />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">{t('Upload Files')}</label>
                  <input className="form-control" name="files[]" type="file" onChange={handleFileChange} multiple="multiple" />
                </div>

                <div className="submit-section">
                  {loading ? 'A processar' : <a
                    className="btn btn-primary submit-btn"
                    onClick={submitForm}>
                    {t('Submit')}
                  </a>}

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div id="edit_project" className="modal custom-modal fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t('Edit Project')}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeButton2"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-sm-8">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Project Name')}</label>
                      <input className="form-control" type="text" name="nome" id="nome" value={inputs?.nome} onChange={handleInputsValueChanged} />
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Status')}</label>
                      <select className="form-control select" value={estado} onChange={e => setEstado(e.target.value)} selected={estado === inputs?.estado} >
                        <option value="0">
                          {t('Opened')}
                        </option>
                        <option value="1" >
                          {t('Closed')}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Start Date')}</label>
                      <div className="">
                        <input className="form-control" type="date" name="data_inicio" value={inputs?.data_inicio} onChange={handleInputsValueChanged} />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('End Date')}</label>
                      <div className="">
                        <input className="form-control" type="date" name="data_fim" value={inputs?.data_fim} onChange={handleInputsValueChanged} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Rate')}</label>
                      <input
                        placeholder="Kz 50"
                        className="form-control"
                        type="number"
                        name="orcamento" value={inputs?.orcamento} onChange={handleInputsValueChanged}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">&nbsp;</label>
                      <select className="form-control select" value={tempoSelecionado} onChange={e => handleSelectTempoChange(e.target.value)} >
                        <option value="1" selected={inputs?.tempo == 1}>
                          {t('Fixed')}
                        </option>
                        <option value="2" selected={inputs?.tempo == 2}>
                          {t('Hourly')}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Real Cost')}</label>
                      <input
                        placeholder="0"
                        className="form-control"
                        type="number"
                        name="custo" value={inputs?.custo} onChange={handleInputsValueChanged}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Priority')}</label>
                      <select className="form-control select" value={prioridadeSelecionada} onChange={e => handleSelectPrioridadeChange(e.target.value)} >
                        <option value="1" selected={inputs?.prioridade == 1}>
                          {t('Low')}
                        </option>
                        <option value="2" selected={inputs?.prioridade == 2}>
                          {t('Medium')}
                        </option>
                        <option value="3" selected={inputs?.prioridade == 3}>
                          {t('High')}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>





                <div className="row">

                <div className="col-sm-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Programa semanal ?</label>
                      <select className="form-control select" value={semanalSelecionado} onChange={e => handleSelectSemanalChange(e.target.value)} >
                        <option value="1" selected={inputs?.semanal == 1}>
                          {t('Yes')}
                        </option>
                        <option value="2" selected={inputs?.semanal == 2}>
                          {t('No')}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-9">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Mission')}</label>
                      <select
                        onChange={(e) => setMissaoID(e.target.value)}
                        value={missao_id}
                        className="form-control select"
                      >
                        <option key={0} value={-1}></option>
                        {renderOptionMissoes(missoes)}
                      </select>
                    </div>
                  </div>

                  

                </div>


                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        {t('Add Project Leader')}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="autoCompleteUsers"
                        value={searchTermLider}
                        onChange={handleSearchTermChangeLider}
                      />
                      {filteredLiders.length > 0 && (
                        <ul className="user-list">
                          <li key={0 * 10}>
                            <span onClick={() => handleUserSelect(null, 0)}>

                              <i title="Adicionar" className="fa fa-times text-danger"></i>
                              &nbsp;&nbsp;
                              {'Fechar a lista'}

                            </span>
                          </li>
                          {filteredLiders.map(user => (
                            <li key={user.id * 10}>
                              <span onClick={() => handleUserSelect(user, 1)}>

                                <i title="Adicionar" className="fa fa-plus-circle text-primary"></i>
                                &nbsp;&nbsp;<img src={url_root + "/storage/" + user.fotografia} alt={user.name} title={user.name} className="avatar" />
                                {user.name}

                              </span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Team Leader')}</label>

                      <div className="task-follower-list">

                        {inputs?.colaboradores?.filter(user => user.tipo == 1).slice(0, 4).map(user => (
                          <span data-bs-toggle="tooltip" title={user?.colaborador?.name} key={user?.id}>
                            <img
                              src={url_root + "/storage/" + user?.colaborador?.fotografia} alt={user?.colaborador?.name} title={user?.colaborador?.name} className="avatar"
                              width={20}
                              height={20}
                            />
                            <i className="fa fa-times"
                              onClick={() => removerColaboradoresProjeto(user?.colaborador)}
                            />
                          </span>
                        ))}

                        {
                          inputs?.colaboradores?.filter(colaborador => colaborador.tipo == 1).length > 4 ?
                            <span className="all-team">+{inputs?.colaboradores?.filter(colaborador => colaborador.tipo == 1).length - 4}</span> :
                            ''
                        }


                      </div>


                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Add Team')}</label>
                      <input
                        className="form-control"
                        type="text"
                        id="autoCompleteUsers"
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                      />
                      {filteredUsers.length > 0 && (
                        <ul className="user-list">
                          <li key={0}>
                            <span onClick={() => handleUserSelect(null, 0)}>

                              <i title="Adicionar" className="fa fa-times text-danger"></i>
                              &nbsp;&nbsp;
                              {'Fechar a lista'}

                            </span>
                          </li>
                          {filteredUsers.map(user => (
                            <li key={user.id}>
                              <span onClick={() => handleUserSelect(user, 2)}>

                                <i title="Adicionar" className="fa fa-plus-circle text-primary"></i>
                                &nbsp;&nbsp;<img src={url_root + "/storage/" + user.fotografia} alt={user.name} title={user.name} className="avatar" />
                                {user.name}

                              </span>
                            </li>
                          ))}
                        </ul>
                      )}

                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Team Members')}</label>
                      <div className="project-members">


                        <div className="task-follower-list">

                          {inputs?.colaboradores?.filter(user => user.tipo == 2).slice(0, 4).map(user => (
                            <span data-bs-toggle="tooltip" title={user?.colaborador?.name} key={user?.id}>
                              <img
                                src={url_root + "/storage/" + user?.colaborador?.fotografia} alt={user?.colaborador?.name} title={user?.colaborador?.name} className="avatar"
                                width={20}
                                height={20}
                              />
                              <i className="fa fa-times"
                                onClick={() => removerColaboradoresProjeto(user?.colaborador)}
                              />
                            </span>
                          ))}

                          {
                            inputs?.colaboradores?.filter(colaborador => colaborador.tipo == 2).length > 4 ?
                              <span className="all-team">+{inputs?.colaboradores?.filter(colaborador => colaborador.tipo == 2).length - 4}</span> :
                              ''
                          }

                        </div>



                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">{t('Description')}</label>
                  <DefaultEditor name="descricao" value={inputs?.descricao} onChange={handleInputsValueChanged} />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">{t('Upload Files')}</label>
                  <input className="form-control" name="files[]" type="file" onChange={handleFileChange} id="files_edit" multiple="multiple" />
                </div>
                <div className="submit-section">

                  {loading ? 'Carregando' : <a
                    className="btn btn-primary submit-btn"
                    onClick={submitFormUpdate}>
                    {t('Save')}
                  </a>}

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

    </>
  );
};

export default ProjectModelPopup;
