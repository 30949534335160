import React from "react";

import Breadcrumbs from "../../../../../components/Breadcrumbs";
import ExperienceLevelTable from "./ExperienceLevelTable";
import AddExperiencelModal from "../../../../../components/Administration/Jobs/ExperienceLevel/AddExperiencelModal";

import { useTranslation } from "react-i18next";

const ExperienceLevel = () => {

  const { t } = useTranslation();

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle={t('Experience Level')}
            title="Dashboard"
            subtitle={t('Jobs')+' / '+t('Experience Level') }
            modal="#add_employee"
            name={t('Add Experience Level')}
          />

          <ExperienceLevelTable />

          <AddExperiencelModal />
        </div>
      </div>
    </>
  );
};

export default ExperienceLevel;
