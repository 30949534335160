import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom';
import ProjectViewDetails from './ProjectViewDetails';
import ProjectModelPopup from '../../../../components/modelpopup/ProjectModelPopup';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { useTranslation } from "react-i18next";

import AuthUser from "../../../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../../../components/Spinner";

import Swal from "sweetalert2";

import { format } from 'date-fns';

import DefaultEditor from "react-simple-wysiwyg";
import TaskboardModal from "../../../../components/modelpopup/TaskboardModal";
import TarefasModalPopup from "../../../../components/modelpopup/TarefasModalPopup";
import ModalFicheiroExcel from "../../../../components/modelpopup/ModalFicheiroExcel";

const ProjectView = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    let { projectId } = useParams();

    const [missoes, setMissoes] = useState([]);

    // eslint-disable-next-line no-unused-vars
    const { http, url_root, user_permissoes, verificarPermissao, user, getMissao } = AuthUser();
    const [projeto, setProjeto] = useState([]);
    const [tarefaSelecionada, setTarefaSelecionada] = useState([]);
    const [quadroSelecionado, setQuadroSelecionado] = useState([]);
    const [utilizadores, setUtilizadores] = useState([]);

    const [atividades, setAtividades] = useState([]);

    const [idProject, setProjectID] = useState(projectId);

    const [descricaoWithLineBreaks, setDescricao] = useState([]);    

    // State to track whether the "checkgeral" checkbox is checked
    const [loading, setLoading] = useState(false);

    const getProjeto = async () => {
        try {
            setLoading(true); // Set loading to true when the request starts

            const res = await http.get('/projeto/' + idProject);

            setProjeto(res.data.projeto);

            setAtividades(res.data.atividades);

            setQuadroSelecionado(res.data.projeto?.quadros[0]);

            if(temAcessoAoProjeto==false && user.tipo>1){
                navigate('/admin-dashboard');
            }

            setLoading(false);

        } finally {
            setLoading(false); // Set loading to false when the request is complete (either success or error)
        }
    };

    const getMissoes = async () => {
        try {
            setLoading(true); // Set loading to true when the request starts

            const res = await http.get('/missoes_ativas');

            setMissoes(res.data.missoes);

            setLoading(false);

        } finally {
            setLoading(false); // Set loading to false when the request is complete (either success or error)
        }
    };

    const handleNavegarParaTarefa = (tarefa_id) => {

        navigate('/task/tasks/' + tarefa_id);

    };

    const getUtilizadores = async () => {
        try {

            setLoading(true); // Set loading to true when the request starts

            const res = await http.get('/utilizadores/ativados/'+getMissao()?.id);

            setUtilizadores(res.data.utilizadores);

            

            console.log('Utilizadores : ');
            console.log(res.data.utilizadores);

            setLoading(false);

        } finally {
            setLoading(false); // Set loading to false when the request is complete (either success or error)
        }
    };

    const temAcessoAoProjeto = () => {
           
        // Verificar se o usuário é o criador do projeto
        if (projeto.criador_por == user.id) {
          return true;
        }    
        // Verificar se o usuário é um dos colaboradores do projeto
        if (projeto.colaboradores.some(colaborador => colaborador.colaborador_id == user.id)) {
          return true;
        }    
        // Se não for o criador nem um dos colaboradores, então o usuário não tem acesso ao projeto
        return false;
      }

    useEffect(() => {

        //setProjectID(projectId);

        if (projeto && projeto?.descricao) {
            // Replace <br> with new line characters
            //let updatedDescricao = projeto?.descricao.replace(/<br\s*\/?>/gi, '\n');

            // Remove extra spaces
            let updatedDescricao = projeto?.descricao.replace(/\s+/g, ' ');

            // Remove <b> tags
            updatedDescricao = updatedDescricao.replace(/<b[^>]*>(.*?)<\/b>/gi, '$1');

            setDescricao(updatedDescricao);
        }

        const fetchData = async () => {
            try {
                // Chama a função assíncrona getUtilizador()
                await getProjeto();
                await getUtilizadores();
                await getMissoes();

            } catch (error) {
                console.error('Erro ao obter utilizadores:', error);
            }
        };

        // Chama a função fetchData() para iniciar o processo
        fetchData();
    }, [idProject]);

    const handleSubmissionSuccess = (projectIdSubmited,atividades) => {

        console.log('received : ');
        console.log(projectIdSubmited);

        //setProjectID(projectIdSubmited?.id);      

        setProjeto(projectIdSubmited);
        setAtividades(atividades);

    };

    const chamarModalEdit = (idSelecionado) => {

        const editButton = document.getElementById('triggerModalEdit');

        if (editButton) {
            editButton.click();
        }

    }

    const chamarModalEditTarefa = (tarefa) => {

        setTarefaSelecionada(tarefa);

        setQuadroSelecionado(projeto?.quadros[0]);

        const editButton = document.getElementById('trigger_edit_task_modal');

        if (editButton) {
            editButton.click();
        }

    }

    const removerTarefa = async (tarefa) => {

        Swal.fire({
            title: "Remover tarefa?",
            text: "Tem a certeza que pretende remover este colaborador do projeto?",
            type: "confirm",
            showCancelButton: !0,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, remover!",
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-danger ml-1",
            buttonsStyling: !1,
        }).then(async function (t) {

            if (t.isConfirmed) {
                try {

                    setLoading(true);

                    const formData = new FormData();
                    formData.append('id', tarefa?.id);

                    const response = await http.post('/projeto/eliminar/tarefa', formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    });

                    // Show a success toast
                    toast.success(response.data.message, {
                        position: 'top-right',
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        onClose: () => {

                            setLoading(false);
                            //Refresh colaboradores list
                            getProjeto();

                        }
                    });

                } catch (error) {

                    setLoading(false);
                    console.error(error);

                    toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }

        });
    };


    /*const abrirFecharTarefa = async (tarefa_id, estado) => {

        try {

            const formData = new FormData();
            formData.append('id', tarefa_id);
            formData.append('estado', estado);
            formData.append('projeto_id', projeto?.id);

            const response = await http.post('/projeto/tarefa/activate', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });


            //Toast is not working here
            // Show a success toast
            toast.success(response.data.message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                onClose: () => {


                    setProjeto(response.data.projeto);

                }
            });

        } catch (error) {

            setLoading(false);
            console.error(error);

            toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
                position: 'top-right',
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }



    };*/


    const projectview = {
        project: {
            title: "Hospital Administration",
            taskCounts: {
                openTasks: 2,
                completedTasks: 5,
            },
            description: ["",
            ],
        },
    };

    const initialTasks = [
        'Patient appointment booking',
        'Appointment booking with payment gateway',
        'Doctor available module',
        'Patient and Doctor video conferencing',
        'Private chat module',
        'Patient Profile add',
    ];
    const [tasks, setTasks] = useState(initialTasks);

    const deleteTask = (index) => {
        const updatedTasks = [...tasks];
        updatedTasks.splice(index, 1);
        setTasks(updatedTasks);
    };

    const removerArquivoProjeto = async (arquivo) => {

        Swal.fire({
            title: "Remover arquivo?",
            text: "Tem a certeza que pretende remover este arquivo do projeto?",
            type: "warning",
            showCancelButton: !0,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, remover!",
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-danger ml-1",
            buttonsStyling: !1,
        }).then(async function (t) {

            if (t.isConfirmed) {
                try {

                    setLoading(true);

                    const formData = new FormData();
                    formData.append('arquivo_id', arquivo?.id);
                    formData.append('projeto_id', projeto?.id);

                    const response = await http.post('/projeto/removerArquivo', formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    });

                    setLoading(false);
                    getProjeto();

                    //Toast is not working here
                    // Show a success toast
                    toast.success(response.data.message, {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        onClose: () => {

                            setLoading(false);
                            //Refresh colaboradores list
                            //getProjeto();

                        }
                    });

                } catch (error) {

                    setLoading(false);
                    console.error(error);

                    toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
                        position: 'top-right',
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }

        });
    };

    const aprovarProjeto = async (projeto_id, estado) => {

        if (user?.tipo == 1) {

            Swal.fire({
                title: estado == 1 ? 'Aprovar projeto' : 'Reprovar projeto',
                text: "Tem a certeza que pretende " + (estado == 1 ? 'aprovar' : 'reprovar') + " este projeto ?",
                type: "confirm",
                showCancelButton: !0,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim!",
                confirmButtonClass: "btn btn-primary",
                cancelButtonClass: "btn btn-danger ml-1",
                buttonsStyling: !1,
            }).then(async function (t) {

                if (t.isConfirmed) {
                    try {

                        setLoading(true);

                        const formData = new FormData();
                        formData.append('id', projeto_id);
                        formData.append('estado', estado);

                        const response = await http.post('/projeto/aprovar', formData, {
                            headers: { 'Content-Type': 'multipart/form-data' },
                        });

                        setLoading(false);
                        //Refresh colaboradores list
                        getProjeto();

                        // Show a success toast
                        toast.success(response.data.message, {
                            position: 'top-right',
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            onClose: () => {

                                setLoading(false);
                                //Refresh colaboradores list
                                //getProjetos();

                            }
                        });

                    } catch (error) {

                        setLoading(false);
                        console.error(error);

                        toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }
                }

            });

        } else {

            toast.error('Seu utilizador não tem permissão para aprovar ou negar aprovação de projeto', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });

        }




    };


    const abrirFecharProjeto = async (projeto_id, estado) => {

        try {
       
    
          const formData = new FormData();
          formData.append('estado', estado);
          formData.append('projeto_id', projeto_id);
    
          const response = await http.post('/projeto/activate', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
    
    
    
          //Toast is not working here
          // Show a success toast
          toast.success(response.data.message, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => {
    
                getProjeto();
    
            }
          });
    
        } catch (error) {
    
          setLoading(false);
          console.error(error);
    
          toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
    
    
    
      };

      const abrirFecharTarefa = async (tarefa_id, estado) => {

        try {
    
    
    
          const formData = new FormData();
          formData.append('estado', estado);
          formData.append('tarefa_id', tarefa_id);
    
          const response = await http.post('/projeto/tarefa/activate', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
    
    
    
          //Toast is not working here
          // Show a success toast
          toast.success(response.data.message, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => {
    
              getProjeto();
    
            }
          });
    
        } catch (error) {
    
          setLoading(false);
          console.error(error);
    
          toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }    
    
      };

    if (loading) return (<><Spinner></Spinner><ToastContainer /></>); else return (
        <>        <div>

            <div className='page-wrapper'>
                <div className="content container-fluid">
                    {/* Page Header */}
                    <Breadcrumbs
                        maintitle={projeto?.projecto_code + ' - ' + projeto?.nome}
                        title="Dashboard"
                        subtitle={t('Project')}
                        modal="#create_project"
                        add="Edit Project"
                    />

                    <div className="row">
                        <div className="col-lg-8 col-xl-8">
                            <div className="card">
                                <div className="card-body">
                                    <div className="project-title">
                                        <h5 className="card-title">

                                            {
                                                projeto?.aprovado == 0 ? <><img width={24}
                                                    height={24} title={t('Pending to approve')} src={url_root + "/storage/uploads/icons/siren.png"} />
                                                </> : projeto?.aprovado == 1 ? <span title={t('Approved')}><i className="fa fa-thumbs-up text-success"></i></span> : <span title={t('Negated')}><i className="fa fa-thumbs-down text-danger"></i></span>
                                            }&nbsp;

                                            {
                                                user.tipo == 1 ? <>
                                                    <span
                                                        className="badge bg-inverse-success"
                                                        title={projeto?.nome}
                                                        onClick={() => aprovarProjeto(projeto?.id, 1)}
                                                    >
                                                        <i className="fa fa-thumbs-up text-success m-r-5" /> {t('Approve')}
                                                    </span>&nbsp;
                                                    <span
                                                        className="badge bg-inverse-danger"
                                                        title={projeto?.nome}
                                                        onClick={() => aprovarProjeto(projeto?.id, 2)}
                                                    >
                                                        <i className="fa fa-thumbs-down text-danger m-r-5" /> {t('Negate')}
                                                    </span>
                                                </> : <></>
                                            }&nbsp;


                                            {
                                                (projeto?.aprovado == 0 || projeto?.aprovado == 2) ? <></> : projeto?.estado == 1 ? <><span
                                                    className={"badge bg-inverse-success"}
                                                >
                                                    <i className="fa fa-check"></i>&nbsp;&nbsp; <span>{t('Closed')}</span>
                                                </span>&nbsp;&nbsp;</> : ''
                                            }&nbsp;

                                            {
                                                (projeto?.aprovado == 0 || projeto?.aprovado == 2) ? <></> : ((new Date(projeto?.data_fim) < new Date()) && projeto?.estado != 1) ? (<><span
                                                    className={"badge bg-inverse-danger"}
                                                >
                                                    <i className="fa fa-clock"></i>&nbsp;&nbsp; <span>{t('Pending')}</span>
                                                </span>&nbsp;&nbsp;</>) : (projeto?.estado != 1 ? (<span
                                                    className={"badge bg-inverse-warning"}
                                                >
                                                    <i className="fa fa-list"></i>&nbsp;&nbsp; <span>{t('Opened')}</span>
                                                </span>) : '')
                                            }&nbsp;


                                            &nbsp;&nbsp;
                                            <Link to={'/task-board/' + projeto?.id} title="Quadro de tarefas" className={"badge bg-inverse-info"}>
                                                <i className="fa fa-tasks"></i>&nbsp;{t('Task-board')}
                                            </Link>&nbsp;&nbsp;

                                            {
                                                projeto?.estado == 1 ?
                                            <span
                                                className={"badge bg-inverse-danger"}
                                                onClick={() => abrirFecharProjeto(projeto?.id,0)}
                                            >
                                                <i className="fa fa-check" />{t(' Abrir ')}&nbsp;&nbsp;
                                            </span>:
                                            <span
                                                className={"badge bg-inverse-success"}
                                                onClick={() => abrirFecharProjeto(projeto?.id,1)}
                                            >
                                                <i className="fa fa-check" />{t(' Fechar ')}&nbsp;&nbsp;
                                            </span>
                                            }

                                            <Link
                                                to="#"
                                                className={"badge bg-inverse-success"}
                                                data-bs-toggle="modal"
                                                data-bs-target="#modal_form_excel_import"
                                            >
                                                <i className="fa fa-file-excel" />{t(' Excel')}
                                            </Link>&nbsp;&nbsp;
                                            
                                            <a title={projeto?.nome} className={"badge bg-inverse-danger"} href={url_root + '/relatorio_produtividade/' + projeto?.id} target="_blank" rel="noreferrer"><i className="fa fa-file-pdf"></i>&nbsp;Relatório de produtividade</a>
                                            &nbsp;&nbsp;
                                            <span
                                                className={"badge bg-inverse-warning"}
                                                onClick={() => chamarModalEdit(projeto)}
                                            >
                                                <i className="fa fa-pencil"></i>&nbsp;&nbsp; <span>{t('Edit Project')}</span>
                                            </span>
                                        </h5>
                                        <h5 className="card-title">
                                            {projeto?.projecto_code + ' - ' + projeto?.nome}
                                        </h5>
                                        <small className="block text-ellipsis m-b-15">
                                            <span className="text-xs">{projeto?.tarefas?.filter(tarefa => tarefa.estado == 0).length}</span>{" "}
                                            <span className="text-warning">{t('open tasks')}, </span>

                                            <span className="text-xs">
                                                {projeto?.tarefas?.filter(tarefa => tarefa.estado == 1).length}
                                            </span>{" "}
                                            <span className="text-success">{t('tasks completed')}</span>

                                            {", "}
                                            <span className="text-xs">
                                                {projeto?.tarefas?.filter(tarefa => (((new Date(tarefa?.prazo) < new Date()) && tarefa?.estado != 1))).length}
                                            </span>{" "}
                                            <span className="text-danger">{t('Pending Tasks')}</span>

                                        </small>
                                    </div>

                                    <div className="col-12 mb-4">
                                        <div className="pro-progress">
                                            <div className="pro-progress-bar">
                                                <h4>{t('Progress')}</h4>
                                                <div className="progress progress-xs mb-0">
                                                    <div
                                                        className="progress-bar bg-success"
                                                        role="progressbar"
                                                        data-bs-toggle="tooltip"
                                                        title={(Math.round(((projeto?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / projeto?.tarefas?.length) * 100)) + '%'}
                                                        style={{ width: `${Math.round(((projeto?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / projeto?.tarefas?.length) * 100)}%` }}
                                                    />
                                                </div>
                                                <span>

                                                    {Math.round(((projeto?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / projeto?.tarefas?.length) * 100) ? Math.round(((projeto?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / projeto?.tarefas?.length) * 100) : 0}
                                                    %</span>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <pre>{descricaoWithLineBreaks}</pre> */}

                                    <DefaultEditor
                                        value={projeto?.descricao}
                                        style={{ minHeight: '400px', overflow: 'auto' }}
                                        disabled

                                    />


                                </div>
                            </div>


                            <div className="project-task">

                                <span
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_task_modal"
                                    id="trigger_modal_add_task"
                                    className="btn btn-primary mb-2"
                                >
                                    <i className="fa fa-plus"></i>{' '}
                                    {t('Add Task')}
                                </span>

                                {' '}
                                <span
                                    data-bs-toggle="modal"
                                    data-bs-target="#all_tasks_modal"
                                    id="trigger_modal_all_task"
                                    className="btn btn-secondary mb-2 ml-2"
                                >
                                    <i className="fa fa-list"></i>{' '}
                                    {t('All Tasks')}
                                </span>

                                <ul className="nav nav-tabs nav-tabs-top nav-justified mb-0">

                                    <li className="nav-item">
                                        <Link
                                            className="nav-link active"
                                            to="#all_tasks"
                                            data-bs-toggle="tab"
                                            aria-expanded="true">
                                            {t('All Tasks')}
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link"
                                            to="#open_tasks"
                                            data-bs-toggle="tab"
                                            aria-expanded="false">
                                            {t('Progress')}
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link"
                                            to="#pending_tasks"
                                            data-bs-toggle="tab"
                                            aria-expanded="false">
                                            {t('Pending Tasks')}
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link"
                                            to="#completed_tasks"
                                            data-bs-toggle="tab"
                                            aria-expanded="false">
                                            {t('Completed Tasks')}
                                        </Link>
                                    </li>



                                </ul>

                                <div className="tab-content">
                                    <div className="tab-pane show active" id="all_tasks">
                                        <div className="task-list-body">

                                        <div className="table-responsive">

                                            <table className="table table-bordered" width="100%">
                                                <tbody>

                                                    {projeto?.tarefas?.map(task => (

                                                        <tr key={task?.id}>
                                                            <td>
                                                                <span className={"task-action-btn task-check " + (
                                                                    task?.estado == 1
                                                                        ? " text-success "
                                                                        : ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? " text-danger " : " text-warning "
                                                                )}

                                                                    onClick={() => abrirFecharTarefa(task?.id, task?.estado == 1 ? 0 : 1)}

                                                                >
                                                                    <span
                                                                        className="action-circle large complete-btn"
                                                                        title="Mark Complete"
                                                                    >
                                                                        <i className="material-icons">check</i>
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="task-label text-start" >
                                                                    {format(new Date(task?.prazo), 'dd/MM/yyyy')}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {
                                                                    task?.estado == 1 ? <>
                                                                        <span
                                                                            className={"badge bg-inverse-success"}
                                                                        >
                                                                            <i className="fa fa-check"></i> <span>{t('Closed')}</span>
                                                                        </span></> : ''
                                                                }

                                                                {

                                                                    ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? (<><span
                                                                        className={"badge bg-inverse-danger"}
                                                                    >
                                                                        <i className="fa fa-clock"></i> <span>{t('Pending')}</span>
                                                                    </span></>) : (task?.estado != 1 ? (<span
                                                                        className={"badge bg-inverse-warning"}
                                                                    >
                                                                        <i className="fa fa-list"></i> <span>{t('Opened')}</span>
                                                                    </span>) : '')
                                                                }

                                                            </td>
                                                            <td>
                                                                <span
                                                                    className={
                                                                        task?.prioridade == 1
                                                                            ? "badge bg-inverse-success"
                                                                            : task?.prioridade == 2 ? "badge bg-inverse-warning" : "badge bg-inverse-danger"
                                                                    }
                                                                >{t('Priority')}{' : '}
                                                                    {task?.prioridade == 1 ? t('Low') : task?.prioridade == 2 ? t('Medium') : t('High')}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className={"task-label text-start"}
                                                                >[{(Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) ? (Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) : 0}%]
                                                                </span>
                                                            </td>
                                                            <td>

                                                                <span className="task-label" >{task?.nome}
                                                                </span>
                                                            </td>
                                                            <td>

                                                                <span className="task-action-btn task-btn-right">
                                                                    <Link className="action-circle large delete-btn" title="Delete Task" to={"/task/tasks/" + task?.id} >
                                                                        <i className="fa fa-link"></i>
                                                                    </Link>
                                                                    <span className="action-circle large me-1" title="edit" onClick={() => chamarModalEditTarefa(task)}>
                                                                        <i className="fa fa-edit"></i>
                                                                    </span>
                                                                    <span className="action-circle large me-1" title="Assign">
                                                                        <i className="material-icons">person_add</i>
                                                                    </span>
                                                                    <span className="action-circle large delete-btn" title="Delete Task" onClick={() => removerTarefa(task)}>
                                                                        <i className="material-icons">delete</i>
                                                                    </span>
                                                                </span>


                                                            </td>
                                                        </tr>

                                                    ))}

                                                </tbody>
                                            </table>


                                        </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="open_tasks">
                                        <div className="task-wrapper">
                                        <div className="table-responsive">

                                            <table className="table table-bordered" width="100%">
                                                <tbody>

                                                    {projeto?.tarefas
                                                        // Filtrar apenas tarefas pendentes
                                                        ?.filter(task => task.estado == 0)
                                                        .map(task => (

                                                            <tr key={task?.id}>
                                                                <td>
                                                                    <span className={"task-action-btn task-check " + (
                                                                        task?.estado == 1
                                                                            ? " text-success "
                                                                            : ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? " text-danger " : " text-warning "
                                                                    )}

                                                                        onClick={() => abrirFecharTarefa(task?.id, task?.estado == 1 ? 0 : 1)}

                                                                    >
                                                                        <span
                                                                            className="action-circle large complete-btn"
                                                                            title="Mark Complete"
                                                                        >
                                                                            <i className="material-icons">check</i>
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span className="task-label text-start" >
                                                                        {format(new Date(task?.prazo), 'dd/MM/yyyy')}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        task?.estado == 1 ? <>
                                                                            <span
                                                                                className={"badge bg-inverse-success"}
                                                                            >
                                                                                <i className="fa fa-check"></i> <span>{t('Closed')}</span>
                                                                            </span></> : ''
                                                                    }

                                                                    {

                                                                        ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? (<><span
                                                                            className={"badge bg-inverse-danger"}
                                                                        >
                                                                            <i className="fa fa-clock"></i> <span>{t('Pending')}</span>
                                                                        </span></>) : (task?.estado != 1 ? (<span
                                                                            className={"badge bg-inverse-warning"}
                                                                        >
                                                                            <i className="fa fa-list"></i> <span>{t('Opened')}</span>
                                                                        </span>) : '')
                                                                    }

                                                                </td>
                                                                <td>
                                                                    <span
                                                                        className={
                                                                            task?.prioridade == 1
                                                                                ? "badge bg-inverse-success"
                                                                                : task?.prioridade == 2 ? "badge bg-inverse-warning" : "badge bg-inverse-danger"
                                                                        }
                                                                    >{t('Priority')}{' : '}
                                                                        {task?.prioridade == 1 ? t('Low') : task?.prioridade == 2 ? t('Medium') : t('High')}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        className={"task-label text-start"}
                                                                    >[{(Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) ? (Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) : 0}%]
                                                                    </span>
                                                                </td>
                                                                <td>

                                                                    <span className="task-label" >{task?.nome}
                                                                    </span>
                                                                </td>
                                                                <td>

                                                                    <span className="task-action-btn task-btn-right">
                                                                        <Link className="action-circle large delete-btn" title="Delete Task" to={"/task/tasks/" + task?.id} >
                                                                            <i className="fa fa-link"></i>
                                                                        </Link>
                                                                        <span className="action-circle large me-1" title="edit" onClick={() => chamarModalEditTarefa(task)}>
                                                                            <i className="fa fa-edit"></i>
                                                                        </span>
                                                                        <span className="action-circle large me-1" title="Assign">
                                                                            <i className="material-icons">person_add</i>
                                                                        </span>
                                                                        <span className="action-circle large delete-btn" title="Delete Task" onClick={() => removerTarefa(task)}>
                                                                            <i className="material-icons">delete</i>
                                                                        </span>
                                                                    </span>


                                                                </td>
                                                            </tr>

                                                        ))}

                                                </tbody>
                                            </table>




                                        </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="pending_tasks">
                                        <div className="task-wrapper">

                                        <div className="table-responsive">

                                            <table className="table table-bordered" width="100%">
                                                <tbody>

                                                    {projeto?.tarefas
                                                        // Filtrar apenas tarefas pendentes
                                                        ?.filter(task => task.estado == 0 && new Date(task.prazo) < new Date())
                                                        .map(task => (

                                                            <tr key={task?.id}>
                                                                <td>
                                                                    <span className={"task-action-btn task-check " + (
                                                                        task?.estado == 1
                                                                            ? " text-success "
                                                                            : ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? " text-danger " : " text-warning "
                                                                    )}

                                                                        onClick={() => abrirFecharTarefa(task?.id, task?.estado == 1 ? 0 : 1)}

                                                                    >
                                                                        <span
                                                                            className="action-circle large complete-btn"
                                                                            title="Mark Complete"
                                                                        >
                                                                            <i className="material-icons">check</i>
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span className="task-label text-start" >
                                                                        {format(new Date(task?.prazo), 'dd/MM/yyyy')}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        task?.estado == 1 ? <>
                                                                            <span
                                                                                className={"badge bg-inverse-success"}
                                                                            >
                                                                                <i className="fa fa-check"></i> <span>{t('Closed')}</span>
                                                                            </span></> : ''
                                                                    }

                                                                    {

                                                                        ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? (<><span
                                                                            className={"badge bg-inverse-danger"}
                                                                        >
                                                                            <i className="fa fa-clock"></i> <span>{t('Pending')}</span>
                                                                        </span></>) : (task?.estado != 1 ? (<span
                                                                            className={"badge bg-inverse-warning"}
                                                                        >
                                                                            <i className="fa fa-list"></i> <span>{t('Opened')}</span>
                                                                        </span>) : '')
                                                                    }

                                                                </td>
                                                                <td>
                                                                    <span
                                                                        className={
                                                                            task?.prioridade == 1
                                                                                ? "badge bg-inverse-success"
                                                                                : task?.prioridade == 2 ? "badge bg-inverse-warning" : "badge bg-inverse-danger"
                                                                        }
                                                                    >{t('Priority')}{' : '}
                                                                        {task?.prioridade == 1 ? t('Low') : task?.prioridade == 2 ? t('Medium') : t('High')}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        className={"task-label text-start"}
                                                                    >[{(Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) ? (Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) : 0}%]
                                                                    </span>
                                                                </td>
                                                                <td>

                                                                    <span className="task-label" >{task?.nome}
                                                                    </span>
                                                                </td>
                                                                <td>

                                                                    <span className="task-action-btn task-btn-right">
                                                                        <Link className="action-circle large delete-btn" title="Delete Task" to={"/task/tasks/" + task?.id} >
                                                                            <i className="fa fa-link"></i>
                                                                        </Link>
                                                                        <span className="action-circle large me-1" title="edit" onClick={() => chamarModalEditTarefa(task)}>
                                                                            <i className="fa fa-edit"></i>
                                                                        </span>
                                                                        <span className="action-circle large me-1" title="Assign">
                                                                            <i className="material-icons">person_add</i>
                                                                        </span>
                                                                        <span className="action-circle large delete-btn" title="Delete Task" onClick={() => removerTarefa(task)}>
                                                                            <i className="material-icons">delete</i>
                                                                        </span>
                                                                    </span>


                                                                </td>
                                                            </tr>

                                                        ))}

                                                </tbody>
                                            </table>





                                        </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="completed_tasks">
                                        <div className="task-wrapper">

                                        <div className="table-responsive">


                                            <table className="table table-bordered" width="100%">
                                                <tbody>

                                                    {projeto?.tarefas
                                                        // Filtrar apenas tarefas pendentes
                                                        ?.filter(task => task.estado == 1)
                                                        .map(task => (

                                                            <tr key={task?.id}>
                                                                <td>
                                                                    <span className={"task-action-btn task-check " + (
                                                                        task?.estado == 1
                                                                            ? " text-success "
                                                                            : ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? " text-danger " : " text-warning "
                                                                    )}

                                                                        onClick={() => abrirFecharTarefa(task?.id, task?.estado == 1 ? 0 : 1)}

                                                                    >
                                                                        <span
                                                                            className="action-circle large complete-btn"
                                                                            title="Mark Complete"
                                                                        >
                                                                            <i className="material-icons">check</i>
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span className="task-label text-start" >
                                                                        {format(new Date(task?.prazo), 'dd/MM/yyyy')}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        task?.estado == 1 ? <>
                                                                            <span
                                                                                className={"badge bg-inverse-success"}
                                                                            >
                                                                                <i className="fa fa-check"></i> <span>{t('Closed')}</span>
                                                                            </span></> : ''
                                                                    }

                                                                    {

                                                                        ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? (<><span
                                                                            className={"badge bg-inverse-danger"}
                                                                        >
                                                                            <i className="fa fa-clock"></i> <span>{t('Pending')}</span>
                                                                        </span></>) : (task?.estado != 1 ? (<span
                                                                            className={"badge bg-inverse-warning"}
                                                                        >
                                                                            <i className="fa fa-list"></i> <span>{t('Opened')}</span>
                                                                        </span>) : '')
                                                                    }

                                                                </td>
                                                                <td>
                                                                    <span
                                                                        className={
                                                                            task?.prioridade == 1
                                                                                ? "badge bg-inverse-success"
                                                                                : task?.prioridade == 2 ? "badge bg-inverse-warning" : "badge bg-inverse-danger"
                                                                        }
                                                                    >{t('Priority')}{' : '}
                                                                        {task?.prioridade == 1 ? t('Low') : task?.prioridade == 2 ? t('Medium') : t('High')}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        className={"task-label text-start"}
                                                                    >[{(Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) ? (Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) : 0}%]
                                                                    </span>
                                                                </td>
                                                                <td>

                                                                    <span className="task-label" >{task?.nome}
                                                                    </span>
                                                                </td>
                                                                <td>

                                                                    <span className="task-action-btn task-btn-right">
                                                                        <Link className="action-circle large delete-btn" title="Delete Task" to={"/task/tasks/" + task?.id} >
                                                                            <i className="fa fa-link"></i>
                                                                        </Link>
                                                                        <span className="action-circle large me-1" title="edit" onClick={() => chamarModalEditTarefa(task)}>
                                                                            <i className="fa fa-edit"></i>
                                                                        </span>
                                                                        <span className="action-circle large me-1" title="Assign">
                                                                            <i className="material-icons">person_add</i>
                                                                        </span>
                                                                        <span className="action-circle large delete-btn" title="Delete Task" onClick={() => removerTarefa(task)}>
                                                                            <i className="material-icons">delete</i>
                                                                        </span>
                                                                    </span>


                                                                </td>
                                                            </tr>

                                                        ))}

                                                </tbody>
                                            </table>



                                        </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title m-b-20">{t('Uploaded image files')}</h5>
                                    <div className="row">

                                        {projeto?.arquivos?.filter(arquivo => arquivo.tipo_ficheiro == 'media').map(arquivo => (

                                            <div className="col-md-3 col-sm-4 col-lg-4 col-xl-3" key={arquivo?.id * 10}>
                                                <div className="uploaded-box">
                                                    <div className="uploaded-img">
                                                        <i className="fa fa-times text-danger"
                                                            onClick={() => removerArquivoProjeto(arquivo)}
                                                        />
                                                        <img src={url_root + "/storage/uploads/" + arquivo?.ficheiro} alt={arquivo?.ficheiro} title={arquivo?.ficheiro} className="img-fluid" />
                                                    </div>
                                                    <div className="uploaded-img-name">
                                                        <a className="text-primary" href={url_root + '/api/ler_anexo/' + arquivo?.ficheiro} target="_blank" rel="noreferrer">{arquivo?.ficheiro}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title m-b-20">{t('Uploaded files')}</h5>

                                    <ul className="files-list">

                                        {projeto?.arquivos?.filter(arquivo => arquivo.tipo_ficheiro != 'media').map(arquivo => (

                                            <li key={arquivo?.id}>
                                                <div className="files-cont">

                                                    <div className="file-type">
                                                        <span className="files-icon">
                                                            <img src={url_root + "/storage/uploads/icons/" + arquivo?.file_icon} width={20} height={20} />
                                                        </span>
                                                    </div>

                                                    <div className="files-info">
                                                        <span className="file-name text-ellipsis">
                                                            <a className="text-primary" href={url_root + '/api/ler_anexo/' + arquivo?.ficheiro} target="_blank" rel="noreferrer">{arquivo?.ficheiro}</a>
                                                        </span>
                                                        <span className="file-author">
                                                            <Link to="#">{arquivo?.colaborador?.name}</Link>
                                                        </span>{" "}
                                                        <span className="file-date">{arquivo?.created_at ? new Date(arquivo?.created_at).toLocaleDateString('pt-BR') : ''}</span>
                                                        <div className="file-size">{t('Size')}: {arquivo?.tamanho}Mb</div>
                                                    </div>
                                                    <ul className="files-action">
                                                        <li className="dropdown dropdown-action">
                                                            <Link
                                                                to="#"
                                                                className="dropdown-toggle btn btn-link"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false">
                                                                <i className="material-icons">more_horiz</i>
                                                            </Link>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                <span><a className="text-primary" href={url_root + '/api/ler_anexo/' + arquivo?.ficheiro} target="_blank" rel="noreferrer">{t('Download')}</a></span>
                                                                <span className="dropdown-item" onClick={() => removerArquivoProjeto(arquivo)}>
                                                                    {t('Delete')}
                                                                </span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>

                                        ))}

                                    </ul>
                                    
                                </div>
                            </div>



                        </div>
                        <ProjectViewDetails project={projeto} onSubmissionSuccess={handleSubmissionSuccess} utilizadores={utilizadores} atividades={atividades} />
                    </div>

                </div>
            </div>

            <ModalFicheiroExcel  projeto_id={projeto?.id} onSubmissionSuccess={handleSubmissionSuccess}  />

            <ProjectModelPopup onSubmissionSuccess={handleSubmissionSuccess} projeto={projeto} prioridadeParam={projeto?.prioridade} tempoParam={projeto?.tempo} semanalParam={projeto?.semanal} utilizadores={utilizadores} missoes={missoes} />

            <TaskboardModal quadro={quadroSelecionado} tarefa={tarefaSelecionada} projeto={projeto} onSubmissionSuccess={getProjeto} utilizadores={utilizadores} defaultPrioridade={tarefaSelecionada?.prioridade} quadros={projeto?.quadros} />


            <TarefasModalPopup tarefas={projeto?.tarefas} handleNavigateTarefas={handleNavegarParaTarefa} />


            <span
                data-bs-toggle="modal"
                data-bs-target="#edit_project"
                id="triggerModalEdit"
            >
            </span>

            <span
                data-bs-toggle="modal"
                data-bs-target="#delete"
                id="triggerModalEliminar"
            >
            </span>

            <span
                data-bs-toggle="modal"
                data-bs-target="#edit_task_modal"
                id="trigger_edit_task_modal"
            >
            </span>




        </div >
            <ToastContainer />
        </>
    )
}

export default ProjectView
