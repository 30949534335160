import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Avatar_16,
  Avatar_02,
  Avatar_05,
  Avatar_09,
  Avatar_10,
} from "../../Routes/ImagePath/index";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import AuthUser from "../AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../Spinner";
import Swal from "sweetalert2";

const ModalFicheiroExcel = ({ onSubmissionSuccess, projeto_id }) => {
  const { t } = useTranslation();
  const { http, url_root, user, user_permissoes, verificarPermissao } = AuthUser();
  const [error, setError] = useState(null);
  const [ficheiro, setFicheiro] = useState(null);
  const [loading, setLoading] = useState(false);

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event('click'));
    }
  };

  useEffect(() => {}, [projeto_id]);

  const handleFileChange = (e) => {
    setFicheiro(e.target.files[0]);
  };

  const submitFormFicheiro = async () => {
    if (verificarPermissao('PROJETOS', 'adicionar')) {
      try {
        toggleLoading(true);
        if (!ficheiro) {
          setError('O ficheiro deve ser carregado');
          setLoading(false);
          toast.error('O ficheiro deve ser carregado', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          return;
        }
        await uploadFicheiro();
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.');
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const uploadFicheiro = async () => {
    if (verificarPermissao('PROJETOS', 'adicionar')) {
      try {
        const formData = new FormData();
        formData.append('file', ficheiro);
        formData.append('projeto_id', projeto_id);

        const response = await http.post('/projetos/upload-excel', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {
            triggerCloseButtonClick('closeButton30');
            onSubmissionSuccess(response.data.data);
          },
        });
      } catch (error) {
        console.error(error);
        toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  return (
    <>
      <div
        id="modal_form_excel_import"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Importar tarefas no projeto</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="closeButton30"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="input-block mb-3">
                  <label className="col-form-label">{t('Carregue um ficheiro Excel')}</label>
                  <input type="file" className="form-control" onChange={handleFileChange} />
                </div>
                <div className="submit-section m-t-20 text-center">
                  {loading ? 'A processar' : <button
                    type="button"
                    className="btn btn-primary submit-btn"
                    onClick={submitFormFicheiro}>
                    {t('Importar')}
                  </button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ModalFicheiroExcel;
