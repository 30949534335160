import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../../components/Spinner";
import MarcarFaltaModal from "../../../../components/modelpopup/MarcarFaltaModal";
import MarcarFaltaConjuntaModal from "../../../../components/modelpopup/MarcarFaltaConjuntaModal";
import MarcarFeriaConjuntaModal from "../../../../components/modelpopup/MarcarFeriaConjuntaModal";

const UserFilter = ({
  onFilterSubmit,
  tipo,
  departamentos,
  cargos,
  utilizadoresSelecionados,
}) => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } =
    AuthUser();
  const [utilizadores, setUtilizador] = useState([]);

  const [nomeFilter, setNomeFilter] = useState("");
  const [telefoneFilter, setTelefoneFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [contaFilter, setContaFilter] = useState("");
  const [IBANFilter, setIBANFilter] = useState("");
  const [estadoFilter, setEstadoFilter] = useState(1);

  const [generoFilter, setGeneroFilter] = useState(-1);
  const [expatriadoFilter, setExpatriadoFilter] = useState(-1);

  const [cargo_id, setCargoID] = useState(-1);
  const [departamento_id, setDepartamentoID] = useState(-1);

  const [setSelectedOption] = useState(null);
  const [setSelectedOptionTwo] = useState(null);

  const [itemFocus, setItemFocus] = useState(false);

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible((prevVisible) => !prevVisible);
  };

  const inputFocus = () => {
    setItemFocus(true);
  };

  const inputBlur = () => {
    setItemFocus(false);
  };

  const handleFilterSubmit = async () => {
    try {
      console.log("consultar");

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append("nome", nomeFilter);
      formData.append("conta", contaFilter);
      formData.append("IBAN", IBANFilter);
      formData.append("telefone", telefoneFilter);
      formData.append("email", emailFilter);
      formData.append("estado", estadoFilter);
      formData.append("tipo", tipo);

      formData.append("missao_id", getMissao()?.id);

      formData.append("genero", generoFilter);
      formData.append("expatriado", expatriadoFilter);

      formData.append("cargo_id", cargo_id);
      formData.append("departamento_id", departamento_id);

      const response = await http.post("/utilizador/pesquisar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      //console.log(response.data.users);

      // Atualize a lista de utilizadores após resetar a sesssnha
      //setUtilizador(response.data.users);

      // Update the parent component state with filtered users
      onFilterSubmit(response.data.users);

      setLoading(false);
    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error(
        "Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      setLoading(false);
    }
  };

  const handleExportarExcelSubmit = async () => {
    try {
      console.log("consultar");

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append("nome", nomeFilter);
      formData.append("conta", contaFilter);
      formData.append("IBAN", IBANFilter);
      formData.append("telefone", telefoneFilter);
      formData.append("email", emailFilter);
      formData.append("estado", estadoFilter);
      formData.append("tipo", tipo);

      formData.append("missao_id", getMissao()?.id);

      formData.append("genero", generoFilter);
      formData.append("expatriado", expatriadoFilter);

      formData.append("cargo_id", cargo_id);
      formData.append("departamento_id", departamento_id);

      const response = await http.post("/utilizador/exportar/excel", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });


      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(url_root + "" + response?.data?.url, "_blank");

  
    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error(
        "Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      setLoading(false);
    }
  };

  const handleExportarCargoExcelSubmit = async () => {
    try {
      console.log("consultar");

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();

      formData.append("missao_id", getMissao()?.id);

      const response = await http.post("/cargo/exportar/excel", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });


      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(url_root + "" + response?.data?.url, "_blank");

  
    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error(
        "Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      setLoading(false);
    }
  };


  const handleExportarDepartamentoExcelSubmit = async () => {
    try {
      console.log("consultar");

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();

      formData.append("missao_id", getMissao()?.id);

      const response = await http.post("/departamento/exportar/excel", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });


      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(url_root + "" + response?.data?.url, "_blank");

  
    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error(
        "Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      setLoading(false);
    }
  };

  const handleExportarPostoExcelSubmit = async () => {
    try {
      console.log("consultar");

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();

      formData.append("missao_id", getMissao()?.id);

      const response = await http.post("/posto/exportar/excel", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });


      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(url_root + "" + response?.data?.url, "_blank");

  
    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error(
        "Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      setLoading(false);
    }
  };
 
  const chamarModalMarcarFaltaConjunta = () => {
    const editButton = document.getElementById("triggerMarcarFaltaConjunta");

    if (editButton) {
      editButton.click();
    }
  };

  const chamarModalMarcarFeriaConjunta = () => {
    const editButton = document.getElementById("triggerMarcarFeriaConjunta");

    if (editButton) {
      editButton.click();
    }
  };

  const renderOptionCargos = (cargos) => {
    return cargos?.map((cargo) => (
      <React.Fragment key={cargo.id}>
        <option value={cargo.id}>{cargo.designacao}</option>
      </React.Fragment>
    ));
  };

  const renderOptionDepartamentos = (departamentos) => {
    return departamentos?.map((departamento) => (
      <React.Fragment key={departamento.id}>
        <option value={departamento.id}>{departamento.designacao}</option>
      </React.Fragment>
    ));
  };

  return (
    <div className="row filter-row space">
      <div className="col-sm-6 col-md-4">
        <div
          className={`input-block mb-3 form-focus  ${
            itemFocus ? "focused" : ""
          } `}
        >
          <input
            type="text"
            name="nameFilter"
            className="form-control floating"
            onFocus={inputFocus}
            onBlur={inputBlur}
            value={nomeFilter}
            onChange={(e) => setNomeFilter(e.target.value)}
          />
          <label className="focus-label">{t("Name")}</label>
        </div>
      </div>
      <div className="col-sm-6 col-md-2">
        <div
          className={`input-block mb-3 form-focus  ${
            itemFocus ? "focused" : ""
          } `}
        >
          <input
            type="text"
            name="nameFilter"
            className="form-control floating"
            onFocus={inputFocus}
            onBlur={inputBlur}
            value={emailFilter}
            onChange={(e) => setEmailFilter(e.target.value)}
          />
          <label className="focus-label">{t("Email")}</label>
        </div>
      </div>
      <div className="col-sm-6 col-md-2">
        <div
          className={`input-block mb-3 form-focus  ${
            itemFocus ? "focused" : ""
          } `}
        >
          <input
            type="text"
            name="nameFilter"
            className="form-control floating"
            onFocus={inputFocus}
            onBlur={inputBlur}
            value={telefoneFilter}
            onChange={(e) => setTelefoneFilter(e.target.value)}
          />
          <label className="focus-label">{t("Phone")}</label>
        </div>
      </div>
      <div className="col-sm-6 col-md-2">
        <div className="input-block form-focus select-focus">
          <select
            className="form-control"
            onChange={(e) => setExpatriadoFilter(e.target.value)}
          >
            <option value="-1">Todos</option>
            <option value="2">Sim</option>
            <option value="1">Não</option>
          </select>
          <label className="focus-label">{t("Expatriados")}</label>
        </div>
      </div>
      <div className="col-sm-6 col-md-2">
        <div className="input-block form-focus select-focus">
          <select
            className="form-control"
            onChange={(e) => setGeneroFilter(e.target.value)}
          >
            <option value="-1">Todos os generos</option>
            <option value="1">Feminino</option>
            <option value="2">Masculino</option>
          </select>
          <label className="focus-label">{t("Genero")}</label>
        </div>
      </div>


      <div className="col-sm-6 col-md-2">
        <div
          className={`input-block mb-3 form-focus  ${
            itemFocus ? "focused" : ""
          } `}
        >
          <input
            type="text"
            name="nameFilter"
            className="form-control floating"
            onFocus={inputFocus}
            onBlur={inputBlur}
            value={contaFilter}
            onChange={(e) => setContaFilter(e.target.value)}
          />
          <label className="focus-label">{t("Conta Bancaria")}</label>
        </div>
      </div>

      <div className="col-sm-6 col-md-2">
        <div
          className={`input-block mb-3 form-focus  ${
            itemFocus ? "focused" : ""
          } `}
        >
          <input
            type="text"
            name="nameFilter"
            className="form-control floating"
            onFocus={inputFocus}
            onBlur={inputBlur}
            value={IBANFilter}
            onChange={(e) => setIBANFilter(e.target.value)}
          />
          <label className="focus-label">{t("IBAN")}</label>
        </div>
      </div>


      <div className="col-sm-6 col-md-2">
        <div className="input-block form-focus select-focus">
          <select
            className="form-control"
            onChange={(e) => setEstadoFilter(e.target.value)}
            value={estadoFilter}
          >
            <option value="-1">Todos estados</option>
            <option value="0">Desativado</option>
            <option value="1">Ativado</option>
          </select>
          <label className="focus-label">{t("Status")}</label>
        </div>
      </div>
      <div className="col-sm-6 col-md-2">
        <div
          className={`input-block mb-3 form-focus  ${
            itemFocus ? "focused" : ""
          } `}
        >
          <select
            onChange={(e) => setCargoID(e.target.value)}
            value={cargo_id}
            className="form-control select"
          >
            <option value="-1">Todos cargos</option>
            <option key={0} value={-1}></option>
            {renderOptionCargos(cargos)}
          </select>
        </div>
      </div>
      <div className="col-sm-6 col-md-2">
        <div
          className={`input-block mb-3 form-focus  ${
            itemFocus ? "focused" : ""
          } `}
        >
          <select
            onChange={(e) => setDepartamentoID(e.target.value)}
            value={departamento_id}
            className="form-control select"
          >
            <option value="-1">Todos departamentos</option>
            <option key={0} value={-1}></option>
            {renderOptionDepartamentos(departamentos)}
          </select>
        </div>
      </div>

      <div className="col-sm-6 col-md-1">
        {!loading ? (
          <>
            <span
              className="btn btn-success btn-block w-100"
              onClick={() => handleFilterSubmit()}
            >
              <i className="fa fa-search"></i>
            </span>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="col-sm-6 col-md-1">
        <span className="btn btn-dark btn-block w-100" onClick={toggleDropdown}>
          <i className="fa fa-cogs"></i>
        </span>
      </div>
      <div className={`${dropdownVisible ? "d-block" : "d-none"}`}>
        <Link
          to="#"
          className=" mt-2 text-success"
          data-bs-toggle="modal"
          data-bs-target="#modal_form_excel_import"
        >
          <i className="fa fa-file-excel text-success" />
          {t(" Importar Colaborador Excel")}
        </Link>
        {"  "} | {"    "}
        <Link
          to="#"
          className=" mt-2 text-danger"
          data-bs-toggle="modal"
          data-bs-target="#registar_divida_modal"
        >
          <i className="fa fa-coin text-danger" />
          {t(" Adicionar divida coletiva")}
        </Link>
        {"  "} | {"    "}
        <Link
          to="#"
          className=" mt-2 text-success"
          data-bs-toggle="modal"
          data-bs-target="#modal_form_excel_ferias_import"
        >
          <i className="fa fa-file-excel text-success" />
          {t(" Importar Férias Excel")}
        </Link>
        {"  "} | {"    "}
        <span
          className=" mt-2 text-success"
          onClick={() => handleExportarExcelSubmit()}
        >
          <i className="fa fa-file-excel text-success" />
          {t(" Exportar Excel")}
        </span>
        {"  "} | {"    "}
        <span
          className=" mt-2 text-success"
          onClick={() => handleExportarCargoExcelSubmit()}
        >
          <i className="fa fa-file-excel text-success" />
          {t(" Exportar Cargos Excel")}
        </span>
        {"  "} | {"    "}
        <span
          className=" mt-2 text-success"
          onClick={() => handleExportarPostoExcelSubmit()}
        >
          <i className="fa fa-file-excel text-success" />
          {t(" Exportar Postos Excel")}
        </span>
        {"  "} | {"    "}
        <span
          className=" mt-2 text-success"
          onClick={() => handleExportarDepartamentoExcelSubmit()}
        >
          <i className="fa fa-file-excel text-success" />
          {t(" Exportar Departamentos Excel")}
        </span>
        {"  "} | {"    "}
        <span
          className=" mt-2"
          onClick={() => chamarModalMarcarFaltaConjunta()}
        >
          <i className="fa fa-calendar" /> {t("Falta")}
        </span>
        {"  "} | {"    "}
        <span
          className=" mt-2"
          onClick={() => chamarModalMarcarFeriaConjunta()}
        >
          <i className="fa fa-umbrella" /> {t("Ferias")}
        </span>
        {"  "} | {"    "}
        <span className="">
          <i className="fa fa-trash" /> {t("Delete")}
        </span>
        <hr></hr>
      </div>
      <span
        data-bs-toggle="modal"
        data-bs-target="#marcar_falta_conjunta_modal"
        id="triggerMarcarFaltaConjunta"
      ></span>

<span
        data-bs-toggle="modal"
        data-bs-target="#marcar_feria_conjunta_modal"
        id="triggerMarcarFeriaConjunta"
      ></span>

      <MarcarFaltaConjuntaModal colaboradores={utilizadoresSelecionados} />
      <MarcarFeriaConjuntaModal colaboradores={utilizadoresSelecionados} />
      <ToastContainer />
      {loading ? (
        <>
          <Spinner></Spinner>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UserFilter;
