import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { User9, User11 } from "../../../../Routes/ImagePath";
import LeaderModelPopup from "../../../../components/modelpopup/LeaderModelPopup";
import ColaboradorModelPopup from "../../../../components/modelpopup/ColaboradorModelPopup";

import ActivitiesList from "../../../../assets/json/activities";

import { useTranslation } from "react-i18next";

import { format } from 'date-fns';

import AuthUser from "../../../../components/AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../../../components/Spinner";

import Swal from "sweetalert2";
import TodasAtividadesModalPopup from "../../../../components/modelpopup/TodasAtividadesModalPopup";

const ProjectViewDetails = ({ project, onSubmissionSuccess, utilizadores, atividades }) => {

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user, user_permissoes, verificarPermissao } = AuthUser();

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const [projeto, setProjeto] = useState([]);

  useEffect(() => {

    setProjeto(project);

    console.log("projeto perfil:", project);

  }, [project]);

  const getProjeto = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/projeto/' + projeto?.id);

      console.log('Projeto :');
      console.log(res.data.projeto);

      setLoading(false);

      onSubmissionSuccess(res.data.projeto, res.data.atividades);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const removerColaboradoresProjeto = async (user) => {

    Swal.fire({
      title: "Remover colaborador?",
      text: "Tem a certeza que pretende remover este colaborador neste projeto?",
      type: "confirm",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(async function (t) {

      if (t.isConfirmed) {
        try {

          setLoading(true);

          const formData = new FormData();
          formData.append('user_id', user?.id);
          formData.append('projeto_id', projeto?.id);

          const response = await http.post('/projeto/removerColaborador', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          // Show a success toast
          toast.success(response.data.message, {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => {

              setLoading(false);
              //Refresh colaboradores list
              getProjeto();

            }
          });

        } catch (error) {

          setLoading(false);
          console.error(error);

          toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }

    });
  };

  const projectData = {
    projectDetails: {
      cost: projeto?.orcamento,
      real_cost: projeto?.custo,
      totalHours: projeto?.tempo == 1 ? t('Fixed') : t('Hourly'),
      created: projeto?.created_at ? new Date(projeto.created_at).toLocaleDateString('pt-BR') : '',
      deadline: projeto?.data_fim ? new Date(projeto.data_fim).toLocaleDateString('pt-BR') : '',
      priority: projeto?.prioridade,
      createdBy: projeto?.criador?.name,
      status: projeto?.estado,
      tarefas: projeto?.tarefas,
      progress: "40%",
    }
  };

  return (
    <>
      <div className="col-lg-4 col-xl-4">
        <div className="card">
          <div className="card-body">
            <h6 className="card-title m-b-15">{t('Project details')}</h6>
            <table className="table table-striped table-border">
              <tbody>
                <tr>
                  <td>{t('Cost')}:</td>
                  <td className="text-end">
                    {projectData.projectDetails.cost}
                  </td>
                </tr>
                <tr>
                  <td>{t('Real Cost')}:</td>
                  <td className="text-end">
                    {projectData.projectDetails.real_cost}
                  </td>
                </tr>
                <tr>
                  <td>{t('Cost Type')}:</td>
                  <td className="text-end">
                    {projectData.projectDetails.totalHours}
                  </td>
                </tr>
                <tr>
                  <td>{t('Created')}:</td>
                  <td className="text-end">
                    {projectData.projectDetails.created}
                  </td>
                </tr>
                <tr>
                  <td>{t('Deadline')}:</td>
                  <td className="text-end">
                    {projectData.projectDetails.deadline}
                  </td>
                </tr>
                <tr>
                  <td>{t('Priority')}:</td>
                  <td className="text-end">
                    <div className="btn-group">

                      <span
                        className={
                          projectData.projectDetails.priority == 1
                            ? "badge bg-inverse-success"
                            : projectData.projectDetails.priority == 2 ? "badge bg-inverse-warning" : "badge bg-inverse-danger"
                        }
                      >
                        {projectData.projectDetails.priority == 1 ? t('Low') : projectData.projectDetails.priority == 2 ? t('Medium') : t('High')}
                      </span>

                    </div>
                  </td>
                </tr>
                <tr>
                  <td>{t('Created by')} :</td>
                  <td className="text-end">
                    <Link to="/app/profile/employee-profile">
                      {projectData.projectDetails.createdBy}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>{t('Status')}:</td>
                  <td className="text-end">

                    {
                      projeto?.aprovado == 0 ? <><img width={24}
                        height={24} title={t('Pending to approve')} src={url_root + "/storage/uploads/icons/siren.png"} />{t('Pending to approve')}</> : projeto?.aprovado == 1 ? <span title={t('Approved')}><i className="fa fa-thumbs-up text-success"></i></span> : <span title={t('Negated')}><i className="fa fa-thumbs-down text-danger"></i></span>
                    }

                    {(projeto?.aprovado == 0 || projeto?.aprovado == 2) ? <></> :
                      <span
                        className={
                          projectData.projectDetails.status == 1
                            ? "badge bg-inverse-success"
                            : "badge bg-inverse-warning"
                        }
                      >
                        {projectData.projectDetails.status == 1 ? t('Closed') : t('Progress')}
                      </span>
                    }

                  </td>
                </tr>
              </tbody>
            </table>
            <p className="m-b-5">
              {t('Progress')}
              <span className="text-success float-end">
                {Math.round(((projectData?.projectDetails?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / projectData?.projectDetails?.tarefas?.length) * 100) ? Math.round(((projectData?.projectDetails?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / projectData?.projectDetails?.tarefas?.length) * 100) : 0}%
              </span>
            </p>
            <div className="progress progress-xs mb-0">
              <div
                className="progress-bar bg-success"
                role="progressbar"
                data-bs-toggle="tooltip"
                title={(Math.round(((projectData?.projectDetails?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / projectData?.projectDetails?.tarefas?.length) * 100)) + '%'}
                style={{ width: `${Math.round(((projectData?.projectDetails?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / projectData?.projectDetails?.tarefas?.length) * 100)}%` }}
              />
            </div>
          </div>
        </div>
        <div className="card project-user">
          <div className="card-body">
            <h6 className="card-title m-b-20">
              {t('Assigned Leader')}
              <button
                type="button"
                className="float-end btn btn-primary btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#assign_leader"
              >
                <i className="fa fa-plus" /> {t('Add')}
              </button>
            </h6>
            <ul className="list-box">

              {project?.colaboradores?.filter(user => user.tipo == 1).map(user => (
                <li key={project?.id}>
                  <div>
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">
                          <i className="fa fa-times text-danger"
                            onClick={() => removerColaboradoresProjeto(user?.colaborador)}
                          />
                          <img src={url_root + "/storage/" + user?.colaborador?.fotografia} alt={user.colaborador?.name} title={user?.colaborador?.name} />
                        </span>
                      </div>
                      <div className="list-body">
                        <Link to={"/profile/" + user?.colaborador?.id}>
                          <span className="message-author">{user?.colaborador?.name}</span>
                        </Link>
                        <div className="clearfix" />
                        <span className="message-content">{user?.colaborador?.cargo?.designacao}</span>

                      </div>
                    </div>
                  </div>
                </li>
              ))}


            </ul>
          </div>
        </div>
        <div className="card project-user">
          <div className="card-body">
            <h6 className="card-title m-b-20">
              {t('Assigned users')}
              <button
                type="button"
                className="float-end btn btn-primary btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#assign_colaborador"
              >
                <i className="fa fa-plus" /> {t('Add')}
              </button>
            </h6>
            <ul className="list-box">

              {project?.colaboradores?.filter(user => user.tipo == 2).map(user => (
                <li key={project?.id}>
                  <div>
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">
                          <i className="fa fa-times text-danger"
                            onClick={() => removerColaboradoresProjeto(user?.colaborador)}
                          />
                          <img src={url_root + "/storage/" + user?.colaborador?.fotografia} alt={user.colaborador?.name} title={user?.colaborador?.name} />
                        </span>
                      </div>
                      <div className="list-body">
                        <Link to={"/profile/" + user?.colaborador?.id}>
                          <span className="message-author">{user?.colaborador?.name}</span>
                        </Link>
                        <div className="clearfix" />
                        <span className="message-content">{user?.colaborador?.cargo?.designacao}</span>

                      </div>
                    </div>
                  </div>
                </li>
              ))}


            </ul>
          </div>
        </div>

        <div className="card project-user">
          <div className="card-body">
            <h6 className="card-title m-b-20">
              {t('Activities')}
            </h6>

            <span
              data-bs-toggle="modal"
              data-bs-target="#all_activities_modal"
              className="btn btn-secondary mb-2 ml-2"
            >
              <i className="fa fa-list"></i>{' '}
              {t('See All Activities')}
            </span>

            <div className="activity">
              <div className="activity-box">

                <ul className="activity-list">
                  {atividades?.slice(0, 5)?.map((activity, index) => (
                    <li key={index}>
                      <div className="activity-user">
                        <Link
                          to={"/profile/" + activity?.user?.id}
                          title={activity?.user?.name}
                          data-bs-toggle="tooltip"
                          className="avatar"
                        >
                          <img src={url_root + "/storage/" + activity?.user?.fotografia} alt={activity?.user?.name} title={activity?.user?.name} />
                        </Link>
                      </div>
                      <div className="activity-content">
                        <div className="timeline-content">

                          <Link to={"/profile/" + activity?.user?.id} className="name">
                            {activity?.user?.name}
                          </Link>{" "}

                          {/* PROJETOS */}

                          {/* Adicionou projeto */}
                          {(activity?.tipo == 1 && activity?.accao == 1 && activity?.receptor_id == 0) ? ' adicionou novo projeto, ' : ''}

                          {/* editou projeto */}
                          {(activity?.tipo == 1 && activity?.accao == 2) ? ' editou a informação neste projeto ' + activity?.mensagem + '  ' : ''}

                          {/* fechou projeto */}
                          {(activity?.tipo == 1 && activity?.accao == 9) ? ' fechou o projeto, ' + activity?.mensagem + '  ' : ''}

                          {/* abriu projeto */}
                          {(activity?.tipo == 1 && activity?.accao == 10) ? ' voltou a abrir o projeto para execução, ' + activity?.mensagem + '  ' : ''}

                          {/* prazo projeto */}
                          {(activity?.tipo == 1 && activity?.accao == 11) ? ' Alterou o prazo ( deadline ) neste projeto, ' + activity?.mensagem + '  ' : ''}

                          {/* adicionou colaborador ao projeto */}
                          {
                            (activity?.tipo == 1 && activity?.accao == 1 && activity?.receptor_id != 0) &&
                            <span>
                              adicionou o colaborador <Link to={"/profile/" + activity?.receptor?.id} className="name">
                                {activity?.receptor?.name}
                              </Link> ao projeto, {activity?.mensagem}{', '}
                            </span>
                          }


                          {/* removeu colaborador neste projeto */}
                          {
                            (activity?.tipo == 1 && activity?.accao == 3 && activity?.receptor_id != 0) &&
                            <span>
                              removeu o colaborador <Link to={"/profile/" + activity?.receptor?.id} className="name">
                                {activity?.receptor?.name}
                              </Link> neste projeto, {activity?.mensagem}{', '}
                            </span>
                          }

                          {/* Aprovar, reprovar projeto */}
                          {
                            (activity?.tipo == 1 && activity?.accao == 4 && activity?.receptor_id == 0) &&
                            <span>
                              {activity?.mensagem} a execução neste projeto{', '}
                            </span>
                          }

                          {/* Arquivar, desarquivar projeto */}
                          {
                            (activity?.tipo == 1 && activity?.accao == 6 && activity?.receptor_id == 0) &&
                            <span>
                              {activity?.mensagem} o projeto{', '}
                            </span>
                          }


                          {/* eliminar projeto */}
                          {
                            (activity?.tipo == 1 && activity?.accao == 3 && activity?.receptor_id == 0) &&
                            <span>
                              {'removeu o projeto ' + activity?.mensagem}{' '}
                            </span>
                          }

                          {/* TAREFAS */}

                          {/* adicionou tarefa ao projeto */}
                          {
                            (activity?.tipo == 2 && activity?.accao == 1 && activity?.receptor_id == 0) &&
                            <span>
                              adicionou a tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                {' '}{activity?.mensagem}
                              </Link> ao projeto {', '}
                            </span>
                          }

                          {/* editou a tarefa neste projeto */}
                          {
                            (activity?.tipo == 2 && activity?.accao == 2 && activity?.receptor_id == 0) &&
                            <span>
                              editou a tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                {' '}{activity?.mensagem}
                              </Link> neste projeto {', '}
                            </span>
                          }


                          {/* fechou tarefa */}
                          {(activity?.tipo == 2 && activity?.accao == 9) ? ' fechou a tarefa, ' + activity?.tarefa?.nome + ' neste projeto,  ' : ''}

                          {/* abriu tarefa */}
                          {(activity?.tipo == 2 && activity?.accao == 10) ? ' voltou a abrir a tarefa ' + activity?.tarefa?.nome + ' neste projeto,  ' : ''}

                          {/* prazo tarefa */}
                          {(activity?.tipo == 2 && activity?.accao == 11) ? ' Alterou o prazo ( deadline ) da tarefa ' + activity?.tarefa?.nome + ' neste projeto,  ' : ''}

                          {/* eliminar tarefa */}
                          {
                            (activity?.tipo == 2 && activity?.accao == 3 && activity?.receptor_id == 0) &&
                            <span>
                              {'removeu a tarefa ' + activity?.mensagem}{' neste projeto, '}
                            </span>
                          }


                          {/* adicionou colaborador na tarefa */}
                          {
                            (activity?.tipo == 2 && activity?.accao == 1 && activity?.receptor_id != 0) &&
                            <span>
                              adicionou o colaborador <Link to={"/profile/" + activity?.receptor?.id} className="name">
                                {activity?.receptor?.name}
                              </Link> a tarefa, <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">{activity?.tarefa?.nome}</Link>{', neste projeto '}
                            </span>
                          }


                          {/* removeu colaborador da tarefa */}
                          {
                            (activity?.tipo == 2 && activity?.accao == 3 && activity?.receptor_id != 0) &&
                            <span>
                              removeu o colaborador <Link to={"/profile/" + activity?.receptor?.id} className="name">
                                {activity?.receptor?.name}
                              </Link> da tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">{activity?.tarefa?.nome}</Link>{', neste projeto '}
                            </span>
                          }



                          {/* ITEMS DE TAREFA */}

                          {/* adicionou item de tarefa a tarefa */}
                          {
                            (activity?.tipo == 3 && activity?.accao == 1 && activity?.receptor_id == 0) &&
                            <span>
                              adicionou item de tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                {' '}{activity?.item?.nome}
                              </Link> a tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                {' '}{activity?.tarefa?.nome}
                              </Link>  neste projeto,
                            </span>
                          }

                          {/* editou a tarefa neste projeto */}
                          {
                            (activity?.tipo == 3 && activity?.accao == 2 && activity?.receptor_id == 0) &&
                            <span>
                              editou a informação do item de tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                {' '}{activity?.item?.nome}
                              </Link> a tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                {' '}{activity?.tarefa?.nome}
                              </Link>  neste projeto,
                            </span>
                          }

                          {/* fechou item de tarefa */}
                          {(activity?.tipo == 3 && activity?.accao == 9) ? <span>fechou o item de tarefa, {activity?.item?.nome} na tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                            {' ' + activity?.tarefa?.nome}</Link> neste projeto </span> : ''}

                          {/* abriu item de tarefa */}
                          {(activity?.tipo == 3 && activity?.accao == 10) ? <span>voltou a abrir o item de tarefa, {activity?.item?.nome} para execução na tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                            {' ' + activity?.tarefa?.nome}</Link> neste projeto </span> : ''}


                          {/* eliminar item de tarefa */}
                          {
                            (activity?.tipo == 3 && activity?.accao == 3 && activity?.receptor_id == 0) &&
                            <span>
                              removeu item de tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                {' '}{activity?.mensagem}
                              </Link> na a tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                {' '}{activity?.tarefa?.nome}
                              </Link>  neste projeto,
                            </span>
                          }

                          {
                            (activity?.tipo == 6 && activity?.accao == 2 && activity?.projeto_id != 0) &&
                            <span>
                              Adicionou ficheiros ao projeto <Link to={"/project-view/" + activity?.projeto?.id}>{activity?.projeto?.nome}</Link>
                            </span>
                          }

                          {
                            (activity?.tipo == 6 && activity?.accao == 3 && activity?.projeto_id != 0) &&
                            <span>
                              Removeu ficheiros ao projeto <Link to={"/project-view/" + activity?.projeto?.id}>{activity?.projeto?.nome}</Link>
                            </span>
                          }

                            {/* CHATS */}

                           {/* chat na tarefa */}

                           {
                            (activity?.tipo == 7 && activity?.accao == 1 && activity?.chat_para == 2) &&
                            <span>
                              conversou no chat da tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">{activity?.tarefa?.nome}
                              </Link>  neste projeto <Link to={"/project-view/" + activity?.projeto?.id}>{activity?.projeto?.nome}</Link>
                            </span>
                          }


                          {/* NOME E LINK neste projeto, DATA E HORA */}


                          <span className="time">{format(new Date(activity.data), 'dd/MM/yyyy') + ' ' + activity.hora}</span>

                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

          </div>
        </div>

        <TodasAtividadesModalPopup atividades={atividades} projeto={projeto} />

        <LeaderModelPopup utilizadores={utilizadores} onSubmissionSuccess={getProjeto} projeto={projeto} />
        <ColaboradorModelPopup utilizadores={utilizadores} onSubmissionSuccess={getProjeto} projeto={projeto} />
      </div>

      <ToastContainer />

    </>
  );
};

export default ProjectViewDetails;
