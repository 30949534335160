import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import DeleteModal from "../../../../components/modelpopup/DeleteModal";
import RolesPermissionsModal from "../../../../components/modelpopup/RolesPermissionsModal";
import Breadcrumbs from "../../../../components/Breadcrumbs";

import Swal from "sweetalert2";

import AuthUser from "../../../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../../../components/Spinner";

import { useTranslation } from "react-i18next";

const RolesPermissions = () => {

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } = AuthUser();
  const [acessos, setAcesso] = useState([]);
  const [acessoSelecionado, setAcessoSelecionado] = useState();
  const [acesso_id, setAcessoID] = useState();
  const [permissoes, setPermissoes] = useState([]);
  const [accoes, setAccoes] = useState([]);
  // As permissões
  const [permissaoVer, setPermissaoVer] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const tableData = [
    {
      category: "Employee",
      items: ["Item 1", "Item 2", "Item 3", "Item 4", "Item 5", "Item 6"],
    },
    {
      category: "Holidays",
      items: ["Item 1", "Item 2", "Item 3", "Item 4", "Item 5", "Item 6"],
    },
    {
      category: "Leaves",
      items: ["Item 1", "Item 2", "Item 3", "Item 4", "Item 5", "Item 6"],
    },
    {
      category: "Events",
      items: ["Item 1", "Item 2", "Item 3", "Item 4", "Item 5", "Item 6"],
    },
  ];

  const notificationItems = [
    { id: "staff_module", label: "Employee", defaultChecked: false },
    { id: "holidays_module", label: "Holidays", defaultChecked: true },
    { id: "leave_module", label: "Leaves", defaultChecked: true },
    { id: "events_module", label: "Events", defaultChecked: true },
    { id: "chat_module", label: "Chat", defaultChecked: true },
    { id: "job_module", label: "Jobs", defaultChecked: false },
  ];

  const chamarModalEdit = (idSelecionado) => {

    setAcessoSelecionado(idSelecionado);

    const editButton = document.getElementById('editModal');

    if (editButton) {
      editButton.click();
    }

  }

  const chamarModalDelete = (idSelecionado) => {

    if (verificarPermissao('ACESSO', 'remover')) {

      setAcessoID(idSelecionado);

      const editButton = document.getElementById('deleteModal');

      if (editButton) {
        editButton.click();
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  }

  const handle_ativar_desativar = async (id, newState) => {
    if (verificarPermissao('ACESSO', 'ativar')) {
      try {

        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append('id', id);
        formData.append('estado', estado);

        const response = await http.post('/acesso/activate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });



        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {
            // Redirecionar para a lista de Acessos após o cadastro bem-sucedido

            getAcesso();

          },
        });



        // Atualize a lista de acessos após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  useEffect(() => {

    setPermissaoVer(verificarPermissao('ACESSO', 'ver'));

    const fetchData = async () => {
      try {
        // Chama a função assíncrona getAcesso()
        await getAcesso();

      } catch (error) {
        console.error('Erro ao obter acessos:', error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();


  }, [permissaoVer]);

  const handleSubmissionSuccess = () => {
    // Refresh acessos state or trigger any necessary actions
    getAcesso();
  };

  const getAcesso = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/acessos/'+getMissao()?.id);

      console.log("Acessos :");
      console.log(res.data.acessos);
      console.log("Permissão Ver :");
      console.log(permissaoVer);

      setAcesso(res.data.acessos);

      if (res.data.acessos.length > 0 && !acessoSelecionado) {
        setAcessoSelecionado(res.data.acessos[0]);
        getPermissoes(res.data.acessos[0]);
      }

      getAccoes();

      setLoading(false);


    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('acesso não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };


  const getPermissoes = async (acesso) => {

    try {

      setLoading(true);

      const formData = new FormData();
      formData.append('nivel_id', acesso?.id);

      const res = await http.post('/permissoes', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setPermissoes(res.data.permissoes);

      console.log('Permissões');
      console.log(res.data.permissoes);

      console.log("Nivel 2 ID");
      console.log(acesso?.id);

    } catch (error) {
      console.error('Ocorreu um erro ao carregar os permissões:', error);
    } finally {
      setLoading(false);
    }

  };

  const getAccoes = async () => {
    try {
      setLoading(true);

      const res = await http.get('/accoes');

      console.log('Acções :');
      console.log(res.data.accoes)

      setAccoes(res.data.accoes);


    } catch (error) {
      console.error('Ocorreu um erro ao carregar os acções:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAcessoChange = (acesso) => {

    setAcessoSelecionado(acesso);

    // Fetch new permissions after navigation
    getPermissoes(acesso);

    console.log("Permissoes");
    console.log(permissoes);

  };

  const handleCheckboxChange = async (nivelId, acaoId, action) => {

    if (verificarPermissao('ACESSO', 'atualizar')) {
      try {

        setLoading(true);

        const formData = new FormData();
        formData.append('nivel_id', nivelId);
        formData.append('accao_id', acaoId);
        formData.append('tipo', action);

        // Set the value based on the current state (toggle)
        const valor = permissoes.some(obj => obj.nivel_id === nivelId && obj.accao_id === acaoId && obj[action] === 1) ? 0 : 1;
        formData.append('value', valor);

        console.log(nivelId, acaoId, action, valor);

        const res = await http.post('/update-permissao', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        console.log(res);

        if (res) {
          setPermissoes(res.data.permissoes);
        }

      } catch (error) {

        // Show a success toast
        toast.error('Erro ao atualizar permissão:', error, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });

      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleSelectRow = (index, accao_id) => {
    // Recupera o estado atual do checkbox "Selecionar tudo" da linha
    const selectAllChecked = document.querySelector(`.chk_selecionar_linhas_${index}`).checked;

    console.log(accao_id);

    // Atualiza o estado de todos os checkboxes da linha com base no estado do "Selecionar tudo"
    document.querySelectorAll(`.chk_linha_permissao_${index}`).forEach((checkbox) => {
      checkbox.checked = selectAllChecked;
      // Chame a função handleCheckboxChange para atualizar o estado no back-end, se necessário
      // Substitua os valores de acesso_id e cdata.id pelos valores corretos
    });

    const valor = selectAllChecked ? 1 : 0;

    console.log('Valor todos :');
    console.log(valor);

    handleAtivarDesativarLinha(acessoSelecionado?.id, accao_id, valor);
  };

  const handleAtivarDesativarLinha = async (acesso_id, accao_id, valor) => {

    if (verificarPermissao('ACESSO', 'atualizar')) {
      try {

        setLoading(true);

        const formData = new FormData();
        formData.append('nivel_id', acesso_id);
        formData.append('accao_id', accao_id);
        formData.append('valor', valor);


        console.log(acesso_id, accao_id, valor);

        const res = await http.post('/update-permissao-multipla', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        console.log(res);

        if (res) {
          setPermissoes(res.data.permissoes);
        }

      } catch (error) {

        // Show a success toast
        toast.error('Erro ao atualizar permissão:', error, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });

      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  }


  const handleSelectedAll = async () => {

    if (verificarPermissao('ACESSO', 'atualizar')) {

      setSelectAllChecked(!selectAllChecked);

      const checkboxes = document.querySelectorAll('.chk_selecionar_linhas');

      try {
        setLoading(true);

        for (let i = 0; i < checkboxes.length; i++) {
          const checkbox = checkboxes[i];

          // Defina o valor para todos os checkboxes (verificar ou desmarcar)
          checkbox.checked = !selectAllChecked;

          const accao_id = checkbox.getAttribute('code');
          const valor = !selectAllChecked ? 1 : 0;

          const formData = new FormData();
          formData.append('nivel_id', acessoSelecionado?.id);
          formData.append('accao_id', accao_id);
          formData.append('valor', valor);

          const res = await http.post('/update-permissao-multipla', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          // Atualize o estado apenas com a última resposta
          if (i === checkboxes.length - 1) {
            const ultima_permissao = res.data.permissoes;
            setPermissoes(ultima_permissao);
          }
        }
      } catch (error) {
        // Mostrar um toast de erro
        toast.error('Erro ao atualizar permissão:', error, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  const removerAccao = async (accao_id) => {

    Swal.fire({
      title: "Remover acção?",
      text: "Tem a certeza que pretende este item de tarefa ?",
      type: "confirm",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(async function (t) {

      if (t.isConfirmed) {
        try {

          setLoading(true);

          const formData = new FormData();
          formData.append('id', accao_id);

          const response = await http.post('/accao/eliminar', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          setLoading(false);
          getAcesso();

          // Show a success toast
          toast.success(response.data.message, {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => {

              setLoading(false);
              //Refresh colaboradores list
              //getAcesso();

            }
          });

        } catch (error) {

          setLoading(false);
          console.error(error);

          toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }

    });
  };


  if (verificarPermissao('ACESSO', 'ver'))
    if (loading) return (<><Spinner></Spinner></>); else return (
      <>
        (
        <>
          <div className="page-wrapper">
            {/* Page Content */}
            <div className="content container-fluid">
              {/* Page Header */}
              <Breadcrumbs maintitle={t('Roles & Permissions')} />

              {/* /Page Header */}
              <div className="row">
                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-3">
                  <Link
                    to="#"
                    className="btn btn-primary btn-block w-100"
                    data-bs-toggle="modal"
                    data-bs-target="#add_role"
                  >
                    <i className="fa fa-plus" /> {t('Add Roles')}
                  </Link>
                  <div className="roles-menu">
                    <ul>
                      {acessos?.map((role, index) => (
                        <li key={index} className={role.id == acessoSelecionado.id ? "active" : ""}>
                          <Link>

                            <span className={role.estado == 1 ? '' : 'text-danger'}
                             onClick={() => handleSelectAcessoChange(role)}
                            >{role.designacao}</span>

                            <span className="role-action">

                              <span
                                className="action-circle large"
                                onClick={() => chamarModalEdit(role)}
                              >
                                <i className="material-icons">{t('edit')}</i>
                              </span>

                              <span
                                className={'action-circle large check-btn' + (role.estado == 1 ? '  text-success' : ' text-danger')} title={role.estado == 1 ? 'Desativar' : 'Ativar'}
                                onClick={() => handle_ativar_desativar(role.id, role.estado == 1 ? 0 : 1)}
                              >
                                <i className="material-icons">{t('check')}</i>
                              </span>


                              {verificarPermissao('ACESSO', 'remover') ? <span
                                className="action-circle large delete-btn"
                                onClick={() => chamarModalDelete(role.id)}
                              >
                                <i className="material-icons">{t('delete')}</i>
                              </span> : <></>}


                            </span>

                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-9">
                  <h6 className="card-title m-b-20">{t('Module Access').toLocaleUpperCase()}: {acessoSelecionado?.designacao.toLocaleUpperCase()}</h6>


                  <label className="inputcheck">
                    <input type="checkbox" id="select-all" onChange={() => handleSelectedAll()} checked={selectAllChecked} />
                    &nbsp; Selecionar tudo
                  </label>


                  {/* <div className="m-b-30">
                <ul className="list-group notification-list">
                  {notificationItems.map((item) => (
                    <li key={item.id} className="list-group-item">
                      {t(item.label)}
                      <div className="status-toggle">
                        <input
                          type="checkbox"
                          id={item.id}
                          className="check"
                          defaultChecked={item.defaultChecked}
                        />
                        <label htmlFor={item.id} className="checktoggle">
                          checkbox
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </div> */}


                  <div className="table-responsive">
                    <table className="table table-striped custom-table">
                      <thead>
                        <tr>
                          <th>{t('Module Permission')}</th>
                          <th className="text-center">{t('Read')}</th>
                          <th className="text-center">{t('Write')}</th>
                          <th className="text-center">{t('Create')}</th>
                          <th className="text-center">{t('Delete')}</th>
                          <th className="text-center">{t('Active')}</th>
                          <th className="text-center">{t('All')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {

                          accoes?.map((cdata, index) => {

                            // Find the corresponding object in your array
                            const matchingObject = permissoes?.find(obj => {
                              return obj.accao_id == cdata.id && obj.nivel_id == acessoSelecionado?.id;
                            });

                            return (
                              <tr key={index}>
                                <td >
                                  {/* <span className='text-danger' onClick={()=>removerAccao(cdata.id)}><i className='fa fa-trash'></i></span> */}
                                {' '}{cdata.designacao}</td>

                                <td className="text-center">
                                  <label className="custom_check">
                                    <input key={cdata.id + 'ver'} type="checkbox" checked={matchingObject && matchingObject.ver == 1} onChange={() => handleCheckboxChange(acessoSelecionado?.id, cdata.id, 'ver')} className={'chk_linha_permissao_' + index} />
                                    <span className="checkmark" />
                                  </label>
                                </td>


                                <td className="text-center">
                                  <label className="custom_check">
                                    <input key={cdata.id + 'atualizar'} type="checkbox" checked={matchingObject && matchingObject.atualizar == 1} onChange={() => handleCheckboxChange(acessoSelecionado?.id, cdata.id, 'atualizar')} className={'chk_linha_permissao_' + index} />
                                    <span className="checkmark" />
                                  </label>
                                </td>


                                <td className="text-center">
                                  <label className="custom_check">
                                    <input key={cdata.id + 'adicionar'} type="checkbox" checked={matchingObject && matchingObject.adicionar == 1} onChange={() => handleCheckboxChange(acessoSelecionado?.id, cdata.id, 'adicionar')} className={'chk_linha_permissao_' + index} />
                                    <span className="checkmark" />
                                  </label>
                                </td>


                                <td className="text-center">
                                  <label className="custom_check">
                                    <input key={cdata.id + 'remover'} type="checkbox" checked={matchingObject && matchingObject.remover == 1} onChange={() => handleCheckboxChange(acessoSelecionado?.id, cdata.id, 'remover')} className={'chk_linha_permissao_' + index} />
                                    <span className="checkmark" />
                                  </label>
                                </td>

                                <td className="text-center">
                                  <label className="custom_check">
                                    <input key={cdata.id + 'ativar'} type="checkbox" checked={matchingObject && matchingObject.ativar == 1} onChange={() => handleCheckboxChange(acessoSelecionado?.id, cdata.id, 'ativar')} className={'chk_linha_permissao_' + index} />
                                    <span className="checkmark" />
                                  </label>
                                </td>

                                <td className="text-center">
                                  <label className="custom_check">
                                    <input type="checkbox" checked={matchingObject && matchingObject.ver == 1 && matchingObject.adicionar && matchingObject.atualizar && matchingObject.ativar && matchingObject.remover} key={cdata.id + 'select'} onChange={() => handleSelectRow(index, cdata.id)} className={'chk_selecionar_linhas_' + index + ' chk_selecionar_linhas'} code={cdata.id} />
                                    <span className="checkmark" />
                                  </label>
                                </td>


                              </tr>)


                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>


            <span
              data-bs-toggle="modal"
              data-bs-target="#edit_role"
              id="editModal"
            >
            </span>

            <span
              data-bs-toggle="modal"
              data-bs-target="#delete"
              id="deleteModal"
            >
            </span>

            <ToastContainer />

            {/* /Page Content */}
            <RolesPermissionsModal onSubmissionSuccess={handleSubmissionSuccess} acesso={acessoSelecionado} />

            <DeleteModal objectoName={t('Role')} onSubmissionSuccess={handleSubmissionSuccess} id={acesso_id} endpoint={'acesso'} />


          </div>
        </>
        ); </>
    );
  else
    return (<>
      <div className="page-wrapper cardhead">
        <div className="content ">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">SEM PERMISSÃO</h3>

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <div className="card">
                <div className="card-header">
                  <h4 className="card-title"><i className="fa fa-times-circle text-danger"></i>&nbsp; OPERAÇÃO NÃO AUTORIZADA</h4>
                </div>
                <div className="card-body">
                  <p>
                    Esta operação não é autorizada para o seu nivel de acesso ao sistema, por favor contacte o administrador do sistema.
                  </p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>)
};

export default RolesPermissions;
