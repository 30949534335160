import React, { useState, useEffect } from "react";

import Breadcrumbs from "../../../../components/Breadcrumbs";
import UserFilter from "./UserFilter";
import UsersTable from "./UsersTable";
import AddUserModal from "../../../../components/Administration/Users/AddUseModal";

import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";

import AuthUser from "../../../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../../../components/Spinner";

const Users = () => {

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } = AuthUser();
  const [utilizadores, setUtilizador] = useState([]);  
  const [cargos, setCargos] = useState([]);  
  const [departamentos, setDepartamentos] = useState([]);  
  const [missoes, setMissoes] = useState([]);  
  const [accoes, setAccoes] = useState([]);

  const [filteredUsers, setFilteredUsers] = useState([]);

  const { t } = useTranslation();

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  // Callback function to receive filtered users from UserFilter
  const handleFilterSubmit = (filteredUsers) => {
    setFilteredUsers(filteredUsers);
  };


  const handleSubmissionSuccess = () => {
    // Refresh acessos state or trigger any necessary actions
    getUtilizador();    
  };

  const getUtilizador = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/utilizadores/'+getMissao()?.id);

      setUtilizador(res.data.utilizadores);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  }; 

  const getCargos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/cargos_ativos/'+getMissao()?.id);

      setCargos(res.data.cargos);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  }; 

  const getDepartamentos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/departamentos_ativos/'+getMissao()?.id);

      setDepartamentos(res.data.departamentos);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  }; 

  const getMissoes = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/missoes_ativas');

      setMissoes(res.data.missoes);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  }; 
  
  const getAccoes = async () => {
    try {
      setLoading(true);

      const res = await http.get('/accoes');

      setAccoes(res.data.accoes);

      console.log('Acções');
      console.log(res.data.accoes);

    } catch (error) {
      console.error('Ocorreu um erro ao carregar os acções:', error);
    } finally {
      setLoading(false);
    }
  };

 

  useEffect(() => {

    const fetchData = async () => {
      try {
        // Chama a função assíncrona getUtilizador()
        //await getUtilizador();

        await getAccoes();

        await getCargos();
        await getDepartamentos();
        await getMissoes();

      } catch (error) {
        console.error('Erro ao obter utilizadores:', error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, []);

  if (verificarPermissao('UTILIZADORES', 'ver')) 
  if (loading) return (<><Spinner></Spinner></>); else return (
    <>
      (
      <>
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle={t('Users')}
          title="Dashboard"
          subtitle={t('Users')}
          modal="#add_user"
          name={t('Add User')}
        />

        <UserFilter onFilterSubmit={handleFilterSubmit} cargos={cargos} departamentos={departamentos}  tipo={-1} />

        <UsersTable users={filteredUsers.length > 0 ? filteredUsers : utilizadores} cargos={cargos} departamentos={departamentos} missoes={missoes} />

        <AddUserModal onSubmissionSuccess={handleSubmissionSuccess} accoes={accoes} cargos={cargos} departamentos={departamentos} missoes={missoes} />

      </div>
    </div> </>
        ); </>
    );
  else
    return (<>
      <div className="page-wrapper cardhead">
        <div className="content ">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">SEM PERMISSÃO</h3>

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <div className="card">
                <div className="card-header">
                  <h4 className="card-title"><i className="fa fa-times-circle text-danger"></i>&nbsp; OPERAÇÃO NÃO AUTORIZADA</h4>
                </div>
                <div className="card-body">
                  <p>
                    Esta operação não é autorizada para o seu nivel de acesso ao sistema, por favor contacte o administrador do sistema.
                  </p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>)
};


export default Users;
