import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import AuthUser from "../AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../Spinner";

import Swal from "sweetalert2";

import { startOfMonth, endOfMonth, format } from "date-fns";

const AtualizarFeriaModal = ({ colaborador, onSubmitSuccess, feria }) => {
  // eslint-disable-next-line no-unused-vars
  const {
    http,
    url_root,
    user,
    user_permissoes,
    verificarPermissao,
    getMissao,
  } = AuthUser();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [data1, setData1] = useState(feria?.data1);
  const [data2, setData2] = useState(feria?.data2);
  const [gozada, setgozada] = useState(0);
  const [remunerada, setRemunerada] = useState(0);

  const [ficheiros, setSelectedFile] = useState([]);

  const toggleLoading = (value) => {
    setLoading(value);
  };

  useEffect(() => {

    const currentDate = new Date();

    setData1(feria?.data1);
    setData2(feria?.data2);

    setgozada(feria?.saiu);
    setRemunerada(feria?.remunerada);

  }, [colaborador,feria]);

  // REGISTO NOVO

  const atualizar_feria = async () => {
    try {
      toggleLoading(true);

      await registar();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });
      } else {
        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } finally {
      toggleLoading(false);
    }
  };

  const registar = async () => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("id", feria?.id);

      formData.append("data1", data1);
      formData.append("dia1", format(data1, "d"));
      formData.append("mes1", format(data1, "M"));
      formData.append("ano1", format(data1, "yyyy"));

      formData.append("data2", data2);
      formData.append("dia2", format(data2, "d"));
      formData.append("mes2", format(data2, "M"));
      formData.append("ano2", format(data2, "yyyy"));

      formData.append("saiu", gozada);
      formData.append("remunerada", remunerada);

      const response = await http.post("/ferias/atualizar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setLoading(false);

      onSubmitSuccess(colaborador);

      Swal.fire({
        title: "INFORMAÇÃO",
        text: response?.data?.mensagem,
        type: "success",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        }
      );
    }
  };

  const handleFileChange = (e) => {
    
    const newFile = e.target.files;

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Chamar uploadFiles() apenas se houver arquivos selecionados
    uploadFilesGeral(newFile);
  };

  const uploadFilesGeral = async (ficheiros) => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("missao_id", getMissao()?.id);
      formData.append(
        "descricao",
        "Documento justificativa de feria " + feria?.data
      );
      formData.append("para_id", colaborador?.id);
      formData.append("tipo", 5); //Utilizador
      formData.append("feria_id", feria?.id);

      console.log("feria ID");
      console.log(feria?.id);

      // Append each file separately
      if (ficheiros)
        for (let i = 0; i < ficheiros.length; i++) {
          formData.append("files[]", ficheiros[i]);
        }

      const response = await http.post("/criar-arquivo", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setLoading(false);

      onSubmitSuccess();

    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <>
      {/* Personal Info Modal */}
      <div
        id="atualizar_feria_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <i className="fa fa-calendar" /> Atualizar feria Para -{" "}
                {colaborador?.name}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="input-block mb-3 mb-3 form-focus focused">
                      <div>
                        <input
                          className="form-control"
                          type="date"
                          value={data1}
                          onChange={(e)=>setData1(e.target.value)}
                        />
                        <label className="focus-label">{t("Data Saida")}</label>
                      </div>
                    </div>

                    <div className="input-block mb-3 mb-3 form-focus focused">
                      <div>
                        <input
                          className="form-control"
                          type="date"
                          value={data2}
                          onChange={(e)=>setData2(e.target.value)}
                        />
                        <label className="focus-label">{t("Data Regresso")}</label>
                      </div>
                    </div>

                    <div className="input-block mb-3 mb-3 form-focus focused">
                      <div>
                        <select
                          className="form-control select"
                          value={gozada}
                          onChange={(e) => setgozada(e.target.value)}
                        >
                          <option value="0">Não gozada</option>
                          <option value="1">Sim, gozada</option>
                        </select>-
                        <label className="focus-label">{t("Feria Gozada?")}</label>
                      </div>
                    </div>

                    <div className="input-block mb-3 mb-3 form-focus focused">
                      <div>
                        <select
                          className="form-control select"
                          value={remunerada}
                          onChange={(e) => setRemunerada(e.target.value)}
                        >
                          <option value="0">Não remunerada</option>
                          <option value="1">Sim, remunerada</option>
                        </select>
                        <label className="focus-label">{t("Feria remunerada?")}</label>
                      </div>
                    </div>

                    <div className="input-block mb-3 mb-3 form-focus focused">
                      <div>
                        <input
                          type="file"
                          className="upload"
                          name="files[]"
                          onChange={(e) => handleFileChange(e)}
                          id="files_edit"
                          multiple="multiple"
                        />

                       
                      </div>
                    </div>

                    <div className="submit-section m-t-20 text-center">
                      <a
                        className="btn btn-primary submit-btn"
                        onClick={atualizar_feria}
                      >
                        {t("Save")}
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Personal Info Modal */}

      <ToastContainer />

      {loading ? (
        <>
          <Spinner></Spinner>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AtualizarFeriaModal;
