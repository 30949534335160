import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  User1,
  User2,
  User3,
  User4,
  User5,
  User6,
} from "../../../../Routes/ImagePath";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import TaskboardModal from "../../../../components/modelpopup/TaskboardModal";
import ProjectModelPopup from "../../../../components/modelpopup/ProjectModelPopup";
import { useTranslation } from "react-i18next";

import LeaderModelPopup from "../../../../components/modelpopup/LeaderModelPopup";
import ColaboradorModelPopup from "../../../../components/modelpopup/ColaboradorModelPopup";

import AuthUser from "../../../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../../../components/Spinner";

import Swal from "sweetalert2";

import { format } from 'date-fns';
import TarefasModalPopup from "../../../../components/modelpopup/TarefasModalPopup";
import ModalFicheiroExcel from "../../../../components/modelpopup/ModalFicheiroExcel";

const TaskBoard = () => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  let { projectId } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } = AuthUser();
  const [projeto, setProjeto] = useState([]);
  const [quadroSelecionado, setQuadroSelecionado] = useState([]);
  const [tarefaSelecionada, setTarefaSelecionada] = useState([]);
  const [utilizadores, setUtilizadores] = useState([]);
  const [selectedColor, setSelectedColor] = useState('primary');
  const [selectedPrioridade, setSelectedPrioridade] = useState(1);

  const [missoes, setMissoes] = useState([]);

  const [idProject, setProjectID] = useState(projectId);

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const getProjeto = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/projeto/' + idProject);

      setProjeto(res.data.projeto);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getMissoes = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/missoes_ativas');

      setMissoes(res.data.missoes);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getUtilizadores = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/utilizadores/ativados/'+getMissao()?.id);

      setUtilizadores(res.data.utilizadores);

      console.log('Utilizadores : ');
      console.log(res.data.utilizadores);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  useEffect(() => {

    const fetchData = async () => {
      try {
        // Chama a função assíncrona getUtilizador()
        await getProjeto();
        await getUtilizadores();
        await getMissoes();
      } catch (error) {
        console.error('Erro ao obter utilizadores:', error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, [idProject]);

  const handleSubmissionSuccess = (projectIdSubmited) => {

    console.log('received : ');
    console.log(projectIdSubmited);

    //setProjectID(projectIdSubmited?.id);

    setProjeto(projectIdSubmited);

  };

  const chamarModalEdit = (idSelecionado) => {

    const editButton = document.getElementById('triggerModalEdit');

    if (editButton) {
      editButton.click();
    }

  }

  const removerArquivoProjeto = async (arquivo) => {

    Swal.fire({
      title: "Remover arquivo?",
      text: "Tem a certeza que pretende remover este arquivo do projeto?",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(async function (t) {

      if (t.isConfirmed) {
        try {

          setLoading(true);

          const formData = new FormData();
          formData.append('arquivo_id', arquivo?.id);
          formData.append('projeto_id', projeto?.id);

          const response = await http.post('/projeto/removerArquivo', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          setLoading(false);
          getProjeto();

          //Toast is not working here
          // Show a success toast
          toast.success(response.data.message, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => {

              setLoading(false);
              //Refresh colaboradores list
              //getProjeto();

            }
          });

        } catch (error) {

          setLoading(false);
          console.error(error);

          toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }

    });
  };

  const chamarModalEditQuadro = (quadro) => {

    setSelectedColor(quadro?.cor);
    setQuadroSelecionado(quadro);

    console.log('Quadro selecionado:');
    console.log(quadroSelecionado);

    const editButton = document.getElementById('trigger_edit_task_board');

    if (editButton) {
      editButton.click();
    }

  }

  const chamarModalEditTarefa = (tarefa, quadro) => {

    setSelectedColor(quadro?.cor);
    setQuadroSelecionado(quadro);
    setTarefaSelecionada(tarefa);

    console.log('Quadro selecionado:');
    console.log(quadroSelecionado);

    const editButton = document.getElementById('trigger_edit_task_modal');

    if (editButton) {
      editButton.click();
    }

  }

  const chamarModalAddTarefa = (quadro) => {

    setSelectedColor(quadro?.cor);
    setQuadroSelecionado(quadro);

    const editButton = document.getElementById('trigger_add_task_modal');

    if (editButton) {
      editButton.click();
    }

  }

  const chamarModalImportarFicheiro = () => {

    const editButton = document.getElementById('trigger_modal_ficheiro_excel');

    if (editButton) {
      editButton.click();
    }

  }


  const removerQuadro = async (quadro) => {

    Swal.fire({
      title: "Remover quadro?",
      text: "Tem a certeza que pretende remover este colaborador do projeto?",
      type: "confirm",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(async function (t) {

      if (t.isConfirmed) {
        try {

          setLoading(true);

          const formData = new FormData();
          formData.append('id', quadro?.id);

          const response = await http.post('/projeto/eliminar/quadro', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          // Show a success toast
          toast.success(response.data.message, {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => {

              setLoading(false);
              //Refresh colaboradores list
              getProjeto();

            }
          });

        } catch (error) {

          setLoading(false);
          console.error(error);

          toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }

    });
  };

  const removerTarefa = async (tarefa) => {

    Swal.fire({
      title: "Remover tarefa?",
      text: "Tem a certeza que pretende remover este colaborador do projeto?",
      type: "confirm",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(async function (t) {

      if (t.isConfirmed) {
        try {

          setLoading(true);

          const formData = new FormData();
          formData.append('id', tarefa?.id);

          const response = await http.post('/projeto/eliminar/tarefa', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          // Show a success toast
          toast.success(response.data.message, {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => {

              setLoading(false);
              //Refresh colaboradores list
              getProjeto();

            }
          });

        } catch (error) {

          setLoading(false);
          console.error(error);

          toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }

    });
  };

  const handleNavegarParaTarefa = (tarefa_id) => {

    navigate('/task/tasks/' + tarefa_id);

  };

  const [tasks, setTasks] = useState({
    pending: [
      {
        id: "task1",
        title: "Website redesign",
        progress: "70%",
        date: "Sep 26",
        priority: t('High'),
        priorityClsss: "task-priority badge bg-inverse-danger",
        users: [{ userCount: "+2" }],
      },
      {
        id: "task2",
        title: "Make a wireframe",
        progress: "70%",
        date: "Sep 26",
        priority: t('Low'),
        priorityClsss: "task-priority badge bg-inverse-success",
        users: [{ userCount: "+2" }],
      },
      {
        id: "task3",
        title: "Website redesign",
        progress: "70%",
        date: "Sep 26",
        priority: t('Medium'),
        priorityClsss: "task-priority badge bg-inverse-warning",
        users: [{ userCount: "+2" }],
      },
    ],
    progress: [
      {
        id: "task4",
        title: "Website redesign",
        progress: "70%",
        date: "Sep 26",
        priority: t('Medium'),
        priorityClsss: "task-priority badge bg-inverse-warning",
        users: [{ userCount: "+2" }],
      },
      {
        id: "task5",
        title: "Make a redesign",
        progress: "70%",
        date: "Sep 26",
        priority: t('High'),
        priorityClsss: "task-priority badge bg-inverse-danger",
        users: [{ userCount: "+2" }],
      },
    ],
    success: [
      {
        id: "task6",
        title: "Website redesign",
        progress: "70%",
        date: "Sep 26",
        priority: t('Medium'),
        priorityClsss: "task-priority badge bg-inverse-warning",
        users: [{ userCount: "+2" }],
      },
    ],
    warning: [
      {
        id: "task7",
        title: "Website redesign",
        progress: "70%",
        date: "Sep 26",
        priority: t('Low'),
        priorityClsss: "task-priority badge bg-inverse-success",
        users: [{ userCount: "+2" }],
      },
    ],
    purple: [
      {
        id: "task8",
        title: "Website redesign",
        progress: "70%",
        date: "Sep 26",
        priority: t('High'),
        priorityClsss: "task-priority badge bg-inverse-danger",
        users: [{ userCount: "+2" }],
      },
    ],
    primary: [
      {
        id: "task9",
        title: "Website redesign",
        progress: "70%",
        date: "Sep 26",
        priority: t('High'),
        priorityClsss: "task-priority badge bg-inverse-danger",
        users: [{ userCount: "+2" }],
      },
    ],
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return; // Dropped outside the list
    }

    const { source, destination } = result;

    if (source.droppableId == destination.droppableId) {
      // Reorder tasks in the same list
      const updatedTasks = [...tasks[source.droppableId]];
      const [reorderedTask] = updatedTasks.splice(source.index, 1);
      updatedTasks.splice(destination.index, 0, reorderedTask);

      setTasks({
        ...tasks,
        [source.droppableId]: updatedTasks,
      });
    } else {
      // Move task from pending to progress
      const sourceTasks = [...tasks[source.droppableId]];
      const destinationTasks = [...tasks[destination.droppableId]];
      const [movedTask] = sourceTasks.splice(source.index, 1);
      destinationTasks.splice(destination.index, 0, movedTask);

      setTasks({
        ...tasks,
        [source.droppableId]: sourceTasks,
        [destination.droppableId]: destinationTasks,
      });
    }
  };

  const encontrarUtilizadorPorId = (id) => {

    // Encontre o objeto com a ID correspondente
    const userEncontrado = utilizadores.find((lista) => lista.id == id);

    // Verifique se o objeto foi encontrado
    if (userEncontrado != undefined) {
      return userEncontrado;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }

  };


  const abrirFecharProjeto = async (projeto_id, estado) => {

    try {


      const formData = new FormData();
      formData.append('estado', estado);
      formData.append('projeto_id', projeto_id);

      const response = await http.post('/projeto/activate', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });



      //Toast is not working here
      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {

          getProjeto();

        }
      });

    } catch (error) {

      setLoading(false);
      console.error(error);

      toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }



  };

  const abrirFecharTarefa = async (tarefa_id, estado) => {

    try {



      const formData = new FormData();
      formData.append('estado', estado);
      formData.append('tarefa_id', tarefa_id);

      const response = await http.post('/projeto/tarefa/activate', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });



      //Toast is not working here
      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {

          getProjeto();

        }
      });

    } catch (error) {

      setLoading(false);
      console.error(error);

      toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

  };

  if (loading) return (<><Spinner></Spinner><ToastContainer /></>); else return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle={projeto?.projecto_code + ' ' + projeto?.nome + ' -> ' + (new Date(projeto?.data_fim).toLocaleDateString('pt-BR'))}
            title="Dashboard"
            subtitle={t('Task Board')}
            modal="#create_project"
          />
          <div className="row board-view-header">
            <div className="col-4">
              <div className="pro-teams">

                <div className="pro-team-lead">
                  <h4>{t('Lead')}</h4>
                  <div className="avatar-group">

                    {projeto?.colaboradores?.filter(user => user.tipo == 1).map(user => (
                      <div className="avatar">

                        <img
                          className="avatar-img rounded-circle border border-white"
                          src={url_root + "/storage/" + user?.colaborador?.fotografia} alt={user?.colaborador?.name} title={user?.colaborador?.name}
                        />
                      </div>
                    ))}

                    <div className="avatar">
                      <Link
                        to="#"
                        className="avatar-title rounded-circle border border-white"
                        data-bs-toggle="modal"
                        data-bs-target="#assign_leader"
                      >
                        <i className="fa fa-plus" />
                      </Link>
                    </div>

                  </div>
                </div>

                <div className="pro-team-members">
                  <h4>{t('Team')}</h4>
                  <div className="avatar-group">

                    {projeto?.colaboradores?.filter(user => user.tipo == 2).slice(0, 4).map(user => (
                      <div className="avatar">
                        <img
                          className="avatar-img rounded-circle border border-white"
                          src={url_root + "/storage/" + user?.colaborador?.fotografia} alt={user?.colaborador?.name} title={user?.colaborador?.name}
                        />
                      </div>
                    ))}

                    <div className="avatar">
                      <Link
                        to="#"
                        className="avatar-title rounded-circle border border-white"
                        data-bs-toggle="modal"
                        data-bs-target="#assign_colaborador"
                      >
                        <i className="fa fa-plus" />
                      </Link>
                    </div>

                  </div>
                </div>

              </div>
            </div>
            <div className="col-8 text-end">

              <Link
                to="#"
                className="btn btn-white float-end ms-2"
                data-bs-toggle="modal"
                data-bs-target="#all_tasks_modal"
              >

                <i className="fa fa-list"></i>{' '}
                {t('All Tasks')}

              </Link>

              <Link
                to="#"
                className="btn btn-white float-end ms-2"
                data-bs-toggle="modal"
                data-bs-target="#edit_project"
              >
                <i className="fa fa-edit" />{t('Edit Project')}
              </Link>

              {
                projeto?.estado == 1 ?
                  <span
                    className="btn btn-info float-end ms-2"
                    onClick={() => abrirFecharProjeto(projeto?.id, 0)}
                  >
                    <i className="fa fa-check" />{t(' Abrir ')}&nbsp;&nbsp;
                  </span> :
                  <span
                    className="btn btn-info float-end ms-2"
                    onClick={() => abrirFecharProjeto(projeto?.id, 1)}
                  >
                    <i className="fa fa-check" />{t(' Fechar ')}&nbsp;&nbsp;
                  </span>
              }
              <Link
                to="#"
                className="btn btn-success float-end ms-2"
                data-bs-toggle="modal"
                data-bs-target="#modal_form_excel_import"
              >
                <i className="fa fa-file-excel" />{t(' Excel')}
              </Link>


              <a title={'Relatorio de produtividade'} className="btn btn-danger float-end ms-2" href={url_root + '/relatorio_produtividade/' + projeto?.id} target="_blank" rel="noreferrer"><i className="fa fa-file-pdf"></i>&nbsp;</a>
              <Link
                to="#"
                className="btn btn-white float-end ms-2"
                data-bs-toggle="modal"
                data-bs-target="#add_task_board"
              >
                <i className="fa fa-plus" />{t('Create List')}
              </Link>
              <Link
                to={"/project-view/" + projeto?.id}
                className="btn btn-white float-end mx-2"
                title={t('View Project')}
              >
                <i className="fa fa-link" />
              </Link>
              {
                <Link
                  to="#"
                  className="btn btn-white float-end ms-2"
                  data-bs-toggle="modal"
                  data-bs-target="#edit_project"
                >

                  {
                    projeto?.aprovado == 0 ? <><img width={24}
                      height={24} title={t('Pending to approve')} src={url_root + "/storage/uploads/icons/siren.png"} /></> : projeto?.aprovado == 1 ? <span title={t('Approved')}><i className="fa fa-thumbs-up text-success"></i></span> : <span title={t('Negated')}><i className="fa fa-thumbs-down text-danger"></i></span>
                  }

                  {
                    (projeto?.aprovado == 0 || projeto?.aprovado == 2) ? <></> : (projeto?.estado == 1) ? (<><span
                      className={"badge bg-inverse-success"}
                    >
                      <i className="fa fa-check"></i>&nbsp;&nbsp; <span>{t('Closed')}</span>
                    </span>&nbsp;&nbsp;</>) : <></>
                  }

                  {
                    (projeto?.aprovado == 0 || projeto?.aprovado == 2) ? <></> : ((new Date(projeto?.data_fim) < new Date()) && projeto?.estado != 1) ? (<><span
                      className={"badge bg-inverse-danger"}
                    >
                      <i className="fa fa-clock"></i>&nbsp;&nbsp; <span>{t('Pending')}</span>
                    </span>&nbsp;&nbsp;</>) : (projeto?.estado != 1 ? (<span
                      className={"badge bg-inverse-warning"}
                    >
                      <i className="fa fa-list"></i>&nbsp;&nbsp; <span>{t('Opened')}</span>
                    </span>) : '')
                  }
                </Link>}
            </div>
            <div className="col-12">
              <div className="pro-progress">
                <div className="pro-progress-bar">
                  <h4>{t('Progress')}</h4>
                  <div className="progress progress-xs mb-0">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      data-bs-toggle="tooltip"
                      title={(Math.round(((projeto?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / projeto?.tarefas?.length) * 100)) + '%'}
                      style={{ width: `${Math.round(((projeto?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / projeto?.tarefas?.length) * 100)}%` }}
                    />
                  </div>
                  <span>{Math.round(((projeto?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / projeto?.tarefas?.length) * 100) ? Math.round(((projeto?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / projeto?.tarefas?.length) * 100) : 0}%</span>
                </div>
              </div>
            </div>
          </div>
          <div className="kanban-board card mb-0">

            {/* Lista de tarefas inicio          */}
            <div className="card-body">
              <div className="kanban-cont">
                <DragDropContext onDragEnd={onDragEnd}>

                  {projeto?.quadros?.map(quadro => (

                    <div className={"kanban-list kanban-" + quadro?.cor} key={quadro?.id}>
                      <div className="kanban-header">
                        <span className="status-title">{quadro?.nome}</span>
                        <div className="dropdown kanban-action">
                          <Link to="#" data-bs-toggle="dropdown">
                            <i className="fa-solid fa-ellipsis-vertical" />
                          </Link>
                          <div className="dropdown-menu dropdown-menu-right">
                            <span
                              className="dropdown-item"
                              title={quadro?.nome}
                              onClick={() => chamarModalEditQuadro(quadro)}
                            >
                              <i className="fa fa-pencil m-r-5" /> {t('Edit')}
                            </span>
                            <span
                              className="dropdown-item"
                              title={quadro?.nome}
                              onClick={() => removerQuadro(quadro)}
                            >
                              <i className="fa fa-trash m-r-5" /> {t('Delete')}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* Pending List */}
                      <Droppable droppableId="pending" direction="vertical">
                        {(provided) => (
                          <div className="kanban-wrap" ref={provided.innerRef}>
                            {quadro?.tarefas?.map((task, index) => (
                              <Draggable
                                key={task.id}
                                draggableId={task.id}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    className="card panel kanban-box"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div className="task-board-header">
                                      <span className="status-title">
                                        <Link to={'/task/tasks/' + task?.id}> {task.nome}</Link>
                                      </span>

                                      <div className="dropdown kanban-task-action">
                                        <Link to="#" data-bs-toggle="dropdown">
                                          <i className="fa-solid fa-angle-down" />
                                        </Link>
                                        <div className="dropdown-menu dropdown-menu-right">
                                          <span
                                            className="dropdown-item"
                                            title={task?.nome}
                                            onClick={() => chamarModalEditTarefa(task, quadro)}
                                          >
                                            <i className="fa fa-pencil m-r-5" /> {t('Edit')}
                                          </span>
                                          <span
                                            className="dropdown-item"
                                            title={task?.nome}
                                            onClick={() => removerTarefa(task)}
                                          >
                                            <i className="fa fa-trash m-r-5" /> {t('Delete')}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="task-board-body">
                                      <div className="kanban-info">



                                        <div className="pro-progress w-100">
                                          <div className="pro-progress-bar">

                                            <div className="progress progress-xs mb-0">
                                              <div
                                                className="progress-bar"
                                                role="progressbar"
                                                data-bs-toggle="tooltip"
                                                title={(Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) + '%'}
                                                style={{ width: `${Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)}%` }}
                                              />
                                            </div>
                                            <span className="status-title">
                                              [ {task?.items?.filter(tarefa => tarefa.estado == 1).length} de {task?.items?.length} ] = &nbsp;  
                                            </span>

                                            <span>{(Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) ? (Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) : 0}%</span>
                                          </div>
                                        </div>

                                        <span>{task.progress}</span>
                                      </div>
                                      <div className="kanban-footer">
                                        <span className="task-info-cont">

                                          <span className="task-date">
                                            <i className="fa-regular fa-clock" />&nbsp;
                                            {task?.prazo ? new Date(task?.prazo).toLocaleDateString('pt-BR') : ''}
                                          </span>

                                          <span
                                            className={
                                              task?.prioridade == 1
                                                ? "badge bg-inverse-success"
                                                : task?.prioridade == 2 ? "badge bg-inverse-warning" : "badge bg-inverse-danger"
                                            }
                                          >
                                            {task?.prioridade == 1 ? t('Low') : task?.prioridade == 2 ? t('Medium') : t('High')}
                                          </span>

                                          {
                                            task?.estado == 1 ? <>&nbsp;&nbsp;<span
                                              className={"badge bg-inverse-success"}
                                            >
                                              <i className="fa fa-check"></i>&nbsp;&nbsp; <span>{t('Closed')}</span>
                                            </span>&nbsp;&nbsp;</> : <>&nbsp;&nbsp;<span className={"badge " + (new Date(task?.prazo) < new Date() ? 'bg-inverse-danger' : 'bg-inverse-warning')}>
                                              <i className="fa fa-clock"></i>&nbsp;&nbsp; <span>{(new Date(task?.prazo) < new Date()) ? t('Pending') : t('Progress')}</span>
                                            </span>&nbsp;&nbsp;</>
                                          }
                                        </span>

                                        {
                                          task?.estado == 1 ?
                                            <span
                                              className={'badge bg-inverse-info'}
                                              onClick={() => abrirFecharTarefa(task?.id, 0)}
                                            >
                                              <i className="fa fa-check" />{t(' Abrir ')}
                                            </span> :
                                            <span
                                              className={'badge bg-inverse-info'}
                                              onClick={() => abrirFecharTarefa(task?.id, 1)}
                                            >
                                              <i className="fa fa-check" />{t(' Fechar ')}
                                            </span>
                                        }


                                        <Link to={'/profile/' + task?.criado_por} className="task-users">

                                          <img
                                            src={url_root + "/storage/" + encontrarUtilizadorPorId(task?.criado_por)?.fotografia} alt={encontrarUtilizadorPorId(task?.criado_por)?.name} title={encontrarUtilizadorPorId(task?.criado_por)?.name}
                                            className="task-avatar"
                                            width={24}
                                            height={24}
                                          />
                                          <span className="task-user-count">
                                            {/* {task?.users[0]?.userCount} */}
                                          </span>

                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>

                      <div className="add-new-task">
                        <a
                          onClick={() => chamarModalAddTarefa(quadro)}
                        >
                          {t('Add New Task')}
                        </a>
                      </div>
                    </div>

                  ))}

                </DragDropContext>
              </div>
            </div>
            {/* lista de tarefas fim */}

            {/* imagenbs do projeto inicio                     */}
            <div className="card">
              <div className="card-body">
                <h5 className="card-title m-b-20">{t('Uploaded image files')}</h5>
                <div className="row">

                  {projeto?.arquivos?.filter(arquivo => arquivo.tipo_ficheiro == 'media').map(arquivo => (

                    <div className="col-md-3 col-sm-4 col-lg-4 col-xl-3" key={arquivo?.id * 10}>
                      <div className="uploaded-box">
                        <div className="uploaded-img">
                          <i className="fa fa-times text-danger"
                            onClick={() => removerArquivoProjeto(arquivo)}
                          />
                          <img src={url_root + "/storage/uploads/" + arquivo?.ficheiro} alt={arquivo?.ficheiro} title={arquivo?.ficheiro} className="img-fluid" />
                        </div>
                        <div className="uploaded-img-name">
                          <a className="text-primary" href={url_root + '/api/ler_anexo/' + arquivo?.ficheiro} target="_blank" rel="noreferrer">{arquivo?.ficheiro}</a>
                        </div>
                      </div>
                    </div>
                  ))}

                </div>
              </div>
            </div>
            {/* imagens do projeto fim */}

            {/* documentos do projeto inicio         */}
            <div className="card">
              <div className="card-body">
                <h5 className="card-title m-b-20">{t('Uploaded files')}</h5>
                <ul className="files-list">

                  {projeto?.arquivos?.filter(arquivo => arquivo.tipo_ficheiro != 'media').map(arquivo => (

                    <li key={arquivo?.id}>
                      <div className="files-cont">

                        <div className="file-type">
                          <span className="files-icon">
                            <img src={url_root + "/storage/uploads/icons/" + arquivo?.file_icon} width={20} height={20} />
                          </span>
                        </div>

                        <div className="files-info">
                          <span className="file-name text-ellipsis">
                            <a className="text-primary" href={url_root + '/api/ler_anexo/' + arquivo?.ficheiro} target="_blank" rel="noreferrer">{arquivo?.ficheiro}</a>
                          </span>
                          <span className="file-author">
                            <Link to="#">{arquivo?.colaborador?.name}</Link>
                          </span>{" "}
                          <span className="file-date">{arquivo?.created_at ? new Date(arquivo?.created_at).toLocaleDateString('pt-BR') : ''}</span>
                          <div className="file-size">{t('Size')}: {arquivo?.tamanho}Mb</div>
                        </div>
                        <ul className="files-action">
                          <li className="dropdown dropdown-action">
                            <Link
                              to="#"
                              className="dropdown-toggle btn btn-link"
                              data-bs-toggle="dropdown"
                              aria-expanded="false">
                              <i className="material-icons">more_horiz</i>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-right">
                              <span><a className="text-primary" href={url_root + '/api/ler_anexo/' + arquivo?.ficheiro} target="_blank" rel="noreferrer">{t('Download')}</a></span>
                              <span className="dropdown-item" onClick={() => removerArquivoProjeto(arquivo)}>
                                {t('Delete')}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>

                  ))}

                </ul>
              </div>
            </div>
            {/* documentos do projeto fim */}



          </div>
        </div>
      </div >


      <span
        data-bs-toggle="modal"
        data-bs-target="#edit_task_board"
        id="trigger_edit_task_board"
      >
      </span>

      <span
        data-bs-toggle="modal"
        data-bs-target="#edit_task_modal"
        id="trigger_edit_task_modal"
      >
      </span>

      <span
        data-bs-toggle="modal"
        data-bs-target="#add_task_modal"
        id="trigger_add_task_modal"
      >
      </span>

      <span
        data-bs-toggle="modal"
        data-bs-target="#modal_form_excel_import"
        id="trigger_modal_ficheiro_excel"
      >
      </span>

      <ModalFicheiroExcel projeto_id={projeto?.id} onSubmissionSuccess={handleSubmissionSuccess} />

      <TaskboardModal quadro={quadroSelecionado} tarefa={tarefaSelecionada} projeto={projeto} onSubmissionSuccess={handleSubmissionSuccess} utilizadores={utilizadores} defaultColor={selectedColor} defaultPrioridade={selectedPrioridade} quadros={projeto?.quadros} />

      <LeaderModelPopup utilizadores={utilizadores} onSubmissionSuccess={getProjeto} projeto={projeto} />
      <ColaboradorModelPopup utilizadores={utilizadores} onSubmissionSuccess={getProjeto} projeto={projeto} />

      <ProjectModelPopup onSubmissionSuccess={handleSubmissionSuccess} projeto={projeto} prioridadeParam={projeto?.prioridade} tempoParam={projeto?.tempo} semanalParam={projeto?.semanal} utilizadores={utilizadores} missoes={missoes} />

      <TarefasModalPopup tarefas={projeto?.tarefas} handleNavigateTarefas={handleNavegarParaTarefa} />

      <ToastContainer />

    </>
  );
};

export default TaskBoard;
