import React, { useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const UserSearchFilter = () => {

  const { t } = useTranslation();

  const [setSelectedOption] = useState(null);
  const [setSelectedOptionTwo] = useState(null);
  const [setSelectedOptionThree] = useState(null);

  const options = [
    { value: "1", label: t('Select') },
    { value: "2", label: t('Development') },
    { value: "3", label: t('Designing') },
    { value: "4", label: t('Android') },
  ];
  const optionsTwo = [
    { value: "1", label: t('Select') },
    { value: "2", label: t('Full Time') },
    { value: "3", label: t('Part') },
    { value: "4", label: t('Pending') },
  ];

  const optionsThree = [
    { value: "1", label: t('Select Designation') },
    { value: "2", label: "Web Developer" },
    { value: "3", label: "Web Designer" },
    { value: "4", label: "Android Developer" },
    { value: "5", label: "IOS Developer" },
  ];
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#00b0f0" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#00b0f0",
      },
    }),
  };

  return (
    <div className="row filter-row">
      <div className="col-sm-6 col-md-3">
        <div className="input-block mb-3 form-focus select-focus">
          <Select
            placeholder="Select"
            onChange={setSelectedOption}
            options={options}
            styles={customStyles}
          />
          <label className="focus-label">{t('Department')}</label>
        </div>
      </div>
      <div className="col-sm-6 col-md-3">
        <div className="input-block mb-3 form-focus select-focus">
          <Select
            placeholder="Select"
            onChange={setSelectedOptionTwo}
            options={optionsTwo}
            styles={customStyles}
          />
          <label className="focus-label">{t('Job Type')}</label>
        </div>
      </div>

      <div className="col-sm-6 col-md-3">
        <div className="input-block mb-3 form-focus select-focus">
          <Select
            placeholder="Select Designation"
            onChange={setSelectedOptionThree}
            options={optionsThree}
            styles={customStyles}
          />
          <label className="focus-label">{t('Designation')}</label>
        </div>
      </div>
      <div className="col-sm-6 col-md-3">
        <Link to="#" className="btn btn-success w-100">
        {t('Search')}
        </Link>
      </div>
    </div>
  );
};

export default UserSearchFilter;
