/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { User, User1, User2 } from "../../../../../Routes/ImagePath";

import { useTranslation } from "react-i18next";
import AuthUser from "../../../../../components/AuthUser";

import formatNumber from 'format-number';

const Statistics = ({ projetos, tarefas, tickets }) => {

  const { t } = useTranslation();

  const [projetos_lista, setProjetos] = useState(projetos);
  const [tickets_lista, setTickets] = useState(tickets);

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getToken, getMissao, user } = AuthUser();

  useEffect(() => {

    const fetchData = async () => {
      try {
        // Chama a função assíncrona getUtilizador()
        setProjetos(projetos);
        setTickets(tickets);

      } catch (error) {
        console.error('Erro ao obter utilizadores:', error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, [projetos_lista]);

  const formatarNumero = (numero, casasDecimais, separadorMilhares, separadorDecimal) => {
    const options = {
      round: casasDecimais,
      decimal: separadorDecimal,
      thousand: separadorMilhares,
    };

    return formatNumber(options)(numero);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12 col-lg-12 col-xl-3 d-flex">
          <div className="card flex-fill dash-statistics">
            <div className="card-body">
              <h5 className="card-title">{t('Projects')}</h5>
              <div className="stats-list">
                <div className="stats-info">
                  <p>
                    {t('Progress')}
                    <strong>

                      {projetos?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        if (project.estado == 1) {
                          return false;
                        }

                        if (project.estado != 0) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length} <small>/ {projetos?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length}</small>

                    </strong>
                  </p>
                  <div className="progress">

                    <div
                      className="progress-bar bg-primary"
                      role="progressbar"
                      data-bs-toggle="tooltip"
                      title={(Math.round(((projetos?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        if (project.estado == 1) {
                          return false;
                        }

                        if (project.estado != 0) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length) / projetos?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length) * 100)) + '%'}
                      style={{
                        width: `${Math.round(((projetos?.filter(project => {

                          // Excluir se a missão do projeto é diferente da missão na sessão
                          if (getMissao()?.id != project.missao_id) {
                            return false;
                          }

                          // Excluir se o projeto esta arquivado
                          if (project.estado == 2) {
                            return false;
                          }

                          if (project.estado == 1) {
                            return false;
                          }

                          if (project.estado != 0) {
                            return false;
                          }

                          // Se o tipo de usuário for 1, retorna true para todos os projetos
                          if (user.tipo == 1) {
                            return true;
                          }

                          // Se o projeto foi criado pelo usuário
                          if (project.criado_por == user.id) {
                            return true;
                          }

                          // Se o usuário é participante do projeto
                          const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                          if (participante) {
                            return true;
                          }

                          return false;

                        }).length) / projetos?.filter(project => {

                          // Excluir se a missão do projeto é diferente da missão na sessão
                          if (getMissao()?.id != project.missao_id) {
                            return false;
                          }

                          // Excluir se o projeto esta arquivado
                          if (project.estado == 2) {
                            return false;
                          }

                          // Se o tipo de usuário for 1, retorna true para todos os projetos
                          if (user.tipo == 1) {
                            return true;
                          }

                          // Se o projeto foi criado pelo usuário
                          if (project.criado_por == user.id) {
                            return true;
                          }

                          // Se o usuário é participante do projeto
                          const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                          if (participante) {
                            return true;
                          }

                          return false;

                        }).length) * 100)}%`
                      }}

                      aria-valuenow={62}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />

                  </div>
                </div>
                <div className="stats-info">
                  <p>
                    {t('Fechados')}
                    <strong>

                      {projetos?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        if (project.estado != 1) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length} <small>/ {projetos?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length}</small>

                    </strong>
                  </p>
                  <div className="progress">

                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      data-bs-toggle="tooltip"
                      title={(Math.round(((projetos?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        if (project.estado != 1) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length) / projetos?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length) * 100)) + '%'}
                      style={{
                        width: `${Math.round(((projetos?.filter(project => {

                          // Excluir se a missão do projeto é diferente da missão na sessão
                          if (getMissao()?.id != project.missao_id) {
                            return false;
                          }

                          // Excluir se o projeto esta arquivado
                          if (project.estado == 2) {
                            return false;
                          }

                          if (project.estado != 1) {
                            return false;
                          }

                          // Se o tipo de usuário for 1, retorna true para todos os projetos
                          if (user.tipo == 1) {
                            return true;
                          }

                          // Se o projeto foi criado pelo usuário
                          if (project.criado_por == user.id) {
                            return true;
                          }

                          // Se o usuário é participante do projeto
                          const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                          if (participante) {
                            return true;
                          }

                          return false;

                        }).length) / projetos?.filter(project => {

                          // Excluir se a missão do projeto é diferente da missão na sessão
                          if (getMissao()?.id != project.missao_id) {
                            return false;
                          }

                          // Excluir se o projeto esta arquivado
                          if (project.estado == 2) {
                            return false;
                          }

                          // Se o tipo de usuário for 1, retorna true para todos os projetos
                          if (user.tipo == 1) {
                            return true;
                          }

                          // Se o projeto foi criado pelo usuário
                          if (project.criado_por == user.id) {
                            return true;
                          }

                          // Se o usuário é participante do projeto
                          const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                          if (participante) {
                            return true;
                          }

                          return false;

                        }).length) * 100)}%`
                      }}

                      aria-valuenow={62}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />


                  </div>
                </div>

                <div className="stats-info">
                  <p>
                    {t('Pending')}
                    <strong>

                      {projetos?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        if (project.estado == 1) {
                          return false;
                        }

                        if (project.estado != 0) {
                          return false;
                        }

                        if (!((new Date(project?.data_fim) < new Date()) && project?.estado != 1)) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length} <small>/ {projetos?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length}</small>

                    </strong>
                  </p>
                  <div className="progress">

                    <div
                      className="progress-bar bg-danger"
                      role="progressbar"
                      data-bs-toggle="tooltip"
                      title={(Math.round(((projetos?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        if (project.estado == 1) {
                          return false;
                        }

                        if (project.estado != 0) {
                          return false;
                        }

                        if (!((new Date(project?.data_fim) < new Date()) && project?.estado != 1)) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length) / projetos?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length) * 100)) + '%'}
                      style={{
                        width: `${Math.round(((projetos?.filter(project => {

                          // Excluir se a missão do projeto é diferente da missão na sessão
                          if (getMissao()?.id != project.missao_id) {
                            return false;
                          }

                          // Excluir se o projeto esta arquivado
                          if (project.estado == 2) {
                            return false;
                          }

                          if (project.estado == 1) {
                            return false;
                          }

                          if (project.estado != 0) {
                            return false;
                          }

                          if (!((new Date(project?.data_fim) < new Date()) && project?.estado != 1)) {
                            return false;
                          }

                          // Se o tipo de usuário for 1, retorna true para todos os projetos
                          if (user.tipo == 1) {
                            return true;
                          }

                          // Se o projeto foi criado pelo usuário
                          if (project.criado_por == user.id) {
                            return true;
                          }

                          // Se o usuário é participante do projeto
                          const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                          if (participante) {
                            return true;
                          }

                          return false;

                        }).length) / projetos?.filter(project => {

                          // Excluir se a missão do projeto é diferente da missão na sessão
                          if (getMissao()?.id != project.missao_id) {
                            return false;
                          }

                          // Excluir se o projeto esta arquivado
                          if (project.estado == 2) {
                            return false;
                          }

                          // Se o tipo de usuário for 1, retorna true para todos os projetos
                          if (user.tipo == 1) {
                            return true;
                          }

                          // Se o projeto foi criado pelo usuário
                          if (project.criado_por == user.id) {
                            return true;
                          }

                          // Se o usuário é participante do projeto
                          const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                          if (participante) {
                            return true;
                          }

                          return false;

                        }).length) * 100)}%`
                      }}

                      aria-valuenow={62}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-6 col-xl-6 d-flex">
          <div className="card flex-fill">
            <div className="card-body">
              <h4 className="card-title">{t('Task Statistics')}</h4>
              <div className="statistics">
                <div className="row">
                  <div className="col-md-6 col-6 text-center">
                    <div className="stats-box mb-4">
                      <p>{t('Total Tasks')}</p>
                      <h3>
                        {tarefas?.filter(project => {

                          // Excluir se a missão do projeto é diferente da missão na sessão
                          if (getMissao()?.id != project?.projeto?.missao_id) {
                            return false;
                          }

                          // Excluir se o projeto esta arquivado
                          if (project?.projeto?.estado == 2) {
                            return false;
                          }

                          // Se o tipo de usuário for 1, retorna true para todos os projetos
                          if (user.tipo == 1) {
                            return true;
                          }

                          // Se o projeto foi criado pelo usuário
                          if (project?.projeto?.criado_por == user.id) {
                            return true;
                          }

                          // Se o usuário é participante do projeto
                          const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                          if (participante) {
                            return true;
                          }

                          return false;

                        }).length}
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-6 col-6 text-center">
                    <div className="stats-box mb-4">
                      <p>{t('Pending Tasks')}</p>
                      <h3>{tarefas?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project?.projeto?.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project?.projeto?.estado == 2) {
                          return false;
                        }

                        // Excluir se o tarefa esta não concluida no tempo previsto
                        if (!(project.estado == 0 && new Date(project.prazo) < new Date())) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project?.projeto?.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="progress mb-4">

                <div
                  className="progress-bar bg-warning"
                  role="progressbar"
                  style={{
                    width: Number((
                      (tarefas?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project?.projeto?.missao_id) {
                          return false;
                        }
  
                        // Excluir se o projeto esta arquivado
                        if (project?.projeto?.estado == 2) {
                          return false;
                        }
  
                        // Excluir se o tarefa esta não concluida
                        if (project?.estado != 0) {
                          return false;
                        }
  
                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }
  
                        // Se o projeto foi criado pelo usuário
                        if (project?.projeto?.criado_por == user.id) {
                          return true;
                        }
  
                        // Se o usuário é participante do projeto
                        const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }
  
                        return false;
  
                      }).length) * 100 / (tarefas?.filter(project => {
  
                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project?.projeto?.missao_id) {
                          return false;
                        }
  
                        // Excluir se o projeto esta arquivado
                        if (project?.projeto?.estado == 2) {
                          return false;
                        }
  
                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }
  
                        // Se o projeto foi criado pelo usuário
                        if (project?.projeto?.criado_por == user.id) {
                          return true;
                        }
  
                        // Se o usuário é participante do projeto
                        const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }
  
                        return false;
  
                      }).length) ? (tarefas?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project?.projeto?.missao_id) {
                          return false;
                        }
  
                        // Excluir se o projeto esta arquivado
                        if (project?.projeto?.estado == 2) {
                          return false;
                        }
  
                        // Excluir se o tarefa esta não concluida
                        if (project?.estado != 0) {
                          return false;
                        }
  
                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }
  
                        // Se o projeto foi criado pelo usuário
                        if (project?.projeto?.criado_por == user.id) {
                          return true;
                        }
  
                        // Se o usuário é participante do projeto
                        const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }
  
                        return false;
  
                      }).length) * 100 / (tarefas?.filter(project => {
  
                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project?.projeto?.missao_id) {
                          return false;
                        }
  
                        // Excluir se o projeto esta arquivado
                        if (project?.projeto?.estado == 2) {
                          return false;
                        }
  
                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }
  
                        // Se o projeto foi criado pelo usuário
                        if (project?.projeto?.criado_por == user.id) {
                          return true;
                        }
  
                        // Se o usuário é participante do projeto
                        const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }
  
                        return false;
  
                      }).length) : 0))+"%" }}
                  aria-valuenow={Number((
                    (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Excluir se o tarefa esta não concluida
                      if (project?.estado != 0) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) * 100 / (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) ? (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Excluir se o tarefa esta não concluida
                      if (project?.estado != 0) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) * 100 / (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) : 0))}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  {formatarNumero(Number((
                    (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Excluir se o tarefa esta não concluida
                      if (project?.estado != 0) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) * 100 / (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) ? (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Excluir se o tarefa esta não concluida
                      if (project?.estado != 0) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) * 100 / (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) : 0)), 0, ',', '.')}%
                </div>

                <div
                  className="progress-bar bg-danger"
                  role="progressbar"
                  style={{
                    width: Number((
                      (tarefas?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project?.projeto?.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project?.projeto?.estado == 2) {
                          return false;
                        }

                        // Excluir se o tarefa esta não concluida no tempo previsto
                        if (!(project.estado == 0 && new Date(project.prazo) < new Date())) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project?.projeto?.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length) * 100 / (tarefas?.filter(project => {
  
                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project?.projeto?.missao_id) {
                          return false;
                        }
  
                        // Excluir se o projeto esta arquivado
                        if (project?.projeto?.estado == 2) {
                          return false;
                        }
  
                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }
  
                        // Se o projeto foi criado pelo usuário
                        if (project?.projeto?.criado_por == user.id) {
                          return true;
                        }
  
                        // Se o usuário é participante do projeto
                        const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }
  
                        return false;
  
                      }).length) ? (tarefas?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project?.projeto?.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project?.projeto?.estado == 2) {
                          return false;
                        }

                        // Excluir se o tarefa esta não concluida no tempo previsto
                        if (!(project.estado == 0 && new Date(project.prazo) < new Date())) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project?.projeto?.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length) * 100 / (tarefas?.filter(project => {
  
                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project?.projeto?.missao_id) {
                          return false;
                        }
  
                        // Excluir se o projeto esta arquivado
                        if (project?.projeto?.estado == 2) {
                          return false;
                        }
  
                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }
  
                        // Se o projeto foi criado pelo usuário
                        if (project?.projeto?.criado_por == user.id) {
                          return true;
                        }
  
                        // Se o usuário é participante do projeto
                        const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }
  
                        return false;
  
                      }).length) : 0))+"%" }}
                  aria-valuenow={Number((
                    (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Excluir se o tarefa esta não concluida no tempo previsto
                      if (!(project.estado == 0 && new Date(project.prazo) < new Date())) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) * 100 / (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) ? (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Excluir se o tarefa esta não concluida no tempo previsto
                      if (!(project.estado == 0 && new Date(project.prazo) < new Date())) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) * 100 / (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) : 0))}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  {formatarNumero(Number((
                    (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Excluir se o tarefa esta não concluida no tempo previsto
                      if (!(project.estado == 0 && new Date(project.prazo) < new Date())) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) * 100 / (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) ? (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Excluir se o tarefa esta não concluida no tempo previsto
                      if (!(project.estado == 0 && new Date(project.prazo) < new Date())) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) * 100 / (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) : 0)), 0, ',', '.')}%
                </div>

                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{
                    width: Number((
                      (tarefas?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project?.projeto?.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project?.projeto?.estado == 2) {
                          return false;
                        }

                        // Excluir se o tarefa esta não concluida no tempo previsto
                        if (project.estado != 1) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project?.projeto?.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length) * 100 / (tarefas?.filter(project => {
  
                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project?.projeto?.missao_id) {
                          return false;
                        }
  
                        // Excluir se o projeto esta arquivado
                        if (project?.projeto?.estado == 2) {
                          return false;
                        }
  
                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }
  
                        // Se o projeto foi criado pelo usuário
                        if (project?.projeto?.criado_por == user.id) {
                          return true;
                        }
  
                        // Se o usuário é participante do projeto
                        const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }
  
                        return false;
  
                      }).length) ? (tarefas?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project?.projeto?.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project?.projeto?.estado == 2) {
                          return false;
                        }

                        // Excluir se o tarefa esta não concluida no tempo previsto
                        if (project.estado != 1) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project?.projeto?.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length) * 100 / (tarefas?.filter(project => {
  
                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project?.projeto?.missao_id) {
                          return false;
                        }
  
                        // Excluir se o projeto esta arquivado
                        if (project?.projeto?.estado == 2) {
                          return false;
                        }
  
                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }
  
                        // Se o projeto foi criado pelo usuário
                        if (project?.projeto?.criado_por == user.id) {
                          return true;
                        }
  
                        // Se o usuário é participante do projeto
                        const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }
  
                        return false;
  
                      }).length) : 0))+"%" }}
                  aria-valuenow={Number((
                    (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Excluir se o tarefa esta não concluida no tempo previsto
                      if (project.estado != 1) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) * 100 / (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) ? (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Excluir se o tarefa esta não concluida no tempo previsto
                      if (project.estado != 1) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) * 100 / (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) : 0))}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  {formatarNumero(Number((
                    (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Excluir se o tarefa esta não concluida no tempo previsto
                      if (project.estado != 1) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) * 100 / (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) ? (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Excluir se o tarefa esta não concluida no tempo previsto
                      if (project.estado != 1) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) * 100 / (tarefas?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project?.projeto?.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project?.projeto?.estado == 2) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project?.projeto?.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length) : 0)), 0, ',', '.')}%
                </div>


              </div>
              <div>
                <p>
                  <i className="far fa-dot-circle text-success me-2" />
                  {t('Completed Tasks')} <span className="float-end">{Number((
                      (tarefas?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project?.projeto?.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project?.projeto?.estado == 2) {
                          return false;
                        }

                        // Excluir se o tarefa esta não concluida no tempo previsto
                        if (project.estado != 1) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project?.projeto?.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length)))? formatarNumero(Number((
                        (tarefas?.filter(project => {
  
                          // Excluir se a missão do projeto é diferente da missão na sessão
                          if (getMissao()?.id != project?.projeto?.missao_id) {
                            return false;
                          }
  
                          // Excluir se o projeto esta arquivado
                          if (project?.projeto?.estado == 2) {
                            return false;
                          }
  
                          // Excluir se o tarefa esta não concluida no tempo previsto
                          if (project.estado != 1) {
                            return false;
                          }
  
                          // Se o tipo de usuário for 1, retorna true para todos os projetos
                          if (user.tipo == 1) {
                            return true;
                          }
  
                          // Se o projeto foi criado pelo usuário
                          if (project?.projeto?.criado_por == user.id) {
                            return true;
                          }
  
                          // Se o usuário é participante do projeto
                          const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                          if (participante) {
                            return true;
                          }
  
                          return false;
  
                        }).length))),0,'.',','): 0}</span>
                </p>
                <p>
                  <i className="far fa-dot-circle text-warning me-2" />
                  {t('Inprogress Tasks')} <span className="float-end">

                  {Number((
                      (tarefas?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project?.projeto?.missao_id) {
                          return false;
                        }
  
                        // Excluir se o projeto esta arquivado
                        if (project?.projeto?.estado == 2) {
                          return false;
                        }
  
                        // Excluir se o tarefa esta não concluida
                        if (project?.estado != 0) {
                          return false;
                        }
  
                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }
  
                        // Se o projeto foi criado pelo usuário
                        if (project?.projeto?.criado_por == user.id) {
                          return true;
                        }
  
                        // Se o usuário é participante do projeto
                        const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }
  
                        return false;
  
                      }).length)))? formatarNumero(Number((
                        (tarefas?.filter(project => {

                          // Excluir se a missão do projeto é diferente da missão na sessão
                          if (getMissao()?.id != project?.projeto?.missao_id) {
                            return false;
                          }
    
                          // Excluir se o projeto esta arquivado
                          if (project?.projeto?.estado == 2) {
                            return false;
                          }
    
                          // Excluir se o tarefa esta não concluida
                          if (project?.estado != 0) {
                            return false;
                          }
    
                          // Se o tipo de usuário for 1, retorna true para todos os projetos
                          if (user.tipo == 1) {
                            return true;
                          }
    
                          // Se o projeto foi criado pelo usuário
                          if (project?.projeto?.criado_por == user.id) {
                            return true;
                          }
    
                          // Se o usuário é participante do projeto
                          const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                          if (participante) {
                            return true;
                          }
    
                          return false;
    
                        }).length))),0,'.',','): 0}

                  </span>
                </p>
                <p>
                  <i className="far fa-dot-circle text-danger me-2" />
                  {t('Pending Tasks')} <span className="float-end">
                    
               
                  {Number((
                      (tarefas?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project?.projeto?.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project?.projeto?.estado == 2) {
                          return false;
                        }

                        // Excluir se o tarefa esta não concluida no tempo previsto
                        if (!(project.estado == 0 && new Date(project.prazo) < new Date())) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project?.projeto?.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length)))? formatarNumero(Number((
                        (tarefas?.filter(project => {

                          // Excluir se a missão do projeto é diferente da missão na sessão
                          if (getMissao()?.id != project?.projeto?.missao_id) {
                            return false;
                          }
  
                          // Excluir se o projeto esta arquivado
                          if (project?.projeto?.estado == 2) {
                            return false;
                          }
  
                          // Excluir se o tarefa esta não concluida no tempo previsto
                          if (!(project.estado == 0 && new Date(project.prazo) < new Date())) {
                            return false;
                          }
  
                          // Se o tipo de usuário for 1, retorna true para todos os projetos
                          if (user.tipo == 1) {
                            return true;
                          }
  
                          // Se o projeto foi criado pelo usuário
                          if (project?.projeto?.criado_por == user.id) {
                            return true;
                          }
  
                          // Se o usuário é participante do projeto
                          const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                          if (participante) {
                            return true;
                          }
  
                          return false;
  
                        }).length))),0,'.',','): 0}
                    
                    </span>
                </p>               

              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-12 col-xl-3 d-flex">
          <div className="card flex-fill dash-statistics">
            <div className="card-body">
              <h5 className="card-title">{t('Tickets')}</h5>
              <div className="stats-list">
                <div className="stats-info">
                  <p>
                    {t('Progress')}
                    <strong>

                      {tickets?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        if (project.estado == 1) {
                          return false;
                        }

                        if (project.estado != 0) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length} <small>/ {tickets?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length}</small>

                    </strong>
                  </p>
                  <div className="progress">

                    <div
                      className="progress-bar bg-primary"
                      role="progressbar"
                      data-bs-toggle="tooltip"
                      title={(Math.round(((tickets?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        if (project.estado == 1) {
                          return false;
                        }

                        if (project.estado != 0) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length) / projetos?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length) * 100)) + '%'}
                      style={{
                        width: `${Math.round(((tickets?.filter(project => {

                          // Excluir se a missão do projeto é diferente da missão na sessão
                          if (getMissao()?.id != project.missao_id) {
                            return false;
                          }

                          // Excluir se o projeto esta arquivado
                          if (project.estado == 2) {
                            return false;
                          }

                          if (project.estado == 1) {
                            return false;
                          }

                          if (project.estado != 0) {
                            return false;
                          }

                          // Se o tipo de usuário for 1, retorna true para todos os projetos
                          if (user.tipo == 1) {
                            return true;
                          }

                          // Se o projeto foi criado pelo usuário
                          if (project.criado_por == user.id) {
                            return true;
                          }

                          // Se o usuário é participante do projeto
                          const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                          if (participante) {
                            return true;
                          }

                          return false;

                        }).length) / projetos?.filter(project => {

                          // Excluir se a missão do projeto é diferente da missão na sessão
                          if (getMissao()?.id != project.missao_id) {
                            return false;
                          }

                          // Excluir se o projeto esta arquivado
                          if (project.estado == 2) {
                            return false;
                          }

                          // Se o tipo de usuário for 1, retorna true para todos os projetos
                          if (user.tipo == 1) {
                            return true;
                          }

                          // Se o projeto foi criado pelo usuário
                          if (project.criado_por == user.id) {
                            return true;
                          }

                          // Se o usuário é participante do projeto
                          const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                          if (participante) {
                            return true;
                          }

                          return false;

                        }).length) * 100)}%`
                      }}

                      aria-valuenow={62}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />

                  </div>
                </div>
                <div className="stats-info">
                  <p>
                    {t('Fechados')}
                    <strong>

                      {tickets?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        if (project.estado != 1) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length} <small>/ {tickets?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length}</small>

                    </strong>
                  </p>
                  <div className="progress">

                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      data-bs-toggle="tooltip"
                      title={(Math.round(((tickets?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        if (project.estado != 1) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length) / projetos?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length) * 100)) + '%'}
                      style={{
                        width: `${Math.round(((tickets?.filter(project => {

                          // Excluir se a missão do projeto é diferente da missão na sessão
                          if (getMissao()?.id != project.missao_id) {
                            return false;
                          }

                          // Excluir se o projeto esta arquivado
                          if (project.estado == 2) {
                            return false;
                          }

                          if (project.estado != 1) {
                            return false;
                          }

                          // Se o tipo de usuário for 1, retorna true para todos os projetos
                          if (user.tipo == 1) {
                            return true;
                          }

                          // Se o projeto foi criado pelo usuário
                          if (project.criado_por == user.id) {
                            return true;
                          }

                          // Se o usuário é participante do projeto
                          const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                          if (participante) {
                            return true;
                          }

                          return false;

                        }).length) / projetos?.filter(project => {

                          // Excluir se a missão do projeto é diferente da missão na sessão
                          if (getMissao()?.id != project.missao_id) {
                            return false;
                          }

                          // Excluir se o projeto esta arquivado
                          if (project.estado == 2) {
                            return false;
                          }

                          // Se o tipo de usuário for 1, retorna true para todos os projetos
                          if (user.tipo == 1) {
                            return true;
                          }

                          // Se o projeto foi criado pelo usuário
                          if (project.criado_por == user.id) {
                            return true;
                          }

                          // Se o usuário é participante do projeto
                          const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                          if (participante) {
                            return true;
                          }

                          return false;

                        }).length) * 100)}%`
                      }}

                      aria-valuenow={62}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />


                  </div>
                </div>

                <div className="stats-info">
                  <p>
                    {t('Pending')}
                    <strong>

                      {tickets?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        if (project.estado == 1) {
                          return false;
                        }

                        if (project.estado != 0) {
                          return false;
                        }

                        if (!((new Date(project?.data_fim) < new Date()) && project?.estado != 1)) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length} <small>/ {tickets?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length}</small>

                    </strong>
                  </p>
                  <div className="progress">

                    <div
                      className="progress-bar bg-danger"
                      role="progressbar"
                      data-bs-toggle="tooltip"
                      title={(Math.round(((tickets?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        if (project.estado == 1) {
                          return false;
                        }

                        if (project.estado != 0) {
                          return false;
                        }

                        if (!((new Date(project?.data_fim) < new Date()) && project?.estado != 1)) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length) / projetos?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length) * 100)) + '%'}
                      style={{
                        width: `${Math.round(((tickets?.filter(project => {

                          // Excluir se a missão do projeto é diferente da missão na sessão
                          if (getMissao()?.id != project.missao_id) {
                            return false;
                          }

                          // Excluir se o projeto esta arquivado
                          if (project.estado == 2) {
                            return false;
                          }

                          if (project.estado == 1) {
                            return false;
                          }

                          if (project.estado != 0) {
                            return false;
                          }

                          if (!((new Date(project?.data_fim) < new Date()) && project?.estado != 1)) {
                            return false;
                          }

                          // Se o tipo de usuário for 1, retorna true para todos os projetos
                          if (user.tipo == 1) {
                            return true;
                          }

                          // Se o projeto foi criado pelo usuário
                          if (project.criado_por == user.id) {
                            return true;
                          }

                          // Se o usuário é participante do projeto
                          const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                          if (participante) {
                            return true;
                          }

                          return false;

                        }).length) / projetos?.filter(project => {

                          // Excluir se a missão do projeto é diferente da missão na sessão
                          if (getMissao()?.id != project.missao_id) {
                            return false;
                          }

                          // Excluir se o projeto esta arquivado
                          if (project.estado == 2) {
                            return false;
                          }

                          // Se o tipo de usuário for 1, retorna true para todos os projetos
                          if (user.tipo == 1) {
                            return true;
                          }

                          // Se o projeto foi criado pelo usuário
                          if (project.criado_por == user.id) {
                            return true;
                          }

                          // Se o usuário é participante do projeto
                          const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                          if (participante) {
                            return true;
                          }

                          return false;

                        }).length) * 100)}%`
                      }}

                      aria-valuenow={62}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-12 col-lg-6 col-xl-4 d-flex">
          <div className="card flex-fill">
            <div className="card-body">
              <h4 className="card-title">
              {t('Today Absent')}
                <span className="badge bg-inverse-danger ms-2">5</span>
              </h4>
              <div className="leave-info-box">
                <div className="media d-flex align-items-center">
                  <Link to="/profile" className="avatar">
                    <img alt="" src={User1} />
                  </Link>
                  <div className="media-body">
                    <div className="text-sm my-0">Diogo Luís</div>
                  </div>
                </div>
                <div className="row align-items-center mt-3">
                  <div className="col-6">
                    <h6 className="mb-0">4 Sep 2023</h6>
                    <span className="text-sm text-muted">{t('Leave Date')}</span>
                  </div>
                  <div className="col-6 text-end">
                    <span className="badge bg-inverse-danger">{t('Pending')}</span>
                  </div>
                </div>
              </div>
              <div className="leave-info-box">
                <div className="media d-flex align-items-center">
                  <Link to="/profile" className="avatar">
                    <img alt="" src={User2} />
                  </Link>
                  <div className="media-body">
                    <div className="text-sm my-0">Maria Adriana</div>
                  </div>
                </div>
                <div className="row align-items-center mt-3">
                  <div className="col-6">
                    <h6 className="mb-0">4 Sep 2023</h6>
                    <span className="text-sm text-muted">{t('Leave Date')}</span>
                  </div>
                  <div className="col-6 text-end">
                    <span className="badge bg-inverse-success">{t('Approved')}</span>
                  </div>
                </div>
              </div>
              <div className="load-more text-center">
                <Link className="text-dark" to="#">
                {t('Load More')}
                </Link>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Statistics;
