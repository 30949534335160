/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Table } from "antd";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { base_url } from "../../../base_urls";

import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";

import AuthUser from "../../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../../components/Spinner";
import VideoTutorialModalPopup from "../../../components/modelpopup/VideoTutorialModalPopup";

const Tutorial = () => {

  const [users, setUsers] = useState([]);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [video, setVideo] = useState('');
  const [videoTitulo, setVideoTitulo] = useState('');
  const [tickets, setTickets] = useState([]);
  const [ticketSelecionado, setTicketSelecionado] = useState();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getToken, user, getMissao, logout } = AuthUser();
  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);


  useEffect(() => {

    if (getToken() == undefined) {
      logout();
      //return <Redirect to="/signIn" />;
      //props.history.push('/trainning/system/signIn');
      //history.push('');
      navigate("/");
    } else {

      const fetchData = async () => {
        try {
          // Chama a função assíncrona getUtilizador()
          await getTutorial();
        } catch (error) {
          console.error('Erro ao obter utilizadores:', error);
        }
      };

      // Chama a função fetchData() para iniciar o processo
      fetchData();

    }

  }, []);




  

  const getTutorial = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/tutorial');

      setTickets(res.data.videos);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  

  const chamarModalVideo = (video, titulo) => {

    setVideo(url_root + "/storage/tutorial/" + video);
    setVideoTitulo(titulo);    

    const editButton = document.getElementById('triggerModalVideo');

    if (editButton) {
      editButton.click();
    }

  }

  const columns = [
    {
      title: t('#'),
      dataIndex: "x",
      render: (text, record) => (
        <span
        >
           <a title={record?.titulo} className="dropdown-item" href={url_root + '/storage/tutorial/' + record?.video} target="_blank" rel="noreferrer"><i className="fa fa-video"></i></a>
          
        </span>
      ),
      sorter: (a, b) => a.ticket_code.length - b.ticket_code.length,
    },
    {
      title: t('titulo'),
      dataIndex: "ticket_code",
      render: (text, record) => (
        <span
          onClick={() => chamarModalVideo(record.video,record.titulo)}
        >
          {record?.titulo}
        </span>
      ),
      sorter: (a, b) => a.ticket_code.length - b.ticket_code.length,
    },
  ];

  if (loading) return (<><Spinner></Spinner></>); else return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={t('Tutorial')}
            title="Dashboard"
          />

          <div className="row">
            <div className="col-md-12">
            

              <div className="table-responsive">
                <Table
                  className="table-striped"
                  rowKey={(record) => record.id}
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={tickets?.length > 0 ? tickets : []}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <VideoTutorialModalPopup titulo={videoTitulo} video={video} />

      <span
        data-bs-toggle="modal"
        data-bs-target="#modal_video"
        id="triggerModalVideo"
      >
      </span>

    </>
  );
};

export default Tutorial;
