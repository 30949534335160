import React from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";

import InterviewQuestionsTable from "./InterviewQuestionTable";
import AddCategoryModal from "../../../../../components/Administration/Jobs/InterviewQuestions/AddCategory";
import AddQuestionsModal from "../../../../../components/Administration/Jobs/InterviewQuestions/AddQuestions";
import EditQuestions from "../../../../../components/Administration/Jobs/InterviewQuestions/EditQuestions";
import { useTranslation } from "react-i18next";

const InterviewingQuestions = () => {

  const { t } = useTranslation();

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle={t('Interview Questions')}
            title="Dashboard"
            subtitle={t('Jobs')+' / '+t('Interview Questions')}
            modalone="#add_question"
            nameone={t('Add Questions')}
            modaltwo="#add_category"
            nametwo={t('Add Category')}
          />

          <AddQuestionsModal />
          <AddCategoryModal />
          <EditQuestions />
          <InterviewQuestionsTable />
        </div>
      </div>
    </>
  );
};

export default InterviewingQuestions;
