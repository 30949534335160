/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { Avatar_09, Avatar_10, Avatar_16 } from "../../Routes/ImagePath";
import { Link, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import AuthUser from "../AuthUser";

import { format } from 'date-fns';

const TodasAtividadesModalPopup = ({ atividades, handleNavigateAtividade }) => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getToken, getMissao, user } = AuthUser();

  useEffect(() => {

  }, [atividades]);

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event('click'));
    }
  };

  const closeModal = () => {
    const closeButton = document.getElementById('trigger_close_modal_atividades');
    if (closeButton) {
      closeButton.dispatchEvent(new Event('click'));
    }
  };

  const navegar_na_atividade = (link) => {

    const editButton = document.getElementById('trigger_close_modal_atividades');

    handleNavigateAtividade(link);

    if (editButton) {
      editButton.click();
    }

  }


  return (
    <div>
      <div id="all_activities_modal" className="modal custom-modal fade modal-lg" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t('All Activities')}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="trigger_close_modal_atividades"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">


              <div className="activity">
                <div className="activity-box">

                  <ul className="activity-list">
                    {atividades?.map((activity, index) => (
                      <li key={index}>
                        <div className="activity-user">
                          <Link
                            to={"/profile/" + activity?.user?.id}
                            title={activity?.user?.name}
                            data-bs-toggle="tooltip"
                            className="avatar"
                          >
                            <img src={url_root + "/storage/" + activity?.user?.fotografia} alt={activity?.user?.name} title={activity?.user?.name} />
                          </Link>
                        </div>
                        <div className="activity-content">
                          <div className="timeline-content">

                            <Link to={"/profile/" + activity?.user?.id} className="name">
                              {activity?.user?.name}
                            </Link>{" "}

                            {/* PROJETOS */}

                            {/* Adicionou projeto */}
                            {(activity?.tipo == 1 && activity?.accao == 1 && activity?.receptor_id == 0) ? ' adicionou novo projeto, ' : ''}

                            {/* editou projeto */}
                            {(activity?.tipo == 1 && activity?.accao == 2) ? ' editou a informação do projeto ' + activity?.mensagem + '  ' : ''}

                            {/* fechou projeto */}
                            {(activity?.tipo == 1 && activity?.accao == 9) ? ' fechou o projeto, ' + activity?.mensagem + '  ' : ''}

                            {/* abriu projeto */}
                            {(activity?.tipo == 1 && activity?.accao == 10) ? ' voltou a abrir o projeto para execução, ' + activity?.mensagem + '  ' : ''}

                            {/* prazo projeto */}
                            {(activity?.tipo == 1 && activity?.accao == 11) ? ' Alterou o prazo ( deadline ) do projeto, ' + activity?.mensagem + '  ' : ''}

                            {/* adicionou colaborador ao projeto */}
                            {
                              (activity?.tipo == 1 && activity?.accao == 1 && activity?.receptor_id != 0) &&
                              <span>
                                adicionou o colaborador <Link to={"/profile/" + activity?.receptor?.id} className="name">
                                  {activity?.receptor?.name}
                                </Link> ao projeto, {activity?.mensagem}{', '}
                              </span>
                            }


                            {/* removeu colaborador do projeto */}
                            {
                              (activity?.tipo == 1 && activity?.accao == 3 && activity?.receptor_id != 0) &&
                              <span>
                                removeu o colaborador <Link to={"/profile/" + activity?.receptor?.id} className="name">
                                  {activity?.receptor?.name}
                                </Link> do projeto, {activity?.mensagem}{', '}
                              </span>
                            }

                            {/* Aprovar, reprovar projeto */}
                            {
                              (activity?.tipo == 1 && activity?.accao == 4 && activity?.receptor_id == 0) &&
                              <span>
                                {activity?.mensagem} a execução do projeto{', '}
                              </span>
                            }

                            {/* Arquivar, desarquivar projeto */}
                            {
                              (activity?.tipo == 1 && activity?.accao == 6 && activity?.receptor_id == 0) &&
                              <span>
                                {activity?.mensagem} o projeto{', '}
                              </span>
                            }


                            {/* eliminar projeto */}
                            {
                              (activity?.tipo == 1 && activity?.accao == 3 && activity?.receptor_id == 0) &&
                              <span>
                                {'removeu o projeto ' + activity?.mensagem}{' '}
                              </span>
                            }

                            {/* TAREFAS */}

                            {/* adicionou tarefa ao projeto */}
                            {
                              (activity?.tipo == 2 && activity?.accao == 1 && activity?.receptor_id == 0) &&
                              <span>
                                adicionou a tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                  {' '}{activity?.mensagem}
                                </Link> ao projeto {', '}
                              </span>
                            }

                            {/* editou a tarefa do projeto */}
                            {
                              (activity?.tipo == 2 && activity?.accao == 2 && activity?.receptor_id == 0) &&
                              <span>
                                editou a tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                  {' '}{activity?.mensagem}
                                </Link> no projeto {', '}
                              </span>
                            }


                            {/* fechou tarefa */}
                            {(activity?.tipo == 2 && activity?.accao == 9) ? ' fechou a tarefa, ' + activity?.tarefa?.nome + ' no projeto,  ' : ''}

                            {/* abriu tarefa */}
                            {(activity?.tipo == 2 && activity?.accao == 10) ? ' voltou a abrir a tarefa ' + activity?.tarefa?.nome + ' no projeto,  ' : ''}

                            {/* prazo tarefa */}
                            {(activity?.tipo == 2 && activity?.accao == 11) ? ' Alterou o prazo ( deadline ) da tarefa ' + activity?.tarefa?.nome + ' no projeto,  ' : ''}

                            {/* eliminar tarefa */}
                            {
                              (activity?.tipo == 2 && activity?.accao == 3 && activity?.receptor_id == 0) &&
                              <span>
                                {'removeu a tarefa ' + activity?.mensagem}{' no projeto, '}
                              </span>
                            }


                            {/* adicionou colaborador na tarefa */}
                            {
                              (activity?.tipo == 2 && activity?.accao == 1 && activity?.receptor_id != 0) &&
                              <span>
                                adicionou o colaborador <Link to={"/profile/" + activity?.receptor?.id} className="name">
                                  {activity?.receptor?.name}
                                </Link> a tarefa, <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">{activity?.tarefa?.nome}</Link>{', no projeto '}
                              </span>
                            }


                            {/* removeu colaborador da tarefa */}
                            {
                              (activity?.tipo == 2 && activity?.accao == 3 && activity?.receptor_id != 0) &&
                              <span>
                                removeu o colaborador <Link to={"/profile/" + activity?.receptor?.id} className="name">
                                  {activity?.receptor?.name}
                                </Link> da tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">{activity?.tarefa?.nome}</Link>{', no projeto '}
                              </span>
                            }



                            {/* ITEMS DE TAREFA */}

                            {/* adicionou item de tarefa a tarefa */}
                            {
                              (activity?.tipo == 3 && activity?.accao == 1 && activity?.receptor_id == 0) &&
                              <span>
                                adicionou item de tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                  {' '}{activity?.item?.nome}
                                </Link> a tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                  {' '}{activity?.tarefa?.nome}
                                </Link>  no projeto,
                              </span>
                            }

                            {/* editou a tarefa do projeto */}
                            {
                              (activity?.tipo == 3 && activity?.accao == 2 && activity?.receptor_id == 0) &&
                              <span>
                                editou a informação do item de tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                  {' '}{activity?.item?.nome}
                                </Link> a tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                  {' '}{activity?.tarefa?.nome}
                                </Link>  no projeto,
                              </span>
                            }

                            {/* fechou item de tarefa */}
                            {(activity?.tipo == 3 && activity?.accao == 9) ? <span>fechou o item de tarefa, {activity?.item?.nome} na tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                              {' ' + activity?.tarefa?.nome}</Link> do projeto </span> : ''}

                            {/* abriu item de tarefa */}
                            {(activity?.tipo == 3 && activity?.accao == 10) ? <span>voltou a abrir o item de tarefa, {activity?.item?.nome} para execução na tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                              {' ' + activity?.tarefa?.nome}</Link> do projeto </span> : ''}


                            {/* eliminar item de tarefa */}
                            {
                              (activity?.tipo == 3 && activity?.accao == 3 && activity?.receptor_id == 0) &&
                              <span>
                                removeu item de tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                  {' '}{activity?.mensagem}
                                </Link> na a tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">
                                  {' '}{activity?.tarefa?.nome}
                                </Link>  do projeto,
                              </span>
                            }

                            {
                              (activity?.tipo == 6 && activity?.accao == 2 && activity?.projeto_id != 0) &&
                              <span>
                                Adicionou ficheiros ao projeto <Link to={"/project-view/" + activity?.projeto?.id}>{activity?.projeto?.nome}</Link>
                              </span>
                            }

                            {
                              (activity?.tipo == 6 && activity?.accao == 3 && activity?.projeto_id != 0) &&
                              <span>
                                Removeu ficheiros ao projeto <Link to={"/project-view/" + activity?.projeto?.id}>{activity?.projeto?.nome}</Link>
                              </span>
                            }


                            {/* TICKETS */}

                            {/* Adicionou ticket */}
                            {(activity?.tipo == 4 && activity?.accao == 1 && activity?.receptor_id == 0) ? ' adicionou novo ticket, ' + <span><Link to={"/task/ticket-view/" + activity?.tipo_id} className="name">
                              {activity?.mensagem}{', '}
                            </Link></span> : ''}

                            {/* editou ticket */}
                            {(activity?.tipo == 4 && activity?.accao == 2) ? <span> editou a informação do ticket <Link to={"/task/ticket-view/" + activity?.tipo_id} className="name">
                              {activity?.mensagem}{', '}
                            </Link></span> : ''}

                            {/* fechou ticket */}
                            {(activity?.tipo == 4 && activity?.accao == 9) ? <span> fechou o ticket, <Link to={"/task/ticket-view/" + activity?.tipo_id} className="name">
                              {activity?.mensagem}{', '}
                            </Link></span> : ''}

                            {/* abriu ticket */}
                            {(activity?.tipo == 4 && activity?.accao == 10) ? ' voltou a abrir o ticket para execução, ' + activity?.mensagem + '  ' : ''}

                            {/* prazo ticket */}
                            {(activity?.tipo == 4 && activity?.accao == 11) ? <span> Alterou o prazo ( deadline ) do ticket, <Link to={"/task/ticket-view/" + activity?.tipo_id} className="name">
                              {activity?.mensagem}{', '}
                            </Link></span> : ''}

                            {/* adicionou colaborador ao ticket */}
                            {
                              (activity?.tipo == 4 && activity?.accao == 1 && activity?.receptor_id != 0) &&
                              <span>
                                adicionou o colaborador <Link to={"/profile/" + activity?.receptor?.id} className="name">
                                  {activity?.receptor?.name}
                                </Link> ao ticket, <Link to={"/task/ticket-view/" + activity?.tipo_id} className="name">
                                  {activity?.mensagem}{', '}
                                </Link>
                              </span>
                            }


                            {/* removeu colaborador do ticket */}
                            {
                              (activity?.tipo == 4 && activity?.accao == 3 && activity?.receptor_id != 0) &&
                              <span>
                                removeu o colaborador <Link to={"/profile/" + activity?.receptor?.id} className="name">
                                  {activity?.receptor?.name}
                                </Link> do ticket, <Link to={"/task/ticket-view/" + activity?.tipo_id} className="name">
                                  {activity?.mensagem}{', '}
                                </Link>
                              </span>
                            }

                            {/* Aprovar, reprovar ticket */}
                            {
                              (activity?.tipo == 4 && activity?.accao == 4 && activity?.receptor_id == 0) &&
                              <span>
                                {activity?.mensagem} a execução do ticket <Link to={"/task/ticket-view/" + activity?.tipo_id} className="name">
                                  {activity?.mensagem}{', '}
                                </Link>
                              </span>
                            }

                            {/* Arquivar, desarquivar ticket */}
                            {
                              (activity?.tipo == 4 && activity?.accao == 6 && activity?.receptor_id == 0) &&
                              <span>
                                {activity?.mensagem} o ticket <Link to={"/task/ticket-view/" + activity?.tipo_id} className="name">
                                  {activity?.mensagem}{', '}
                                </Link>
                              </span>
                            }


                            {/* eliminar ticket */}
                            {
                              (activity?.tipo == 4 && activity?.accao == 3 && activity?.receptor_id == 0) &&
                              <span>
                                {'removeu o ticket ' + activity?.mensagem}{' '}
                              </span>
                            }


                            {/* CHATS */}

                            {/* chat no direto */}

                            {
                              (activity?.tipo == 7 && activity?.accao == 1 && activity?.chat_para == 1) &&
                              <span>
                                deixou mensagem para <Link to={"/call/chat/1/" + activity?.receptor_id}>{activity?.receptor?.name}</Link>
                              </span>
                            }

                            {/* chat na tarefa */}

                            {
                              (activity?.tipo == 7 && activity?.accao == 1 && activity?.chat_para == 2) &&
                              <span>
                                conversou no chat da tarefa <Link to={"/task/tasks/" + activity?.tarefa_id} className="name">{activity?.tarefa?.nome}
                                </Link>  do projeto <Link to={"/project-view/" + activity?.projeto?.id}>{activity?.projeto?.nome}</Link>
                              </span>
                            }

                            {/* chat no ticket */}

                            {
                              (activity?.tipo == 7 && activity?.accao == 1 && activity?.chat_para == 3) &&
                              <span>
                                conversou no chat do ticket <Link to={"/ticket-view/" + activity?.tipo_id}>{activity?.mensagem}</Link>
                              </span>
                            }


                            {/* NOME E LINK DO PROJETO, DATA E HORA */}

                            {activity?.tipo == 1 || activity?.tipo == 2 || activity?.tipo == 3 ?
                              <Link to={"/project-view/" + activity?.projeto?.id}>{activity?.projeto?.nome}</Link>
                              : ''}

                            <span className="time">{format(new Date(activity.data), 'dd/MM/yyyy') + ' ' + activity.hora}</span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>


    </div>
  );
};

export default TodasAtividadesModalPopup;
