import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PersonalInformationModelPopup from "../../../components/modelpopup/PersonalInformationModelPopup";
import { ListItem, ProjectDetails } from "./ProfileContent";
import { useTranslation } from "react-i18next";
import EditUserModal from "../../../components/Administration/Users/EditUseModal";

import Swal from "sweetalert2";

import { startOfMonth, endOfMonth, format } from "date-fns";

import InfoPessoalModal from "../../../components/modelpopup/InfoPessoalModal";
import ContactoEmergenciaModal from "../../../components/modelpopup/ContactoEmergenciaModal";
import FilhosModal from "../../../components/modelpopup/FilhosModal";

import AuthUser from "../../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../components/Spinner";
import ExperienciaModal from "../../../components/modelpopup/ExperienciaModal";
import FormacaoModal from "../../../components/modelpopup/FormacaoModal";
import ContaBancariaModal from "../../../components/modelpopup/ContaBancariaModal";
import MarcarFaltaModal from "../../../components/modelpopup/MarcarFaltaModal";
import JustificarFaltaModal from "../../../components/modelpopup/JustificarFaltaModal";
import RegistarDividaModal from "../../../components/modelpopup/RegistarDividaModal";
import EditarDividaModal from "../../../components/modelpopup/EditarDividaModal";
import MarcarFeriaModal from "../../../components/modelpopup/MarcarFeriaModal";
import AtualizarFeriaModal from "../../../components/modelpopup/AtualizarFeriaModal";
import MarcarHoraExtraModal from "../../../components/modelpopup/MarcarHoraExtraModal";
import PostoDoColaboradorModal from "../../../components/Administration/Users/PostoDoColaboradorModal";
import SubsidioDoColaboradorModal from "../../../components/Administration/Users/SubsidioDoColaboradorModal";

const ProfileTab = ({
  projetos,
  tarefas,
  utilizadores,
  missoes,
  utilizador,
  faltas,
  ferias,
  dobras,
  dividas,
  faltasAnual,
  dobrasAnual,
  feriasAnual,
  onSubmissionSuccess,
  onSubmissionUserSuccess,
  atividades,
  salariosP,
  salario_base,
  salarioP,
  agregadosP,
  dividaP,
}) => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const {
    http,
    url_root,
    user_permissoes,
    verificarPermissao,
    getToken,
    user,
    getMissao,
  } = AuthUser();
  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);
  const [imagem, setSelectedFile] = useState([]);

  const [data1, setData1] = useState(0);
  const [data2, setData2] = useState(0);

  const [ano, setAno] = useState(0);
  const [mes, setMes] = useState(1);

  const [anoSalario, setAnoSalario] = useState(0);
  const [anoSalarial, setAnoSalarial] = useState(0);
  const [mesSalario, setMesSalario] = useState(1);

  const [filho_id, setFilho] = useState(0);
  const [arquivos, setArquivos] = useState([]);
  const [faltas_colaborador, setFaltas] = useState([]);
  const [dobras_colaborador, setDobras] = useState([]);
  const [ferias_colaborador, setFerias] = useState([]);
  const [dividas_colaborador, setDividas] = useState([]);
  const [faltas_anual, setFaltasAnual] = useState([]);
  const [dobras_anual, setDobrasAnual] = useState([]);
  const [ferias_anual, setFeriasAnual] = useState([]);
  const [faltaSelecionada, setFaltaSelecionada] = useState([]);
  const [dividaSelecionada, setDividaSelecionada] = useState([]);

  const [arquivo_nome, setArquivoNome] = useState("");
  const [arquivo_id, setArquivoID] = useState(0);

  const [horas_extras_anual, setHorasExtrasAnual] = useState([]);
  const [horas_extras, setHorasExtras] = useState([]);

  const [salarios, setSalarios] = useState([]);
  const [salario, setSalario] = useState([]);
  const [agregados, setAgregados] = useState([]);
  const [dividas_salario, setDivida] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [subsidios, setSubsidios] = useState([]);

  const formatNumber = (number) => {
    return new Intl.NumberFormat("pt-PT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  useEffect(() => {
    const currentDate = new Date();

    setData1(format(startOfMonth(currentDate), "yyyy-MM-dd"));
    setData2(format(endOfMonth(currentDate), "yyyy-MM-dd"));

    setAno(format(endOfMonth(currentDate), "yyyy"));
    setAnoSalarial(format(endOfMonth(currentDate), "yyyy"));

    setAnoSalario(format(endOfMonth(currentDate), "yyyy"));

    setMesSalario(format(endOfMonth(currentDate), "MM"));

    setDobras(dobras);
    setDobrasAnual(dobrasAnual);
    setFaltas(faltas);
    setFaltasAnual(faltasAnual);
    setDividas(dividas);

    setFerias(ferias);
    setFeriasAnual(feriasAnual);

    setSalarios(salariosP);
    setSalario(salarioP);
    setAgregados(agregadosP);
    setDivida(dividaP);

    const fetchData = async () => {
      try {
        await getpostos();
        await getSubsidios();
      } catch (error) {
        console.error("Erro ao obter utilizadores:", error);
      }
    };

    fetchData();

    //console.log(faltasAnual);
  }, [utilizador]);

  const getSubsidios = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const formData = new FormData();
      formData.append("termo", '');
      formData.append("missao_id", getMissao()?.id);
      formData.append("tipo",-1);

      const response = await http.post("/subsidio/pesquisar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setSubsidios(response?.data?.subsidios);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getpostos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/postos/" + getMissao()?.id);
      setClientes(res.data.postos);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const submitReciboPDFreport = async (recibo_id) => {
    try {
      setLoading(true);

      await getReportRecibo(recibo_id);

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const getReportRecibo = async (recibo_id) => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("recibo_id", recibo_id);

      const response = await http.post("/recibo_salarial/pdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  /*
  useEffect(() => {

  }, [arquivos]);*/

  const chamarModalJustificar = (falta) => {
    const editButton = document.getElementById("ModalJustificarFalta");

    setFaltaSelecionada(falta);

    if (editButton) {
      editButton.click();
    }
  };

  const chamarModalAtualizarFeria = (falta) => {
    const editButton = document.getElementById("ModalAtualizarFeria");

    setFaltaSelecionada(falta);

    if (editButton) {
      editButton.click();
    }
  };

  const chamarModaleditarDivida = (item) => {
    const editButton = document.getElementById("triggerModalEditarDivida");

    setDividaSelecionada(item);

    if (editButton) {
      editButton.click();
    }
  };

 
  const getUtilizador = async (colaborador_id) => {
    try {
      setLoading(true); // Set loading to true when the request starts

      if (colaborador_id != 0 && colaborador_id != null) {
        const res = await http.get(
          "/utilizador/" + (colaborador_id ? colaborador_id : 0)
        );

        onSubmissionUserSuccess(res.data.utilizador, res.data.arquivos);
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const verArquivos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const formData = new FormData();
      formData.append("colaborador_id", utilizador?.id);

      const response = await http.post("/utilizador/arquivos", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setArquivos(response.data.arquivos);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const consultarFaltas = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const formData = new FormData();
      formData.append("colaborador_id", utilizador?.id);
      formData.append("data1", data1);
      formData.append("data2", data2);

      const response = await http.post(
        "/faltas/colaborador/intervalo",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setFaltas(response.data.faltas);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const consultarFaltasAnual = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const formData = new FormData();
      formData.append("colaborador_id", utilizador?.id);
      formData.append("ano", ano);

      const response = await http.post("/faltas/colaborador/anual", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log(response.data.faltas);

      setFaltasAnual(response.data.faltas);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const consultarHorasExtras = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const formData = new FormData();
      formData.append("colaborador_id", utilizador?.id);
      formData.append("data1", data1);
      formData.append("data2", data2);

      const response = await http.post(
        "/hora-extra/colaborador/intervalo",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setHorasExtras(response.data.horas_extras);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const consultarHoraExtraAnual = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const formData = new FormData();
      formData.append("colaborador_id", utilizador?.id);
      formData.append("ano", ano);

      const response = await http.post(
        "/hora-extra/colaborador/anual",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log(response.data.horas_extras_por_mes);

      setHorasExtrasAnual(response.data.horas_extras_por_mes);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const consultarDobras = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const formData = new FormData();
      formData.append("colaborador_id", utilizador?.id);
      formData.append("data1", data1);
      formData.append("data2", data2);

      const response = await http.post(
        "/dobras/colaborador/intervalo",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setDobras(response.data.dobras);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const consultarDobrasAnual = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const formData = new FormData();
      formData.append("colaborador_id", utilizador?.id);
      formData.append("ano", ano);

      const response = await http.post("/dobras/colaborador/anual", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log(response.data.dobras_por_mes);
      setDobrasAnual(response.data.dobras_por_mes);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const consultarFerias = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const formData = new FormData();
      formData.append("colaborador_id", utilizador?.id);
      formData.append("data1", data1);
      formData.append("data2", data2);

      const response = await http.post(
        "/ferias/colaborador/intervalo",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setFerias(response.data.ferias);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const consultarFeriasAnual = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const formData = new FormData();
      formData.append("colaborador_id", utilizador?.id);
      formData.append("ano", ano);

      const response = await http.post("/ferias/colaborador/anual", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log(response.data.ferias);

      setFeriasAnual(response.data.ferias);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const consultarSalario = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const formData = new FormData();
      formData.append("colaborador_id", utilizador?.id);
      formData.append("mes", mesSalario);
      formData.append("ano", anoSalario);

      const response = await http.post(
        "/salario/colaborador/pesquisa/ano-mes",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setSalario(response.data.salario);
      setAgregados(response.data.agregados);
      setDivida(response.data.dividas);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const consultarSalarioAnual = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const formData = new FormData();
      formData.append("colaborador_id", utilizador?.id);
      formData.append("ano", anoSalarial);

      const response = await http.post(
        "/salario/colaborador/pesquisa/ano",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setSalarios(response.data.salarios);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const consultarDividaAnual = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const formData = new FormData();
      formData.append("colaborador_id", utilizador?.id);
      formData.append("ano", ano);

      const response = await http.post("/dividas/colaborador/anual", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setDividas(response.data.dividas);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const submitedSuccesfully = (colaborador) => {
    onSubmissionUserSuccess(colaborador);
  };

  const submitedDividaSuccesfully = () => {
    consultarDividaAnual();
  };

  const faltaSubmitedSuccesfully = (colaborador) => {
    consultarFaltas();
    consultarFaltasAnual();
  };

  const horaE$xtraSubmitedSuccesfully = (colaborador) => {
    consultarHorasExtras();
    consultarHoraExtraAnual();
  };

  const feriaSubmitedSuccesfully = (colaborador) => {
    consultarFerias();
    consultarFeriasAnual();
  };

  const removerFalta = async (falta_id) => {
    Swal.fire({
      title: "Remover falta?",
      text: "Tem a certeza que pretende remover esta falta?",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(async function (t) {
      if (t.isConfirmed) {
        try {
          setLoading(true);

          const formData = new FormData();
          formData.append("id", falta_id);

          const response = await http.post("/faltas/eliminar", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          setLoading(false);

          consultarFaltas();
          consultarFaltasAnual();
        } catch (error) {
          setLoading(false);
          console.error(error);

          toast.error(
            "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      }
    });
  };

  const removerHoraExtra = async (falta_id) => {
    Swal.fire({
      title: "Remover hora extra?",
      text: "Tem a certeza que pretende remover esta hora extra?",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(async function (t) {
      if (t.isConfirmed) {
        try {
          setLoading(true);

          const formData = new FormData();
          formData.append("id", falta_id);

          const response = await http.post("/hora-extra/eliminar", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          setLoading(false);

          consultarHorasExtras();
          consultarHoraExtraAnual();
        } catch (error) {
          setLoading(false);
          console.error(error);

          toast.error(
            "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      }
    });
  };

  const removerFeria = async (falta_id) => {
    Swal.fire({
      title: "Remover feria?",
      text: "Tem a certeza que pretende remover esta feria?",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(async function (t) {
      if (t.isConfirmed) {
        try {
          setLoading(true);

          const formData = new FormData();
          formData.append("id", falta_id);

          const response = await http.post("/ferias/eliminar", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          setLoading(false);

          consultarFerias();
          consultarFeriasAnual();
        } catch (error) {
          setLoading(false);
          console.error(error);

          toast.error(
            "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      }
    });
  };

  const removerDivida = async (falta_id) => {
    Swal.fire({
      title: "Remover divida?",
      text: "Tem a certeza que pretende remover esta divida do colaborador?",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(async function (t) {
      if (t.isConfirmed) {
        try {
          setLoading(true);

          const formData = new FormData();
          formData.append("id", falta_id);

          const response = await http.post("/divida/eliminar", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          setLoading(false);

          consultarDividaAnual();
        } catch (error) {
          setLoading(false);
          console.error(error);

          toast.error(
            "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      }
    });
  };

  const handleFileChange = (e, filho_id) => {
    setFilho(filho_id);
    const newFile = e.target.files;

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Chamar uploadFiles() apenas se houver arquivos selecionados
    uploadFilesGeral(newFile, filho_id);
  };

  const handleFileChangeAnexo = async (e) => {
    const newFile = e.target.files;

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("missao_id", getMissao()?.id);
      formData.append("descricao", "Anexo utilizador");
      formData.append("para_id", utilizador?.id);
      formData.append("tipo", 5); //Utilizador

      // Append each file separately
      if (newFile)
        for (let i = 0; i < newFile.length; i++) {
          formData.append("files[]", newFile[i]);
        }

      const response = await http.post("/criar-arquivo", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      getUtilizador(utilizador?.id);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const uploadFilesGeral = async (ficheiros, filho_id) => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("missao_id", getMissao()?.id);
      formData.append("descricao", "Documento filho " + filho_id);
      formData.append("para_id", utilizador?.id);
      formData.append("tipo", 5); //Utilizador
      formData.append("filho_id", filho_id);

      console.log("Filho ID");
      console.log(filho_id);

      // Append each file separately
      if (ficheiros)
        for (let i = 0; i < ficheiros.length; i++) {
          formData.append("files[]", ficheiros[i]);
        }

      const response = await http.post("/criar-arquivo", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      getUtilizador(utilizador?.id);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const removerArquivo = async (arquivo_id) => {
    Swal.fire({
      title: "Remover arquivo?",
      text: "Tem a certeza que pretende remover este arquivo?",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(async function (t) {
      if (t.isConfirmed) {
        try {
          setLoading(true);

          const formData = new FormData();
          formData.append("arquivo_id", arquivo_id);

          const response = await http.post(
            "/utilizador/arquivo/eliminar",
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );

          verArquivos();
        } catch (error) {
          setLoading(false);
          console.error(error);

          toast.error(
            "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      }
    });
  };

  //Renomear

  const chamarModalRenomear = (nome, id) => {
    setArquivoNome(nome);
    setArquivoID(id);

    const editButton = document.getElementById("triggerModalRenomear");

    if (editButton) {
      editButton.click();
    }
  };

  const renomearArquivo = async () => {
    try {
      setLoading(true);

      console.log("nome", arquivo_nome);
      console.log("id", arquivo_id);

      const formData = new FormData();
      formData.append("descricao", arquivo_nome);
      formData.append("id", arquivo_id);

      const response = await http.post("/renomear-arquivo", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      verArquivos();

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="tab-content">
        <div
          id="emp_profile"
          className="pro-overview tab-pane fade show active"
        >
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Informação Pessoal
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#personal_info_modal"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <hr></hr>

                  <ul className="personal-info">
                    {utilizador?.info_pessoal
                      ?.slice(0, 1)
                      .map((item, index) => (
                        <>
                          <li>
                            <div className="title">Estado Civil</div>
                            <div className="text">
                              {item?.estado_civil == 1
                                ? "Solteiro(a)"
                                : item?.estado_civil == 2
                                ? "Casado(a)"
                                : item?.estado_civil == 3
                                ? "Divorciado(a)"
                                : "Viuvo(a)"}
                            </div>
                          </li>
                          <li>
                            <div className="title">Filhos</div>
                            <div className="text">{item?.filhos}</div>
                          </li>
                          <li>
                            <div className="title">Nacionalidade</div>
                            <div className="text">{item?.nacionalidade}</div>
                          </li>
                          <li>
                            <div className="title">Religiao</div>
                            <div className="text">{item?.religiao}</div>
                          </li>
                          <li>
                            <div className="title">Passaporte</div>
                            <div className="text">
                              {item?.passaporte} - Expira em{" "}
                              {format(
                                new Date(item?.passaporte_exp),
                                "dd/MM/yyyy"
                              )}
                            </div>
                          </li>
                          <li>
                            <div className="title">
                              Parceiro(a) empregado(a)
                            </div>
                            <div className="text">
                              {item?.parceiro_empregado == 1 ? "Sim" : "Nao"}
                            </div>
                          </li>
                        </>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    {t("Emergency Contact")}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#contacto_emergencia_modal"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>

                  <hr></hr>

                  {utilizador?.contacto_emergencia
                    ?.slice(0, 2)
                    .map((item, index) => (
                      <>
                        <h5 className="section-title">
                          Contacto Nº {index + 1}
                        </h5>
                        <ul className="personal-info">
                          <li>
                            <div className="title">Nome</div>
                            <div className="text">{item?.nome}</div>
                          </li>
                          <li>
                            <div className="title">Telefone</div>
                            <div className="text">{item?.telefone}</div>
                          </li>
                          <li>
                            <div className="title">Parentesco</div>
                            <div className="text">
                              {item?.parentesco == 1
                                ? "Conjuge"
                                : item?.parentesco == 2
                                ? "Filho(a)"
                                : item?.parentesco == 3
                                ? "Pai | Mãe"
                                : item?.parentesco == 4
                                ? "Irmão | Irmã"
                                : item?.parentesco == 5
                                ? "Primo(a)"
                                : item?.parentesco == 6
                                ? "Avó | Avô"
                                : item?.parentesco == 7
                                ? "Tio(a)"
                                : item?.parentesco == 8
                                ? "Sobrinho(a)"
                                : item?.parentesco == 9
                                ? "Cunhado(a)"
                                : "Outro"}
                            </div>
                          </li>
                        </ul>

                        <hr />
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    {t("Bank information")}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#conta_bancaria_modal"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <hr></hr>
                  <ul className="personal-info">
                    {utilizador?.contas?.slice(0, 1).map((item, index) => (
                      <>
                        <li>
                          <div className="title">Banco</div>
                          <div className="text">{item?.banco?.sigla}</div>
                        </li>
                        <li>
                          <div className="title">Nº De Conta</div>
                          <div className="text">{item?.conta}</div>
                        </li>
                        <li>
                          <div className="title">IBAN</div>
                          <div className="text">{item?.IBAN}</div>
                        </li>
                        <li>
                          <div className="title">NIB</div>
                          <div className="text">{item?.NIB}</div>
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    {t("Filhos menores")}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#filhos_modal"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>

                  <div className="table-responsive">
                    <table className="table table-nowrap">
                      <thead>
                        <tr>
                          <th>{t("Name")}</th>
                          <th>{t("Data Nascimento")}</th>
                          <th>{t("Documento")}</th>
                          <th>{t("Operação")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {utilizador?.filhos?.slice(0, 4).map((item, index) => (
                          <tr key={item?.id}>
                            <td>{item?.nome}</td>
                            <td>
                              {" "}
                              {format(new Date(item?.dataNasc), "dd/MM/yyyy")}
                            </td>
                            <td>
                              {item?.documento ? (
                                <>
                                  <span className="file-name text-ellipsis">
                                    <a
                                      className="text-primary"
                                      href={
                                        url_root +
                                        "/api/ler_anexo/" +
                                        item?.documento
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <i className="fa fa-file-pdf text-danger"></i>
                                    </a>
                                  </span>
                                </>
                              ) : (
                                <>Sem documento</>
                              )}
                            </td>
                            <td>
                              {" "}
                              <span className="btn-file">
                                <input
                                  type="file"
                                  className="upload"
                                  name="files[]"
                                  onChange={(e) =>
                                    handleFileChange(e, item?.id)
                                  }
                                  id="files_edit"
                                  multiple="multiple"
                                />
                                <i className="fa-solid fa-upload" />
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    {t("Education Informations")}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#formacao_modal"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <div className="experience-box">
                    <ul className="experience-list">
                      {utilizador?.formacao?.slice(0, 3).map((item) => (
                        <li key={item.id}>
                          <div className="experience-user">
                            <div className="before-circle" />
                          </div>
                          <div className="experience-content">
                            <div className="timeline-content">
                              <Link to="/" className="name">
                                {item.instituicao}
                              </Link>
                              <div>{item.curso}</div>
                              <span className="time">
                                {format(new Date(item?.data_ini), "dd/MM/yyyy")}{" "}
                                -{" "}
                                {format(new Date(item?.data_fim), "dd/MM/yyyy")}
                              </span>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    {t("Experience")}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#experiencias_modal"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <div className="experience-box">
                    <ul className="experience-list">
                      {utilizador?.experiencias?.slice(0, 2).map((item) => (
                        <li key={item.id}>
                          <div className="experience-user">
                            <div className="before-circle" />
                          </div>
                          <div className="experience-content">
                            <div className="timeline-content">
                              <Link to="/" className="name">
                                {item.ocupacao} - {item.empresa}
                              </Link>
                              <span className="time">
                                {format(new Date(item?.desde), "dd/MM/yyyy")} -{" "}
                                {format(new Date(item.ate), "dd/MM/yyyy")}
                              </span>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">{t("Anexos")}</h3>
                  <hr></hr>
                  <p className="" onClick={() => verArquivos()}>
                    <i className="fa-solid fa-spinner" /> Se tem anexos e não
                    estão visiveis clique aqui para atualizar
                  </p>
                  <hr></hr>
                  <div className="">
                    <span className="btn-file mb-2 mt-2">
                      <input
                        type="file"
                        className="upload"
                        name="files[]"
                        onChange={(e) => handleFileChangeAnexo(e)}
                        id="files_edit"
                        multiple="multiple"
                      />
                      <i className="fa-solid fa-upload" />
                      &nbsp;Carregar anexo
                    </span>
                    <hr></hr>
                    <ul className="files-list">
                      {arquivos?.map((arquivo, index) => (
                        <li key={index}>
                          <div className="files-cont">
                            <div className="file-type">
                              <span className="files-icon">
                                <img
                                  src={
                                    url_root +
                                    "/storage/uploads/icons/" +
                                    arquivo?.file_icon
                                  }
                                  width={20}
                                  height={20}
                                />
                              </span>
                            </div>

                            <div className="files-info">
                              <span className="file-name text-ellipsis">
                                <a
                                  className="text-primary"
                                  href={
                                    url_root +
                                    "/api/ler_anexo/" +
                                    arquivo?.ficheiro
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {arquivo?.descricao}
                                </a>
                              </span>

                              <span className="file-date">
                                {arquivo?.created_at
                                  ? new Date(
                                      arquivo?.created_at
                                    ).toLocaleDateString("pt-BR")
                                  : ""}
                              </span>
                              <div className="file-size">
                                {t("Size")}: {arquivo?.tamanho}Mb
                              </div>
                            </div>
                            <ul className="files-action">
                              <li className="dropdown dropdown-action">
                                <Link
                                  to="#"
                                  className="dropdown-toggle btn btn-link"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="material-icons">more_horiz</i>
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <span>
                                    <a
                                      className="text-primary"
                                      href={
                                        url_root +
                                        "/api/ler_anexo/" +
                                        arquivo?.ficheiro
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {t("Download")}
                                    </a>
                                  </span>
                                  <span
                                    className="dropdown-item"
                                    onClick={() =>
                                      chamarModalRenomear(
                                        arquivo?.descricao,
                                        arquivo?.id
                                      )
                                    }
                                  >
                                    {t("Rename")}
                                  </span>
                                  <span
                                    className="dropdown-item"
                                    onClick={() => removerArquivo(arquivo?.id)}
                                  >
                                    {t("Delete")}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="emp_faltas" className="pro-overview tab-pane fade">
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Faltas Do Periodo
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#marcar_falta_modal"
                    >
                      <i className="fa fa-calendar" />
                    </Link>
                  </h3>
                  <hr></hr>

                  <form>
                    <div className="row">
                      <div className="col-sm-5">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <div>
                            <input
                              className="form-control"
                              type="date"
                              value={data1}
                              onChange={(e) => setData1(e.target.value)}
                            />
                            <label className="focus-label">{t("Desde")}</label>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-5">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <input
                            className="form-control"
                            type="date"
                            value={data2}
                            onChange={(e) => setData2(e.target.value)}
                          />
                          <label className="focus-label">
                            {t("Ate")} <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>

                      <div className="col-sm-2">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <a
                            className="btn btn-primary"
                            onClick={consultarFaltas}
                          >
                            {loading ? (
                              "Carregando"
                            ) : (
                              <i className="fa fa-search"></i>
                            )}
                          </a>
                        </div>
                      </div>

                      <hr className=""></hr>
                    </div>
                  </form>

                  <ul className="personal-info">
                    {faltas_colaborador?.slice(0, 356).map((item, index) => (
                      <>
                        <li>
                          <div className="title">
                            <i className="fa fa-calendar"></i>{" "}
                            {format(new Date(item?.data), "dd/MM/yyyy")}
                          </div>

                          {item?.justificada == 0 ? (
                            <>
                              <i
                                className="fa fa-pencil text-primary rounded bg-light p-1"
                                onClick={() => chamarModalJustificar(item)}
                              ></i>
                              &nbsp;
                              <i
                                className="fa fa-trash text-danger rounded bg-light p-1"
                                onClick={() => removerFalta(item?.id)}
                              ></i>
                              &nbsp;
                              <div className="text text-danger">
                                Injustificada
                              </div>
                            </>
                          ) : (
                            <>
                              <i
                                className="fa fa-pencil text-primary rounded bg-light p-1"
                                onClick={() => chamarModalJustificar(item)}
                              ></i>
                              &nbsp;
                              <i
                                className="fa fa-trash text-danger rounded bg-light p-1"
                                onClick={() => removerFalta(item?.id)}
                              ></i>
                              &nbsp;
                              <div className="text text-success">
                                Justificada
                                {item?.justificada == 1 ? (
                                  <>
                                    <span className="file-name text-ellipsis">
                                      &nbsp;
                                      <a
                                        className="text-primary"
                                        href={
                                          url_root +
                                          "/api/ler_anexo/" +
                                          item?.documento
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <i className="fa fa-file-pdf text-danger"></i>
                                      </a>
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          )}
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">{t("Faltas Mensal")}</h3>

                  <hr></hr>

                  <form>
                    <div className="row">
                      <div className="col-sm-5">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <div>
                            <input
                              className="form-control"
                              type="number"
                              value={ano}
                              onChange={(e) => setAno(e.target.value)}
                            />
                            <label className="focus-label">{t("Ano")}</label>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-2">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <a
                            className="btn btn-primary"
                            onClick={consultarFaltasAnual}
                          >
                            {loading ? (
                              "Carregando"
                            ) : (
                              <i className="fa fa-search"></i>
                            )}
                          </a>
                        </div>
                      </div>

                      <hr className=""></hr>
                    </div>
                  </form>

                  <table className="table table-responsive">
                    <thead>
                      <tr>
                        <th>Mês</th>
                        <th>Injustificadas</th>
                        <th>Justificadas</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(faltas_anual).map(([mes, dados]) => (
                        <tr key={mes}>
                          <td>{mes}</td>
                          <td>{dados.faltas_injustificadas}</td>
                          <td>{dados.faltas_justificadas}</td>
                          <td>{dados.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="emp_dobras" className="pro-overview tab-pane fade">
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">Dobras Do Periodo</h3>
                  <hr></hr>

                  <form>
                    <div className="row">
                      <div className="col-sm-5">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <div>
                            <input
                              className="form-control"
                              type="date"
                              value={data1}
                              onChange={(e) => setData1(e.target.value)}
                            />
                            <label className="focus-label">{t("Desde")}</label>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-5">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <input
                            className="form-control"
                            type="date"
                            value={data2}
                            onChange={(e) => setData2(e.target.value)}
                          />
                          <label className="focus-label">
                            {t("Ate")} <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>

                      <div className="col-sm-2">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <a
                            className="btn btn-primary"
                            onClick={consultarDobras}
                          >
                            {loading ? (
                              "Carregando"
                            ) : (
                              <i className="fa fa-search"></i>
                            )}
                          </a>
                        </div>
                      </div>

                      <hr className=""></hr>
                    </div>
                  </form>

                  <ul className="personal-info">
                    {dobras_colaborador?.slice(0, 356).map((item, index) => (
                      <>
                        <li>
                          <div className="title">
                            <i className="fa fa-calendar"></i>{" "}
                            {format(new Date(item?.data), "dd/MM/yyyy")}
                          </div>
                          &nbsp;
                          <div className="text text-success">
                            {formatNumber(item?.valor_dobra)}{" "}
                            <Link
                              to={"/efetividade/esacala/" + item?.escala_id}
                            >
                              <i className="fa fa-eyes"></i>
                              {" Ver Efetividade"}
                            </Link>
                          </div>
                          &nbsp;
                          <div className="text text-danger">
                            <Link to={"/profile/" + item?.colaborador?.id}>
                              {item?.colaborador?.name}
                            </Link>
                          </div>
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">{t("Dobras Mensal")}</h3>

                  <hr></hr>

                  <form>
                    <div className="row">
                      <div className="col-sm-5">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <div>
                            <input
                              className="form-control"
                              type="number"
                              value={ano}
                              onChange={(e) => setAno(e.target.value)}
                            />
                            <label className="focus-label">{t("Ano")}</label>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-2">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <a
                            className="btn btn-primary"
                            onClick={consultarDobrasAnual}
                          >
                            {loading ? (
                              "Carregando"
                            ) : (
                              <i className="fa fa-search"></i>
                            )}
                          </a>
                        </div>
                      </div>

                      <hr className=""></hr>
                    </div>
                  </form>

                  <table className="table table-responsive">
                    <thead>
                      <tr>
                        <th>Mês</th>
                        <th>Qtd</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(dobras_anual).map(
                        ([mes, valores], index) => (
                          <tr key={index}>
                            <td>{mes}</td>
                            <td>{valores.quantidade_dobras}</td>
                            <td>{formatNumber(valores.valor_total_dobras)}</td>
                          </tr>
                        )
                      )}

                      <tr key="total">
                        <td>TOTAL</td>
                        <td>
                          {Object.values(dobras_anual).reduce(
                            (acc, cur) => acc + cur.quantidade_dobras,
                            0
                          )}
                        </td>
                        <td>
                          {formatNumber(
                            Object.values(dobras_anual).reduce(
                              (acc, cur) => acc + cur.valor_total_dobras,
                              0
                            )
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="emp_horas_extra" className="pro-overview tab-pane fade">
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Horas Extras Do Periodo
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#marcar_hora_extra_modal"
                    >
                      <i className="fa fa-clock" />
                    </Link>
                  </h3>
                  <hr></hr>

                  <form>
                    <div className="row">
                      <div className="col-sm-5">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <div>
                            <input
                              className="form-control"
                              type="date"
                              value={data1}
                              onChange={(e) => setData1(e.target.value)}
                            />
                            <label className="focus-label">{t("Desde")}</label>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-5">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <input
                            className="form-control"
                            type="date"
                            value={data2}
                            onChange={(e) => setData2(e.target.value)}
                          />
                          <label className="focus-label">
                            {t("Ate")} <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>

                      <div className="col-sm-2">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <a
                            className="btn btn-primary"
                            onClick={consultarHorasExtras}
                          >
                            {loading ? (
                              "Carregando"
                            ) : (
                              <i className="fa fa-search"></i>
                            )}
                          </a>
                        </div>
                      </div>

                      <hr className=""></hr>
                    </div>
                  </form>

                  <ul className="personal-info">
                    {horas_extras?.slice(0, 356).map((item, index) => (
                      <>
                        <li>
                          <div className="title">
                            <i className="fa fa-calendar"></i>{" "}
                            {format(new Date(item?.data), "dd/MM/yyyy")}
                          </div>
                          &nbsp;
                          <i
                            className="fa fa-trash text-danger rounded bg-light p-1"
                            onClick={() => removerHoraExtra(item?.id)}
                          ></i>
                          &nbsp;
                          <div className="title">
                            <i className="fa fa-clock text-success"></i>{" "}
                            {item?.hora1}
                          </div>
                          &nbsp;
                          <div className="title">
                            <i className="fa fa-clock text-danger"></i>{" "}
                            {item?.hora2}
                          </div>
                          &nbsp;
                          <div className="title">
                            <i className="fa fa-clock"></i>{" "}
                            {formatNumber(item?.hora_total)}
                          </div>
                          &nbsp;
                          <div className="text text-success">
                            Kz {formatNumber(item?.valor)}
                          </div>
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">{t("Horas Extras Mensal")}</h3>

                  <hr></hr>

                  <form>
                    <div className="row">
                      <div className="col-sm-5">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <div>
                            <input
                              className="form-control"
                              type="number"
                              value={ano}
                              onChange={(e) => setAno(e.target.value)}
                            />
                            <label className="focus-label">{t("Ano")}</label>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-2">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <a
                            className="btn btn-primary"
                            onClick={consultarHoraExtraAnual}
                          >
                            {loading ? (
                              "Carregando"
                            ) : (
                              <i className="fa fa-search"></i>
                            )}
                          </a>
                        </div>
                      </div>

                      <hr className=""></hr>
                    </div>
                  </form>

                  <table className="table table-responsive">
                    <thead>
                      <tr>
                        <th>Mês</th>
                        <th>Qtd Horas</th>
                        <th>Valor Acumulado</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(horas_extras_anual).map(
                        ([mes, valores], index) => (
                          <tr key={index}>
                            <td>{mes}</td>
                            <td>{valores.quantidade_horas_extras}</td>
                            <td>{formatNumber(valores.valor_total_extras)}</td>
                          </tr>
                        )
                      )}

                      <tr key="total">
                        <td>TOTAL</td>
                        <td>
                          {Object.values(horas_extras_anual).reduce(
                            (acc, cur) => acc + cur.quantidade_horas_extras,
                            0
                          )}
                        </td>
                        <td>
                          {formatNumber(
                            Object.values(horas_extras_anual).reduce(
                              (acc, cur) => acc + cur.valor_total_extras,
                              0
                            )
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="emp_ferias" className="pro-overview tab-pane fade">
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Ferias do periodo
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#marcar_feria_modal"
                    >
                      <i className="fa fa-calendar" />
                    </Link>
                  </h3>
                  <hr></hr>

                  <form>
                    <div className="row">
                      <div className="col-sm-5">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <div>
                            <input
                              className="form-control"
                              type="date"
                              value={data1}
                              onChange={(e) => setData1(e.target.value)}
                            />
                            <label className="focus-label">{t("Desde")}</label>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-5">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <input
                            className="form-control"
                            type="date"
                            value={data2}
                            onChange={(e) => setData2(e.target.value)}
                          />
                          <label className="focus-label">
                            {t("Ate")} <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>

                      <div className="col-sm-2">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <a
                            className="btn btn-primary"
                            onClick={consultarFerias}
                          >
                            {loading ? (
                              "Carregando"
                            ) : (
                              <i className="fa fa-search"></i>
                            )}
                          </a>
                        </div>
                      </div>

                      <hr className=""></hr>
                    </div>
                  </form>

                  <ul className="personal-info">
                    {ferias_colaborador?.slice(0, 356).map((item, index) => (
                      <>
                        <li>
                          <div className="title">
                            <i className="fa fa-calendar"></i>{" "}
                            {format(new Date(item?.data1), "dd/MM/yyyy")}
                          </div>

                          <div className="title">
                            <i className="fa fa-calendar"></i>{" "}
                            {format(new Date(item?.data2), "dd/MM/yyyy")}
                          </div>

                          {item?.saiu == 0 ? (
                            <>
                              <i
                                className="fa fa-pencil text-primary rounded bg-light p-1"
                                onClick={() => chamarModalAtualizarFeria(item)}
                              ></i>
                              &nbsp;
                              <i
                                className="fa fa-trash text-danger rounded bg-light p-1"
                                onClick={() => removerFeria(item?.id)}
                              ></i>
                              &nbsp;
                              <div className="text text-danger">
                                Planificada
                              </div>
                            </>
                          ) : (
                            <>
                              <i
                                className="fa fa-pencil text-primary rounded bg-light p-1"
                                onClick={() => chamarModalAtualizarFeria(item)}
                              ></i>
                              &nbsp;
                              <i
                                className="fa fa-trash text-danger rounded bg-light p-1"
                                onClick={() => removerFeria(item?.id)}
                              ></i>
                              &nbsp;
                              <div className="text text-success">
                                Gozada
                                {item?.saiu == 1 ? (
                                  <>
                                    <span className="file-name text-ellipsis">
                                      &nbsp;
                                      <a
                                        className="text-primary"
                                        href={
                                          url_root +
                                          "/api/ler_anexo/" +
                                          item?.documento
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <i className="fa fa-file-pdf text-danger"></i>
                                      </a>
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          )}
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">{t("Ferias Mensal")}</h3>

                  <hr></hr>

                  <form>
                    <div className="row">
                      <div className="col-sm-5">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <div>
                            <input
                              className="form-control"
                              type="number"
                              value={ano}
                              onChange={(e) => setAno(e.target.value)}
                            />
                            <label className="focus-label">{t("Ano")}</label>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-2">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <a
                            className="btn btn-primary"
                            onClick={consultarFeriasAnual}
                          >
                            {loading ? (
                              "Carregando"
                            ) : (
                              <i className="fa fa-search"></i>
                            )}
                          </a>
                        </div>
                      </div>

                      <hr className=""></hr>
                    </div>
                  </form>

                  <table className="table table-responsive">
                    <thead>
                      <tr>
                        <th>Mês</th>
                        <th>Planificadas</th>
                        <th>Gozadas</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(ferias_anual).map(([mes, dados]) => (
                        <tr key={mes}>
                          <td>{mes}</td>
                          <td>{dados.planificadas}</td>
                          <td>{dados.gozadas}</td>
                          <td>{dados.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="emp_salarios" className="pro-overview tab-pane fade">
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Ultimo Salário Recebido
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#marcar_falta_modal"
                    >
                      <i className="fa fa-calendar" />
                    </Link>
                  </h3>
                  <hr></hr>

                  <form>
                    <div className="row">
                      <div className="col-sm-5">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <div>
                            <input
                              className="form-control"
                              type="number"
                              value={anoSalario}
                              onChange={(e) => setAnoSalario(e.target.value)}
                            />
                            <label className="focus-label">{t("Ano")}</label>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-5">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <div>
                            <label className="focus-label">{t("Mês")}</label>

                            <select
                              onChange={(e) => setMesSalario(e.target.value)}
                              value={mesSalario}
                              className="form-control select"
                            >
                              <option value="1">Janeiro</option>
                              <option value="2">Fevereiro</option>
                              <option value="3">Março</option>
                              <option value="4">Abril</option>
                              <option value="5">Maio</option>
                              <option value="6">Junho</option>
                              <option value="7">Julho</option>
                              <option value="8">Agosto</option>
                              <option value="9">Setembro</option>
                              <option value="10">Outubro</option>
                              <option value="11">Novembro</option>
                              <option value="12">Dezembro</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-2">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <a
                            className="btn btn-primary"
                            onClick={consultarSalario}
                          >
                            {loading ? (
                              "Carregando"
                            ) : (
                              <i className="fa fa-search"></i>
                            )}
                          </a>
                        </div>
                      </div>

                      <hr className=""></hr>
                    </div>
                  </form>

                  {salario ? (
                    <div className="row">
                      <div className="col-sm-12">
                        <div>
                          <>
                            {meses[salario?.mes - 1]} - {salario?.ano}{" "}
                            {salario?.pago != 1 ? (
                              <>
                                <span className="btn btn-white btn-sm btn-rounded">
                                  <i className="far fa-dot-circle text-danger" />{" "}
                                  {t("Not Paid")}
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="btn btn-white btn-sm btn-rounded">
                                  <i className="far fa-dot-circle text-success" />{" "}
                                  {t("Paid")}
                                </span>
                              </>
                            )}
                            <span
                              className="text-white btn btn-danger"
                              onClick={() => submitReciboPDFreport(salario?.id)}
                            >
                              <i className="fa fa-file-pdf"></i>&nbsp;Recibo
                            </span>
                            <hr></hr>
                          </>

                          <h4 className="m-b-10">
                            <strong>{t("Earnings")}</strong>
                          </h4>
                          <table className="table table-bordered">
                            <tbody>
                              <tr>
                                <td>
                                  <strong>{t("Basic Salary")}</strong>
                                  <span className="float-end">
                                    Kz {formatNumber(salario?.salario_base)}
                                  </span>
                                </td>
                              </tr>

                              {agregados
                                ?.filter((agregado) => agregado.tipo == 1)
                                .map((item, index) =>
                                  item?.valor > 0 ? (
                                    <>
                                      <tr>
                                        <td>
                                          <strong>
                                            {item?.designacao}
                                            {item?.natureza == 2 ? (
                                              <>[{item?.referencia}%]</>
                                            ) : (
                                              <></>
                                            )}
                                          </strong>{" "}
                                          <span className="float-end">
                                            Kz {formatNumber(item?.valor)}
                                          </span>
                                        </td>
                                      </tr>
                                    </>
                                  ) : (
                                    <></>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div>
                          <h4 className="m-b-10">
                            <strong>{t("Deductions")}</strong>
                          </h4>
                          <table className="table table-bordered">
                            <tbody>
                              {salario?.INSS > 0 ? (
                                <>
                                  <tr>
                                    <td>
                                      <strong>{t("INSS")}</strong>
                                      <span className="float-end">
                                        Kz {formatNumber(salario?.INSS)}
                                      </span>
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <></>
                              )}

                              {salario?.IRT > 0 ? (
                                <>
                                  <tr>
                                    <td>
                                      <strong>{t("IRT")}</strong>
                                      <span className="float-end">
                                        Kz {formatNumber(salario?.IRT)}
                                      </span>
                                    </td>
                                  </tr>{" "}
                                </>
                              ) : (
                                <></>
                              )}

                              {agregados
                                ?.filter((agregado) => agregado.tipo == 2)
                                .map((item, index) =>
                                  item?.valor > 0 ? (
                                    <>
                                      <tr>
                                        <td>
                                          <strong>
                                            {item?.designacao}
                                            {item?.natureza == 2 ? (
                                              <>[{item?.referencia}%]</>
                                            ) : (
                                              <></>
                                            )}
                                          </strong>{" "}
                                          <span className="float-end">
                                            Kz {formatNumber(item?.valor)}
                                          </span>
                                        </td>
                                      </tr>
                                    </>
                                  ) : (
                                    <></>
                                  )
                                )}

                              {dividas_salario.map((item, index) =>
                                item?.valor > 0 ? (
                                  <>
                                    <tr>
                                      <td className="text-danger">
                                        <strong>{item?.designacao}</strong>{" "}
                                        <span className="float-end">
                                          Kz {formatNumber(item?.valor)}
                                        </span>
                                      </td>
                                    </tr>
                                  </>
                                ) : (
                                  <></>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div>
                          <table className="table table-bordered">
                            <tbody>
                              <tr>
                                <td>
                                  <strong className="text-primary">
                                    {t("Salario liquido")}
                                  </strong>
                                  <span className="float-end text-primary">
                                    <strong>
                                      Kz{" "}
                                      {formatNumber(salario?.salario_iliquido)}{" "}
                                    </strong>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">{t("Salários")}</h3>

                  <hr></hr>

                  <form>
                    <div className="row">
                      <div className="col-sm-5">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <div>
                            <input
                              className="form-control"
                              type="number"
                              value={anoSalarial}
                              onChange={(e) => setAnoSalarial(e.target.value)}
                            />
                            <label className="focus-label">{t("Ano")}</label>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-2">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <a
                            className="btn btn-primary"
                            onClick={consultarSalarioAnual}
                          >
                            {loading ? (
                              "Carregando"
                            ) : (
                              <i className="fa fa-search"></i>
                            )}
                          </a>
                        </div>
                      </div>

                      <hr className=""></hr>
                    </div>
                  </form>

                  <table className="table table-responsive">
                    <thead>
                      <tr>
                        <th>Mês</th>
                        <th>Valor</th>
                        <th>Detalhes</th>
                        <th>Recibo</th>
                        <th>Pago</th>
                      </tr>
                    </thead>
                    <tbody>
                      {salarios?.slice(0, 356).map((item, index) => (
                        <tr key={index}>
                          <td>{meses[item?.mes - 1]}</td>
                          <td
                            className={
                              item?.pago == 1 ? "text-success" : "text-danger"
                            }
                          >
                            {formatNumber(item?.salario_iliquido)}
                          </td>
                          <td>
                            {" "}
                            <Link
                              className="dropdown-item"
                              to={"/salary-view/" + item?.id}
                            >
                              <i className="la la-money m-r-5" />{" "}
                              {t("Ver Detalhes")}
                            </Link>
                          </td>
                          <td>
                            {" "}
                            <span
                              className="text-white btn btn-danger"
                              onClick={() => submitReciboPDFreport(item?.id)}
                            >
                              <i className="fa fa-file-pdf"></i>&nbsp;Recibo
                            </span>
                          </td>
                          <td>
                            {item?.pago != 1 ? (
                              <>
                                <span className="btn btn-white btn-sm btn-rounded">
                                  <i className="far fa-dot-circle text-danger" />{" "}
                                  {t("Not Paid")}
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="btn btn-white btn-sm btn-rounded">
                                  <i className="far fa-dot-circle text-success" />{" "}
                                  {t("Paid")}
                                </span>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="emp_dividas" className="pro-overview tab-pane fade">
          <div className="row">
            <div className="col-md-12 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    {t("Dividas")}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#registar_divida_modal"
                    >
                      <i className="fa fa-plus" />
                    </Link>
                  </h3>

                  <hr></hr>

                  <form>
                    <div className="row">
                      <div className="col-sm-5">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <div>
                            <input
                              className="form-control"
                              type="number"
                              value={ano}
                              onChange={(e) => setAno(e.target.value)}
                            />
                            <label className="focus-label">{t("Ano")}</label>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-2">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <a
                            className="btn btn-primary"
                            onClick={consultarDividaAnual}
                          >
                            {loading ? (
                              "Carregando"
                            ) : (
                              <i className="fa fa-search"></i>
                            )}
                          </a>
                        </div>
                      </div>

                      <hr className=""></hr>
                    </div>
                  </form>

                  <table className="table table-responsive">
                    <thead>
                      <tr>
                        <th>Quando</th>
                        <th>Valor (Kz)</th>
                        <th>Mes A Pagar</th>
                        <th>Ano A Pagar</th>
                        <th>Qtd Meses</th>
                        <th>Valor Parcelar</th>
                        <th>
                          <i className="fa fa-cogs"></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dividas_colaborador?.slice(0, 356).map((item, index) => (
                        <tr key={index} title={item?.descricao}>
                          <td> {format(new Date(item?.data), "dd/MM/yyyy")}</td>
                          <td>{formatNumber(item?.valor)}</td>
                          <td>{meses[item?.mes_a_pagar - 1]}</td>{" "}
                          {/* Nome do mês */}
                          <td>{item?.ano_a_pagar}</td>
                          <td>{item?.qtd_meses}</td>
                          <td>{formatNumber(item?.valor_parcelar)}</td>
                          <td>
                            <i className="fa fa-info text-secondary rounded bg-light p-1"></i>
                            &nbsp;
                            <i
                              className="fa fa-pencil text-primary rounded bg-light p-1"
                              onClick={() => chamarModaleditarDivida(item)}
                            ></i>
                            &nbsp;
                            <i
                              className="fa fa-trash text-danger rounded bg-light p-1"
                              onClick={() => removerDivida(item?.id)}
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <PostoDoColaboradorModal clientes={clientes} colaborador={utilizador} />

        <SubsidioDoColaboradorModal
          subsidios={subsidios}
          colaborador={utilizador}
        />

        <ProjectDetails
          projetos={projetos}
          tarefas={tarefas}
          utilizadores={utilizadores}
          missoes={missoes}
          utilizador={utilizador}
          atividades={atividades}
        />
        {/* Bank Statutory Tab */}

        {/* Bank Statutory Tab */}
        {/*  Bank Tab */}
      </div>
      {/* Model Popup*/}
      <PersonalInformationModelPopup />

      <InfoPessoalModal
        colaborador_id={utilizador?.id}
        onSubmitSuccess={submitedSuccesfully}
      />

      <ContactoEmergenciaModal
        colaborador_id={utilizador?.id}
        onSubmitSuccess={submitedSuccesfully}
      />

      <FilhosModal
        colaborador_id={utilizador?.id}
        onSubmitSuccess={submitedSuccesfully}
      />

      <ExperienciaModal
        colaborador_id={utilizador?.id}
        onSubmitSuccess={submitedSuccesfully}
      />

      <ContaBancariaModal
        colaborador_id={utilizador?.id}
        onSubmitSuccess={submitedSuccesfully}
      />

      <FormacaoModal
        colaborador_id={utilizador?.id}
        onSubmitSuccess={submitedSuccesfully}
      />

      <RegistarDividaModal
        colaborador_id={utilizador?.id}
        onSubmitSuccess={submitedSuccesfully}
        onSubmitDividaSuccess={submitedDividaSuccesfully}
      />

      <EditarDividaModal
        colaborador_id={utilizador?.id}
        divida={dividaSelecionada}
        onSubmitSuccess={submitedSuccesfully}
        onSubmitDividaSuccess={submitedDividaSuccesfully}
      />

      <MarcarFaltaModal
        colaborador={utilizador}
        onSubmitSuccess={faltaSubmitedSuccesfully}
      />

      <MarcarHoraExtraModal
        colaborador={utilizador}
        onSubmitSuccessHoraExtra={faltaSubmitedSuccesfully}
        salario_base={salario_base}
      />

      <MarcarFeriaModal
        colaborador={utilizador}
        onSubmitSuccess={feriaSubmitedSuccesfully}
      />

      <JustificarFaltaModal
        colaborador={utilizador}
        onSubmitSuccess={faltaSubmitedSuccesfully}
        falta={faltaSelecionada}
      />

      <AtualizarFeriaModal
        colaborador={utilizador}
        onSubmitSuccess={faltaSubmitedSuccesfully}
        feria={faltaSelecionada}
      />

      <span
        data-bs-toggle="modal"
        data-bs-target="#add_subsidio_colaborador_modal"
        id="chamar_modal_subsidios_colaborador"
      ></span>

      <span
        data-bs-toggle="modal"
        data-bs-target="#add_posto_colaborador_modal"
        id="chamar_modal_postos_colaborador"
      ></span>

      <span
        data-bs-toggle="modal"
        data-bs-target="#editar_divida_modal"
        id="triggerModalEditarDivida"
      ></span>

      <span
        data-bs-toggle="modal"
        data-bs-target="#ModalRenomear"
        id="triggerModalRenomear"
      ></span>

      <span
        data-bs-toggle="modal"
        data-bs-target="#justificar_falta_modal"
        id="ModalJustificarFalta"
      ></span>

      <span
        data-bs-toggle="modal"
        data-bs-target="#atualizar_feria_modal"
        id="ModalAtualizarFeria"
      ></span>

      <div id="ModalRenomear" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{t("Name")}</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="closeButton10"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="input-block mb-3">
                  <label className="col-form-label">{t("Type a name")}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={arquivo_nome}
                    onChange={(e) => setArquivoNome(e.target.value)}
                  />
                </div>

                <div className="submit-section m-t-20 text-center">
                  <a
                    className="btn btn-primary submit-btn"
                    onClick={() => renomearArquivo()}
                  >
                    {t("Save")}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

      {loading ? (
        <>
          <Spinner></Spinner>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ProfileTab;
