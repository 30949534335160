/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Attachment,
  User1,
  User2,
  User3,
  User4,
  User5,
  User6,
} from "../../../../Routes/ImagePath";
import ProjectModelPopup from "../../../../components/modelpopup/ProjectModelPopup";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../../../components/Spinner";

import Swal from "sweetalert2";

import { format } from 'date-fns';
import TaskModal from "../../../../components/modelpopup/TaskModal";
import TaskboardModal from "../../../../components/modelpopup/TaskboardModal";
import ColaboradorTarefaModalPopup from "../../../../components/modelpopup/ColaboradorTarefaModalPopup";
import TarefasModalPopup from "../../../../components/modelpopup/TarefasModalPopup";

const Tasks = () => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  let { taskId } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, user, getMissao } = AuthUser();
  const [projectId, setProjetoID] = useState();
  const [mensagem_tipo, setTipoMensagem] = useState(1);
  const [mensagem, setMensagem] = useState('');
  const [projeto, setProjeto] = useState([]);
  const [conversa, setConversa] = useState([]);
  const [tarefa, setTarefa] = useState([]);
  const [itemSelecionado, setItemSelecionado] = useState([]);
  const [quadroSelecionado, setQuadroSelecionado] = useState([]);
  const [tarefaSelecionada, setTarefaSelecionada] = useState([]);
  const [utilizadores, setUtilizadores] = useState([]);
  const [selectedColor, setSelectedColor] = useState('primary');
  const [selectedPrioridade, setSelectedPrioridade] = useState(1);

  const [missoes, setMissoes] = useState([]);

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const getProjeto = async (projeto_id) => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/projeto/' + projeto_id);

      setProjeto(res.data.projeto);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getTarefa = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/tarefa/' + taskId);

      setTarefa(res.data.tarefa);

      setProjetoID(res.data.tarefa.projeto_id);

      getProjeto(res.data.tarefa.projeto_id);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getConversa = async () => {
    try {

      const formData = new FormData();
      formData.append('tarefa_id', taskId);

      const response = await http.post('/chat-tarefa', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      console.log(response);

      setConversa(response.data.chat);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const fetchMensagens = async () => {
    try {

      const formData = new FormData();
      formData.append('tarefa_id', taskId);

      const response = await http.post('/chat-tarefa', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      console.log(response);

      setConversa(response.data.chat);

    } catch (error) {
      console.error('Erro ao buscar mensagens:', error);
    }
  };

  const getMissoes = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/missoes_ativas');

      setMissoes(res.data.missoes);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getUtilizadores = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/utilizadores/ativados/'+getMissao()?.id);

      setUtilizadores(res.data.utilizadores);

      console.log('Utilizadores : ');
      console.log(res.data.utilizadores);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleNavegarParaTarefa = (tarefa_id) => {

    navigate('/task/tasks/' + tarefa_id);

  };


  const encontrarUtilizadorPorId = (id) => {

    // Encontre o objeto com a ID correspondente
    const userEncontrado = utilizadores.find((lista) => lista.id == id);

    // Verifique se o objeto foi encontrado
    if (userEncontrado != undefined) {
      return userEncontrado;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }

  };



  /* Default from template */

  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });
  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {

    const fetchData = async () => {
      try {

        // Chama a função assíncrona getUtilizador()
        await getTarefa();
        await getConversa();
        await getUtilizadores();
        await getMissoes();

      } catch (error) {
        console.error('Erro ao obter utilizadores:', error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();

    /* From template */
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };

  }, [taskId]);

  // useEffect para buscar as mensagens inicialmente e então configurar a atualização periódica
  useEffect(() => {
    fetchMensagens(); // Busca as mensagens inicialmente

    // Configura a atualização periódica a cada 5 segundos (5000ms)
    const intervalId = setInterval(() => {
      fetchMensagens(); // Busca as mensagens periodicamente
    }, 5000); // Ajuste o intervalo conforme necessário

    // Retorna uma função de limpeza para limpar o intervalo quando o componente é desmontado ou o useEffect é re-executado
    return () => clearInterval(intervalId);
  }, [taskId]); // O array vazio indica que o useEffect só será executado uma vez após a montagem inicial do componente


  const handleSubmissionSuccess = (tarefaItem) => {

    console.log('received : ');
    console.log(tarefaItem);

    //setProjectID(projectIdSubmited?.id);

    setTarefa(tarefaItem);

  };

  const handleProjectSubmissionSuccess = (projeto) => {

    console.log('received : ');
    console.log(projeto);

    setProjeto(projeto);

  };

  const handleAddColaboradorSubmissionSuccess = (tarefa) => {

    console.log('received : ');
    console.log(tarefa);
    setTarefa(tarefa);

  };

  const handleTaskSubmissionSuccess = (projeto) => {

    console.log('received : ');
    console.log(projeto);

    getTarefa();

  };

  const chamarModalEditItem = (idSelecionado) => {

    setItemSelecionado(idSelecionado);

    const editButton = document.getElementById('trigger_modal_edit_task_item');

    if (editButton) {
      editButton.click();
    }

  }

  const chamarModalNovoItem = () => {

    const editButton = document.getElementById('trigger_modal_add_task_item');

    if (editButton) {
      editButton.click();
    }

  }

  const abrirFecharItem = async (item_id, estado) => {

    try {



      const formData = new FormData();
      formData.append('id', item_id);
      formData.append('estado', estado);
      formData.append('tarefa_id', tarefa?.id);

      const response = await http.post('/projeto/tarefa/item/activate', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });



      //Toast is not working here
      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {


          setTarefa(response.data.tarefa);

        }
      });

    } catch (error) {

      setLoading(false);
      console.error(error);

      toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }



  };


  const removerItemDeTarefa = async (itemTarefa) => {

    Swal.fire({
      title: "Remover item de tarefa?",
      text: "Tem a certeza que pretende este item de tarefa ?",
      type: "confirm",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(async function (t) {

      if (t.isConfirmed) {
        try {

          setLoading(true);

          const formData = new FormData();
          formData.append('id', itemTarefa?.id);
          formData.append('tarefa_id', itemTarefa?.tarefa_id);

          const response = await http.post('/projeto/eliminar/tarefa/item', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          // Show a success toast
          toast.success(response.data.message, {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => {

              setLoading(false);
              //Refresh colaboradores list
              setTarefa(response.data.tarefa);

            }
          });

        } catch (error) {

          setLoading(false);
          console.error(error);

          toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }

    });
  };


  const chamarModalEditTarefa = (tarefa, quadro) => {

    setSelectedColor(quadro?.cor);
    setQuadroSelecionado(quadro);
    setTarefaSelecionada(tarefa);

    console.log('Quadro selecionado:');
    console.log(quadroSelecionado);

    const editButton = document.getElementById('trigger_edit_task_modal');

    if (editButton) {
      editButton.click();
    }

  }

  const removerColaboradoresTarefa = async (user) => {

    Swal.fire({
      title: "Remover colaborador?",
      text: "Tem a certeza que pretende remover este colaborador desta tarefa?",
      type: "confirm",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(async function (t) {

      if (t.isConfirmed) {
        try {

          setLoading(true);

          const formData = new FormData();
          formData.append('user_id', user?.id);
          formData.append('tarefa_id', tarefa?.id);

          const response = await http.post('/projeto/tarefa/removerColaborador', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          // Show a success toast
          toast.success(response.data.message, {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => {

              setLoading(false);
              //Refresh colaboradores list
              setTarefa(response.data.tarefa);

            }
          });

        } catch (error) {

          setLoading(false);
          console.error(error);

          toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }

    });
  };


  const enviar_mensagem = async () => {

    if (verificarPermissao('PROJETOS', 'adicionar')) {
      try {

        if (mensagem.length > 0) {

          console.log('mensagem', mensagem);

          /*
  
         - para //1-direto, 2-grupo, 3-tarefa de projeto, 4-solicitações
         - mensagem_tipo // 1-texto, 2-ficheiro, 3-nota
         - nota_tipo // 1-criou tarefa, 2-editou a tarefa, 3-removeu a tarefa, 4-adicionou membro, 5-removeu membro, 6-conclui tarefa, 7-desmarcou conclusão da tarefa
  
        */
          const formData = new FormData();
          formData.append('missao_id', getMissao()?.id);
          formData.append('mensagem', mensagem);
          formData.append('emissor_id', user.id);
          formData.append('receptor_id', taskId);
          formData.append('projeto_id', projectId);
          formData.append('para', 3);
          formData.append('para_id', taskId);
          formData.append('mensagem_tipo', mensagem_tipo);
          formData.append('ficheiro', '');
          formData.append('tipo_ficheiro', 0);
          formData.append('icon', '');
          formData.append('nota_tipo', 0);

          // Obter data e hora atuais
          const dataAtual = new Date();
          const dataFormatada = `${dataAtual.getFullYear()}-${String(dataAtual.getMonth() + 1).padStart(2, '0')}-${String(dataAtual.getDate()).padStart(2, '0')}`;

          const horaFormatada = `${String(dataAtual.getHours()).padStart(2, '0')}:${String(dataAtual.getMinutes()).padStart(2, '0')}`;

          formData.append('data', dataFormatada);
          formData.append('hora', horaFormatada);

          const response = await http.post('/register-chat', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          console.log('conversa', response);

          setMensagem('');

          setConversa(response.data.data);

        }

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  const abrirFecharTarefa = async (tarefa_id, estado) => {

    try {



      const formData = new FormData();
      formData.append('estado', estado);
      formData.append('tarefa_id', tarefa_id);

      const response = await http.post('/projeto/tarefa/activate', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });



      //Toast is not working here
      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {

           getTarefa();

        }
      });

    } catch (error) {

      setLoading(false);
      console.error(error);

      toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

  };




  if (loading) return (<><Spinner></Spinner><ToastContainer /></>); else return (
    <>

      <div
        className="page-wrapper"
        style={{ minHeight: windowDimension.winHeight }}
      >

        <div className="chat-main-row">

          <div className="chat-main-wrapper">

            <div className="col-lg-7 message-view task-view task-left-sidebar">

              <div className="chat-window">
                <div className="fixed-header">
                  <div className="navbar">
                    <div className="float-start me-auto">
                      <div className="add-task-btn-wrapper">

                        {
                          <Link
                            to="#"
                            className="btn btn-white float-start ms-2"
                            data-bs-toggle="modal"
                            data-bs-target="#edit_project"
                          >
                            {
                              projeto?.estado == 1 ? <><span
                                className={"badge bg-inverse-success"}
                              >
                                <i className="fa fa-check"></i>&nbsp;&nbsp; <span>{t('Closed')}</span>
                              </span>&nbsp;&nbsp;</> : ''
                            }

                            {
                              ((new Date(projeto?.data_fim) < new Date()) && projeto?.estado != 1) ? (<><span
                                className={"badge bg-inverse-danger"}
                              >
                                <i className="fa fa-clock"></i>&nbsp;&nbsp; <span>{t('Pending')}</span>
                              </span>&nbsp;&nbsp;</>) : (projeto?.estado != 1 ? (<span
                                className={"badge bg-inverse-warning"}
                              >
                                <i className="fa fa-list"></i>&nbsp;&nbsp; <span>{t('Opened')}</span>
                              </span>) : '')
                            }

                          </Link>}


                        <Link
                          to={"/project-view/" + projeto?.id}
                          className="btn btn-white float-start mx-2"
                          title={t('View Project')}
                        >

                          {projeto?.projecto_code + ' ' + projeto?.nome + ' -> ' + (new Date(projeto?.data_fim).toLocaleDateString('pt-BR'))}

                        </Link>










                      </div>
                    </div>


                    <ul className="nav float-end custom-menu mt-4">

                      <li className="nav-item">
                        <Link to={'/task-board/' + projeto?.id} title="Quadro de tarefas" >
                          <span className="btn btn-white "><i className="fa fa-tasks"></i>&nbsp;</span>

                        </Link>&nbsp;&nbsp;

                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_project"
                        >
                          <span className="btn btn-white "><i className="fa fa-edit" /></span>
                        </Link>

                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#all_tasks_modal"
                        >

                          <span className="btn btn-white ">
                            <i className="fa fa-list"></i>{' '}
                            {t('All Tasks')}
                          </span>

                        </Link>&nbsp;

                        {
                          tarefa?.estado == 1 ?
                            <span
                              className="btn btn-white "
                              onClick={() => abrirFecharTarefa(tarefa?.id, 0)}
                            >
                              <i className="fa fa-check" />{t(' Abrir ')}
                            </span> :
                            <span
                              className="btn btn-white "
                              onClick={() => abrirFecharTarefa(tarefa?.id, 1)}
                            >
                              <i className="fa fa-check" />{t(' Fechar ')}
                            </span>
                        }


                        <Link
                          className="task-chat profile-rightbar float-end"
                          id="task_chat"
                          to="#task_window"
                        >
                          <i className="fa fa fa-comment" />
                        </Link>
                      </li>

                      {/* <li className="nav-item dropdown dropdown-action">
                        <Link
                          to="#"
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          title={t('Boards')}
                        >
                          <i className="la la-columns" />
                        </Link>
                        <div className="dropdown-menu dropdown-menu-right">
                          <Link className="dropdown-item" to="#">
                            {t('Pending Tasks')}
                          </Link>
                          <Link className="dropdown-item" to="#">
                            {t('Completed Tasks')}
                          </Link>
                          <Link className="dropdown-item" to="#">
                            {t('All Tasks')}
                          </Link>
                        </div>
                      </li> */}



                    </ul>
                  </div>
                </div>

                <div className="mt-4 row board-view-header">
                  <div className="col-8">
                    <div className="container">

                      <div className="pro-team-lead">
                        <h4 onClick={() => chamarModalEditTarefa(tarefa, tarefa?.quadro)}><i className="fa fa-check-square"></i>{" "}{new Date(tarefa?.prazo).toLocaleDateString('pt-BR')}{" > "} {tarefa?.nome}</h4>
                      </div>

                    </div>
                  </div>
                  <div className="col-4 text-end">
                    {
                      <Link
                        to="#"
                        className="btn btn-white float-end ms-2"
                        onClick={() => chamarModalEditTarefa(tarefa, tarefa?.quadro)}
                      >
                        {
                          tarefa?.estado == 1 ? <><span
                            className={"badge bg-inverse-success"}
                          >
                            <i className="fa fa-check"></i>&nbsp;&nbsp; <span>{t('Closed')}</span>
                          </span>&nbsp;&nbsp;</> : ''
                        }

                        {
                          ((new Date(tarefa?.prazo) < new Date()) && tarefa?.estado != 1) ? (<><span
                            className={"badge bg-inverse-danger"}
                          >
                            <i className="fa fa-clock"></i>&nbsp;&nbsp; <span>{t('Pending')}</span>
                          </span>&nbsp;&nbsp;</>) : (tarefa?.estado != 1 ? (<span
                            className={"badge bg-inverse-warning"}
                          >
                            <i className="fa fa-list"></i>&nbsp;&nbsp; <span>{t('Opened')}</span>
                          </span>) : '')
                        }


                        <span
                          className={
                            tarefa?.prioridade == 1
                              ? "badge bg-inverse-success"
                              : tarefa?.prioridade == 2 ? "badge bg-inverse-warning" : "badge bg-inverse-danger"
                          }
                        >{t('Priority')}{' : '}
                          {tarefa?.prioridade == 1 ? t('Low') : tarefa?.prioridade == 2 ? t('Medium') : t('High')}
                        </span>

                      </Link>}
                  </div>


                  <div className="row mt-5">
                    <div className="col-12">
                      <div className="pro-progress">
                        <div className="pro-progress-bar">

                          <div className="progress progress-xs mb-0">
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              data-bs-toggle="tooltip"
                              title={(Math.round(((tarefa?.items?.filter(tarefa => tarefa.estado == 1).length) / tarefa?.items?.length) * 100)) + '%'}
                              style={{ width: `${Math.round(((tarefa?.items?.filter(tarefa => tarefa.estado == 1).length) / tarefa?.items?.length) * 100)}%` }}
                            />
                          </div>
                          <span>{Math.round(((tarefa?.items?.filter(tarefa => tarefa.estado == 1).length) / tarefa?.items?.length) * 100) ? Math.round(((tarefa?.items?.filter(tarefa => tarefa.estado == 1).length) / tarefa?.items?.length) * 100) : 0}%</span>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>

                <div className="chat-contents">



                  <div className="chat-content-wrap">
                    <div className="chat-wrap-inner">
                      <div className="chat-box">
                        <div className="task-wrapper">
                          <div className="task-list-container">
                            <div className="task-list-body">
                              <ul id="task-list">

                                <li
                                  className="task"
                                  key={0}
                                >
                                  <div className="task-container">

                                    <span
                                      onClick={() => chamarModalNovoItem()}
                                      className={"add-task-btn btn btn-white btn-sm"}
                                    >
                                      {t('Add CheckList Item')}
                                    </span>

                                  </div>
                                </li>



                                {tarefa?.items?.map(task => (
                                  <li
                                    className="task"
                                    key={`${task?.id}`}
                                  >
                                    <div className="task-container text-start">

                                      <span
                                        className={"action-circle large complete-btn " + (task?.estado == 1 ? ' text-success ' : '')}
                                        title={t('Mark Complete')}
                                        onClick={() =>
                                          abrirFecharItem(task?.id, task?.estado == 1 ? 0 : 1)
                                        }
                                      >
                                        <i className="fa fa-check-circle"></i>
                                      </span>
                                      <span
                                        className="task-label"
                                      >
                                        {task?.nome}
                                      </span>
                                      <span className="task-action-btn task-btn-right">

                                        <span
                                          className="action-circle large edit-btn"
                                          title={t('Delete Task')}
                                        >
                                          <i
                                            className="material-icons"
                                            onClick={() =>
                                              chamarModalEditItem(task)
                                            }
                                          >
                                            edit
                                          </i>
                                        </span>
                                        <span
                                          className="action-circle large delete-btn"
                                          title={t('Delete Task')}
                                        >
                                          <i
                                            className="material-icons"
                                            onClick={() => removerItemDeTarefa(task)}
                                          >
                                            delete
                                          </i>
                                        </span>
                                      </span>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>


                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div className="col-lg-5 message-view task-chat-view task-right-sidebar"
              id="task_window"
            >
              <div className="chat-window">
                <div className="fixed-header">
                  <div className="navbar">
                    <div className="task-assign">
                      {/* <Link
                        className="task-complete-btn"
                        id="task_complete"
                        to="#"
                      >
                        <i className="material-icons">check</i>{t('Mark Complete')}
                      </Link> */}
                    </div>
                    <ul className="nav float-end custom-menu">
                      <li className="dropdown dropdown-action">
                        <Link
                          to="#"
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="material-icons">more_vert</i>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-right">
                          <Link className="dropdown-item" to="#">
                            {t('Delete Task')}
                          </Link>
                          <Link className="dropdown-item" to="#">
                            {t('Settings')}
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="chat-contents task-chat-contents">
                  <div className="chat-content-wrap">
                    <div className="chat-wrap-inner">
                      <div className="chat-box">
                        <div className="chats">
                          <h4>{t('Task')} : {tarefa?.nome}</h4>
                          <div className="task-header">
                            <div className="assignee-info">
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#assignee"
                              >
                                <div className="avatar">
                                  <img src={url_root + "/storage/" + encontrarUtilizadorPorId(tarefa?.criado_por)?.fotografia} alt={encontrarUtilizadorPorId(tarefa?.criado_por)?.name} title={encontrarUtilizadorPorId(tarefa?.criado_por)?.name} />
                                </div>
                                <div className="assigned-info">
                                  <div className="task-head-title">
                                    {t('Created By')}
                                  </div>
                                  <div className="task-assignee">{encontrarUtilizadorPorId(tarefa?.criado_por)?.name}</div>
                                </div>
                              </Link>
                              <span className="remove-icon">
                                <i className="fa fa-close" />
                              </span>
                            </div>
                            <div className="task-due-date">
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#assignee"
                              >
                                <div className="due-icon">
                                  <span>
                                    <i className="material-icons">date_range</i>
                                  </span>
                                </div>
                                <div className="due-info">
                                  <div className="task-head-title">
                                    {t('Due Date')}
                                  </div>
                                  <div className="due-date">
                                    {new Date(tarefa.prazo).toLocaleDateString('pt-BR')}
                                  </div>
                                </div>
                              </Link>
                              <span className="remove-icon">
                                <i className="fa fa-close" />
                              </span>
                            </div>
                          </div>
                          <hr className="task-line" />
                          {/* <div className="task-desc">
                            <div className="task-desc-icon">
                              <i className="material-icons">subject</i>
                            </div>
                            <div className="task-textarea">
                              <textarea
                                className="form-control"
                                placeholder={t('Description')}
                                defaultValue={""}
                              />
                            </div>
                          </div> */}

                          {/* <div className="task-information">
                            <span className="task-info-line">
                              <Link className="task-user" to="#">
                                Leila Gavião
                              </Link>{" "}
                              <span className="task-info-subject">
                                created task
                              </span>
                            </span>
                            <div className="task-time">Jan 20, 2023</div>
                          </div>
                          <div className="task-information">
                            <span className="task-info-line">
                              <Link className="task-user" to="#">
                                Leila Gavião
                              </Link>{" "}
                              <span className="task-info-subject">
                                {t('added to')} Hospital Administration
                              </span>
                            </span>
                            <div className="task-time">Jan 20, 2023</div>
                          </div>
                          <div className="task-information">
                            <span className="task-info-line">
                              <Link className="task-user" to="#">
                                Leila Gavião
                              </Link>{" "}
                              <span className="task-info-subject">
                                assigned to John Doe
                              </span>
                            </span>
                            <div className="task-time">Jan 20, 2023</div>
                          </div>
                          <hr className="task-line" />
                          <div className="task-information">
                            <span className="task-info-line">
                              <Link className="task-user" to="#">
                                John Doe
                              </Link>{" "}
                              <span className="task-info-subject">
                                {t('changed the due date to ')} Sep 28
                              </span>{" "}
                            </span>
                            <div className="task-time">9:09pm</div>
                          </div>
                          <div className="task-information">
                            <span className="task-info-line">
                              <Link className="task-user" to="#">
                                John Doe
                              </Link>{" "}
                              <span className="task-info-subject">
                                assigned to you
                              </span>
                            </span>
                            <div className="task-time">9:10pm</div>
                          </div> */}

                          {conversa?.map(chat => (

                            chat?.emissor_id == user?.id ?
                              <div key={`${chat?.id}`} className="chat chat-left">
                                <div className="chat-avatar">
                                  <Link to={"/profile/" + chat?.emissor_id} className="avatar">
                                    <img src={url_root + "/storage/" + chat?.emissor?.fotografia} alt={chat.emissor?.name} title={chat?.emissor?.name} />
                                  </Link>
                                </div>
                                <div className="chat-body">
                                  <div className="chat-bubble">
                                    <div className="chat-content">
                                      <span className="task-chat-user">
                                        {chat?.emissor?.name}
                                      </span>{" "}
                                      <span className="chat-time">{chat?.hora}</span>
                                      <p>{chat?.mensagem}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              :
                              <div key={`${chat?.id}`} className="chat chat-right">
                                <div className="chat-avatar">
                                  <Link to={"/profile/" + chat?.emissor_id} className="avatar">
                                    <img src={url_root + "/storage/" + chat?.emissor?.fotografia} alt={chat.emissor?.name} title={chat?.emissor?.name} />
                                  </Link>
                                </div>
                                <div className="chat-body">
                                  <div className="chat-bubble">
                                    <div className="chat-content">
                                      <span className="task-chat-user">
                                        {chat?.emissor?.name}
                                      </span>{" "}
                                      <span className="chat-time">{chat?.hora}</span>
                                      <p>{chat?.mensagem}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                          ))}


                          {/* <div className="completed-task-msg">
                            <span className="task-success">
                              <Link to="#">John Doe</Link> completed this task.
                            </span>{" "}
                            <span className="task-time">Today at 9:27am</span>
                          </div>
                          <div className="chat chat-left">
                            <div className="chat-avatar">
                              <Link to="/profile" className="avatar">
                                <img alt="Image" src={User6} />
                              </Link>
                            </div>
                            <div className="chat-body">
                              <div className="chat-bubble">
                                <div className="chat-content">
                                  <span className="task-chat-user">
                                    John Doe
                                  </span>{" "}
                                  <span className="file-attached">
                                    attached 3 files{" "}
                                    <i className="fa fa-paperclip" />
                                  </span>{" "}
                                  <span className="chat-time">
                                    Feb 17, 2023 at 4:32am
                                  </span>
                                  <ul className="attach-list">
                                    <li>
                                      <i className="fa fa-file" />{" "}
                                      <Link to="#">project_document.avi</Link>
                                    </li>
                                    <li>
                                      <i className="fa fa-file" />{" "}
                                      <Link to="#">video_conferencing.psd</Link>
                                    </li>
                                    <li>
                                      <i className="fa fa-file" />{" "}
                                      <Link to="#">landing_page.psd</Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="chat chat-left">
                            <div className="chat-avatar">
                              <Link to="/profile" className="avatar">
                                <img alt="Image" src={User2} />
                              </Link>
                            </div>
                            <div className="chat-body">
                              <div className="chat-bubble">
                                <div className="chat-content">
                                  <span className="task-chat-user">
                                    João Lauriano
                                  </span>{" "}
                                  <span className="file-attached">
                                    attached file{" "}
                                    <i className="fa fa-paperclip" />
                                  </span>{" "}
                                  <span className="chat-time">
                                    Yesterday at 9:16pm
                                  </span>
                                  <ul className="attach-list">
                                    <li className="pdf-file">
                                      <i className="fa-regular fa-file-pdf" />{" "}
                                      <Link to="#">Document_2016.pdf</Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="chat chat-left">
                            <div className="chat-avatar">
                              <Link to="/profile" className="avatar">
                                <img alt="Image" src={User2} />
                              </Link>
                            </div>
                            <div className="chat-body">
                              <div className="chat-bubble">
                                <div className="chat-content">
                                  <span className="task-chat-user">
                                    João Lauriano
                                  </span>{" "}
                                  <span className="file-attached">
                                    attached file{" "}
                                    <i className="fa fa-paperclip" />
                                  </span>{" "}
                                  <span className="chat-time">
                                    Today at 12:42pm
                                  </span>
                                  <ul className="attach-list">
                                    <li className="img-file">
                                      <div className="attach-img-download">
                                        <Link to="#">avatar-1.jpg</Link>
                                      </div>
                                      <div className="task-attach-img">
                                        <img src={User1} alt="Image" />
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="task-information">
                            <span className="task-info-line">
                              <Link className="task-user" to="#">
                                John Doe
                              </Link>
                              <span className="task-info-subject">
                                marked task as incomplete
                              </span>
                            </span>
                            <div className="task-time">1:16pm</div>
                          </div> */}


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="chat-footer">
                  <div className="message-bar">
                    <div className="message-inner">
                      <Link className="link attach-icon" to="#">
                        <img src={Attachment} alt="Image" />
                      </Link>
                      <div className="message-area">
                        <div className="input-group">
                          <textarea
                            className="form-control"
                            placeholder={t('Type message...')}
                            value={mensagem}
                            onChange={e => setMensagem(e.target.value)}
                          />
                          <span className="btn btn-primary" onClick={() => enviar_mensagem()}>
                            <i className="fa-solid fa-paper-plane" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="project-members task-followers">
                    <span className="followers-title">{t('Employees')}</span>

                    {tarefa?.colaboradores?.slice(0, 5).map(user => (

                      <Link
                        className="avatar"
                        to="#"
                        data-bs-toggle="tooltip"
                        title={user?.colaborador?.name}
                      >
                        <i className="fa fa-times text-danger"
                          onClick={() => removerColaboradoresTarefa(user?.colaborador)}
                        />
                        <img src={url_root + "/storage/" + user?.colaborador?.fotografia} alt={user?.colaborador?.name} title={user?.colaborador?.name} />
                      </Link>


                    ))}


                    <Link
                      to="#"
                      className="followers-add"
                      data-bs-toggle="modal"
                      data-bs-target="#assign_colaborador"
                    >
                      <i className="material-icons">add</i>
                    </Link>



                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <TaskModal tarefaItem={itemSelecionado} tarefa={tarefa} projeto={projeto} onSubmissionSuccess={handleSubmissionSuccess} />

        <TaskboardModal quadro={quadroSelecionado} tarefa={tarefaSelecionada} projeto={projeto} onSubmissionSuccess={handleTaskSubmissionSuccess} utilizadores={utilizadores} defaultColor={selectedColor} defaultPrioridade={tarefa?.prioridade} quadros={projeto?.quadros} />

        <ProjectModelPopup onSubmissionSuccess={handleProjectSubmissionSuccess} projeto={projeto} prioridadeParam={projeto?.prioridade} semanalParam={projeto?.semanal} tempoParam={projeto?.tempo} utilizadores={utilizadores} missoes={missoes} />

        <ColaboradorTarefaModalPopup utilizadores={utilizadores} onSubmissionSuccess={handleAddColaboradorSubmissionSuccess} projeto={projeto} tarefa={tarefa} />

        <TarefasModalPopup tarefas={projeto?.tarefas} handleNavigateTarefas={handleNavegarParaTarefa} />


        <span
          data-bs-toggle="modal"
          data-bs-target="#edit_task_item"
          id="trigger_modal_edit_task_item"
        >
        </span>

        <span
          data-bs-toggle="modal"
          data-bs-target="#add_task_item"
          id="trigger_modal_add_task_item"
        >
        </span>

        <span
          data-bs-toggle="modal"
          data-bs-target="#edit_task_modal"
          id="trigger_edit_task_modal"
        >
        </span>

      </div>
    </>
  );
};

export default Tasks;
