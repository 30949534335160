import React, { useState, useEffect } from "react";

import Breadcrumbs from "../../../components/Breadcrumbs";
import CargoFilter from "../Administration/Users/CargoFilter";
import CargoTable from "../Administration/Users/CargoTable";
import CargoModal from "../../../components/Administration/cargos/CargoModal";

import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";

import AuthUser from "../../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../components/Spinner";
import CargoVinculoModal from "../../../components/Administration/comercial/CargoVinculoModal";

const Designation = () => {
  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } =
    AuthUser();
  const [funcaos, setFuncoes] = useState([]);

  const [filteredCargos, setfilteredCargos] = useState([]);

  const [vinculos, setVinculos] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [cargoselecionado, setCargoSelecionado] = useState([]);
  const [subsidios, setSubsidios] = useState([]);


  const { t } = useTranslation();

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  // Callback function to receive filtered users from UserFilter
  const handleFilterSubmit = (filteredCargos) => {
    console.log("received:");
    console.log(filteredCargos);

    setfilteredCargos(filteredCargos);
  };

  const handleSubmissionSuccess = (cargos) => {
    // Refresh acessos state or trigger any necessary actions
    setFuncoes(cargos);
  };

  const getFuncaos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/funcaos/" + getMissao()?.id);

      setFuncoes(res.data.funcaos);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Chama a função assíncrona getUtilizador()
        await getFuncaos();
        await getColaboradores();
        await getSubsidios();
      } catch (error) {
        console.error("Erro ao obter cargos:", error);
      }

    }
    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, []);

  const getSubsidios = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const formData = new FormData();
      formData.append("termo", '');
      formData.append("missao_id", getMissao()?.id);
      formData.append("tipo",-1);

      const response = await http.post("/subsidio/pesquisar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setSubsidios(response?.data?.subsidios);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getColaboradores = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/utilizadores/ativados/" + getMissao()?.id);

      setColaboradores(res.data.utilizadores);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const verVinculos = async (cargo, vinculosParam) => {
    try {
      setCargoSelecionado(cargo);
      setVinculos(vinculosParam);

      const editButton = document.getElementById(
        "triggerModalEditcargoVinculo"
      );

      if (editButton) {
        // Click the button to trigger the modal
        editButton.click();
      }
    } catch (error) {
      console.error("Erro ao obter vínculos:", error);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  if (verificarPermissao("CARGO", "ver"))
    if (loading)
      return (
        <>
          <Spinner></Spinner>
        </>
      );
    else
      return (
        <>
          (
          <>
            <div className="page-wrapper">
              <div className="content container-fluid">
                <Breadcrumbs
                  maintitle={t("Job Role")}
                  title="Dashboard"
                  subtitle={t("Job Role")}
                  modal="#add_cargo_modal"
                  name={t("Add Job Role")}
                />

                <CargoFilter onFilterSubmit={handleFilterSubmit} />
                <CargoTable
                  onLerVinculos={verVinculos}
                  cargos={filteredCargos.length > 0 ? filteredCargos : funcaos}
                  subsidios={subsidios}
                />
                <CargoModal onSubmissionSuccess={handleSubmissionSuccess} />

                <CargoVinculoModal
                  cargo={cargoselecionado}
                  vinculos={vinculos}
                  colaboradores={colaboradores}
                  
                />

                <span
                  data-bs-toggle="modal"
                  data-bs-target="#cargo_colaborador_modal"
                  id="triggerModalEditcargoVinculo"
                ></span>

              </div>
            </div>{" "}
          </>
          );{" "}
        </>
      );
  else
    return (
      <>
        <div className="page-wrapper cardhead">
          <div className="content ">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">SEM PERMISSÃO</h3>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      <i className="fa fa-times-circle text-danger"></i>&nbsp;
                      OPERAÇÃO NÃO AUTORIZADA
                    </h4>
                  </div>
                  <div className="card-body">
                    <p>
                      Esta operação não é autorizada para o seu nivel de acesso
                      ao sistema, por favor contacte o administrador do sistema.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default Designation;
