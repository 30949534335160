import React, { useEffect, useState, useRef } from "react";
import { User2, PlaceHolder } from "../../../../../Routes/ImagePath";
import { Link, useParams, useNavigate } from "react-router-dom";

import AuthUser from "../../../../../components/AuthUser";

import { useTranslation } from "react-i18next";

const ChatContent = ({ utilizador, conversa }) => {

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, user, getMissao } = AuthUser();

  const { t } = useTranslation();

  const chatEndRef = useRef(null); // Ref para o final da janela de chat

  useEffect(() => {
    scrollToBottom(); // Quando a conversa muda, role para o final
  }, [conversa]);

  let currentDate = null;

  // Função para rolar para o final da janela de chat
  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <div className="chats">


        {/* <div className="chat-line">
          <span className="chat-date">Oct 8, 2018</span>
        </div> */}

        {conversa?.reverse()?.map((chat, index) => {
          const chatDate = new Date(chat.data).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
          const displayDate = chatDate !== currentDate ? (
            <div className="chat-line" key={`date-${index}`}>
              <span className="chat-date">{chatDate}</span>
            </div>
          ) : null;

          currentDate = chatDate;

          return (
            <React.Fragment key={index}>
              {displayDate}
              {chat?.emissor_id != user?.id ? (
                <div className="chat chat-left">
                  <div className="chat-avatar">
                    <Link to={"/profile/" + chat?.emissor?.id} className="avatar">
                      <img src={url_root + "/storage/" + chat?.emissor?.fotografia} alt={chat?.emissor?.name} title={chat?.emissor?.name} />
                    </Link>
                  </div>
                  <div className="chat-body">
                    <div className="chat-bubble">
                      <div className="chat-content">
                        <p>{chat?.mensagem}</p>
                        <span className="chat-time">{chat?.hora}</span>
                      </div>
                      <div className="chat-action-btns">
                        <ul>
                          <li>
                            <Link to="#" className="edit-msg">
                              <i className="fa fa-pencil" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="del-msg">
                              <i className="fa fa-trash" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>) : (
                <div className="chat chat-right">
                  <div className="chat-body">

                    <div className="chat-bubble">
                      <div className="chat-content">
                        <p>{chat?.mensagem}</p>
                        <span className="chat-time">{chat?.hora}</span>
                      </div>
                      <div className="chat-action-btns">
                        <ul>
                          <li>
                            <Link to="#" className="edit-msg">
                              <i className="fa fa-pencil" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="del-msg">
                              <i className="fa fa-trash" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>)}
            </React.Fragment>

          );
        })}

        <div ref={chatEndRef} /> {/* Ref para o final da janela de chat */}

      </div>
    </div>
  );
};

export default ChatContent;
