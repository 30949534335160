import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Avatar_16,
  Avatar_02,
  Avatar_05,
  Avatar_09,
  Avatar_10,
} from "../../../Routes/ImagePath/index";

import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import React, { useState, useEffect } from "react";

import AuthUser from "../../AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../../Spinner";

import Swal from "sweetalert2";

const DepartamentoVinculoModal = ({ departamento, colaboradores, vinculos }) => {

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user, user_permissoes, verificarPermissao } =
    AuthUser();

  const [vinculo_colaboradores, setVinculoColaborador] = useState([]);
  const [vinculo_colaboradores_selecionados, setVinculoColaboradorSelecionado] =
    useState([]);

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const [colaboradoresFiltrados, setColaboradoresFiltrados] = useState(colaboradores);

  useEffect(() => {
    console.log("departamento modal:");
    console.log(departamento);
    console.log("Vinculos modal :");
    console.log(vinculos);

    setVinculoColaborador(
      vinculos?.colaboradores ? vinculos?.colaboradores : []
    );
    setVinculoColaboradorSelecionado(
      vinculos?.colaboradores ? vinculos?.colaboradores : []
    );

    setColaboradoresFiltrados(colaboradores);

  }, [departamento]);

  const verTodos = () => {
    setColaboradoresFiltrados(colaboradores);
  };
  
  const verVinculados = () => {
    setColaboradoresFiltrados(
      colaboradores.filter((colaborador) =>
        vinculo_colaboradores.some(
          (vc) => vc.id == colaborador.id
        )
      )
    );
  };
  

  const filtrarLista = (valor) => {
    setColaboradoresFiltrados(
      colaboradores.filter((colaborador) =>
        colaborador.name.toLowerCase().includes(valor.toLowerCase())
      )
    );
  };
  

  //Vinculo Colaboradores

  const getClassForColaborador = (colaboradorId) => {
    if (
      vinculo_colaboradores_selecionados?.some(
        (vc) => vc?.id == colaboradorId
      )
    ) {
      return "bg-primary";
    } else if (
      vinculo_colaboradores?.some((vc) => vc?.id == colaboradorId)
    ) {
      return "bg-secondary";
    } else {
      return "";
    }
  };

  const selecionarColaborador = (colaborador) => {
    const exists = vinculo_colaboradores_selecionados?.some(
      (vc) => vc?.id == colaborador.id
    );
    if (exists) {
      setVinculoColaboradorSelecionado(
        vinculo_colaboradores_selecionados?.filter(
          (vc) => vc?.id != colaborador.id
        )
      );
    } else {
      setVinculoColaboradorSelecionado([
        ...vinculo_colaboradores_selecionados,
        { id: colaborador.id , departamento_id: departamento?.id},
      ]);
    }
  };

  const vincular_departamento_colaborador = async () => {
    if (verificarPermissao("departamento", "adicionar")) {
      try {
        setLoading(true);

        const formData = new FormData();
        formData.append(
          "colaboradores",
          JSON.stringify(vinculo_colaboradores_selecionados)
        );

        const response = await http.post(
          "/departamento/colaborador/register",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        setLoading(false);

      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const getVinculos = async () => {

    setLoading(true); // Set loading to true when the request starts

    const formData = new FormData();
    formData.append("id", departamento?.id);

    const response = await http.post("/departamento/vinculos", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    //setVinculos(response?.data?.colaboradores);

    setLoading(false);

    setVinculoColaborador(
      response?.data?.colaboradores ? response?.data?.colaboradores : []
    );
    setVinculoColaboradorSelecionado(
      response?.data?.colaboradores ? response?.data?.colaboradores : []
    );
  };

  return (
    <>

      {/* Add Task Modal */}

      <div
        id="departamento_colaborador_modal"
        className="modal custom-modal fade modal-lg"
        role="dialog"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                Departamento : {departamento?.designacao}
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="closeButton30"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              {/* start tab */}

              <div className="page-menu">
                <div className="row">
                  <div className="col-sm-12">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          data-bs-toggle="tab"
                          to="#tab_colaboradores"
                        >
                          {t("Colaboradores")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="tab-content">
                <div className="tab-pane show active" id="tab_colaboradores">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="input-block mb-3 mb-3 form-focus focused">
                        <div>
                          <input className="form-control" type="text" onKeyUp={(e) => filtrarLista(e.target.value)} />
                          <label className="focus-label">
                            {t("Digite um nome para procurar")}
                          </label>
                        </div>
                      </div>
                    </div>

                   

                    <div className="col-sm-4">
                      <div className="input-block mb-3 mb-3 form-focus focused">
                        <a
                          className="btn btn-primary"
                          onClick={() => vincular_departamento_colaborador()}
                        >
                          {loading ? (
                            "Carregando"
                          ) : (
                            <>
                              <i className="fa fa-save"></i> SALVAR ATRIBUIÇÕES{" "}
                            </>
                          )}
                        </a>
                      </div>
                    </div>

                    <div className="col-sm-2" title="Atualizar a lista de vinculados">
                      <div className="input-block mb-3 mb-3 form-focus focused">
                        <a
                          className="btn btn-secondary"
                          onClick={() => getVinculos()}
                        >
                          {loading ? (
                            "Carregando"
                          ) : (
                            <>
                              <i className="fa fa-recycle"></i> 
                            </>
                          )}
                        </a>
                      </div>
                    </div>

                    <div className="col-sm-1" title="Ver todos os colaboradores">
                      <div className="input-block mb-3 mb-3 form-focus focused">
                        <a
                          className="btn btn-secondary"
                          onClick={() => verTodos()}
                        >
                          {loading ? (
                            "Carregando"
                          ) : (
                            <>
                              <i className="fa fa-users"></i> 
                            </>
                          )}
                        </a>
                      </div>
                    </div>

                    <div className="col-sm-1" title="ver so os vinculados">
                      <div className="input-block mb-3 mb-3 form-focus focused">
                        <a
                          className="btn btn-secondary"
                          onClick={() => verVinculados()}
                        >
                          {loading ? (
                            "Carregando"
                          ) : (
                            <>
                              <i className="fa fa-check"></i> 
                            </>
                          )}
                        </a>
                      </div>
                    </div>

                    <hr className=""></hr>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-nowrap">
                      <thead>
                        <tr>
                          <th>{t("Foto")}</th>
                          <th>{t("Colaborador")}</th>
                          <th>{t("Dept/departamento")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {colaboradoresFiltrados?.map((item) => (
                          <tr
                            key={item?.id}
                            onClick={() => selecionarColaborador(item)}
                          >
                            <td className={getClassForColaborador(item.id)}>
                              <Link
                                to={"/profile/" + item.id}
                                className="avatar"
                              >
                                <img
                                  src={url_root + "/storage/" + item.fotografia}
                                  alt="foto"
                                />
                              </Link>
                            </td>
                            <td className={getClassForColaborador(item.id)}>
                              {item?.name}
                              <br></br>
                              {item?.departamento?.designacao}
                              <br></br>
                              {item?.telefone}
                            </td>
                            <td className={getClassForColaborador(item.id)}>
                              {item?.departamento?.designacao}
                              <br></br>
                              {item?.departamento?.designacao}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* end tab */}
            </div>
          </div>
        </div>
      </div>
      {/* /Add Task Modal */}

      {loading ? (
        <>
          <Spinner></Spinner>
        </>
      ) : (
        <></>
      )}

      <ToastContainer />

    </>
  );
};

export default DepartamentoVinculoModal;
