import React from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import ShortListCanditatesTable from "./ShortListCandidatesTable";
import { useTranslation } from "react-i18next";

const ShortListCandidates = () => {

  const { t } = useTranslation();

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle={t('Shortlist Candidates')}
            title="Dashboard"
            subtitle={t('Jobs')+' / '+t('Shortlist Candidates')}
          />
          <ShortListCanditatesTable />
        </div>
      </div>
    </>
  );
};

export default ShortListCandidates;
