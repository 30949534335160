/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { Avatar_09, Avatar_10, Avatar_16 } from "../../Routes/ImagePath";
import { Link, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";



import { format } from 'date-fns';

const TarefasModalPopup = ({ tarefas, handleNavigateTarefas }) => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {

  }, [tarefas]);

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event('click'));
    }
  };

  const navegar_na_tarefa = (tarefa_id) => {

    const editButton = document.getElementById('trigger_close_modal_tarefas');

    handleNavigateTarefas(tarefa_id);

    if (editButton) {
      editButton.click();
    }

  }


  return (
    <div>
      <div id="all_tasks_modal" className="modal custom-modal fade modal-lg" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t('Tasks Of This Project')}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="trigger_close_modal_tarefas"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">


              <div className="project-task text-start">

                <ul className="nav nav-tabs nav-tabs-top nav-justified mb-0">

                  <li className="nav-item">
                    <Link
                      className="nav-link active"
                      to="#tarefas_todas"
                      data-bs-toggle="tab"
                      aria-expanded="true">
                      {t('All Tasks')}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="#tarefas_abertas"
                      data-bs-toggle="tab"
                      aria-expanded="false">
                      {t('Progress')}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="#tarefas_pendentes"
                      data-bs-toggle="tab"
                      aria-expanded="false">
                      {t('Pending Tasks')}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="#tarefas_concluidas"
                      data-bs-toggle="tab"
                      aria-expanded="false">
                      {t('Completed Tasks')}
                    </Link>
                  </li>

                </ul>
                <div className="tab-content">
                  <div className="tab-pane show active" id="tarefas_todas">
                    <div className="task-wrapper">
                      <div className="task-list-container text-start">
                        <div className="task-list-body text-start">

                          <table className="table table-bordered" width="100%">
                            <tbody>

                              {tarefas?.map(task => (

                                <tr key={task?.id} onClick={() => navegar_na_tarefa(task?.id)}>
                                  <td>
                                    <span className={"task-action-btn task-check " + (
                                      task?.estado == 1
                                        ? " text-success "
                                        : ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? " text-danger " : " text-warning "
                                    )}



                                    >
                                      <span
                                        className="action-circle large complete-btn"
                                        title="Mark Complete"
                                      >
                                        <i className="material-icons">check</i>
                                      </span>
                                    </span>
                                  </td>
                                  <td>
                                    <span className="task-label text-start" >
                                      {format(new Date(task?.prazo), 'dd/MM/yyyy')}
                                    </span>
                                  </td>
                                  <td>
                                    {
                                      task?.estado == 1 ? <>
                                        <span
                                          className={"badge bg-inverse-success"}
                                        >
                                          <i className="fa fa-check"></i> <span>{t('Closed')}</span>
                                        </span></> : ''
                                    }

                                    {

                                      ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? (<><span
                                        className={"badge bg-inverse-danger"}
                                      >
                                        <i className="fa fa-clock"></i> <span>{t('Pending')}</span>
                                      </span></>) : (task?.estado != 1 ? (<span
                                        className={"badge bg-inverse-warning"}
                                      >
                                        <i className="fa fa-list"></i> <span>{t('Opened')}</span>
                                      </span>) : '')
                                    }

                                  </td>
                                  <td>
                                    <span
                                      className={
                                        task?.prioridade == 1
                                          ? "badge bg-inverse-success"
                                          : task?.prioridade == 2 ? "badge bg-inverse-warning" : "badge bg-inverse-danger"
                                      }
                                    >{t('Priority')}{' : '}
                                      {task?.prioridade == 1 ? t('Low') : task?.prioridade == 2 ? t('Medium') : t('High')}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={"task-label text-start"}
                                    >[{(Math.round(((task?.items?.filter(tarefa => tarefa.estado === 1).length) / task?.items?.length) * 100)) ? (Math.round(((task?.items?.filter(tarefa => tarefa.estado === 1).length) / task?.items?.length) * 100)) : 0}%]
                                    </span>
                                  </td>
                                  <td>

                                    <span className="task-label" >{task?.nome}
                                    </span>
                                  </td>
                                  <td>

                                    <span className="task-action-btn task-btn-right">
                                      <span className="action-circle large delete-btn" title="Tarefas" onClick={() => navegar_na_tarefa(task?.id)} >
                                        <i className="fa fa-link"></i>
                                      </span>
                                    </span>

                                  </td>
                                </tr>

                              ))}

                            </tbody>
                          </table>

                        </div>

                      </div>

                    </div>
                  </div>
                  <div className="tab-pane" id="tarefas_abertas">
                    <div className="task-wrapper">
                      <div className="task-list-container">
                        <div className="task-list-body">


                          <table className="table table-bordered" width="100%">
                            <tbody>

                              {tarefas
                                // Filtrar apenas tarefas pendentes
                                ?.filter(task => task.estado == 0)
                                .map(task => (

                                  <tr key={task?.id} onClick={() => navegar_na_tarefa(task?.id)}>
                                    <td>
                                      <span className={"task-action-btn task-check " + (
                                        task?.estado == 1
                                          ? " text-success "
                                          : ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? " text-danger " : " text-warning "
                                      )}



                                      >
                                        <span
                                          className="action-circle large complete-btn"
                                          title="Mark Complete"
                                        >
                                          <i className="material-icons">check</i>
                                        </span>
                                      </span>
                                    </td>
                                    <td>
                                      <span className="task-label text-start" >
                                        {format(new Date(task?.prazo), 'dd/MM/yyyy')}
                                      </span>
                                    </td>
                                    <td>
                                      {
                                        task?.estado == 1 ? <>
                                          <span
                                            className={"badge bg-inverse-success"}
                                          >
                                            <i className="fa fa-check"></i> <span>{t('Closed')}</span>
                                          </span></> : ''
                                      }

                                      {

                                        ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? (<><span
                                          className={"badge bg-inverse-danger"}
                                        >
                                          <i className="fa fa-clock"></i> <span>{t('Pending')}</span>
                                        </span></>) : (task?.estado != 1 ? (<span
                                          className={"badge bg-inverse-warning"}
                                        >
                                          <i className="fa fa-list"></i> <span>{t('Opened')}</span>
                                        </span>) : '')
                                      }

                                    </td>
                                    <td>
                                      <span
                                        className={
                                          task?.prioridade == 1
                                            ? "badge bg-inverse-success"
                                            : task?.prioridade == 2 ? "badge bg-inverse-warning" : "badge bg-inverse-danger"
                                        }
                                      >{t('Priority')}{' : '}
                                        {task?.prioridade == 1 ? t('Low') : task?.prioridade == 2 ? t('Medium') : t('High')}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={"task-label text-start"}
                                      >[{(Math.round(((task?.items?.filter(tarefa => tarefa.estado === 1).length) / task?.items?.length) * 100)) ? (Math.round(((task?.items?.filter(tarefa => tarefa.estado === 1).length) / task?.items?.length) * 100)) : 0}%]
                                      </span>
                                    </td>
                                    <td>

                                      <span className="task-label" >{task?.nome}
                                      </span>
                                    </td>
                                    <td>

                                      <span className="task-action-btn task-btn-right">
                                        <span className="action-circle large delete-btn" title="Tarefas" onClick={() => navegar_na_tarefa(task?.id)} >
                                          <i className="fa fa-link"></i>
                                        </span>
                                      </span>

                                    </td>
                                  </tr>

                                ))}

                            </tbody>
                          </table>




                        </div>

                      </div>

                    </div>
                  </div>
                  <div className="tab-pane" id="tarefas_pendentes">
                    <div className="task-wrapper">
                      <div className="task-list-container">
                        <div className="task-list-body">

                          <table className="table table-bordered" width="100%">
                            <tbody>

                              {tarefas
                                // Filtrar apenas tarefas pendentes
                                ?.filter(task => task.estado == 0 && new Date(task.prazo) < new Date())
                                .map(task => (

                                  <tr key={task?.id} onClick={() => navegar_na_tarefa(task?.id)}>
                                    <td>
                                      <span className={"task-action-btn task-check " + (
                                        task?.estado == 1
                                          ? " text-success "
                                          : ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? " text-danger " : " text-warning "
                                      )}



                                      >
                                        <span
                                          className="action-circle large complete-btn"
                                          title="Mark Complete"
                                        >
                                          <i className="material-icons">check</i>
                                        </span>
                                      </span>
                                    </td>
                                    <td>
                                      <span className="task-label text-start" >
                                        {format(new Date(task?.prazo), 'dd/MM/yyyy')}
                                      </span>
                                    </td>
                                    <td>
                                      {
                                        task?.estado == 1 ? <>
                                          <span
                                            className={"badge bg-inverse-success"}
                                          >
                                            <i className="fa fa-check"></i> <span>{t('Closed')}</span>
                                          </span></> : ''
                                      }

                                      {

                                        ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? (<><span
                                          className={"badge bg-inverse-danger"}
                                        >
                                          <i className="fa fa-clock"></i> <span>{t('Pending')}</span>
                                        </span></>) : (task?.estado != 1 ? (<span
                                          className={"badge bg-inverse-warning"}
                                        >
                                          <i className="fa fa-list"></i> <span>{t('Opened')}</span>
                                        </span>) : '')
                                      }

                                    </td>
                                    <td>
                                      <span
                                        className={
                                          task?.prioridade == 1
                                            ? "badge bg-inverse-success"
                                            : task?.prioridade == 2 ? "badge bg-inverse-warning" : "badge bg-inverse-danger"
                                        }
                                      >{t('Priority')}{' : '}
                                        {task?.prioridade == 1 ? t('Low') : task?.prioridade == 2 ? t('Medium') : t('High')}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={"task-label text-start"}
                                      >[{(Math.round(((task?.items?.filter(tarefa => tarefa.estado === 1).length) / task?.items?.length) * 100)) ? (Math.round(((task?.items?.filter(tarefa => tarefa.estado === 1).length) / task?.items?.length) * 100)) : 0}%]
                                      </span>
                                    </td>
                                    <td>

                                      <span className="task-label" >{task?.nome}
                                      </span>
                                    </td>
                                    <td>

                                      <span className="task-action-btn task-btn-right">
                                        <span className="action-circle large delete-btn" title="Tarefas" onClick={() => navegar_na_tarefa(task?.id)} >
                                          <i className="fa fa-link"></i>
                                        </span>
                                      </span>

                                    </td>
                                  </tr>

                                ))}

                            </tbody>
                          </table>

                        </div>

                      </div>

                    </div>
                  </div>
                  <div className="tab-pane" id="tarefas_concluidas">
                    <div className="task-wrapper">
                      <div className="task-list-container">
                        <div className="task-list-body">
                         
                          <table className="table table-bordered" width="100%">
                            <tbody>

                            {tarefas
                              // Filtrar apenas tarefas pendentes
                              ?.filter(task => task.estado == 1)
                              .map(task => (

                                  <tr key={task?.id} onClick={() => navegar_na_tarefa(task?.id)}>
                                    <td>
                                      <span className={"task-action-btn task-check " + (
                                        task?.estado == 1
                                          ? " text-success "
                                          : ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? " text-danger " : " text-warning "
                                      )}



                                      >
                                        <span
                                          className="action-circle large complete-btn"
                                          title="Mark Complete"
                                        >
                                          <i className="material-icons">check</i>
                                        </span>
                                      </span>
                                    </td>
                                    <td>
                                      <span className="task-label text-start" >
                                        {format(new Date(task?.prazo), 'dd/MM/yyyy')}
                                      </span>
                                    </td>
                                    <td>
                                      {
                                        task?.estado == 1 ? <>
                                          <span
                                            className={"badge bg-inverse-success"}
                                          >
                                            <i className="fa fa-check"></i> <span>{t('Closed')}</span>
                                          </span></> : ''
                                      }

                                      {

                                        ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? (<><span
                                          className={"badge bg-inverse-danger"}
                                        >
                                          <i className="fa fa-clock"></i> <span>{t('Pending')}</span>
                                        </span></>) : (task?.estado != 1 ? (<span
                                          className={"badge bg-inverse-warning"}
                                        >
                                          <i className="fa fa-list"></i> <span>{t('Opened')}</span>
                                        </span>) : '')
                                      }

                                    </td>
                                    <td>
                                      <span
                                        className={
                                          task?.prioridade == 1
                                            ? "badge bg-inverse-success"
                                            : task?.prioridade == 2 ? "badge bg-inverse-warning" : "badge bg-inverse-danger"
                                        }
                                      >{t('Priority')}{' : '}
                                        {task?.prioridade == 1 ? t('Low') : task?.prioridade == 2 ? t('Medium') : t('High')}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={"task-label text-start"}
                                      >[{(Math.round(((task?.items?.filter(tarefa => tarefa.estado === 1).length) / task?.items?.length) * 100)) ? (Math.round(((task?.items?.filter(tarefa => tarefa.estado === 1).length) / task?.items?.length) * 100)) : 0}%]
                                      </span>
                                    </td>
                                    <td>

                                      <span className="task-label" >{task?.nome}
                                      </span>
                                    </td>
                                    <td>

                                      <span className="task-action-btn task-btn-right">
                                        <span className="action-circle large delete-btn" title="Tarefas" onClick={() => navegar_na_tarefa(task?.id)} >
                                          <i className="fa fa-link"></i>
                                        </span>
                                      </span>

                                    </td>
                                  </tr>

                                ))}

                            </tbody>
                          </table>


                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>


    </div>
  );
};

export default TarefasModalPopup;
