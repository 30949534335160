import React from "react";

import UserSearchFilter from "../../../../../../components/Administration/Jobs/UserJobs/UserSearchFilter";
import UserDashboardHeader from "../../../../../../components/Administration/Jobs/UserJobs/UserDashboardHeader";
import UserDataTable from "../../../../../../components/Administration/Jobs/UserJobs/UserDataTable";
import DeleteModal from "../../../../../../components/modelpopup/deletePopup";
import Breadcrumbs from "../../../../../../components/Breadcrumbs";
import { useTranslation } from "react-i18next";

const VisitedJobs = () => {

  const { t } = useTranslation();

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle={t('Visited Jobs')}
          title="Dashboard"
          subtitle={t('Jobs')+' / '+t('User Dashboard')+' / '+t('Visited Jobs')}
        />
        <UserDashboardHeader />
        <UserSearchFilter />
        <UserDataTable />
        <DeleteModal Name={t('Delete')} />
      </div>
    </div>
  );
};

export default VisitedJobs;
