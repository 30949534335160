import React from "react";

import Breadcrumbs from "../../../../../components/Breadcrumbs";
import CandidatesTable from "./CandidatesTable";
import AddCandidatesModal from "../../../../../components/Administration/Jobs/CandidatesModal/AddCandidatesModal";

import { useTranslation } from "react-i18next";

const CanditatesList = () => {

  const { t } = useTranslation();

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle={t('Candidates List')}
            title="Dashboard"
            subtitle={t('Jobs')+' / '+t('Candidates List')}
            modal="#add_employee"
            name={t('Candidates List')}
          />
          <CandidatesTable />
          <AddCandidatesModal />
        </div>
      </div>
    </>
  );
};

export default CanditatesList;
