import React, { useState } from "react";
import { Avatar_02 } from "../../Routes/ImagePath";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const PersonalInformationModelPopup = () => {
  const { t } = useTranslation();

  const [selectedDate1, setSelectedDate1] = useState(null);

  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
  };
  const domain = [
    { value: 1, label: "Select Department" },
    { value: 2, label: "Web Development+" },
    { value: 3, label: "IT Management" },
    { value: 4, label: "Marketing" },
  ];
  const developer = [
    { value: 1, label: "Select Department" },
    { value: 2, label: "Web Development+" },
    { value: 3, label: "IT Management" },
    { value: 4, label: "Marketing" },
  ];
  const reporter = [
    { value: 2, label: "Wilmer Deluna" },
    { value: 3, label: "Leila Gavião" },
    { value: 4, label: "João Lauriano" },
  ];
  const status = [
    { value: 1, label: "Single" },
    { value: 2, label: "Married" },
  ];
  const gender = [
    { value: 1, label: t("Male") },
    { value: 2, label: t("Female") },
  ];
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#00b0f0" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#00b0f0",
      },
    }),
  };

  return (
    <>
      <div id="profile_info" className="modal custom-modal fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t("Profile Information")}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile-img-wrap edit-img">
                      <img
                        className="inline-block"
                        src={Avatar_02}
                        alt="user"
                      />
                      <div className="fileupload btn">
                        <span className="btn-text">edit</span>
                        <input className="upload" type="file" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            {t("First Name")}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue="John"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            {t("Last Name")}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue="Doe"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            {t("Birth Date")}
                          </label>
                          <div>
                            <DatePicker
                              selected={selectedDate1}
                              onChange={handleDateChange1}
                              className="form-control floating datetimepicker"
                              type="date"
                              placeholderText="04/10/2023"
                              dateFormat="dd-MM-yyyy"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            {t("Gender")}
                          </label>
                          <Select
                            options={gender}
                            placeholder="-"
                            styles={customStyles}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t("Address")}</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="4487 Snowbird Lane"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t("State")}</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="New York"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t("Country")}</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="United States"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t("Pin Code")}</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={10523}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        {t("Phone Number")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="631-889-3206"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        {t("Department")} <span className="text-danger">*</span>
                      </label>
                      <Select
                        options={domain}
                        placeholder="Select Department"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        {t("Designation")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        options={developer}
                        placeholder="Select Department"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        {t("Reports To")} <span className="text-danger">*</span>
                      </label>

                      <Select
                        options={reporter}
                        placeholder="-"
                        styles={customStyles}
                      />
                    </div>
                  </div>

                  <div className="submit-section">
                    <button
                      className="btn btn-primary submit-btn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      type="reset"
                    >
                      {t("Save")}
                    </button>
                  </div>
                </div>
                <div className="add-more">
                        <Link to="#">
                          <i className="fa-solid fa-plus-circle" />
                          {t("Add More")}
                        </Link>
                      </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        id="emergency_contact_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t("Personal Information")}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="card">
                  <div className="card-body">
                    <h3 className="card-title">
                      {t("Primary Contact")}
                      <Link to="#" className="delete-icon">
                        <i className="fa-regular fa-trash-can" />
                      </Link>
                    </h3>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3">
                          <label className="col-form-label">
                            {t("Name")} <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3">
                          <label className="col-form-label">
                            {t("Relationship")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3">
                          <label className="col-form-label">
                            {t("Phone")} <span className="text-danger">*</span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3">
                          <label className="col-form-label">
                            {t("Phone")} 2
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h3 className="card-title">
                      {t("Secondary Contact")}
                      <Link to="#" className="delete-icon">
                        <i className="fa-regular fa-trash-can" />
                      </Link>
                    </h3>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3">
                          <label className="col-form-label">
                            {t("Name")} <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3">
                          <label className="col-form-label">
                            {t("Relationship")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3">
                          <label className="col-form-label">
                            {t("Phone")} <span className="text-danger">*</span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3">
                          <label className="col-form-label">
                            {t("Phone")} 2
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>

                      <div className="submit-section">
                        <button
                          className="btn btn-primary submit-btn"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          type="reset"
                        >
                          {t("Save")}
                        </button>
                      </div>
                    </div>

                    <div className="add-more">
                        <Link to="#">
                          <i className="fa-solid fa-plus-circle" />
                          {t("Add More")}
                        </Link>
                      </div>

                  </div>
                </div>
              
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Education Modal */}
      <div
        id="education_info"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title"> {t("Education Informations")}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-scroll">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">
                        {t("Education Informations")}
                        <Link to="#" className="delete-icon">
                          <i className="fa-regular fa-trash-can" />
                        </Link>
                      </h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              defaultValue="Oxford University"
                              className="form-control floating"
                            />
                            <label className="focus-label">
                              {t("Institution")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              defaultValue="Computer Science"
                              className="form-control floating"
                            />
                            <label className="focus-label">
                              {t("Subject")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <div className="cal-icon">
                              <DatePicker
                                selected={selectedDate1}
                                onChange={handleDateChange1}
                                className="form-control floating datetimepicker"
                                type="date"
                                placeholderText="04/10/2023"
                                dateFormat="dd-MM-yyyy"
                              />
                            </div>
                            <label className="focus-label">
                              {t("Starting Date")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <div className="cal-icon">
                              <DatePicker
                                selected={selectedDate1}
                                onChange={handleDateChange1}
                                className="form-control floating datetimepicker"
                                type="date"
                                placeholderText="04/10/2023"
                                dateFormat="dd-MM-yyyy"
                              />
                            </div>
                            <label className="focus-label">
                              {t("Complete Date")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              defaultValue="BE Computer Science"
                              className="form-control floating"
                            />
                            <label className="focus-label">{t("Degree")}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              defaultValue="Grade A"
                              className="form-control floating"
                            />
                            <label className="focus-label">{t("Grade")}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">
                        {t("Education Informations")}
                        <Link to="#" className="delete-icon">
                          <i className="fa-regular fa-trash-can" />
                        </Link>
                      </h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              defaultValue="Oxford University"
                              className="form-control floating"
                            />
                            <label className="focus-label">
                              {t("Institution")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              defaultValue="Computer Science"
                              className="form-control floating"
                            />
                            <label className="focus-label">
                              {t("Subject")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <div className="cal-icon">
                              <DatePicker
                                selected={selectedDate1}
                                onChange={handleDateChange1}
                                className="form-control floating datetimepicker"
                                type="date"
                                placeholderText="04/10/2023"
                                dateFormat="dd-MM-yyyy"
                              />
                            </div>
                            <label className="focus-label">
                              {t("Starting Date")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <div className="cal-icon">
                              <DatePicker
                                selected={selectedDate1}
                                onChange={handleDateChange1}
                                className="form-control floating datetimepicker"
                                type="date"
                                dateFormat="dd-MM-yyyy"
                              />
                            </div>
                            <label className="focus-label">
                              {t("Complete Date")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              defaultValue="BE Computer Science"
                              className="form-control floating"
                            />
                            <label className="focus-label">{t("Degree")}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              defaultValue="Grade A"
                              className="form-control floating"
                            />
                            <label className="focus-label">{t("Grade")}</label>
                          </div>
                        </div>

                        <div className="submit-section">
                        <button
                          className="btn btn-primary submit-btn"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          type="reset"
                        >
                          {t("Save")}
                        </button>
                      </div>

                      </div>
                      <div className="add-more">
                        <Link to="#">
                          <i className="fa-solid fa-plus-circle" />{" "}
                          {t("Add More")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
               
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Education Modal */}

      {/* Experience Modal */}
      <div
        id="experience_info"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t("Experience Informations")}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-scroll">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">
                        {t("Experience Informations")}
                        <Link to="#" className="delete-icon">
                          <i className="fa-regular fa-trash-can" />
                        </Link>
                      </h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              className="form-control floating"
                              defaultValue="Digital Devlopment Inc"
                            />
                            <label className="focus-label">
                              {t("Company Name")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              className="form-control floating"
                              defaultValue="United States"
                            />
                            <label className="focus-label">
                              {t("Location")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              className="form-control floating"
                              defaultValue="Web Developer"
                            />
                            <label className="focus-label">
                              {t("Job Position")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <div className="cal-icon">
                              <DatePicker
                                selected={selectedDate1}
                                onChange={handleDateChange1}
                                className="form-control floating datetimepicker"
                                type="date"
                                placeholderText="04/10/2023"
                                dateFormat="dd-MM-yyyy"
                              />
                            </div>
                            <label className="focus-label">
                              {t("Period From")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <div className="cal-icon">
                              <DatePicker
                                selected={selectedDate1}
                                onChange={handleDateChange1}
                                className="form-control floating datetimepicker"
                                type="date"
                                placeholderText="04/10/2023"
                                dateFormat="dd-MM-yyyy"
                              />
                            </div>
                            <label className="focus-label">
                              {t("Period To")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">
                        {t("Experience Informations")}
                        <Link to="#" className="delete-icon">
                          <i className="fa-regular fa-trash-can" />
                        </Link>
                      </h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              className="form-control floating"
                              defaultValue="Digital Devlopment Inc"
                            />
                            <label className="focus-label">
                              {t("Company Name")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              className="form-control floating"
                              defaultValue="United States"
                            />
                            <label className="focus-label">
                              {t("Location")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <DatePicker
                              selected={selectedDate1}
                              onChange={handleDateChange1}
                              className="form-control floating datetimepicker"
                              type="date"
                              placeholderText="04/10/2023"
                              dateFormat="dd-MM-yyyy"
                            />
                            <label className="focus-label">
                              {t("Job Position")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <div className="cal-icon">
                              <DatePicker
                                selected={selectedDate1}
                                onChange={handleDateChange1}
                                className="form-control floating datetimepicker"
                                type="date"
                                placeholderText="04/10/2023"
                                dateFormat="dd-MM-yyyy"
                              />
                            </div>
                            <label className="focus-label">
                              {t("Period From")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <div className="cal-icon">
                              <input
                                type="text"
                                className="form-control floating datetimepicker"
                                defaultValue="08/06/2018"
                              />
                            </div>
                            <label className="focus-label">
                              {t("Period To")}
                            </label>
                          </div>
                        </div>

                        <div className="submit-section">
                        <button
                          className="btn btn-primary submit-btn"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          type="reset"
                        >
                          {t("Save")}
                        </button>
                      </div>

                      </div>
                      <div className="add-more">
                        <Link to="#">
                          <i className="fa-solid fa-plus-circle" />{" "}
                          {t("Add More")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Experience Modal */}

      {/* Family Info Modal */}
      <div
        id="family_info_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title"> {t("Family Informations")}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-scroll">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">
                        {t("Family Member")}
                        <Link to="#" className="delete-icon">
                          <i className="fa-regular fa-trash-can" />
                        </Link>
                      </h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3">
                            <label className="col-form-label">
                              {t("Name")} <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3">
                            <label className="col-form-label">
                              {t("Relationship")}
                              <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3">
                            <label className="col-form-label">
                              {t("Date of birth")}
                              <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3">
                            <label className="col-form-label">
                              {t("Phone")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">
                        {t("Education Informations")}
                        <Link to="#" className="delete-icon">
                          <i className="fa-regular fa-trash-can" />
                        </Link>
                      </h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3">
                            <label className="col-form-label">
                              {t("Name")} <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3">
                            <label className="col-form-label">
                              {t("Relationship")}
                              <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3">
                            <label className="col-form-label">
                              {t("Date of birth")}
                              <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3">
                            <label className="col-form-label">
                              {t("Phone")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>

                        <div className="submit-section">
                        <button
                          className="btn btn-primary submit-btn"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          type="reset"
                        >
                          {t("Save")}
                        </button>
                      </div>

                      </div>
                      <div className="add-more">
                        <Link to="#">
                          <i className="fa-solid fa-plus-circle" />
                          {t("Add More")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Family Info Modal */}
    </>
  );
};

export default PersonalInformationModelPopup;
