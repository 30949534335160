import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Avatar_16,
  Avatar_02,
  Avatar_05,
  Avatar_09,
  Avatar_10,
} from "../../../Routes/ImagePath/index";

import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import React, { useState, useEffect } from "react";

import AuthUser from "../../AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../../Spinner";

import Swal from "sweetalert2";

const ContaBancariaEmpresaModal = ({ onSubmissionSuccess, contaP, bancos }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user, user_permissoes, verificarPermissao } =
    AuthUser();

  const [banco_id, setBancoID] = useState(-1);
  const [IBAN, setIBAN] = useState("");
  const [NIB, setNIB] = useState("");
  const [tipo, setTipo] = useState(1);
  const [conta, setConta] = useState(1);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState();

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event("click"));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTipo(1);
        setInputs(contaP);
        setBancoID(contaP?.banco_id);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [contaP]);

  // REGISTO NOVO

  const submitFormNovo = async () => {
    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {
        toggleLoading(true);

        await registar();
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else {
          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      console.log("Clicou em submeter");

      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const registar = async () => {
    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {
        const formData = new FormData();
        formData.append("user_id", 0);
        formData.append("conta", conta);
        formData.append("banco_id", banco_id);
        formData.append("IBAN", IBAN);
        formData.append("NIB", NIB);
        formData.append("tipo", tipo);

        const response = await http.post("/conta/register", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.message == "Registo feito com sucesso") {
          setConta("");
          setIBAN("");
          setNIB("");

          triggerCloseButtonClick("closeButton30");

          onSubmissionSuccess();
        }
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  // ATUALIZAR INFORMACAO

  const submitUpdateForm = async () => {
    if (verificarPermissao("UTILIZADORES", "atualizar")) {
      try {
        toggleLoading(true);

        if(banco_id == -1){

          Swal.fire({
            title: "BANCO INVALIDO",
            text: "Selecione um banco, ou redifina o mesmo.",
            type: "error",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Certo, entendi!",
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-danger ml-1",
            buttonsStyling: false,
          });

          return ;
        }

        await updateInfo();
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else {
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      });
    }
  };

  const updateInfo = async () => {
    if (verificarPermissao("UTILIZADORES", "atualizar")) {
      try {
        const formData = new FormData();
        formData.append("id", inputs?.id);
        formData.append("conta", inputs.conta);
        formData.append("banco_id", banco_id);
        formData.append("IBAN", inputs.IBAN);
        formData.append("NIB", inputs.NIB);
        formData.append("tipo", tipo);

        const response = await http.post(`/conta/update`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        triggerCloseButtonClick("closeButton40");
        onSubmissionSuccess();

      } catch (error) {
        console.error(error);
        toast.error(
          "Ocorreu um erro ao atualizar a informação pessoal. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      });
    }
  };

  const renderOptionBancos = (bancos) => {
    return bancos?.map((banco) => (
      <React.Fragment key={banco.id}>
        <option value={banco.id}>{banco.sigla+" - "+banco.nome}</option>
      </React.Fragment>
    ));
  };

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  return (
    <>
      {/* Add Task Modal */}
      <div
        id="add_conta_modal"
        className="modal modal-lg custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                {t("Add Conta Bancaria Empresarial")}
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="closeButton30"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <div className="card">
                <div className="card-body">
                <hr className="mt-4"></hr>
                  <form>
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <select
                            className="form-control select"
                            onChange={(e) => setBancoID(e.target.value)}
                          >
                            <option value={-1}>Escolha o banco</option>
                            {renderOptionBancos(bancos)}
                          </select>

                          <label className="focus-label">
                            {t("Banco")} <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <input
                            type="number"
                            className="form-control"
                            onChange={(e) => setConta(e.target.value)}
                          />
                          <label className="focus-label">
                            {t("Nº De Conta")}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <div>
                            <input
                              className="form-control"
                              type="text"
                              onChange={(e) => setIBAN(e.target.value)}
                            />
                            <label className="focus-label">{t("IBAN")}</label>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <input
                            className="form-control"
                            type="text"
                            onChange={(e) => setNIB(e.target.value)}
                          />
                          <label className="focus-label">
                            {t("NIB")} <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>

                      <hr className="mt-4"></hr>

                      <div className="submit-section">
                        <a
                          className="btn btn-primary submit-btn"
                          onClick={submitFormNovo}
                        >
                          {loading ? "Carregando" : t("Submit")}
                        </a>
                      </div>

                      
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Task Modal */}

      {/* Edit Task Modal */}
      <div
        id="edit_conta_modal"
        className="modal modal-lg custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                {t("Editar Conta Bancaria Empresarial")}
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="closeButton40"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <div className="card">
                <div className="card-body">
                <hr className="mt-4"></hr>
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <select
                            className="form-control select"
                            value={banco_id}
                            onChange={(e) => setBancoID(e.target.value)}
                          >
                            <option value={-1}>Escolha o banco</option>
                            {renderOptionBancos(bancos)}
                          </select>
                          <label className="focus-label">
                            {t("Banco")} <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <input
                            type="number"
                            className="form-control"
                            name="conta"
                            value={inputs?.conta}
                            onChange={(e) => handleInputsValueChanged(e)}
                          />

                          <label className="focus-label">
                            {t("Nº de Conta")}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <input
                            className="form-control"
                            type="text"
                            name="IBAN"
                            value={inputs?.IBAN}
                            onChange={(e) => handleInputsValueChanged(e)}
                          />

                          <label className="focus-label">{t("IBAN")}</label>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <input
                            className="form-control"
                            type="text"
                            name="NIB"
                            value={inputs?.NIB}
                            onChange={(e) => handleInputsValueChanged(e)}
                          />
                          <label className="focus-label">{t("NIB")}</label>
                        </div>
                      </div>

                      <hr className="mt-4"></hr>

                      <div className="submit-section">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => submitUpdateForm()}
                        >
                          {t("Atualizar Informação")}
                        </button>
                      </div>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Task Modal */}

      <ToastContainer />
    </>
  );
};

export default ContaBancariaEmpresaModal;
