/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  User1,
  User2,
  User3,
  User4,
  User5,
  User6,
  User7,
  User8,
  User9,
  User10,
} from "../../../../Routes/ImagePath";
import DeleteModal from "../../../../components/modelpopup/DeleteModal";
import ProjectModelPopup from "../../../../components/modelpopup/ProjectModelPopup";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import ProjectsFilter from "../../../../components/ProjectsFilter";
import { useTranslation } from "react-i18next";

import { format } from 'date-fns';

import Swal from "sweetalert2";

import AuthUser from "../../../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../../../components/Spinner";
import PlanificacaoModal from "../../../../components/Administration/planificacao/PlanificacaoModal";

const Project = () => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [selectedProjects, setSelectedProjects] = useState([]);

  const [projetos_id_array, setProjetosIDarray] = useState([]);
  const [projetos, setProjetos] = useState([]);
  const [projetoSelecionado, setProjetoSelecionado] = useState();
  const [filteredProjects, setFilteredProjects] = useState([]);

  const [utilizadores, setUtilizadores] = useState([]);
  const [missoes, setMissoes] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getToken, user, getMissao, logout } = AuthUser();
  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);


  useEffect(() => {

    if (getToken() == undefined) {
      logout();
      //return <Redirect to="/signIn" />;
      //props.history.push('/trainning/system/signIn');
      //history.push('');
      navigate("/");
    } else {

      const fetchData = async () => {
        try {
          // Chama a função assíncrona getUtilizador()
          await getProjetos();
          await getUtilizadores();
          await getMissoes();
        } catch (error) {
          console.error('Erro ao obter utilizadores:', error);
        }
      };

      // Chama a função fetchData() para iniciar o processo
      fetchData();

    }

  }, []);

  const getMissoes = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/missoes_ativas');

      setMissoes(res.data.missoes);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getProjetos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/projetos/'+getMissao()?.id);

      setProjetos(res.data.projetos);

      console.log('projetos :');
      console.log(projetos);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getUtilizadores = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/utilizadores/ativados/'+getMissao()?.id);

      setUtilizadores(res.data.utilizadores);

      console.log('Utilizadores : ');
      console.log(res.data.utilizadores);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleSubmissionSuccess = (projectIdSubmited) => {

    if (projectIdSubmited)
      navigate('/project-view/' + projectIdSubmited?.id);
    else {
      setFilteredProjects([]);
      getProjetos();
    }

  };

  const chamarModalEliminar = (idSelecionado) => {

    setProjetoSelecionado(idSelecionado);

    const editButton = document.getElementById('triggerModalEliminar');

    console.log('Projeto selecionado:')
    console.log(projetoSelecionado);

    if (editButton) {
      editButton.click();
    }

  }

  const chamarModalEdit = (idSelecionado) => {

    setProjetoSelecionado(idSelecionado);

    const editButton = document.getElementById('triggerModalEdit');

    console.log('Projeto selecionado:')
    console.log(projetoSelecionado);

    if (editButton) {
      editButton.click();
    }

  }

  const chamarModalPlanificacao = () => {

    const selectedProjectsString = selectedProjects.join(';');

    setProjetosIDarray(selectedProjectsString);

    const editButton = document.getElementById('triggerModalPlanificacao');

    if (editButton) {
      editButton.click();
    }

  }

  const handleCheckboxChange = (e) => {
    const { value } = e.target;

    // Check if the project ID is already in the selectedProjects array
    const isSelected = selectedProjects.includes(value);

    if (isSelected) {
      // Remove the project ID from the selectedProjects array
      setSelectedProjects(selectedProjects.filter(id => id !== value));
    } else {
      // Add the project ID to the selectedProjects array
      setSelectedProjects([...selectedProjects, value]);
    }
  };

  const handleFilterSubmit = (filteredProjects) => {
    setFilteredProjects(filteredProjects);
  };

  const stripHtmlTags = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const aprovarProjeto = async (projeto_id, estado) => {

    if (user?.tipo == 1) {

      Swal.fire({
        title: estado == 1 ? 'Aprovar projeto' : 'Reprovar projeto',
        text: "Tem a certeza que pretende " + (estado == 1 ? 'aprovar' : 'reprovar') + " este projeto ?",
        type: "confirm",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (t) {

        if (t.isConfirmed) {
          try {

            setLoading(true);

            const formData = new FormData();
            formData.append('id', projeto_id);
            formData.append('estado', estado);

            const response = await http.post('/projeto/aprovar', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });

            setLoading(false);
            //Refresh colaboradores list
            getProjetos();

            // Show a success toast
            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              onClose: () => {

                setLoading(false);
                //Refresh colaboradores list
                //getProjetos();

              }
            });

          } catch (error) {

            setLoading(false);
            console.error(error);

            toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        }

      });

    } else {

      toast.error('Seu utilizador não tem permissão para aprovar ou negar aprovação de projeto', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });

    }




  };

  const arquivarProjeto = async (projeto_id, estado) => {

    if (user?.tipo == 1) {

      Swal.fire({
        title: estado == 2 ? 'Arquivar projeto' : 'Desarquivar projeto',
        text: "Tem a certeza que pretende " + (estado == 2 ? 'arquivar' : 'desarquivar') + " este projeto ?",
        type: "confirm",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (t) {

        if (t.isConfirmed) {
          try {

            setLoading(true);

            const formData = new FormData();
            formData.append('id', projeto_id);
            formData.append('estado', estado);

            const response = await http.post('/projeto/arquivar', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });

            setLoading(false);
            //Refresh colaboradores list
            getProjetos();

            // Show a success toast
            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              onClose: () => {

                setLoading(false);
                //Refresh colaboradores list
                //getProjetos();

              }
            });

          } catch (error) {

            setLoading(false);
            console.error(error);

            toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        }

      });

    } else {

      toast.error('Seu utilizador não tem permissão para aprovar ou negar aprovação de projeto', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });

    }
  };


  const abrirFecharProjeto = async (projeto_id, estado) => {

    try {
   

      const formData = new FormData();
      formData.append('estado', estado);
      formData.append('projeto_id', projeto_id);

      const response = await http.post('/projeto/activate', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });



      //Toast is not working here
      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {

            getProjetos();

        }
      });

    } catch (error) {

      setLoading(false);
      console.error(error);

      toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }



  };


  if (loading) return (<><Spinner><ToastContainer /></Spinner></>); else return (
    <div>
      <div className="page-wrapper">

        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={t('Projects')}
            title="Dashboard"
            subtitle={t('Projects')}
            modal="#create_project"
            name={t('Create Project')}
            Linkname="/projects"
            Linkname1="/project-list"
          />

          {/* /Page Header */}
          <ProjectsFilter onFilterSubmit={handleFilterSubmit} />
          <div className="row">

            {user?.tipo < 2 ?
              <div className="col-12 d-flex my-2">
                <span className="btn btn-danger" onClick={() => chamarModalPlanificacao()}><i className="fa fa-calendar"></i>{' '}Exportar planificação geral</span>
              </div> : ''}



            {filteredProjects.length > 0 ? filteredProjects?.filter(project => {

              if (getMissao()?.id != project.missao_id) {
                return false;
              }

              // Excluir se o projeto esta arquivado
              if (project.estado == 2) {
                return false;
              }

              // Se o tipo de usuário for 1, retorna true para todos os projetos
              if (user.tipo == 1) {
                return true;
              }

              // Se o projeto foi criado pelo usuário
              if (project.criado_por == user.id) {
                return true;
              }

              // Se o usuário é participante do projeto
              const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
              if (participante) {
                return true;
              }

              return false;

            })?.map((project) => (
              <div
                className="col-lg-4 col-sm-6 col-md-4 col-xl-3 d-flex"
                key={project?.id}
              >
                <div className="card w-100">
                  <div className="card-body">
                    <div className="dropdown dropdown-action profile-action">
                      <Link
                        to="#"
                        className="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="material-icons">more_vert</i>
                      </Link>
                      <div className="dropdown-menu dropdown-menu-right">
                        <span
                          className="dropdown-item"
                          title={project?.nome}
                          onClick={() => aprovarProjeto(project?.id, 1)}
                        >
                          <i className="fa fa-thumbs-up text-success m-r-5" /> {t('Approve')}
                        </span>
                        <span
                          className="dropdown-item"
                          title={project?.nome}
                          onClick={() => aprovarProjeto(project?.id, 2)}
                        >
                          <i className="fa fa-thumbs-down text-danger m-r-5" /> {t('Negate')}
                        </span>
                        {
                                                project?.estado == 1 ?
                                            <span
                                            className="dropdown-item"
                                                onClick={() => abrirFecharProjeto(project?.id,0)}
                                            >
                                                <i className="fa fa-check" />{t(' Abrir ')}&nbsp;&nbsp;
                                            </span>:
                                            <span
                                            className="dropdown-item"
                                                onClick={() => abrirFecharProjeto(project?.id,1)}
                                            >
                                                <i className="fa fa-check" />{t(' Fechar ')}&nbsp;&nbsp;
                                            </span>
                                            }
                        <span
                          className="dropdown-item"
                          title={project?.nome}
                          onClick={() => chamarModalEdit(project)}
                        >
                          <i className="fa fa-pencil m-r-5" /> {t('Edit')}
                        </span>
                        <span
                          className="dropdown-item"
                          title={project?.nome}
                          onClick={() => arquivarProjeto(project?.id, 2)}
                        >
                          <i className="fa fa-trash m-r-5" /> {t('Arquive')}
                        </span>
                      </div>
                    </div>

                    <h4 className="project-title">



                      {
                        project?.aprovado == 0 ? <><img width={24}
                          height={24} title={t('Pending to approve')} src={url_root + "/storage/uploads/icons/siren.png"} /></> : project?.aprovado == 1 ? <span title={t('Approved')}><i className="fa fa-thumbs-up text-success"></i></span> : <span title={t('Negated')}><i className="fa fa-thumbs-down text-danger"></i></span>
                      }

                      {
                        (project?.aprovado == 0 || project?.aprovado == 2) ? <></> :
                          project?.estado == 1 ? <><span
                            className={"badge bg-inverse-success"}
                          >
                            <i className="fa fa-check"></i>&nbsp;&nbsp; <span>{t('Closed')}</span>
                          </span>&nbsp;&nbsp;</> : ''
                      }

                      {
                        (project?.aprovado == 0 || project?.aprovado == 2) ? <></> : ((new Date(project?.data_fim) < new Date()) && project?.estado != 1) ? (<><span
                          className={"badge bg-inverse-danger"}
                        >
                          <i className="fa fa-clock"></i>&nbsp;&nbsp; <span>{t('Pending')}</span>
                        </span>&nbsp;&nbsp;</>) : (project?.estado != 1 ? (<span
                          className={"badge bg-inverse-warning"}
                        >
                          <i className="fa fa-list"></i>&nbsp;&nbsp; <span>{t('Opened')}</span>
                        </span>) : '')
                      }

                    </h4>
                    <h4 className="project-title">
                      <input type="check" className="form-control" value={project?.id} />
                      <Link to={'/project-view/' + project?.id}>{project?.projecto_code + ' - ' + project?.nome}</Link>
                    </h4>
                    <h4 className="project-title">
                      <Link to={'/task-board/' + project?.id} title="Quadro de tarefas" className={"badge bg-inverse-info"}>
                        <i className="fa fa-tasks"></i>&nbsp;{t('View Task-board')}
                      </Link>
                    </h4>
                    <small className="block text-ellipsis m-b-15">

                      <span className="text-xs">{project?.tarefas?.filter(tarefa => tarefa.estado == 0).length}</span>{" "}
                      <span className="text-warning">{t('open tasks')}, </span>

                      <span className="text-xs">
                        {project?.tarefas?.filter(tarefa => tarefa.estado == 1).length}
                      </span>{" "}
                      <span className="text-success">{t('tasks completed')}</span>

                      {", "}
                      <span className="text-xs">
                        {project?.tarefas?.filter(tarefa => (((new Date(tarefa?.prazo) < new Date()) && tarefa?.estado != 1))).length}
                      </span>{" "}
                      <span className="text-danger">{t('Pending Tasks')}</span>

                    </small>
                    <p className="text-muted">{stripHtmlTags(project?.descricao).length > 140 ? stripHtmlTags(project?.descricao).substring(0, 140) + '...' : stripHtmlTags(project?.descricao)}</p>

                    <div className="pro-deadline m-b-15">
                      <div className="sub-title">{t('Deadline')}:</div>
                      <div className="text-muted">{format(new Date(project?.data_fim), 'dd/MM/yyyy')}</div>
                    </div>
                    <div className="project-members m-b-15">
                      <div>{t('Project Leader')}:</div>
                      <ul className="team-members">
                        {project?.colaboradores?.filter(user => user.tipo == 1).map(user => (
                          <li>
                            <Link
                              to="#"
                              data-bs-toggle="tooltip"
                              title={user?.colaborador?.name}
                            >
                              <img src={url_root + "/storage/" + user?.colaborador?.fotografia} alt={user?.colaborador?.name} title={user?.colaborador?.name} />
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="project-members m-b-15">
                      <div>{t('Team')} :</div>
                      <ul className="team-members">
                        {project?.colaboradores?.filter(user => user.tipo == 2).slice(0, 4).map(user => (
                          <li>
                            <Link
                              to="#"
                              data-bs-toggle="tooltip"
                              title={user?.colaborador?.name}
                            >
                              <img src={url_root + "/storage/" + user?.colaborador?.fotografia} alt={user?.colaborador?.name} title={user?.colaborador?.name} />
                            </Link>
                          </li>
                        ))}

                        <li className="dropdown avatar-dropdown">



                          {
                            project?.colaboradores?.filter(colaborador => colaborador.tipo == 2).length > 4 ? <Link
                              to="#"
                              className="all-users dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >

                              <span>+{project?.colaboradores?.filter(colaborador => colaborador.tipo == 2).length - 4}</span>

                            </Link> : ''
                          }


                          <div className="dropdown-menu dropdown-menu-right">
                            <div className="avatar-group">
                              {project?.colaboradores?.filter(user => user.tipo == 2).map(user => (
                                <Link className="avatar avatar-xs" to="#">
                                  <img src={url_root + "/storage/" + user?.colaborador?.fotografia} alt={user?.colaborador?.name} title={user?.colaborador?.name} />
                                </Link>

                              ))}
                            </div>
                            <div className="avatar-pagination">
                              <ul className="pagination">
                                <li className="page-item">
                                  <Link
                                    className="page-link"
                                    to="#"
                                    aria-label="Previous"
                                  >
                                    <span aria-hidden="true">«</span>
                                    <span className="sr-only">{t('Previous')}</span>
                                  </Link>
                                </li>
                                <li className="page-item">
                                  <Link className="page-link" to="#">
                                    1
                                  </Link>
                                </li>
                                <li className="page-item">
                                  <Link className="page-link" to="#">
                                    2
                                  </Link>
                                </li>
                                <li className="page-item">
                                  <Link
                                    className="page-link"
                                    to="#"
                                    aria-label="Next"
                                  >
                                    <span aria-hidden="true">»</span>
                                    <span className="sr-only">{t('Next')}</span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <p className="m-b-5">
                      {t('Progress')}
                      <span className="text-success float-end">
                        {Math.round(((project?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / project?.tarefas?.length) * 100) ? Math.round(((project?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / project?.tarefas?.length) * 100) : 0}%</span>
                    </p>

                    <div className="progress progress-xs mb-0">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        data-bs-toggle="tooltip"
                        title={(Math.round(((project?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / project?.tarefas?.length) * 100)) + '%'}
                        style={{ width: `${Math.round(((project?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / project?.tarefas?.length) * 100)}%` }}
                      />
                    </div>

                  </div>
                </div>
              </div>
            )) : projetos?.filter(project => {

              // Excluir se a missão do projeto é diferente da missão na sessão
              if (getMissao()?.id != project.missao_id) {
                return false;
              }

              // Excluir se o projeto esta arquivado
              if (project.estado == 2) {
                return false;
              }

              // Se o tipo de usuário for 1, retorna true para todos os projetos
              if (user.tipo == 1) {
                return true;
              }

              // Se o projeto foi criado pelo usuário
              if (project.criado_por == user.id) {
                return true;
              }

              // Se o usuário é participante do projeto
              const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);

              if (participante) {
                return true;
              }

              return false;

            })?.map((project) => (
              <div
                className="col-lg-4 col-sm-6 col-md-4 col-xl-3 d-flex"
                key={project?.id}
              >
                <div className="card w-100">
                  <div className="card-body">

                    <div className="dropdown dropdown-action profile-action">

                      <Link
                        to="#"
                        className="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="material-icons">more_vert</i>
                      </Link>

                      <div className="dropdown-menu dropdown-menu-right">
                        <span
                          className="dropdown-item"
                          title={project?.nome}
                          onClick={() => aprovarProjeto(project?.id, 1)}
                        >
                          <i className="fa fa-thumbs-up text-success m-r-5" /> {t('Approve')}
                        </span>
                        <span
                          className="dropdown-item"
                          title={project?.nome}
                          onClick={() => aprovarProjeto(project?.id, 2)}
                        >
                          <i className="fa fa-thumbs-down text-danger m-r-5" /> {t('Negate')}
                        </span>
                        {
                                                project?.estado == 1 ?
                                            <span
                                            className="dropdown-item"
                                                onClick={() => abrirFecharProjeto(project?.id,0)}
                                            >
                                                <i className="fa fa-check" />{t(' Abrir ')}&nbsp;&nbsp;
                                            </span>:
                                            <span
                                            className="dropdown-item"
                                                onClick={() => abrirFecharProjeto(project?.id,1)}
                                            >
                                                <i className="fa fa-check" />{t(' Fechar ')}&nbsp;&nbsp;
                                            </span>
                                            }
                        <span
                          className="dropdown-item"
                          title={project?.nome}
                          onClick={() => chamarModalEdit(project)}
                        >
                          <i className="fa fa-pencil m-r-5" /> {t('Edit')}
                        </span>

                        <a title={project?.nome} className="dropdown-item" href={url_root + '/relatorio_produtividade/' + project?.id} target="_blank" rel="noreferrer"><i className="fa fa-file-pdf"></i>&nbsp;Relatório de produtividade</a>

                        <span
                          className="dropdown-item"
                          title={project?.nome}
                          onClick={() => arquivarProjeto(project?.id, 2)}
                        >
                          <i className="fa fa-trash m-r-5" /> {t('Arquive')}
                        </span>
                      </div>

                    </div>

                    <h4 className="project-title">

                      {
                        project?.aprovado == 0 ? <><img width={24}
                          height={24} title={t('Pending to approve')} src={url_root + "/storage/uploads/icons/siren.png"} /></> : project?.aprovado == 1 ? <span title={t('Approved')}><i className="fa fa-thumbs-up text-success"></i></span> : <span title={t('Negated')}><i className="fa fa-thumbs-down text-danger"></i></span>
                      }

                      {
                        (project?.aprovado == 0 || project?.aprovado == 2) ? <></> :
                          project?.estado == 1 ? <><span
                            className={"badge bg-inverse-success"}
                          >
                            <i className="fa fa-check"></i>&nbsp;&nbsp; <span>{t('Closed')}</span>
                          </span>&nbsp;&nbsp;</> : ''
                      }

                      {
                        (project?.aprovado == 0 || project?.aprovado == 2) ? <></> : ((new Date(project?.data_fim) < new Date()) && project?.estado != 1) ? (<><span
                          className={"badge bg-inverse-danger"}
                        >
                          <i className="fa fa-clock"></i>&nbsp;&nbsp; <span>{t('Pending')}</span>
                        </span>&nbsp;&nbsp;</>) : (project?.estado != 1 ? (<span
                          className={"badge bg-inverse-warning"}
                        >
                          <i className="fa fa-list"></i>&nbsp;&nbsp; <span>{t('Opened')}</span>
                        </span>) : '')
                      }
                    </h4>

                    {user?.tipo < 2 ?
                      <label className="custom_check">
                        <input type="checkbox"
                          key={project.id}
                          code={project.id}
                          value={project.id}
                          onChange={handleCheckboxChange}
                          checked={selectedProjects.includes(String(project.id))} />
                        <span className="checkmark" />
                      </label> : ''}

                    <h4 className="project-title">

                      <Link to={'/project-view/' + project?.id}>{project?.projecto_code + ' - ' + project?.nome}</Link>
                    </h4>

                    <h4 className="project-title">
                      <Link to={'/task-board/' + project?.id} title="Quadro de tarefas" className={"badge bg-inverse-info"}>
                        <i className="fa fa-tasks"></i>&nbsp;{t('View Task-board')}
                      </Link>
                    </h4>

                    <small className="block text-ellipsis m-b-15">

                      <span className="text-xs">{project?.tarefas?.filter(tarefa => tarefa.estado == 0).length}</span>{" "}
                      <span className="text-warning">{t('open tasks')}, </span>

                      <span className="text-xs">
                        {project?.tarefas?.filter(tarefa => tarefa.estado == 1).length}
                      </span>{" "}
                      <span className="text-success">{t('tasks completed')}</span>

                      {", "}
                      <span className="text-xs">
                        {project?.tarefas?.filter(tarefa => (((new Date(tarefa?.prazo) < new Date()) && tarefa?.estado != 1))).length}
                      </span>{" "}
                      <span className="text-danger">{t('Pending Tasks')}</span>



                    </small>
                    <p className="text-muted">{stripHtmlTags(project?.descricao).length > 140 ? stripHtmlTags(project?.descricao).substring(0, 140) + '...' : stripHtmlTags(project?.descricao)}</p>

                    <div className="pro-deadline m-b-15">
                      <div className="sub-title">{t('Deadline')}:</div>
                      <div className="text-muted">{format(new Date(project?.data_fim), 'dd/MM/yyyy')}</div>
                    </div>
                    <div className="project-members m-b-15">
                      <div>{t('Project Leader')}:</div>
                      <ul className="team-members">
                        {project?.colaboradores?.filter(user => user.tipo == 1).map(user => (
                          <li>
                            <Link
                              to="#"
                              data-bs-toggle="tooltip"
                              title={user?.colaborador?.name}
                            >
                              <img src={url_root + "/storage/" + user?.colaborador?.fotografia} alt={user?.colaborador?.name} title={user?.colaborador?.name} />
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="project-members m-b-15">
                      <div>{t('Team')} :</div>
                      <ul className="team-members">
                        {project?.colaboradores?.filter(user => user.tipo == 2).slice(0, 4).map(user => (
                          <li>
                            <Link
                              to="#"
                              data-bs-toggle="tooltip"
                              title={user?.colaborador?.name}
                            >
                              <img src={url_root + "/storage/" + user?.colaborador?.fotografia} alt={user?.colaborador?.name} title={user?.colaborador?.name} />
                            </Link>
                          </li>
                        ))}

                        <li className="dropdown avatar-dropdown">

                          {
                            project?.colaboradores?.filter(colaborador => colaborador.tipo == 2).length > 4 ? <Link
                              to="#"
                              className="all-users dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >

                              <span>+{project?.colaboradores?.filter(colaborador => colaborador.tipo == 2).length - 4}</span>

                            </Link> : ''
                          }


                          <div className="dropdown-menu dropdown-menu-right">
                            <div className="avatar-group">
                              {project?.colaboradores?.filter(user => user.tipo == 2).map(user => (
                                <Link className="avatar avatar-xs" to="#">
                                  <img src={url_root + "/storage/" + user?.colaborador?.fotografia} alt={user?.colaborador?.name} title={user?.colaborador?.name} />
                                </Link>

                              ))}
                            </div>
                            <div className="avatar-pagination">
                              <ul className="pagination">
                                <li className="page-item">
                                  <Link
                                    className="page-link"
                                    to="#"
                                    aria-label="Previous"
                                  >
                                    <span aria-hidden="true">«</span>
                                    <span className="sr-only">{t('Previous')}</span>
                                  </Link>
                                </li>
                                <li className="page-item">
                                  <Link className="page-link" to="#">
                                    1
                                  </Link>
                                </li>
                                <li className="page-item">
                                  <Link className="page-link" to="#">
                                    2
                                  </Link>
                                </li>
                                <li className="page-item">
                                  <Link
                                    className="page-link"
                                    to="#"
                                    aria-label="Next"
                                  >
                                    <span aria-hidden="true">»</span>
                                    <span className="sr-only">{t('Next')}</span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <p className="m-b-5">
                      {t('Progress')}
                      <span className="text-success float-end">
                        {Math.round(((project?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / project?.tarefas?.length) * 100) ? Math.round(((project?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / project?.tarefas?.length) * 100) : 0}%</span>
                    </p>

                    <div className="progress progress-xs mb-0">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        data-bs-toggle="tooltip"
                        title={(Math.round(((project?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / project?.tarefas?.length) * 100)) + '%'}
                        style={{ width: `${Math.round(((project?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / project?.tarefas?.length) * 100)}%` }}
                      />
                    </div>

                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>



      </div>

      <ProjectModelPopup onSubmissionSuccess={handleSubmissionSuccess} projeto={projetoSelecionado} prioridadeParam={projetoSelecionado?.prioridade} tempoParam={projetoSelecionado?.tempo} semanalParam={projetoSelecionado?.semanal} utilizadores={utilizadores} missoes={missoes} />

      <DeleteModal Name={t('Delete Project')} objectoName={t('Project')} onSubmissionSuccess={handleSubmissionSuccess} id={projetoSelecionado?.id} endpoint={'projeto'} />

      <PlanificacaoModal projetos_array={projetos_id_array} />

      <span
        data-bs-toggle="modal"
        data-bs-target="#edit_project"
        id="triggerModalEdit"
      >
      </span>

      <span
        data-bs-toggle="modal"
        data-bs-target="#planifcacao_geral_modal"
        id="triggerModalPlanificacao"
      >
      </span>

      <span
        data-bs-toggle="modal"
        data-bs-target="#delete"
        id="triggerModalEliminar"
      >
      </span>

      <ToastContainer />

    </div>
  );
};

export default Project;
