import React, { useState, useEffect } from "react";

import Breadcrumbs from "../../../components/Breadcrumbs";

import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";

import AuthUser from "../../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../../components/Spinner";
import ClienteModal from "../../../components/Administration/comercial/ClienteModal";
import ClienteFilter from "../Administration/comercial/ClienteFilter";
import ClienteTable from "../Administration/comercial/ClienteTable";
import FornecedorTable from "../Administration/comercial/FornecedorTable";

const Fornecedor = () => {

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } = AuthUser();
  const [clientes, setclientes] = useState([]);  

  const [filteredclientes, setfilteredclientes] = useState([]);

  const { t } = useTranslation();

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  // Callback function to receive filtered users from UserFilter
  const handleFilterSubmit = (filteredclientes) => {
    setfilteredclientes(filteredclientes?.filter((cliente) => cliente?.tipo == 2)); 
  };


  const handleSubmissionSuccess = (clientes) => {
    // Refresh acessos state or trigger any necessary actions
    setclientes(clientes?.filter((cliente) => cliente?.tipo == 2));    
  };

  const getFornecedores = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/fornecedores/'+getMissao()?.id);

      setclientes(res?.data?.fornecedores);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  }; 
 

  useEffect(() => {

    const fetchData = async () => {
      try {

        // Chama a função assíncrona getUtilizador()
        await getFornecedores();

      } catch (error) {
        console.error('Erro ao obter clientes:', error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, []);

  if (verificarPermissao('CARGO', 'ver')) 
  if (loading) return (<><Spinner></Spinner></>); else return (
    <>
      (
      <>
    <div className="page-wrapper">

      <div className="content container-fluid">
        
        <Breadcrumbs
          maintitle={t('Fornecedor')}
          title="Dashboard"
          subtitle={t('Nossos fornecedores')}
          modal="#add_cliente_modal"
          name={t('Add Fornecedor')}
        />

        <ClienteFilter onFilterSubmit={handleFilterSubmit} />
        <FornecedorTable clientes={filteredclientes.length > 0 ? filteredclientes : clientes} tipo={2} />
        <ClienteModal onSubmissionSuccess={handleSubmissionSuccess} tipo={2} />


      </div>
    </div> </>
        ); </>
    );
  else
    return (<>
      <div className="page-wrapper cardhead">
        <div className="content ">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">SEM PERMISSÃO</h3>

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <div className="card">
                <div className="card-header">
                  <h4 className="card-title"><i className="fa fa-times-circle text-danger"></i>&nbsp; OPERAÇÃO NÃO AUTORIZADA</h4>
                </div>
                <div className="card-body">
                  <p>
                    Esta operação não é autorizada para o seu nivel de acesso ao sistema, por favor contacte o administrador do sistema.
                  </p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>)
};


export default Fornecedor;
