import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import {
  Avatar_02,
  Avatar_03,
  Avatar_04,
  Avatar_05,
  Avatar_08,
  Avatar_09,
  Avatar_10,
  Avatar_11,
  Avatar_12,
  Avatar_13,
  Avatar_19,
} from "../../../Routes/ImagePath";

import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import EmployeeListFilter from "../../../components/EmployeeListFilter";

import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../components/Spinner";
import AddColaboradorModal from "../../../components/Administration/Users/AddColaboradorModal";
import UserFilter from "../Administration/Users/UserFilter";
import EditColaboradorModal from "../../../components/Administration/Users/EditColaboradorModal";

import { startOfMonth, endOfMonth, format } from "date-fns";

const EmployeeList = () => {

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } = AuthUser();
  const [utilizadores, setUtilizador] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [missoes, setMissoes] = useState([]);
  const [accoes, setAccoes] = useState([]);
  const [utilizadorSelecionado, setUtilizadorSelecionado] = useState([]);

  const [filteredUsers, setFilteredUsers] = useState([]);

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const formatNumber = (number) => {
    return new Intl.NumberFormat("pt-PT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const handleSelect = (employee) => {
    setSelectedEmployees((prevSelected) => {
      if (prevSelected.includes(employee.id)) {
        return prevSelected.filter((id) => id !== employee.id);
      } else {
        return [...prevSelected, employee.id];
      }
    });
  };

  // Callback function to receive filtered users from UserFilter
  const handleFilterSubmit = (filteredUsers) => {
    setFilteredUsers(filteredUsers);
    setUtilizador(filteredUsers);
  };

  const handleSubmissionSuccess = () => {
    // Refresh acessos state or trigger any necessary actions
    setUtilizador([]);
    setFilteredUsers([]);
  };

  const getUtilizador = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/utilizadores/"+getMissao()?.id);

      setUtilizador(res.data.utilizadores);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getCargos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/cargos_ativos/"+getMissao()?.id);

      setCargos(res.data.cargos);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getDepartamentos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/departamentos_ativos/"+getMissao()?.id);

      setDepartamentos(res.data.departamentos);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getMissoes = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/missoes_ativas");

      setMissoes(res.data.missoes);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getAccoes = async () => {
    try {
      setLoading(true);

      const res = await http.get("/accoes");

      setAccoes(res.data.accoes);

      console.log("Acções");
      console.log(res.data.accoes);
    } catch (error) {
      console.error("Ocorreu um erro ao carregar os acções:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Chama a função assíncrona getUtilizador()
        //await getUtilizador();

        await getAccoes();

        await getCargos();
        await getDepartamentos();
        await getMissoes();
      } catch (error) {
        console.error("Erro ao obter utilizadores:", error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, []);

  const chamarModalEliminar = (idSelecionado) => {
    setUtilizadorSelecionado(idSelecionado);

    const editButton = document.getElementById("triggerModalEliminar");

    console.log("Utilizador selecionado:");
    console.log(utilizadorSelecionado);

    if (editButton) {
      editButton.click();
    }
  };

  const chamarModalEdit = (idSelecionado) => {
    setUtilizadorSelecionado(idSelecionado);

    const editButton = document.getElementById("triggerModalEdit");

    console.log("Utilizador selecionado:");
    console.log(utilizadorSelecionado);

    if (editButton) {
      editButton.click();
    }
  };


  const handleAtivarDesativar = async (id, newState) => {

    //console.log('Clicou aqui para ativar e desativar');

    if (verificarPermissao('UTILIZADORES', 'ativar')) {
      try {

        const formData = new FormData();
        formData.append('id', id);
        formData.append('estado', newState);

        const response = await http.post('/utilizador/activate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getUtilizador();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



        // Atualize a lista de utilizadores após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleResetSenha = (id) => {

    if (verificarPermissao('UTILIZADORES', 'atualizar')) {
      // Armazena o ID do usuário no estado resetSenhaUserId


      // Exibe a mensagem de confirmação
      Swal.fire({
        title: "Tem certeza que quer resetar a senha?",
        text: "Isso irá resetar a senha do usuário.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, resetar senha!",
      }).then((result) => {
        if (result.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação para resetar a senha
          setLoading(true);
          resetarSenha(id);
          setLoading(false);
        } else {
          // Se o usuário cancelar, limpe o estado resetSenhaUserId
          //setResetSenhaUserId(null);
          setLoading(false);
        }
      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const resetarSenha = async (id) => {
    if (verificarPermissao('UTILIZADORES', 'atualizar')) {
      try {
        // Gere uma senha aleatória no cliente
        const senhaAleatoria = Math.random().toString(36).slice(-8);

        // Exiba a senha antes de enviar a solicitação
        alert(`Nova Senha: ${senhaAleatoria}`);

        // Envie uma solicitação para a API para resetar a senha
        const response = await http.post(`/resetar-senha/${id}`, { novaSenha: senhaAleatoria });


        // Exiba uma mensagem de sucesso
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } catch (error) {
        // Exiba uma mensagem de erro se ocorrer um problema
        toast.error('Ocorreu um erro ao resetar a senha. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const columns = [
    {
      title: t('Name'),
      dataIndex: "name",
      render: (text, record) => (
        <div className="table-avatar">

          {/* <span
            onClick={() => handleResetSenha(record.id, text == 1 ? 0 : 1)}
          >
            <i className="fa fa-key"></i>
          </span>&nbsp; */}
          <Link to={"/profile/"+record.id} className="avatar">
            <img src={url_root + "/storage/" + record.fotografia} alt="foto" />
          </Link>
          <Link to={"/profile/"+record.id}>
            {text}
          </Link>
          

        </div>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Tel/Email",
      dataIndex: "email",
      render: (text, record) => (
        <div className="table-avatar">
          <span>
            {record.telefone}
          </span><br></br>
          <span>
            {record.email}
          </span>
        </div>
      ),
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Dept/cargo",
      dataIndex: "cargo_id",
      render: (text, record) => (
        <div className="table-avatar">
          <span>
            {'DPT : '} {record?.departamento?.designacao}
          </span><br></br>
          <span>
            {'Função : '}{record?.cargo?.designacao}
          </span>
        </div>
      ),
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: t('Status'),
      dataIndex: "estado",
      render: (text, record) => (
        <>
          <span
            className={
              text == 1
                ? "badge bg-inverse-success"
                : "badge bg-inverse-danger"
            }
          >
            {text == 1 ? 'Ativo' : 'Desativado'}
          </span>&nbsp;
          <span
            onClick={() => handleAtivarDesativar(record.id, text == 1 ? 0 : 1)}
          >
            <i className="fa fa-recycle"></i>
          </span>
        </>
      ),
      sorter: (a, b) => a.nivel_id.length - b.nivel_id.length,
    },

    {
      title: t('Action'),
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <span
              className="dropdown-item"
              title={record?.name}
              onClick={() => chamarModalEdit(record)}
            >
              <i className="fa fa-pencil m-r-5" /> {t('Edit')}
            </span>
            <span
              className="dropdown-item"
              title={record?.name}
              onClick={() => chamarModalEliminar(record)}
            >
              <i className="fa fa-trash m-r-5" /> {t('Delete')}
            </span>
          </div>
        </div>
      ),
    },
  ];


  const getTotalColaboradores = () => {
    return utilizadores?.length;
  };

  const getTotalHomens = () => {
    return utilizadores?.filter((utilizador) => utilizador?.genero == 2)
      ?.length;
  };

  const getTotalMulheres = () => {
    return utilizadores?.filter((utilizador) => utilizador?.genero == 1)
      ?.length;
  };

  const getTotalExpatriados = () => {
    return utilizadores?.filter((utilizador) => utilizador?.expatriado == 2)
      ?.length;
  };

  if (verificarPermissao("UTILIZADORES", "ver"))
    if (loading)
      return (
        <>
          <Spinner></Spinner>
        </>
      );
    else
      return (
        <>
          (
          <>
            <div>
              <div className="page-wrapper">
                {/* Page Content */}
                <div className="content container-fluid">
                  {/* Page Header */}
                  <Breadcrumbs
                    maintitle={t("Employee")}
                    title="Dashboard"
                    subtitle={t("Employee")}
                    modal="#add_user"
                    name={t("Add Employee")}
                    Linkname="/employees"
                    Linkname1="/employees-list"
                  />

<div className="row">
                    <div className="col-md-12">
                      <div className="card-group m-b-30">
                        {/* Total */}
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between mb-3">
                              <div>
                                <span className="d-block">
                                  {t("Colaboradores")}
                                </span>
                              </div>
                              <div>
                                <span className={"text-dark"}>
                                  {Math.round(
                                    (100 * getTotalColaboradores()) /
                                      getTotalColaboradores()
                                  )}
                                  %
                                </span>
                              </div>
                            </div>
                            <h3 className="mb-3">
                              {formatNumber(getTotalColaboradores())}
                            </h3>
                            <div
                              className="progress mb-2"
                              style={{ height: "5px" }}
                            >
                              <div
                                className="progress-bar bg-dark"
                                role="progressbar"
                                style={{
                                  width: `${Math.round(
                                    (100 * getTotalColaboradores()) /
                                      getTotalColaboradores()
                                  )}%`,
                                }}
                                aria-valuenow={40}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                        </div>

                        {/* Entrada */}
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between mb-3">
                              <div>
                                <span className="d-block">{t("Homens")}</span>
                              </div>
                              <div>
                                <span className={"text-primary"}>
                                  {Math.round(
                                    (100 * getTotalHomens()) /
                                      getTotalColaboradores()
                                  )
                                    ? Math.round(
                                        (100 * getTotalHomens()) /
                                          getTotalColaboradores()
                                      )
                                    : 0}
                                  %
                                </span>
                              </div>
                            </div>

                            <h3 className="mb-3">
                              {formatNumber(getTotalHomens())}
                            </h3>

                            <div
                              className="progress mb-2"
                              style={{ height: "5px" }}
                            >
                              <div
                                className="progress-bar bg-primary"
                                role="progressbar"
                                style={{
                                  width: `${Math.round(
                                    (100 * getTotalHomens()) /
                                      getTotalColaboradores()
                                  )}%`,
                                }}
                                aria-valuenow={40}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                        </div>

                        {/* Saidas */}
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between mb-3">
                              <div>
                                <span className="d-block">{t("Mulheres")}</span>
                              </div>
                              <div>
                                <span className={"text-warning"}>
                                  {Math.round(
                                    (100 * getTotalMulheres()) /
                                      getTotalColaboradores()
                                  )
                                    ? Math.round(
                                        (100 * getTotalMulheres()) /
                                          getTotalColaboradores()
                                      )
                                    : 0}
                                  %
                                </span>
                              </div>
                            </div>
                            <h3 className="mb-3">
                              {formatNumber(getTotalMulheres())}
                            </h3>
                            <div
                              className="progress mb-2"
                              style={{ height: "5px" }}
                            >
                              <div
                                className="progress-bar bg-warning"
                                role="progressbar"
                                style={{
                                  width: `${Math.round(
                                    (100 * getTotalMulheres()) /
                                      getTotalColaboradores()
                                  )}%`,
                                }}
                                aria-valuenow={40}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                        </div>

                        {/* Saidas */}
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between mb-3">
                              <div>
                                <span className="d-block">
                                  {t("Expatriados")}
                                </span>
                              </div>
                              <div>
                                <span className={"text-primary"}>
                                  {Math.round(
                                    (100 * getTotalExpatriados()) /
                                      getTotalColaboradores()
                                  )
                                    ? Math.round(
                                        (100 * getTotalExpatriados()) /
                                          getTotalColaboradores()
                                      )
                                    : 0}
                                  %
                                </span>
                              </div>
                            </div>
                            <h3 className="mb-3">
                              {formatNumber(getTotalExpatriados())}
                            </h3>
                            <div
                              className="progress mb-2"
                              style={{ height: "5px" }}
                            >
                              <div
                                className="progress-bar bg-primary"
                                role="progressbar"
                                style={{
                                  width: `${Math.round(
                                    (100 * getTotalExpatriados()) /
                                      getTotalColaboradores()
                                  )}%`,
                                }}
                                aria-valuenow={40}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* /Page Header */}
                  <UserFilter onFilterSubmit={handleFilterSubmit} tipo={3} departamentos={departamentos} cargos={cargos} colaboradores={selectedEmployees} />

                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                       
                        <Table
                          className="table-striped"
                          columns={columns}
                          dataSource={filteredUsers.length > 0
                            ? filteredUsers
                            : utilizadores
                          }
                          rowKey={(record) => record.id}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <AddColaboradorModal
                  onSubmissionSuccess={handleSubmissionSuccess}
                  accoes={accoes}
                  cargos={cargos}
                  departamentos={departamentos}
                  missoes={missoes}
                  novaAbertura={1}
                />

                <EditColaboradorModal
                  onSubmissionSuccess={handleSubmissionSuccess}
                  utilizador={utilizadorSelecionado}
                  generoParam={utilizadorSelecionado?.genero}
                  nivelParam={utilizadorSelecionado?.nivel_id}
                  accoes={accoes}
                  cargos={cargos}
                  departamentos={departamentos}
                  missoes={missoes}
                  imagePreview={
                    url_root + "/storage/" + utilizadorSelecionado?.fotografia
                  }
                  novaAbertura={1}
                />

                <DeleteModal
                  objectoName={t("Employee")}
                  onSubmissionSuccess={handleSubmissionSuccess}
                  id={utilizadorSelecionado?.id}
                  endpoint={"utilizador"}
                />

                <span
                  data-bs-toggle="modal"
                  data-bs-target="#edit_user"
                  id="triggerModalEdit"
                ></span>

                <span
                  data-bs-toggle="modal"
                  data-bs-target="#delete"
                  id="triggerModalEliminar"
                ></span>
              </div>
            </div>
          </>
          );{" "}
        </>
      );
  else
    return (
      <>
        <div className="page-wrapper cardhead">
          <div className="content ">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">SEM PERMISSÃO</h3>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      <i className="fa fa-times-circle text-danger"></i>&nbsp;
                      OPERAÇÃO NÃO AUTORIZADA
                    </h4>
                  </div>
                  <div className="card-body">
                    <p>
                      Esta operação não é autorizada para o seu nivel de acesso
                      ao sistema, por favor contacte o administrador do sistema.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default EmployeeList;
