import React from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import JobDashboardWidget from "./JobDashboardWidget";
import UserDashboardGraph from "./UserDashboardGraph";
import ApplicantListTable from "./ApplicantListTable";
import ShortlistTable from "./ShortlistTable";

import { useTranslation } from "react-i18next";

const JobsDashboard = () => {

  const { t } = useTranslation();

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle={t('Applied Jobs')}
            title="Dashboard"
            subtitle={t('Jobs')+' / '+t('Job Dashboard')}
          />
          <JobDashboardWidget />
          <UserDashboardGraph />
          <ApplicantListTable />
          <ShortlistTable />
        </div>
      </div>
    </>
  );
};

export default JobsDashboard;
