import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import AuthUser from "../AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../Spinner";

import { format } from "date-fns";

import Swal from "sweetalert2";

const RegistarDividaModal = ({ colaborador_id, onSubmitSuccess, onSubmitDividaSuccess }) => {
  // eslint-disable-next-line no-unused-vars
  const {
    http,
    url_root,
    user,
    user_permissoes,
    verificarPermissao,
    getMissao,
  } = AuthUser();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [mostrarFormNovo, setMostrar] = useState(true);
  const [colaborador, setColaborador] = useState([]);
  const [registoSelecionado, setRegistoSelecionado] = useState(0);

  const [valor, setValor] = useState(0);
  const [descricao, setDescricao] = useState("");
  const [data, setData] = useState("");
  const [meses, setMeses] = useState(1);
  const [parcela, setParcela] = useState(0);
  const [mes_pagar, setMesPagar] = useState(1);
  const [ano_pagar, setAnoPagar] = useState(0);

  const toggleLoading = (value) => {
    setLoading(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {

        const currentDate = new Date();

        setData(format(currentDate, "yyyy-MM-dd"));
        setAnoPagar(format(currentDate, "yyyy"));

        getUtilizador(colaborador_id);

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [colaborador_id]);

  const getUtilizador = async (colaborador_id) => {
    try {
      setLoading(true); // Set loading to true when the request starts

      if (colaborador_id != 0 && colaborador_id != null) {
        const res = await http.get(
          "/utilizador/" + (colaborador_id ? colaborador_id : 0)
        );

        setColaborador(res.data.utilizador);

        onSubmitSuccess(res.data.utilizador);
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const mostrar = () => {
    setMostrar(!mostrarFormNovo);
  };


  // REGISTO NOVO

  const submitFormNovo = async () => {
    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {
        toggleLoading(true);

        await registar();
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else {
          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      console.log("Clicou em submeter");

      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const registar = async () => {
    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {

        const formData = new FormData();
        formData.append("colaborador_id", colaborador_id);
        formData.append("missao_id", getMissao()?.id);
        formData.append("valor", valor);
        formData.append("descricao", descricao);
        formData.append("data", data);
        formData.append("qtd_meses", meses);
        formData.append("valor_parcelar", parcela);
        formData.append("mes_a_pagar", mes_pagar);
        formData.append("ano_a_pagar", ano_pagar);
        formData.append("registou", user?.id);
        formData.append("pago", 0);

        const response = await http.post("/divida/register", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });       
          
          setValor("");
          setDescricao("");
          setParcela("");
          setMeses("");
          setMesPagar("");

          onSubmitDividaSuccess();

      
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  return (
    <>
      {/* Personal Info Modal */}
      <div
        id="registar_divida_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Registar Divida Para Colaborador</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">

              <div className="card">
                <div className="card-body">
                  {mostrarFormNovo ? (
                    <>
                      {" "}
                      <form>
                        <div className="row mt-4">
                         
                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <div>
                                <input
                                  className="form-control"
                                  type="number"
                                  value={valor}
                                  onChange={(e) =>
                                    setValor(e.target.value)
                                  }
                                />
                                <label className="focus-label">
                                  {t("Valor")}
                                </label>
                              </div>
                            </div>
                          </div>
                          

                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="date"
                                value={data}
                                onChange={(e) =>
                                  setData(e.target.value)
                                }
                              />
                              <label className="focus-label">
                                {t("Data Da Divida")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>


                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="number"
                                value={meses}
                                onChange={(e) =>
                                  setMeses(e.target.value)
                                }
                              />
                              <label className="focus-label">
                                {t("Qtd De Meses A Pagar")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="number"
                                value={ano_pagar}
                                onChange={(e) =>
                                  setAnoPagar(e.target.value)
                                }
                              />
                              <label className="focus-label">
                                {t("Pagar A partir Do Ano")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>   

                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="number"
                                value={parcela}
                                onChange={(e) =>
                                  setParcela(e.target.value)
                                }
                              />
                              <label className="focus-label">
                                {t("Valor Parcelar")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>                         

                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">

                            <select
                                className="form-control select"
                                value={mes_pagar}
                                onChange={(e) => setMesPagar(e.target.value)}
                              >
                               <option value="1">Janeiro</option>
                                <option value="2">Fevereiro</option>
                                <option value="3">Março</option>
                                <option value="4">Abril</option>
                                <option value="5">Maio</option>
                                <option value="6">Junho</option>
                                <option value="7">Julho</option>
                                <option value="8">Agosto</option>
                                <option value="9">Setembro</option>
                                <option value="10">Outubro</option>
                                <option value="11">Novembro</option>
                                <option value="12">Dezembro</option>
                              </select>
                              
                            
                              <label className="focus-label">
                                {t("Pagar A partir Do Mês")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>


                          <div className="col-md-12">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <textarea
                                className="form-control"
                                value={descricao}
                                onChange={(e) =>
                                  setDescricao(e.target.value)
                                }
                              >
                              </textarea>
                              <label className="focus-label">
                                {t("Descrição da divida")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>
                        
                        
                         
                          <div className="submit-section">
                            <a
                              className="btn btn-primary submit-btn"
                              onClick={submitFormNovo}
                            >
                              {loading ? "Carregando" : t("Submit")}
                            </a>
                          </div>

                          <hr className="mt-4"></hr>
                        </div>
                      </form>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Personal Info Modal */}

      <ToastContainer />

      {loading ? (
        <>
          <Spinner></Spinner>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default RegistarDividaModal;
