/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { useTranslation } from "react-i18next";

const EditSalaryModal = () => {
  const [setselectOne] = useState(null);

  const { t } = useTranslation();

  const options = [
    { value: 1, label: "John Doe" },
    { value: 2, label: "Richard Miles" }, 
  ];

  const employee = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#00b0f0" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#00b0f0",
      },
    }),
  };
  return (
    <>
      
      
      <div id="edit_salary" className="modal custom-modal fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t('Edit Salary')} - Individual</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">

              <hr></hr>

              <form>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        {t('Employee Name')} <span className="text-danger">*</span>
                      </label>
                      <div className="input-block mb-3">
                        <Select
                          options={employee}
                          placeholder="Select"
                          styles={customStyles}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Net Salary')} / {t('Hourly payment')} </label>
                      <input type="number" className="form-control" placeholder="0.0" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">{t('INSS')} ({t('According to default table')}) </label>
                    <Select
                      options={employee}
                      placeholder="Não incluir"
                      styles={customStyles}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">{t('IRT')} ({t('According to default table')})</label>
                    <Select
                      options={employee}
                      placeholder="Sim incluir"
                      styles={customStyles}
                    />

                  </div>

                  <div className="col-md-4">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Calculating type')}</label>
                      <Select
                        options={employee}
                        placeholder="Por hora"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">{t('Month')}</label>
                    <Select
                      options={employee}
                      placeholder="Jan"
                      styles={customStyles}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">{t('Year')}</label>
                    <Select
                      options={employee}
                      placeholder="2024"
                      styles={customStyles}
                    />
                  </div>


                  <div className="col-md-4">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('System registed time')}?</label>
                      <Select
                        options={employee}
                        placeholder="Não, hora digitada"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">{t('Time')}</label>
                    <input type="number" className="form-control" placeholder="80" />
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">Horas extra</label>
                    <input type="number" className="form-control" placeholder="10" readOnly />
                  </div>


                  <div className="col-md-4">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Absent days')}?</label>
                      <Select
                        options={employee}
                        placeholder="Aplicar diretamente"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">{t('Days')}</label>
                    <input type="number" className="form-control" placeholder="3" />
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">{t('Preview')}</label>
                    <input type="number" className="form-control" placeholder="10" readOnly />
                  </div>

                  <div className="col-md-4">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Holiday payment')}?</label>
                      <Select
                        options={employee}
                        placeholder="Sim"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">{t('Percent')}(%)</label>
                    <input type="number" className="form-control" placeholder="80" />
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">{t('Preview')}</label>
                    <input type="number" className="form-control" placeholder="10" readOnly />
                  </div>

                  <div className="col-md-4">
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Christmass payment')}?</label>
                      <Select
                        options={employee}
                        placeholder="Sim"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">{t('Percent')}(%)</label>
                    <input type="number" className="form-control" placeholder="50" />
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">{t('Preview')}</label>
                    <input type="number" className="form-control" placeholder="10" readOnly />
                  </div>

                  <hr></hr>

                  <div className="col-md-12 col-sm-12">

                    <div className="row">
                      <div className="col-sm-6">

                        <div className="add-more input-block mb-3">
                          <span title="Adicionar subsidios" className="text-success">
                            <i className="fa-solid fa-plus-circle" /> Adicionar Subsidios
                          </span>
                        </div>

                        <div className="input-block mb-3">
                          <input type="text" className="form-control" placeholder="Descrição do subsidio" />
                          <input type="number" className="form-control" placeholder="valor" />
                        </div>

                      </div>

                      <div className="col-sm-6">

                        <div className="add-more input-block mb-3">
                          <span title="Adicionar descontos" className="text-danger">
                            <i className="fa-solid fa-plus-circle" /> Adicionar Descontos
                          </span>
                        </div>

                        <div className="input-block mb-3">
                          <input type="text" className="form-control" placeholder="Descrição do desconto" />
                          <input type="number" className="form-control" placeholder="valor" />
                        </div>

                      </div>

                    </div>
                  </div>



                  <div className="col-md-12">
                    <div className="input-block mb-5">
                      <label className="col-form-label">{t('Add Note')} </label>
                      <textarea className="form-control" defaultValue={""} />
                    </div>
                  </div>


                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <button
                        className="btn btn-secondary submit-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        type="reset"
                      >
                        SIMULAR PROCESSAMENTO
                      </button>
                    </div>
                  </div>


                  <hr></hr>

                  <div className="col-md-12">
                    <div className="input-block mb-3 row">

                      <div className="col-sm-6">

                        <h4>Remunerações</h4>

                        <ul className="chat-user-list">
                          <li>

                            <div className="chat-block d-flex">

                              <div className="media-body align-self-center text-nowrap">
                                <div className="user-name">290.000</div>
                                <span className="designation">Sálario base</span>
                              </div>
                            </div>

                          </li>
                          <li>
                            <div className="chat-block d-flex">

                              <div className="media-body align-self-center text-nowrap">
                                <div className="user-name">48.000</div>
                                <span className="designation">Sub. Transporte</span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="chat-block d-flex">

                              <div className="media-body align-self-center text-nowrap">
                                <div className="user-name">80.000</div>
                                <span className="designation">Sub. Projeto</span>
                              </div>
                            </div>
                          </li>

                        </ul>

                      </div>


                      <div className="col-sm-6">

                        <h4>Descontos</h4>

                        <ul className="chat-user-list">
                          <li>

                            <div className="chat-block d-flex">

                              <div className="media-body align-self-center text-nowrap">
                                <div className="user-name">30.000</div>
                                <span className="designation">Desconto de crédito</span>
                              </div>
                            </div>

                          </li>
                          <li>
                            <div className="chat-block d-flex">

                              <div className="media-body align-self-center text-nowrap">
                                <div className="user-name">3.000</div>
                                <span className="designation">INSS</span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="chat-block d-flex">

                              <div className="media-body align-self-center text-nowrap">
                                <div className="user-name">30.000</div>
                                <span className="designation">Desvio do LapTop</span>
                              </div>
                            </div>
                          </li>
                        </ul>

                      </div>

                      <div className="col-sm-12">


                        <ul className="chat-user-list">
                          <li>
                            <div className="chat-block d-flex">

                              <div className="media-body align-self-center text-nowrap">
                                <div className="user-name text-success">380.000</div>
                                <span className="designation text-success">Sálario líquido</span>
                              </div>
                            </div>
                          </li>

                        </ul>

                      </div>

                    </div>
                  </div>

                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="reset"
                  >
                    {t('Save')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default EditSalaryModal;
