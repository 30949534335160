import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import AuthUser from "../AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../Spinner";

import Swal from "sweetalert2";

import { startOfMonth, endOfMonth, format } from "date-fns";

const MarcarFaltaModal = ({ colaborador, onSubmitSuccess }) => {
  // eslint-disable-next-line no-unused-vars
  const {
    http,
    url_root,
    user,
    user_permissoes,
    verificarPermissao,
    getMissao,
  } = AuthUser();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");

  const toggleLoading = (value) => {
    setLoading(value);
  };

  useEffect(() => {

    const currentDate = new Date();
    setData(format(currentDate, "yyyy-MM-dd"));
   
  }, [colaborador]);

  
  // REGISTO NOVO

  const marcar_falta = async () => {
   
      try {

        toggleLoading(true);

        await registar();

      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else {
          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
   
  };

  const registar = async () => {
   
      try {

        setLoading(true);

        const formData = new FormData();
        formData.append("colaborador_id", colaborador?.id);
        formData.append("data", data);
        formData.append("dia", (format(data, "d")));
        formData.append("mes", (format(data, "M")));
        formData.append("ano", (format(data, "yyyy")));
        formData.append("registou", user?.id);
        formData.append("justificada", 0);
        formData.append("missao_id", getMissao()?.id);

        const response = await http.post("/faltas/marcar", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        setLoading(false);

        onSubmitSuccess(colaborador);

        Swal.fire({
          title: "INFORMAÇÃO",
          text: response?.data?.mensagem,
          type: "success",
          showCancelButton: !0,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Certo, entendi!",
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-danger ml-1",
          buttonsStyling: !1,
        });

        
       
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
   
  };

  

  return (
    <>
      {/* Personal Info Modal */}
      <div id="marcar_falta_modal" className="modal custom-modal fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title"><i className="fa fa-calendar" />{" "}Marcação De Falta Para - {colaborador?.name}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="card">
                <div className="card-body">
                  <form>
                  <div className="input-block mb-3 mb-3 form-focus focused">
                              <div>
                                <input
                                  className="form-control"
                                  type="date"
                                  value={data}
                                  onChange={(e) =>
                                    setData(e.target.value)
                                  }
                                />
                                <label className="focus-label">
                                  {t("Defina a data para marcar a falta")}
                                </label>
                              </div>
                            </div>

                    <div className="submit-section m-t-20 text-center">

                      <a
                        className="btn btn-primary submit-btn" onClick={marcar_falta}
                      >
                        {t("Marcar a falta")}
                      </a>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Personal Info Modal */}

      <ToastContainer />

      {loading ? (
        <>
          <Spinner></Spinner>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default MarcarFaltaModal;
