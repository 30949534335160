import React, { useState, useEffect, useRef } from 'react';

const CameraComponent = () => {
  const [internalStream, setInternalStream] = useState(null);
  const videoRef = useRef(null);

  useEffect(() => {
    const getCameraStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        setInternalStream(stream);
      } catch (error) {
        console.error('Error accessing internal camera:', error);
      }
    };

    getCameraStream();

    return () => {
      if (internalStream) {
        internalStream.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    if (internalStream && videoRef.current) {
      videoRef.current.srcObject = internalStream;
    }
  }, [internalStream]);

  return (
    <div className="page-wrapper">
      {/* Page Content */}
      <div className="content container-fluid">


        <div className="row">
          <div className="col-md-12">

            {internalStream && <video ref={videoRef} autoPlay />}

          </div>
        </div>
      </div>
    </div>
  );
};

export default CameraComponent;
