import React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { Link, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

const SettingsSidebar = () => {
  const location = useLocation();

  const { t } = useTranslation();

  return (
    <>
      {/* Sidebar */}

      <div className="sidebar" id="sidebar">
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax="95vh"
          thumbMinSize={30}
          universal={false}
          hideTracksWhenNotNeeded={true}
        >
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              <nav className="greedy">
                <ul className="list-inline-item list-unstyled links">
                  <li>
                    <Link to="/admin-dashboard">
                      <i className="la la-home" /><span>{t('Back to Home')}</span>
                    </Link>
                  </li>
                  <li className="menu-title">{t('Settings')}</li>
                  <li
                    className={
                      location.pathname === "/settings/users"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/settings/users">
                      <i className="la la-users" />
                      <span>{t('Users')}</span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/settings/roles-permissions"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/settings/roles-permissions">
                      <i className="la la-key" />
                      <span>{t('Roles and Permissions')}</span>
                    </Link>
                  </li>
                  
                  {/* <li
                    className={
                      location.pathname === "/settings/cargos"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/settings/cargos">
                      <i className="la la-list" />
                      <span>{t('Job Roles')}</span>
                    </Link>
                  </li> */}

                  {/* <li
                    className={
                      location.pathname === "/settings/departamentos"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/settings/departamentos">
                      <i className="la la-list" />
                      <span>{t('Departments')}</span>
                    </Link>
                  </li> */}

                  <li
                    className={
                      location.pathname === "/settings/missoes"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/settings/missoes">
                      <i className="fa fa-building" />
                      <span>{t('Missions')}</span>
                    </Link>
                  </li>
                  {/* <li
                    className={
                      location.pathname === "/settings/company-settings"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/settings/company-settings">
                      <i className="la la-building" />
                      <span>{t('Company Settings')}</span>
                    </Link>
                  </li> */}
                  {/* <li
                    className={
                      location.pathname === "/settings/localization"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/settings/localization">
                      <i className="la la-clock-o" /><span>{t('Localization')}</span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/settings/theme-settings"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/settings/theme-settings">
                      <i className="la la-photo" /> <span>{t('Theme Settings')}</span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/settings/email-settings"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/settings/email-settings">
                      <i className="la la-at" /> <span>{t('Email Settings')}</span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/settings/performance-setting"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/settings/performance-setting">
                      <i className="la la-chart-bar" />
                      <span>{t('Performance Settings')}</span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/settings/approval-setting"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/settings/approval-setting">
                      <i className="la la-thumbs-up" />
                      <span>{t('Approval Settings')}</span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/settings/invoice-settings"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/settings/invoice-settings">
                      <i className="la la-pencil-square" />
                      <span>{t('Invoice Settings')}</span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/settings/salary-settings"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/settings/salary-settings">
                      <i className="la la-money" /> <span>{t('Salary Settings')}</span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/settings/notifications-settings"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/settings/notifications-settings">
                      <i className="la la-globe" /> <span>{t('Notifications')}</span>
                    </Link>
                  </li> */}
                  <li
                    className={
                      location.pathname === "/change-password" ? "active" : ""
                    }
                  >
                    <Link to="/change-password">
                      <i className="la la-lock" /> <span>{t('Change Password')}</span>
                    </Link>
                  </li>
                  {/*<li
                    className={
                      location.pathname === "/settings/leave-type"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/settings/leave-type">
                      <i className="la la-cogs" /> <span>{t('Leave Type')}</span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/settings/toxbox-setting"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/settings/toxbox-setting">
                      <i className="la la-comment" />{" "}
                      <span>ToxBox Settings</span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/settings/cron-setting"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/settings/cron-setting">
                      <i className="la la-rocket" /> <span>Cron Settings</span>
                    </Link>
                  </li> */}
                </ul>
              </nav>
            </div>
          </div>
        </Scrollbars>
      </div>

      {/* Sidebar */}
    </>
  );
  
};

export default SettingsSidebar;
