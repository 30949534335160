import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../../components/Spinner";

const PostoFilter = ({ onFilterSubmit, clientes, zonas }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } = AuthUser();

  const [nomeFilter, setNomeFilter] = useState("");
  const [siglaFilter, setSiglaFilter] = useState("");

  const [cliente_id, setClienteID] = useState(-1);
  const [zona_id, setZonaID] = useState(0);
  const [regime, setRegime] = useState(-1);

  const [itemFocus, setItemFocus] = useState(false);

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const inputFocus = () => {
    setItemFocus(true);
  };

  const inputBlur = () => {
    setItemFocus(false);
  };

  const handleFilterSubmit = async () => {
    try {
      setLoading(true);

      onFilterSubmit([]);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append("termo", nomeFilter);
      formData.append("regime", regime);
      formData.append("cliente_id", cliente_id);
      formData.append("estado", -1);
      formData.append("missao_id", getMissao()?.id);
      formData.append("zona_id", zona_id);

      const response = await http.post("/posto/pesquisar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setLoading(false);

      onFilterSubmit(response.data.postos);

    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error(
        "Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      setLoading(false);
    }
  };

  const renderOptionClientes = (clientes) => {
    return clientes?.map((cliente) => (
      <React.Fragment key={cliente.id}>
        <option value={cliente.id}>{cliente.nome}</option>
      </React.Fragment>
    ));
  };

  const renderOptionZonas = (zonas) => {
    return zonas?.map((zona) => (
      <React.Fragment key={zona.id}>
        <option value={zona.id}>{zona.nome}</option>
      </React.Fragment>
    ));
  };

  return (
    <div className="row filter-row space">
      <div className="col-sm-5 col-md-3">
        <div
          className={`input-block mb-3 form-focus  ${
            itemFocus ? "focused" : ""
          } `}
        >
          <input
            type="text"
            name="nameFilter"
            className="form-control floating"
            onFocus={inputFocus}
            onBlur={inputBlur}
            value={nomeFilter}
            onChange={(e) => setNomeFilter(e.target.value)}
          />
          <label className="focus-label">{t("Termo de pesquisa")}</label>
        </div>
      </div>

      <div className="col-sm-5 col-md-3">
        <div className="input-block mb-3 mb-3 form-focus focused">
          <label className="focus-label">{t("Cliente")}</label>
          <select
            onChange={(e) => setClienteID(e.target.value)}
            value={cliente_id}
            className="form-control select"
          >
            <option key={0} value={-1}></option>
            {renderOptionClientes(clientes)}
          </select>
        </div>
      </div>

      <div className="col-sm-5 col-md-2">
      <div className="input-block mb-3 form-focus focused">
                  <label className="focus-label">{t("Zona")}</label>
                  <select
                    onChange={(e) => setZonaID(e.target.value)}
                    value={zona_id}
                    className="form-control select"
                  >
                    <option key={0} value={-1}></option>
                    {renderOptionZonas(zonas)}
                  </select>
                </div>
      </div>

      <div className="col-sm-5 col-md-2">
      <div className="input-block mb-3 form-focus focused">
                  <label className="col-form-label focus-label">
                    {t("Regime de escala")}
                  </label>
                  <select
                    onChange={(e) => setRegime(e.target.value)}
                    value={regime}
                    className="form-control select"
                  >
                    <option value={-1}> - Escolha o regime - </option>
                    <option value={1}>12/12</option>
                    <option value={2}>12/24</option>
                    <option value={3}>12/48</option>
                    <option value={4}>24/12</option>
                    <option value={5}>24/24</option>
                    <option value={6}>24/48</option>
                    <option value={7}>48/24</option>
                    <option value={8}>48/48</option>
                  </select>
                </div>

      </div>

     

      <div className="col-sm-6 col-md-2">
        <span
          className="btn btn-success btn-block w-100"
          onClick={() => handleFilterSubmit()}
        >
          {t("Search")}
        </span>
      </div>

      <ToastContainer />
    </div>
  );
};

export default PostoFilter;
