import React from "react";
import TableAvatar from "./TableAvatar";
import Breadcrumbs from "../../../components/Breadcrumbs";

import { useTranslation } from "react-i18next";
import FeriasTable from "./FeriasTable";

const FeriasAdmin = () => {

  const { t } = useTranslation();

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={t('Ferias')}
            title="Dashboard"
            subtitle={t('Ferias dos colaboradores')}
          />
          {/* /Page Header */}

          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive">
              <FeriasTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeriasAdmin;
