import { Applogo } from "../../../Routes/ImagePath";
import { Link, useNavigate } from "react-router-dom";

/* eslint-disable no-dupe-keys */
import React, { useState } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../../components/AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../../components/Spinner";

import { useTranslation } from "react-i18next";

const ChangePassword = () => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const [password1, setPassword1] = useState(false);
  const [password2, setPassword2] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [password3, setPassword3] = useState(false);

 
  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user } = AuthUser();

  const submitForm = async () => {
    try {
      toggleLoading(true);
      if (password2.length < 6) {

        toggleLoading(false);

        // Show a error toast
        toast.error('A nova senha deve ter pelo menos seis caracteres.', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        return;
      }

      if (password2 !== password3) {

        toggleLoading(false);

        // Show a error toast
        toast.error('A nova senha e a sua confirmação não coincidem.', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        return;
      }

      await alterarSenha();


      // If the API call is successful, navigate to the dashboard
      //navigate('/dashboard');

    } catch (error) {
      if (error.response && error.response.status === 401) {


        // Show a error toast
        toast.error('Utilizador não autenticado.', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } else {


        // Show a error toast
        toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }
    } finally {
      toggleLoading(false);
    }
  };

  const alterarSenha = async () => {
    try {
      const formData = new FormData();
      formData.append('id', user.id);
      formData.append('old_password', password1);
      formData.append('password', password2);
      formData.append('password_confirmation', password3);

      const response = await http.post('/alterar-senha', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {

          navigate("/admin-dashboard");

        }
      });

    } catch (error) {

      console.error(error);

      toast.error(error.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  return (
    <div>
      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            {/* Account Logo */}
            <div className="account-logo">
              <Link to="/admin-dashboard">
                <img src={Applogo} alt="Dreamguy's Technologies" />
              </Link>
            </div>
            <div className="account-box">
              <div className="account-wrapper">
                <h3 className="account-title">{t('Change Password')}</h3>
                <form>
                  <div className="input-block mb-3">
                    <label className="col-form-label">{t('Old password')}</label>
                    <input type="password" className="form-control" onChange={e => setPassword1(e.target.value)} />
                  </div>
                  <div className="input-block mb-3">
                    <label className="col-form-label">{t('New password')}</label>
                    <input type="password" className="form-control" onChange={e => setPassword2(e.target.value)} />
                  </div>
                  <div className="input-block mb-3">
                    <label className="col-form-label">{t('Confirm password')}</label>
                    <input type="password" className="form-control" onChange={e => setPassword3(e.target.value)} />
                  </div>
                  <div className="submit-section mb-4">
                    <span
                      className="btn btn-primary submit-btn"
                      onClick={() => submitForm()}
                    >
                      {loading ? <Spinner /> : t('Update Password')}
                    </span>
                  </div>

                  <Link to="/admin-dashboard">
                    Voltar ao Dashboard
                  </Link>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

    </div>
  );
};

export default ChangePassword;
