import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Avatar_16,
  Avatar_02,
  Avatar_05,
  Avatar_09,
  Avatar_10,
} from "../../../Routes/ImagePath/index";

import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import React, { useState, useEffect } from "react";

import AuthUser from "../../AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../../Spinner";

import Swal from "sweetalert2";

const PostoModal = ({ onSubmissionSuccess, posto, clientes, zonas }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user, user_permissoes, verificarPermissao, getMissao } =
    AuthUser();

  const [nome, setName] = useState("");
  const [codigo, setCodigo] = useState("");
  const [cliente_id, setClienteID] = useState("");
  const [zona_id, setZonaID] = useState(0);
  const [descricao, setDescricao] = useState("");
  const [telefone, setTelefone] = useState();
  const [email, setEmail] = useState("");
  const [local, setLocal] = useState("");
  const [responsavel, setResponsavel] = useState("");
  const [estado, setEstado] = useState();
  const [regime, setRegime] = useState(0);

  const [cliente_lista, setClienteLista] = useState([]);
  const [zona_lista, setZonaLista] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState(posto);

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event("click"));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {

        setClienteLista(clientes);
        setZonaLista(zonas);

        console.log(cliente_lista);

        setInputs(posto);
        setimagemPreview(url_root + "/storage/" + posto?.cliente?.logo);
        setClienteID(posto?.cliente?.id);
        setZonaID(posto?.zona?.id);
        setRegime(posto?.regime);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [posto]);

  //registar tarefa

  const submitFormposto = async () => {
    if (verificarPermissao("CARGO", "adicionar")) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (nome.length < 2) {
          setError("O nome deve ter pelo menos dois caracteres.");
          setLoading(false);

          // Show a error toast
          toast.error("O nome deve ter pelo menos dois caracteres.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        await uploadposto();
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Utilizador não autenticado.");

          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          setError(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado"
          );

          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      console.log("Clicou em submeter");

      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const uploadposto = async () => {
    if (verificarPermissao("CARGO", "adicionar")) {
      try {
        const formData = new FormData();
        formData.append("nome", nome);
        formData.append("codigo", codigo);
        formData.append("regime", regime);
        formData.append("descricao", descricao);
        formData.append("telefone", telefone);
        formData.append("email", email);
        formData.append("local", local);
        formData.append("responsavel", responsavel);
        formData.append("estado", 0);
        formData.append("cliente_id", cliente_id);
        formData.append("zona_id", zona_id);
        formData.append('missao_id', getMissao()?.id);

        const response = await http.post("/posto/register", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        console.log(response);

        // Show a success toast
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {
            setName("");

            triggerCloseButtonClick("closeButton30");

            console.log("to send : ");
            console.log(response.data.data);

            onSubmissionSuccess(response.data.data);
          },
        });
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  //Update tarefa

  const submitFormUpdate = async () => {
    if (verificarPermissao("CARGO", "atualizar")) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (inputs?.nome.length < 2) {
          setError("O nome deve ter pelo menos dois caracteres.");
          setLoading(false);

          // Show a error toast
          toast.error("O nome deve ter pelo menos dois caracteres.", {
            position: "top-right",
            autoClose: 1000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        await updateposto();
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Utilizador não autenticado.");

          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          setError(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado"
          );

          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const updateposto = async () => {
    try {
      const formData = new FormData();
      formData.append("id", inputs?.id);
      formData.append("codigo", inputs?.codigo);
      formData.append("regime", regime);
      formData.append("nome", inputs?.nome);
      formData.append("descricao", inputs?.descricao);
      formData.append("telefone", inputs?.telefone);
      formData.append("email", inputs?.email);
      formData.append("local", inputs?.local);
      formData.append("responsavel", inputs?.responsavel);
      formData.append("cliente_id", cliente_id);
      formData.append("zona_id", zona_id);

      const response = await http.post("/posto/update", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          triggerCloseButtonClick("closeButton40");

          console.log("to send : ");
          onSubmissionSuccess(response.data.data);
        },
      });
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o postos. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const renderOptionClientes = (clientes) => {
    return clientes?.map((cliente) => (
      <React.Fragment key={cliente.id}>
        <option value={cliente.id}>{cliente.nome}</option>
      </React.Fragment>
    ));
  };

  const renderOptionZonas = (zonas) => {
    return zonas?.map((zona) => (
      <React.Fragment key={zona.id}>
        <option value={zona.id}>{zona.nome}</option>
      </React.Fragment>
    ));
  };

  /* Imagem do logotipo */

  const [imagem, setSelectedFile] = useState(null);
  const [imagemPreview, setimagemPreview] = useState();

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    //document.getElementById('imagem').src = newFileURL;

    setimagemPreview(newFileURL);

    console.log("New image url 1:");
    console.log(newFileURL);
    // Restante do código
  };

  return (
    <>
      {/* Add Task Modal */}
      <div
        id="add_posto_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{t("Add posto")}</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="closeButton30"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="input-block mb-3 mb-3 form-focus focused">
                  <label className="focus-label">{t("Cliente")}</label>
                  <select
                    onChange={(e) => setClienteID(e.target.value)}
                    value={cliente_id}
                    className="form-control select"
                  >
                    <option key={0} value={-1}></option>
                    {renderOptionClientes(cliente_lista)}
                  </select>
                </div>

                <div className="input-block mb-3 form-focus focused">
                  <label className="focus-label">{t("Zona")}</label>
                  <select
                    onChange={(e) => setZonaID(e.target.value)}
                    value={zona_id}
                    className="form-control select"
                  >
                    <option key={0} value={-1}></option>
                    {renderOptionZonas(zona_lista)}
                  </select>
                </div>

                <div className="input-block mb-3 form-focus focused">
                  <label className="col-form-label focus-label">
                    {t("Nome Do posto")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={nome}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className="input-block mb-3 form-focus focused">
                  <label className="col-form-label focus-label">
                    {t("Codigo Do posto")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={codigo}
                    onChange={(e) => setCodigo(e.target.value)}
                  />
                </div>

                <div className="input-block mb-3 form-focus focused">
                  <label className="col-form-label focus-label">
                    {t("Regime de escala")}
                  </label>
                  <select
                    onChange={(e) => setRegime(e.target.value)}
                    value={regime}
                    className="form-control select"
                  >
                    <option value={0}> - Escolha o regime - </option>
                    <option value={1}>12/12</option>
                    <option value={2}>12/24</option>
                    <option value={3}>12/48</option>
                    <option value={4}>24/12</option>
                    <option value={5}>24/24</option>
                    <option value={6}>24/48</option>
                    <option value={7}>48/24</option>
                    <option value={8}>48/48</option>
                  </select>
                </div>

                <div className="input-block mb-3 form-focus focused">
                  <label className="col-form-label focus-label">
                    {t("Telefone")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                  />
                </div>

                <div className="input-block mb-3 form-focus focused">
                  <label className="col-form-label focus-label">
                    {t("E-mail")}
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="input-block mb-3 form-focus focused">
                  <label className="col-form-label focus-label">
                    {t("Responsavel")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={responsavel}
                    onChange={(e) => setResponsavel(e.target.value)}
                  />
                </div>

                <div className="input-block mb-3 form-focus focused">
                  <label className="col-form-label focus-label">
                    {t("Endereço")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={local}
                    onChange={(e) => setLocal(e.target.value)}
                  />
                </div>

                <div className="input-block mb-3 form-focus focused">
                  <label className="col-form-label focus-label">
                    {t("Descrição")}
                  </label>
                  <div className="">
                    <textarea
                      className="form-control"
                      value={descricao}
                      onChange={(e) => setDescricao(e.target.value)}
                    ></textarea>
                  </div>
                </div>

                <div className="submit-section m-t-20 text-center">
                  {loading ? (
                    "A processar"
                  ) : (
                    <a
                      className="btn btn-primary submit-btn"
                      onClick={submitFormposto}
                    >
                      {t("Submit")}
                    </a>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Task Modal */}

      {/* Edit Task Modal */}
      <div
        id="edit_posto_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{t("Editar Posto")}</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="closeButton40"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="col-sm-12">
                  <div className="input-block mb-3 form-focus focused mb-4">
                    <div className="profile-img-wrap edit-img">
                      <img
                        className="inline-block"
                        src={imagemPreview}
                        alt={posto?.cliente?.logo}
                      />
                      <div className="fileupload btn"></div>
                    </div>
                  </div>
                </div>

                <div className="input-block mb-3 mt-5 form-focus focused">
                  <label className="focus-label">{t("Cliente")}</label>
                  <select
                    onChange={(e) => setClienteID(e.target.value)}
                    value={cliente_id}
                    className="form-control select"
                  >
                    <option key={0} value={-1}></option>
                    {renderOptionClientes(cliente_lista)}
                  </select>
                </div>

                <div className="input-block mb-3 form-focus focused">
                  <label className="focus-label">{t("Zona")}</label>
                  <select
                    onChange={(e) => setZonaID(e.target.value)}
                    value={zona_id}
                    className="form-control select"
                  >
                    <option key={0} value={-1}></option>
                    {renderOptionZonas(zona_lista)}
                  </select>
                </div>

                <div className="input-block mb-3 form-focus focused">
                  <label className="col-form-label focus-label">
                    {t("Nome do posto")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="nome"
                    value={inputs?.nome}
                    onChange={(e) => handleInputsValueChanged(e)}
                  />
                </div>

                <div className="input-block mb-3 form-focus focused">
                  <label className="col-form-label focus-label">
                    {t("Codigo do posto")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="codigo"
                    value={inputs?.codigo}
                    onChange={(e) => handleInputsValueChanged(e)}
                  />
                </div>

                <div className="input-block mb-3 form-focus focused">
                  <label className="col-form-label focus-label">
                    {t("Regime de escala")}
                  </label>
                  <select
                    onChange={(e) => setRegime(e.target.value)}
                    value={regime}
                    className="form-control select"
                  >
                    <option value={0}> - Escolha o regime - </option>
                    <option value={1}>12/12</option>
                    <option value={2}>12/24</option>
                    <option value={3}>12/48</option>
                    <option value={4}>24/12</option>
                    <option value={5}>24/24</option>
                    <option value={6}>24/48</option>
                    <option value={7}>48/24</option>
                    <option value={8}>48/48</option>
                  </select>
                </div>

                <div className="input-block mb-3 form-focus focused">
                  <label className="col-form-label focus-label">
                    {t("Telefone")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="telefone"
                    value={inputs?.telefone}
                    onChange={(e) => handleInputsValueChanged(e)}
                  />
                </div>

                <div className="input-block mb-3 form-focus focused">
                  <label className="col-form-label focus-label">
                    {t("E-mail")}
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={inputs?.email}
                    onChange={(e) => handleInputsValueChanged(e)}
                  />
                </div>

                <div className="input-block mb-3 form-focus focused">
                  <label className="col-form-label focus-label">
                    {t("Responsavel")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="responsavel"
                    value={inputs?.responsavel}
                    onChange={(e) => handleInputsValueChanged(e)}
                  />
                </div>

                <div className="input-block mb-3 form-focus focused">
                  <label className="col-form-label focus-label">
                    {t("Endereço")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="local"
                    value={inputs?.local}
                    onChange={(e) => handleInputsValueChanged(e)}
                  />
                </div>

                <div className="input-block mb-3 form-focus focused">
                  <label className="col-form-label focus-label">
                    {t("Descrição")}
                  </label>
                  <div className="">
                    <textarea
                      name="descricao"
                      className="form-control"
                      value={inputs?.descricao}
                      onChange={(e) => handleInputsValueChanged(e)}
                    ></textarea>
                  </div>
                </div>

                <div className="submit-section m-t-20 text-center">
                  {loading ? (
                    "A processar"
                  ) : (
                    <a
                      className="btn btn-primary submit-btn"
                      onClick={submitFormUpdate}
                    >
                      {t("Save")}
                    </a>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Task Modal */}

      <ToastContainer />
    </>
  );
};

export default PostoModal;
