import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import AuthUser from "../AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../Spinner";

import Swal from "sweetalert2";

import { startOfMonth, endOfMonth, format } from "date-fns";

const RegistarFecharAbrirEscalaModal = ({ escala_id, onSubmitSuccess }) => {
  // eslint-disable-next-line no-unused-vars
  const {
    http,
    url_root,
    user,
    user_permissoes,
    verificarPermissao,
    getMissao,
  } = AuthUser();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [tipoMarcacao, setTipoMarcacao] = useState(1);

  const toggleLoading = (value) => {
    setLoading(value);
  };

  useEffect(() => {
    const currentDate = new Date();
    setData(format(currentDate, "yyyy-MM-dd"));
  }, [escala_id]);

  // REGISTO NOVO

  const marcar_fecho_abertura = async () => {
    try {

      toggleLoading(true);
      await registar();

    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });
      } else {
        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } finally {
      toggleLoading(false);
    }
  };

  const registar = async () => {
    try {

      setLoading(true);

      const formData = new FormData();
      formData.append("escala_id", escala_id);
      formData.append("data", data);
      formData.append("tipo", tipoMarcacao);

      const res = await http.post("/escala/registar-abertura-fecho-unica", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setLoading(false);

      onSubmitSuccess(res);
      

    } catch (error) {

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        }
      );
    }
  };

  return (
    <>
      {/* Personal Info Modal */}
      <div
        id="abrir_fecha_escala_conjunta_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <i className="fa fa-calendar" /> Registo De Abertura Ou Fecho de Escala
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="buttonClose7"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <div>
                            <input
                              className="form-control"
                              type="date"
                              value={data}
                              onChange={(e) => setData(e.target.value)}
                            />
                            <label className="focus-label">
                              {t("Defina A Data Para O Caso De Ser Fecho")}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <select
                            className="form-control select"
                            onChange={(e) => setTipoMarcacao(e.target.value)}
                          >
                            <option value="1">Fechar</option>
                            <option value="0">Abrir</option>
                          </select>

                          <label className="focus-label">
                            {t("Abrir Ou Fechar Para Edição")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="submit-section m-t-20 text-center">
                      <a
                        className="btn btn-primary submit-btn"
                        onClick={marcar_fecho_abertura}
                      >
                        {t("Salvar")}
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Personal Info Modal */}

      <ToastContainer />

      {loading ? (
        <>
          <Spinner></Spinner>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default RegistarFecharAbrirEscalaModal;
