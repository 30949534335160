import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import AuthUser from "../AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../Spinner";

import Swal from "sweetalert2";

const InfoPessoalModal = ({ colaborador_id, onSubmitSuccess }) => {
  // eslint-disable-next-line no-unused-vars
  const {
    http,
    url_root,
    user,
    user_permissoes,
    verificarPermissao,
    getMissao,
  } = AuthUser();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [mostrarFormNovo, setMostrar] = useState(false);
  const [colaborador, setColaborador] = useState([]);
  const [registoSelecionado, setRegistoSelecionado] = useState(0);

  const [passaporte, setPassaporte] = useState("");
  const [passaporte_exp, setPassaporteExp] = useState("");
  const [nacionalidade, setNacionalidade] = useState("");
  const [religiao, setReligiao] = useState("");
  const [estado_civil, setEstCivil] = useState("");
  const [conj_empregado, setEmpregado] = useState("");
  const [filhos, setFilhos] = useState(0);

  const toggleLoading = (value) => {
    setLoading(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        getUtilizador(colaborador_id);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [colaborador_id]);

  const getUtilizador = async (colaborador_id) => {
    try {
      setLoading(true); // Set loading to true when the request starts

      if (colaborador_id != 0 && colaborador_id != null) {
        const res = await http.get(
          "/utilizador/" + (colaborador_id ? colaborador_id : 0)
        );

        setColaborador(res.data.utilizador);

        onSubmitSuccess(res.data.utilizador);
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const mostrar = () => {
    setMostrar(!mostrarFormNovo);
  };

  const chamarModalEliminar = (idSelecionado) => {
    setRegistoSelecionado(idSelecionado);

    // eslint-disable-next-line no-restricted-globals
    if (confirm("Tem a certeza que pretende eliminar este registo?")) {
      setLoading(true);
      handleEliminar(idSelecionado);
    }
  };

  const handleEliminar = async (idSelecionado) => {
    try {
      const formData = new FormData();
      formData.append("id", idSelecionado);

      const response = await http.post("/info-pessoal/eliminar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      getUtilizador(colaborador_id);

      // Atualize a lista de acessos após ativar/desativar
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  // REGISTO NOVO

  const submitFormNovo = async () => {
    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {
        toggleLoading(true);

        await registar();
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else {
          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      console.log("Clicou em submeter");

      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const registar = async () => {
    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {
        const formData = new FormData();
        formData.append("user_id", colaborador_id);
        formData.append("passaporte", passaporte);
        formData.append("passaporte_exp", passaporte_exp);
        formData.append("nacionalidade", nacionalidade);
        formData.append("religiao", religiao);
        formData.append("estado_civil", estado_civil);
        formData.append("parceiro_empregado", conj_empregado);
        formData.append("filhos", filhos);

        const response = await http.post("/info-pessoal/register", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.message == "Registo feito com sucesso") {
          setPassaporte("");
          setPassaporteExp("");
          setFilhos("");

          mostrar();

          getUtilizador(colaborador_id);
        }
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  // ATUALIZAR INFORMACAO

  const submitUpdateForm = async (id, updatedData) => {
    if (verificarPermissao("UTILIZADORES", "atualizar")) {
      try {
        toggleLoading(true);
        await updateInfo(id, updatedData);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else {
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      });
    }
  };

  const updateInfo = async (id, updatedData) => {
    if (verificarPermissao("UTILIZADORES", "atualizar")) {
      try {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("passaporte", updatedData.passaporte);
        formData.append("passaporte_exp", updatedData.passaporte_exp);
        formData.append("nacionalidade", updatedData.nacionalidade);
        formData.append("religiao", updatedData.religiao);
        formData.append("estado_civil", updatedData.estado_civil);
        formData.append("parceiro_empregado", updatedData.parceiro_empregado);
        formData.append("filhos", updatedData.filhos);

        const response = await http.post(`/info-pessoal/update`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.message == "Registo atualizado com sucesso") {
          // Atualizar o estado local com os dados atualizados
          setColaborador((prevState) => ({
            ...prevState,
            info_pessoal: prevState.info_pessoal.map((item) =>
              item.id == id ? { ...item, ...updatedData } : item
            ),
          }));

          getUtilizador(colaborador_id);
        }
      } catch (error) {
        console.error(error);
        toast.error(
          "Ocorreu um erro ao atualizar a informação pessoal. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      });
    }
  };

  return (
    <>
      {/* Personal Info Modal */}
      <div
        id="personal_info_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Informação Pessoal [{colaborador?.name}]</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              {colaborador?.info_pessoal?.map((item, index) => (
                <>
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">
                        {t("Informação No : ")} {index + 1}
                        <span
                          className="delete-icon"
                          onClick={() => chamarModalEliminar(item?.id)}
                        >
                          <i className="fa-regular fa-trash-can" />
                        </span>
                      </h3>

                      <form>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue="Nº passaporte"
                                value={item.passaporte?item.passaporte:''}
                                onChange={(e) =>
                                  setColaborador((prevState) => {
                                    const updatedInfo = [
                                      ...prevState.info_pessoal,
                                    ];
                                    updatedInfo[index].passaporte =
                                      e.target.value;
                                    return {
                                      ...prevState,
                                      info_pessoal: updatedInfo,
                                    };
                                  })
                                }
                              />

                              <label className="focus-label">
                                {t("Passport No")}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="date"
                                defaultValue="Passaporte Validade"
                                value={item.passaporte_exp?item.passaporte_exp:''}
                                onChange={(e) =>
                                  setColaborador((prevState) => {
                                    const updatedInfo = [
                                      ...prevState.info_pessoal,
                                    ];
                                    updatedInfo[index].passaporte_exp =
                                      e.target.value;
                                    return {
                                      ...prevState,
                                      info_pessoal: updatedInfo,
                                    };
                                  })
                                }
                              />

                              <label className="focus-label">
                                {t("Passport Expiry Date")}
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="text"
                                value={item.nacionalidade?item.nacionalidade:''}
                                onChange={(e) =>
                                  setColaborador((prevState) => {
                                    const updatedInfo = [
                                      ...prevState.info_pessoal,
                                    ];
                                    updatedInfo[index].nacionalidade =
                                      e.target.value;
                                    return {
                                      ...prevState,
                                      info_pessoal: updatedInfo,
                                    };
                                  })
                                }
                              />
                              <label className="focus-label">
                                {t("Nationality")}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <div className="">
                                <input
                                  className="form-control"
                                  type="text"
                                  value={item.religiao?item.religiao:''}
                                  onChange={(e) =>
                                    setColaborador((prevState) => {
                                      const updatedInfo = [
                                        ...prevState.info_pessoal,
                                      ];
                                      updatedInfo[index].religiao =
                                        e.target.value;
                                      return {
                                        ...prevState,
                                        info_pessoal: updatedInfo,
                                      };
                                    })
                                  }
                                />
                                <label className="focus-label">
                                  {t("Religion")}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <select
                                className="form-control select"
                                value={item.estado_civil?item.estado_civil:1}
                                onChange={(e) =>
                                  setColaborador((prevState) => {
                                    const updatedInfo = [
                                      ...prevState.info_pessoal,
                                    ];
                                    updatedInfo[index].estado_civil =
                                      e.target.value;
                                    return {
                                      ...prevState,
                                      info_pessoal: updatedInfo,
                                    };
                                  })
                                }
                              >
                                <option value="1">Solteiro(a)</option>
                                <option value="2">Casado(a)</option>
                                <option value="3">Divorciado(a)</option>
                                <option value="4">Viuvo(a)</option>
                              </select>
                              <label className="focus-label">
                                {t("Marital status")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <select
                                className="form-control select"
                                value={item.parceiro_empregado?item.parceiro_empregado:1}
                                onChange={(e) =>
                                  setColaborador((prevState) => {
                                    const updatedInfo = [
                                      ...prevState.info_pessoal,
                                    ];
                                    updatedInfo[index].parceiro_empregado =
                                      e.target.value;
                                    return {
                                      ...prevState,
                                      info_pessoal: updatedInfo,
                                    };
                                  })
                                }
                              >
                                <option value="1">Sim</option>
                                <option value="2">Nao</option>
                                <option value="3">
                                  Trabalho por conta propria
                                </option>
                              </select>

                              <label className="focus-label">
                                {t("Employment of spouse")}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="text"
                                value={item.filhos?item.filhos:0}
                                onChange={(e) =>
                                  setColaborador((prevState) => {
                                    const updatedInfo = [
                                      ...prevState.info_pessoal,
                                    ];
                                    updatedInfo[index].filhos = e.target.value;
                                    return {
                                      ...prevState,
                                      info_pessoal: updatedInfo,
                                    };
                                  })
                                }
                              />

                              <label className="focus-label">
                                {t("No. of children")}{" "}
                              </label>
                            </div>
                          </div>

                          <div className="submit-section">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => submitUpdateForm(item.id, item)}
                            >
                              {t("Atualizar Informação")}
                            </button>
                          </div>

                          <hr className="mt-4"></hr>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              ))}

              <div className="add-more ml-2 mt-3 ">
                <span onClick={mostrar} className="mt-4 btn btn-primary">
                  <i className="fa-solid fa-plus-circle" />
                  {t("Add More")}
                </span>
              </div>

              <div className="card">
                <div className="card-body">
                  {mostrarFormNovo ? (
                    <>
                      {" "}
                      <form>
                        <div className="row mt-4">
                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                type="text"
                                className="form-control"
                                onChange={(e) => setPassaporte(e.target.value)}
                              />
                              <label className="focus-label">
                                {t("Passport No")}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <div>
                                <input
                                  className="form-control"
                                  type="date"
                                  onChange={(e) =>
                                    setPassaporteExp(e.target.value)
                                  }
                                />
                                <label className="focus-label">
                                  {t("Passport Expiry Date")}
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="text"
                                onChange={(e) =>
                                  setNacionalidade(e.target.value)
                                }
                              />
                              <label className="focus-label">
                                {t("Nationality")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="text"
                                onChange={(e) => setReligiao(e.target.value)}
                              />
                              <label className="focus-label">
                                {t("Religion")}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <select
                                className="form-control select"
                                onChange={(e) => setEstCivil(e.target.value)}
                              >
                                <option value="1">Solteiro(a)</option>
                                <option value="2">Casado(a)</option>
                                <option value="3">Divorciado(a)</option>
                                <option value="3">Viuvo(a)</option>
                              </select>

                              <label className="focus-label">
                                {t("Marital status")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <select
                                className="form-control select"
                                onChange={(e) => setEmpregado(e.target.value)}
                              >
                                <option value="1">Sim</option>
                                <option value="2">Nao</option>
                                <option value="3">
                                  Trabalho por conta propria
                                </option>
                              </select>
                              <label className="focus-label">
                                {t("Employment of spouse")}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="text"
                                onChange={(e) => setFilhos(e.target.value)}
                              />
                              <label className="focus-label">
                                {t("No. of children")}{" "}
                              </label>
                            </div>
                          </div>

                          <div className="submit-section">
                            <a
                              className="btn btn-primary submit-btn"
                              onClick={submitFormNovo}
                            >
                              {loading ? "Carregando" : t("Submit")}
                            </a>
                          </div>

                          <hr className="mt-4"></hr>
                        </div>
                      </form>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Personal Info Modal */}

      <ToastContainer />

      {loading ? (
        <>
          <Spinner></Spinner>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default InfoPessoalModal;
