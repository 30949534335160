/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { Avatar_09, Avatar_10, Avatar_16 } from "../../Routes/ImagePath";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import AuthUser from "../AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../Spinner";

const PesquisarColaboradorModal = ({ onSubmissionSuccess, utilizadores }) => {

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user, user_permissoes, verificarPermissao, getMissao } = AuthUser();

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {

    setUsers(utilizadores);

  }, []);

  const handleSearchTermChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    const filtered = users.filter(user =>
      user.name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredUsers(filtered);
  };

  const handleUserSelect = (selectedUser) => {
    // Here you can add the selected user to your project team or perform any other action
    console.log("Selected user:", selectedUser);

    if (selectedUser == null) {
      // Clear the search term and filtered users after selecting a user
      setSearchTerm("");
      setFilteredUsers([]);
    } else {
      retornarColaborador(selectedUser?.id);
    }

  };

  const retornarColaborador = (user_id) => {

      onSubmissionSuccess(user_id);

      triggerCloseButtonClick('closeButton5');

  };

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event('click'));
    }
  };


  return (
    <div>
      <div id="find_colaborador" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Pesquisar Colaborador</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeButton5"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group m-b-30">
                <input
                  placeholder={t('Search')}
                  className="form-control search-input"
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                />
                <button className="btn btn-primary">{t('Search')}</button>
              </div>
              <div>

                {loading?<Spinner></Spinner>: filteredUsers.length > 0 && (
                  <ul className="chat-user-list">
                    {filteredUsers.map(user => (

                      <li  key={user.id}>
                        <span onClick={() => handleUserSelect(user, 2)}>
                          <div className="chat-block d-flex">
                            <span className="avatar">
                              <img src={url_root + "/storage/" + user?.fotografia} alt={user?.name} title={user?.name} />
                            </span>
                            <div className="media-body align-self-center text-nowrap">
                              <div className="user-name">{user?.name}</div>
                              <span className="designation">{user?.cargo?.designacao}</span>
                            </div>
                          </div>
                        </span>
                      </li>

                    ))}
                  </ul>
                )}

               
              </div>


            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

    </div>
  );
};

export default PesquisarColaboradorModal;
