import React, { useState, useEffect } from "react";

import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { Applogo } from "../../../../../Routes/ImagePath";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../../../components/AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../../../../../components/Spinner";

import { startOfMonth, endOfMonth, format } from "date-fns";

import { Link, useParams, useNavigate } from "react-router-dom";

import ConverterNumeroEmPalavra from "../../../../../components/ConverterNumeroEmPalavra";
import { ColorFactory } from "antd/es/color-picker/color";

const PaySlip = () => {
  // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao, user } = AuthUser();

  const { t } = useTranslation();
  const navigate = useNavigate();
  // navigate('/admin-dashboard');

  let { salarioID } = useParams();

  const [loading, setLoading] = useState(false);

  const [salario, setSalario] = useState([]);
  const [agregados, setAgregados] = useState([]);
  const [dividas, setDividas] = useState([]);
  const [contacto, setContacto] = useState([]);

  const formatNumber = (number) => {
    return new Intl.NumberFormat("pt-PT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getAssets();
      } catch (error) {
        console.error("Erro ao obter utilizadores:", error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, []);

  const getAssets = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/salario/detalhes/" + salarioID);

      setSalario(res?.data?.salario);
      setAgregados(res?.data?.agregados);
      setDividas(res?.data?.dividas);
      setContacto(res?.data?.contacto);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const submitReciboPDFreport = async (recibo_id) => {
    try {
      setLoading(true);

      await getReportRecibo(recibo_id);

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const getReportRecibo = async (recibo_id) => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("recibo_id", recibo_id);

      const response = await http.post("/recibo_salarial/pdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle={t("Payslip")}
          title="Dashboard"
          subtitle={t("Payslip")}
          modal="#add_categories"
          name={t("Add Salary")}
        />

        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h4 className="payslip-title">
                  {t("Payslip for the month of")} {meses[salario?.mes - 1]}{" "}
                  {salario?.ano} [
                  <Link
                    className="text-primary"
                    to={"/salary-sheet-employees/" + salario?.folha_salarial_id}
                  >
                    <strong>FOLHA SALARIAL</strong>
                  </Link>
                  ]{" "}
                  <span
                    className="btn btn-danger"
                    onClick={() => submitReciboPDFreport(salario?.id)}
                  >
                    <i className="fa fa-file-pdf"></i>&nbsp;PDF
                  </span>
                </h4>
                <div className="row">
                  <div className="col-sm-6 m-b-20">
                    <img
                      src={url_root + "/storage/" + contacto?.logo}
                      className="inv-logo"
                      alt="Logo"
                    />
                    <ul className="list-unstyled mb-0">
                      <li>{contacto?.nome}</li>
                      <li>NIF {contacto?.nif}</li>
                      <li>E-MAIL {contacto?.email}</li>
                      <li>TEL. {contacto?.telefone}</li>
                    </ul>
                  </div>
                  <div className="col-sm-6 m-b-20">
                    <div className="invoice-details">
                      <h3 className="text-uppercase">
                        {t("Payslip")} #{salario?.id}
                      </h3>
                      <ul className="list-unstyled">
                        <li>
                          {t("Salary Month")}:{" "}
                          <span>
                            {meses[salario?.mes - 1]}
                            {", "}
                            {salario?.ano}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 m-b-20">
                    <ul className="list-unstyled">
                      <li>
                        <h5 className="mb-0">
                          <Link
                            className="text-primary"
                            to={"/profile/" + salario?.colaborador?.id}
                          >
                            <strong>{salario?.colaborador?.name}</strong>
                          </Link>
                        </h5>
                      </li>
                      <li>
                        <span>{salario?.cargo}</span>
                      </li>
                      <li>
                        {t("Employee ID")}: ID{salario?.colaborador?.id}
                      </li>
                      <li>
                        {t("Data de admissão")}:{" "}
                        {salario?.colaborador?.admissao
                          ? format(
                              new Date(salario?.colaborador?.admissao),
                              "dd/MM/yyyy"
                            )
                          : salario?.colaborador?.admissao}
                      </li>
                      <li>
                        {t("Faltas")}: {salario?.faltas}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div>
                      <h4 className="m-b-10">
                        <strong>{t("Earnings")}</strong>
                      </h4>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <strong>{t("Basic Salary")}</strong>
                              <span className="float-end">
                                Kz {formatNumber(salario?.salario_base)}
                              </span>
                            </td>
                          </tr>

                          {agregados
                            ?.filter((agregado) => agregado.tipo == 1)
                            .map((item, index) =>
                              item?.valor > 0 ? (
                                <>
                                  <tr>
                                    <td>
                                      <strong>
                                        {item?.designacao}
                                        {item?.natureza == 2 ? (
                                          <>[{item?.referencia}%]</>
                                        ) : (
                                          <></>
                                        )}
                                      </strong>{" "}
                                      <span className="float-end">
                                        Kz {formatNumber(item?.valor)}
                                      </span>
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <></>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div>
                      <h4 className="m-b-10">
                        <strong>{t("Deductions")}</strong>
                      </h4>
                      <table className="table table-bordered">
                        <tbody>
                          {salario?.INSS > 0 ? (
                            <>
                              <tr>
                                <td>
                                  <strong>{t("INSS")}</strong>
                                  <span className="float-end">
                                    Kz {formatNumber(salario?.INSS)}
                                  </span>
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}

                          {salario?.IRT > 0 ? (
                            <>
                              <tr>
                                <td>
                                  <strong>{t("IRT")}</strong>
                                  <span className="float-end">
                                    Kz {formatNumber(salario?.IRT)}
                                  </span>
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}

                          {agregados
                            ?.filter((agregado) => agregado.tipo == 2)
                            .map((item, index) =>
                              item?.valor > 0 ? (
                                <>
                                  <tr>
                                    <td>
                                      <strong>
                                        {item?.designacao}
                                        {item?.natureza == 2 ? (
                                          <>[{item?.referencia}%]</>
                                        ) : (
                                          <></>
                                        )}
                                      </strong>{" "}
                                      <span className="float-end">
                                        Kz {formatNumber(item?.valor)}
                                      </span>
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <></>
                              )
                            )}

                          {dividas.map((item, index) =>
                              item?.valor > 0 ? (
                                <>
                                  <tr>
                                    <td className="text-danger">
                                      <strong>
                                        {item?.designacao}
                                      </strong>{" "}
                                      <span className="float-end">
                                        Kz {formatNumber(item?.valor)}
                                      </span>
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <></>
                              )
                            )}

                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <strong className="text-primary">
                                {t("Salario liquido")}
                              </strong>
                              <span className="float-end text-primary">
                                <strong>
                                  Kz {formatNumber(salario?.salario_iliquido)}{" "}
                                </strong>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <>
          <Spinner></Spinner>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PaySlip;
