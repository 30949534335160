import React, { useEffect, useState } from "react";

import Breadcrumbs from "../../../../components/Breadcrumbs";
import { Link } from "react-router-dom";

import ActivitiesList from "../../../../assets/json/activities";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../../components/AuthUser";

import { format } from "date-fns";

import Spinner from "../../../../components/Spinner";

import { toast, ToastContainer } from "react-toastify";

const Activities = () => {
  // eslint-disable-next-line no-unused-vars
  const {
    http,
    url_root,
    user_permissoes,
    verificarPermissao,
    user,
    getMissao,
  } = AuthUser();

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);
  const [atividades, setAtividades] = useState([]);

  const [data1, setData1] = useState();
  const [data2, setData2] = useState();
  const [descricao, setDescricao] = useState("");

  const [colaboradores, setColaboradores] = useState();
  const [colaborador_id, setColaborador] = useState(-1);
  const [tipo, setTipo] = useState(-1);

  const data = ActivitiesList.ActivitiesList;

  const { t } = useTranslation();

  const getAtividades = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/atividades/" + getMissao()?.id);

      setAtividades(res.data.atividades);
      setColaboradores(res.data?.colaboradores);

      console.log("Atividades : ");
      console.log(res.data.atividades);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Chama a função assíncrona getUtilizador()
        await getAtividades();
      } catch (error) {
        console.error("Erro ao obter utilizadores:", error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, []);


  const pesquisar = async () => {
    try {
      
      setLoading(true);

      const formData = new FormData();

      formData.append("tipo", tipo);
      formData.append("colaborador_id", colaborador_id);
      formData.append("data1", data1);
      formData.append("descricao", descricao);
      formData.append("data2", data2);
      formData.append("missao_id", getMissao()?.id);

      const response = await http.post("/atividades/pesquisa", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log(response.data);

      setAtividades(response.data?.atividades);

      setLoading(false);

    } catch (error) {
      setLoading(false);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        }
      );
    }
  };


  const renderOptionColaboradores = (colaboradores) => {
    return colaboradores?.map((colaborador) => (
      <React.Fragment key={colaborador?.id}>
        <option value={colaborador?.id}>
          {colaborador?.name} - {colaborador?.cargo?.designacao}
        </option>
      </React.Fragment>
    ));
  };

  if (loading)
    return (
      <>
        <Spinner></Spinner>
      </>
    );
  else
    return (
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle={t("Activities")}
            title="Dashboard"
            subtitle={t("Activities")}
          />

          <div className="row filter-row space">
            <div className="col-sm-6 col-md-2">
              <div className="input-block form-focus select-focus">
                <label className="focus-label">{t("Data 1")}</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => setData1(e.target.value)}
                  value={data1}
                />
              </div>
            </div>

            <div className="col-sm-6 col-md-2">
              <div className="input-block form-focus select-focus">
                <label className="focus-label">{t("Data 2")}</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => setData2(e.target.value)}
                  value={data2}
                />
              </div>
            </div>

            <div className="col-sm-6 col-md-2">
              <div className="input-block form-focus select-focus">
                <label className="focus-label">{t("Descrição")}</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setDescricao(e.target.value)}
                  value={descricao}
                />
              </div>
            </div>
          

            <div className="col-sm-6 col-md-2">
              <div className="input-block form-focus select-focus">
                <select
                  className="form-control"
                  onChange={(e) => setTipo(e.target.value)}
                  value={tipo}
                >
                  <option value="-1">-</option>
                  <option value="1">Registado</option>
                  <option value="2">Editado</option>
                  <option value="3">Eliminado</option>
                  <option value="7">Ativado</option>
                  <option value="8">Desativado</option>
                </select>
                <label className="focus-label">{t("Tipo de acção")}</label>
              </div>
            </div>

            <div className="col-sm-12 col-md-3">
              <div className="input-block form-focus select-focus">
                <label className="focus-label">{t("Utilizador")}</label>
                <select
                  onChange={(e) => setColaborador(e.target.value)}
                  value={colaborador_id}
                  className="form-control select"
                >
                  <option key={0} value={-1}>
                    -
                  </option>
                  {renderOptionColaboradores(colaboradores)}
                </select>
              </div>
            </div>

            <div className="col-sm-6 col-md-1">
              {!loading ? (
                <>
                  <span
                    className="btn btn-success btn-block w-100"
                    onClick={() => pesquisar()}
                  >
                    <i className="fa fa-search"></i>
                  </span>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="activity">
                <div className="activity-box">
                  <ul className="activity-list">
                    {atividades?.map((activity, index) => (
                      <li key={index}>
                        <div className="activity-user">
                          <Link
                            to={"/profile/" + activity?.user?.id}
                            title={activity?.user?.name}
                            data-bs-toggle="tooltip"
                            className="avatar"
                          >
                            <img
                              src={
                                url_root +
                                "/storage/" +
                                activity?.user?.fotografia
                              }
                              alt={activity?.user?.name}
                              title={activity?.user?.name}
                            />
                          </Link>
                        </div>
                        <div className="activity-content">
                          <div className="timeline-content">
                            <Link
                              to={"/profile/" + activity?.user?.id}
                              className="name"
                            >
                              {activity?.user?.name}
                            </Link>{" "}
                            {/* PROJETOS */}
                            {/* Adicionou projeto */}
                            {activity?.tipo == 1 &&
                            activity?.accao == 1 &&
                            activity?.receptor_id == 0
                              ? " adicionou novo projeto, "
                              : ""}
                            {/* editou projeto */}
                            {activity?.tipo == 1 && activity?.accao == 2
                              ? " editou a informação do projeto " +
                                activity?.mensagem +
                                "  "
                              : ""}
                            {/* fechou projeto */}
                            {activity?.tipo == 1 && activity?.accao == 9
                              ? " fechou o projeto, " +
                                activity?.mensagem +
                                "  "
                              : ""}
                            {/* abriu projeto */}
                            {activity?.tipo == 1 && activity?.accao == 10
                              ? " voltou a abrir o projeto para execução, " +
                                activity?.mensagem +
                                "  "
                              : ""}
                            {/* prazo projeto */}
                            {activity?.tipo == 1 && activity?.accao == 11
                              ? " Alterou o prazo ( deadline ) do projeto, " +
                                activity?.mensagem +
                                "  "
                              : ""}
                            {/* adicionou colaborador ao projeto */}
                            {activity?.tipo == 1 &&
                              activity?.accao == 1 &&
                              activity?.receptor_id != 0 && (
                                <span>
                                  adicionou o colaborador{" "}
                                  <Link
                                    to={"/profile/" + activity?.receptor?.id}
                                    className="name"
                                  >
                                    {activity?.receptor?.name}
                                  </Link>{" "}
                                  ao projeto, {activity?.mensagem}
                                  {", "}
                                </span>
                              )}
                            {/* removeu colaborador do projeto */}
                            {activity?.tipo == 1 &&
                              activity?.accao == 3 &&
                              activity?.receptor_id != 0 && (
                                <span>
                                  removeu o colaborador{" "}
                                  <Link
                                    to={"/profile/" + activity?.receptor?.id}
                                    className="name"
                                  >
                                    {activity?.receptor?.name}
                                  </Link>{" "}
                                  do projeto, {activity?.mensagem}
                                  {", "}
                                </span>
                              )}
                            {/* Aprovar, reprovar projeto */}
                            {activity?.tipo == 1 &&
                              activity?.accao == 4 &&
                              activity?.receptor_id == 0 && (
                                <span>
                                  {activity?.mensagem} a execução do projeto
                                  {", "}
                                </span>
                              )}
                            {/* Arquivar, desarquivar projeto */}
                            {activity?.tipo == 1 &&
                              activity?.accao == 6 &&
                              activity?.receptor_id == 0 && (
                                <span>
                                  {activity?.mensagem} o projeto{", "}
                                </span>
                              )}
                            {/* eliminar projeto */}
                            {activity?.tipo == 1 &&
                              activity?.accao == 3 &&
                              activity?.receptor_id == 0 && (
                                <span>
                                  {"removeu o projeto " + activity?.mensagem}{" "}
                                </span>
                              )}
                            {/* TAREFAS */}
                            {/* adicionou tarefa ao projeto */}
                            {activity?.tipo == 2 &&
                              activity?.accao == 1 &&
                              activity?.receptor_id == 0 && (
                                <span>
                                  adicionou a tarefa{" "}
                                  <Link
                                    to={"/task/tasks/" + activity?.tarefa_id}
                                    className="name"
                                  >
                                    {" "}
                                    {activity?.mensagem}
                                  </Link>{" "}
                                  ao projeto {", "}
                                </span>
                              )}
                            {/* editou a tarefa do projeto */}
                            {activity?.tipo == 2 &&
                              activity?.accao == 2 &&
                              activity?.receptor_id == 0 && (
                                <span>
                                  editou a tarefa{" "}
                                  <Link
                                    to={"/task/tasks/" + activity?.tarefa_id}
                                    className="name"
                                  >
                                    {" "}
                                    {activity?.mensagem}
                                  </Link>{" "}
                                  no projeto {", "}
                                </span>
                              )}
                            {/* fechou tarefa */}
                            {activity?.tipo == 2 && activity?.accao == 9
                              ? " fechou a tarefa, " +
                                activity?.tarefa?.nome +
                                " no projeto,  "
                              : ""}
                            {/* abriu tarefa */}
                            {activity?.tipo == 2 && activity?.accao == 10
                              ? " voltou a abrir a tarefa " +
                                activity?.tarefa?.nome +
                                " no projeto,  "
                              : ""}
                            {/* prazo tarefa */}
                            {activity?.tipo == 2 && activity?.accao == 11
                              ? " Alterou o prazo ( deadline ) da tarefa " +
                                activity?.tarefa?.nome +
                                " no projeto,  "
                              : ""}
                            {/* eliminar tarefa */}
                            {activity?.tipo == 2 &&
                              activity?.accao == 3 &&
                              activity?.receptor_id == 0 && (
                                <span>
                                  {"removeu a tarefa " + activity?.mensagem}
                                  {" no projeto, "}
                                </span>
                              )}
                            {/* adicionou colaborador na tarefa */}
                            {activity?.tipo == 2 &&
                              activity?.accao == 1 &&
                              activity?.receptor_id != 0 && (
                                <span>
                                  adicionou o colaborador{" "}
                                  <Link
                                    to={"/profile/" + activity?.receptor?.id}
                                    className="name"
                                  >
                                    {activity?.receptor?.name}
                                  </Link>{" "}
                                  a tarefa,{" "}
                                  <Link
                                    to={"/task/tasks/" + activity?.tarefa_id}
                                    className="name"
                                  >
                                    {activity?.tarefa?.nome}
                                  </Link>
                                  {", no projeto "}
                                </span>
                              )}
                            {/* removeu colaborador da tarefa */}
                            {activity?.tipo == 2 &&
                              activity?.accao == 3 &&
                              activity?.receptor_id != 0 && (
                                <span>
                                  removeu o colaborador{" "}
                                  <Link
                                    to={"/profile/" + activity?.receptor?.id}
                                    className="name"
                                  >
                                    {activity?.receptor?.name}
                                  </Link>{" "}
                                  da tarefa{" "}
                                  <Link
                                    to={"/task/tasks/" + activity?.tarefa_id}
                                    className="name"
                                  >
                                    {activity?.tarefa?.nome}
                                  </Link>
                                  {", no projeto "}
                                </span>
                              )}
                            {/* ITEMS DE TAREFA */}
                            {/* adicionou item de tarefa a tarefa */}
                            {activity?.tipo == 3 &&
                              activity?.accao == 1 &&
                              activity?.receptor_id == 0 && (
                                <span>
                                  adicionou item de tarefa{" "}
                                  <Link
                                    to={"/task/tasks/" + activity?.tarefa_id}
                                    className="name"
                                  >
                                    {" "}
                                    {activity?.item?.nome}
                                  </Link>{" "}
                                  a tarefa{" "}
                                  <Link
                                    to={"/task/tasks/" + activity?.tarefa_id}
                                    className="name"
                                  >
                                    {" "}
                                    {activity?.tarefa?.nome}
                                  </Link>{" "}
                                  no projeto,
                                </span>
                              )}
                            {/* editou a tarefa do projeto */}
                            {activity?.tipo == 3 &&
                              activity?.accao == 2 &&
                              activity?.receptor_id == 0 && (
                                <span>
                                  editou a informação do item de tarefa{" "}
                                  <Link
                                    to={"/task/tasks/" + activity?.tarefa_id}
                                    className="name"
                                  >
                                    {" "}
                                    {activity?.item?.nome}
                                  </Link>{" "}
                                  a tarefa{" "}
                                  <Link
                                    to={"/task/tasks/" + activity?.tarefa_id}
                                    className="name"
                                  >
                                    {" "}
                                    {activity?.tarefa?.nome}
                                  </Link>{" "}
                                  no projeto,
                                </span>
                              )}
                            {/* fechou item de tarefa */}
                            {activity?.tipo == 3 && activity?.accao == 9 ? (
                              <span>
                                fechou o item de tarefa, {activity?.item?.nome}{" "}
                                na tarefa{" "}
                                <Link
                                  to={"/task/tasks/" + activity?.tarefa_id}
                                  className="name"
                                >
                                  {" " + activity?.tarefa?.nome}
                                </Link>{" "}
                                do projeto{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            {/* abriu item de tarefa */}
                            {activity?.tipo == 3 && activity?.accao == 10 ? (
                              <span>
                                voltou a abrir o item de tarefa,{" "}
                                {activity?.item?.nome} para execução na tarefa{" "}
                                <Link
                                  to={"/task/tasks/" + activity?.tarefa_id}
                                  className="name"
                                >
                                  {" " + activity?.tarefa?.nome}
                                </Link>{" "}
                                do projeto{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            {/* eliminar item de tarefa */}
                            {activity?.tipo == 3 &&
                              activity?.accao == 3 &&
                              activity?.receptor_id == 0 && (
                                <span>
                                  removeu item de tarefa{" "}
                                  <Link
                                    to={"/task/tasks/" + activity?.tarefa_id}
                                    className="name"
                                  >
                                    {" "}
                                    {activity?.mensagem}
                                  </Link>{" "}
                                  na a tarefa{" "}
                                  <Link
                                    to={"/task/tasks/" + activity?.tarefa_id}
                                    className="name"
                                  >
                                    {" "}
                                    {activity?.tarefa?.nome}
                                  </Link>{" "}
                                  do projeto,
                                </span>
                              )}
                            {activity?.tipo == 6 &&
                              activity?.accao == 2 &&
                              activity?.projeto_id != 0 && (
                                <span>
                                  Adicionou ficheiros ao projeto{" "}
                                  <Link
                                    to={
                                      "/project-view/" + activity?.projeto?.id
                                    }
                                  >
                                    {activity?.projeto?.nome}
                                  </Link>
                                </span>
                              )}
                            {activity?.tipo == 6 &&
                              activity?.accao == 3 &&
                              activity?.projeto_id != 0 && (
                                <span>
                                  Removeu ficheiros ao projeto{" "}
                                  <Link
                                    to={
                                      "/project-view/" + activity?.projeto?.id
                                    }
                                  >
                                    {activity?.projeto?.nome}
                                  </Link>
                                </span>
                              )}
                            {/* TICKETS */}
                            {/* Adicionou ticket */}
                            {activity?.tipo == 4 &&
                            activity?.accao == 1 &&
                            activity?.receptor_id == 0 ? (
                              <span>
                                {" "}
                                adicionou novo ticket,{" "}
                                <Link
                                  to={"/task/ticket-view/" + activity?.tipo_id}
                                  className="name"
                                >
                                  {activity?.mensagem}
                                  {", "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}
                            {/* editou ticket */}
                            {activity?.tipo == 4 && activity?.accao == 2 ? (
                              <span>
                                {" "}
                                editou a informação do ticket{" "}
                                <Link
                                  to={"/task/ticket-view/" + activity?.tipo_id}
                                  className="name"
                                >
                                  {activity?.mensagem}
                                  {", "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}
                            {/* fechou ticket */}
                            {activity?.tipo == 4 && activity?.accao == 9 ? (
                              <span>
                                {" "}
                                fechou o ticket,{" "}
                                <Link
                                  to={"/task/ticket-view/" + activity?.tipo_id}
                                  className="name"
                                >
                                  {activity?.mensagem}
                                  {", "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}
                            {/* abriu ticket */}
                            {activity?.tipo == 4 && activity?.accao == 10
                              ? " voltou a abrir o ticket para execução, " +
                                activity?.mensagem +
                                "  "
                              : ""}
                            {/* prazo ticket */}
                            {activity?.tipo == 4 && activity?.accao == 11 ? (
                              <span>
                                {" "}
                                Alterou o prazo ( deadline ) do ticket,{" "}
                                <Link
                                  to={"/task/ticket-view/" + activity?.tipo_id}
                                  className="name"
                                >
                                  {activity?.mensagem}
                                  {", "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}
                            {/* adicionou colaborador ao ticket */}
                            {activity?.tipo == 4 &&
                              activity?.accao == 1 &&
                              activity?.receptor_id != 0 && (
                                <span>
                                  adicionou o colaborador{" "}
                                  <Link
                                    to={"/profile/" + activity?.receptor?.id}
                                    className="name"
                                  >
                                    {activity?.receptor?.name}
                                  </Link>{" "}
                                  ao ticket,{" "}
                                  <Link
                                    to={
                                      "/task/ticket-view/" + activity?.tipo_id
                                    }
                                    className="name"
                                  >
                                    {activity?.mensagem}
                                    {", "}
                                  </Link>
                                </span>
                              )}
                            {/* removeu colaborador do ticket */}
                            {activity?.tipo == 4 &&
                              activity?.accao == 3 &&
                              activity?.receptor_id != 0 && (
                                <span>
                                  removeu o colaborador{" "}
                                  <Link
                                    to={"/profile/" + activity?.receptor?.id}
                                    className="name"
                                  >
                                    {activity?.receptor?.name}
                                  </Link>{" "}
                                  do ticket,{" "}
                                  <Link
                                    to={
                                      "/task/ticket-view/" + activity?.tipo_id
                                    }
                                    className="name"
                                  >
                                    {activity?.mensagem}
                                    {", "}
                                  </Link>
                                </span>
                              )}
                            {/* Aprovar, reprovar ticket */}
                            {activity?.tipo == 4 &&
                              activity?.accao == 4 &&
                              activity?.receptor_id == 0 && (
                                <span>
                                  {activity?.mensagem} a execução do ticket{" "}
                                  <Link
                                    to={
                                      "/task/ticket-view/" + activity?.tipo_id
                                    }
                                    className="name"
                                  >
                                    {activity?.mensagem}
                                    {", "}
                                  </Link>
                                </span>
                              )}
                            {/* Arquivar, desarquivar ticket */}
                            {activity?.tipo == 4 &&
                              activity?.accao == 6 &&
                              activity?.receptor_id == 0 && (
                                <span>
                                  {activity?.mensagem} o ticket{" "}
                                  <Link
                                    to={
                                      "/task/ticket-view/" + activity?.tipo_id
                                    }
                                    className="name"
                                  >
                                    {activity?.mensagem}
                                    {", "}
                                  </Link>
                                </span>
                              )}
                            {/* eliminar ticket */}
                            {activity?.tipo == 4 &&
                              activity?.accao == 3 &&
                              activity?.receptor_id == 0 && (
                                <span>
                                  {"removeu o ticket " + activity?.mensagem}{" "}
                                </span>
                              )}
                            {/* CHATS */}
                            {/* chat no direto */}
                            {activity?.tipo == 7 &&
                              activity?.accao == 1 &&
                              activity?.chat_para == 1 && (
                                <span>
                                  deixou mensagem para{" "}
                                  <Link
                                    to={"/call/chat/1/" + activity?.receptor_id}
                                  >
                                    {activity?.receptor?.name}
                                  </Link>
                                </span>
                              )}
                            {/* chat na tarefa */}
                            {activity?.tipo == 7 &&
                              activity?.accao == 1 &&
                              activity?.chat_para == 2 && (
                                <span>
                                  conversou no chat da tarefa{" "}
                                  <Link
                                    to={"/task/tasks/" + activity?.tarefa_id}
                                    className="name"
                                  >
                                    {activity?.tarefa?.nome}
                                  </Link>{" "}
                                  do projeto{" "}
                                  <Link
                                    to={
                                      "/project-view/" + activity?.projeto?.id
                                    }
                                  >
                                    {activity?.projeto?.nome}
                                  </Link>
                                </span>
                              )}
                            {/* chat no ticket */}
                            {activity?.tipo == 7 &&
                              activity?.accao == 1 &&
                              activity?.chat_para == 3 && (
                                <span>
                                  conversou no chat do ticket{" "}
                                  <Link
                                    to={"/ticket-view/" + activity?.tipo_id}
                                  >
                                    {activity?.mensagem}
                                  </Link>
                                </span>
                              )}


                            {/* NOME E LINK DO PROJETO, DATA E HORA */}
                            {activity?.tipo == 1 ||
                            activity?.tipo == 2 ||
                            activity?.tipo == 3 ? (
                              <Link
                                to={"/project-view/" + activity?.projeto?.id}
                              >
                                {activity?.projeto?.nome}
                              </Link>
                            ) : (
                              ""
                            )}
                            <span className="time">
                              {format(new Date(activity.data), "dd/MM/yyyy") +
                                " " +
                                activity.hora}
                            </span>


                            {/* Adicionou cargo [8] */}
                            {activity?.tipo == 8 && activity?.accao == 1 ? (
                              <span>
                                {" "}
                                adicionou novo cargo,{" "}
                                <Link to={"/designations"} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}

                            {/* cargos */}
                            {/* editou cargo [8]*/}
                            {activity?.tipo == 8 && activity?.accao == 2 ? (
                              <span>
                                {" "}
                                editou a informação do cargo{" "}
                                <Link to={"/designations"} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}
                            {/* eliminar cargo */}
                            {activity?.tipo == 8 && activity?.accao == 3 ? (
                              <span>
                                {"removeu o cargo " + activity?.mensagem}{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            {/* ativar cargo [8] */}
                            {activity?.tipo == 8 && activity?.accao == 7 ? (
                              <span>
                                {"ativou o cargo " + activity?.mensagem}{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            {/* desativar cargo [8] */}
                            {activity?.tipo == 8 && activity?.accao == 8 ? (
                              <span>
                                {"desativou o cargo " + activity?.mensagem}{" "}
                              </span>
                            ) : (
                              ""
                            )}



                            {/* departamentos */}

                            {/* Adicionou departamento [9] */}
                            {activity?.tipo == 9 && activity?.accao == 1 ? (
                              <span>
                                {" "}
                                adicionou novo departamento,{" "}
                                <Link to={"/departments"} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}
                            {/* editou departamento [9]*/}
                            {activity?.tipo == 9 && activity?.accao == 2 ? (
                              <span>
                                {" "}
                                editou a informação do departamento{" "}
                                <Link to={"/departments"} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}
                            {/* eliminar departamento */}
                            {activity?.tipo == 9 && activity?.accao == 3 ? (
                              <span>
                                {"removeu o departamento " + activity?.mensagem}{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            {/* ativar departamento [9] */}
                            {activity?.tipo == 9 && activity?.accao == 7 ? (
                              <span>
                                {"ativou o departamento " + activity?.mensagem}{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            {/* desativar departamento [8] */}
                            {activity?.tipo == 9 && activity?.accao == 8 ? (
                              <span>
                                {"desativou o departamento " + activity?.mensagem}{" "}
                              </span>
                            ) : (
                              ""
                            )}



                            {/* colaborador */}

                            {/* Adicionou colaborador [10] */}
                            {activity?.tipo == 10 && activity?.accao == 1 ? (
                              <span>
                                {" "}
                                adicionou novo colaborador,{" "}
                                <Link to={"/profile/"+activity?.receptor_id} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}
                            {/* editou colaborador [10]*/}
                            {activity?.tipo == 10 && activity?.accao == 2 ? (
                              <span>
                                {" "}
                                editou a informação do colaborador{" "}
                                <Link to={"/profile/"+activity?.receptor_id} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}
                            {/* eliminar colaborador */}
                            {activity?.tipo == 10 && activity?.accao == 3 ? (
                              <span>
                                {"removeu o colaborador " + activity?.mensagem}{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            {/* ativar colaborador [10] */}
                            {activity?.tipo == 10 && activity?.accao == 7 ? (
                              <span>
                                {"ativou o colaborador "}{" "}
                                <Link to={"/profile/"+activity?.receptor_id} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}
                            {/* desativar colaborador [10] */}
                            {activity?.tipo == 10 && activity?.accao == 8 ? (
                              <span>
                                {"desativou o colaborador "}{" "}
                                <Link to={"/profile/"+activity?.receptor_id} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}


                            {/* Cliente */}

                            {/* Adicionou cliente [11] */}
                            {activity?.tipo == 11 && activity?.accao == 1 ? (
                              <span>
                                {" "}
                                adicionou novo cliente,{" "}
                                <Link to={"/clientes"} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}
                           
                            {/* editou cliente [11]*/}
                            {activity?.tipo == 11 && activity?.accao == 2 ? (
                              <span>
                                {" "}
                                editou a informação do cliente{" "}
                                <Link to={"/clientes"} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}
                            {/* eliminar cliente */}
                            {activity?.tipo == 11 && activity?.accao == 3 ? (
                              <span>
                                {"removeu o cliente " + activity?.mensagem}{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            {/* ativar cliente [11] */}
                            {activity?.tipo == 11 && activity?.accao == 7 ? (
                              <span>
                                {"ativou o cliente "}{" "}
                                <Link to={"/clientes"} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}
                            {/* desativar cliente [11] */}
                            {activity?.tipo == 11 && activity?.accao == 8 ? (
                              <span>
                                {"desativou o cliente "}{" "}
                                <Link to={"/clientes"} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}


                             {/* Posto de cliente */}

                            {/* Adicionou posto de cliente [12] */}
                            {activity?.tipo == 12 && activity?.accao == 1 ? (
                              <span>
                                {" "}
                                adicionou novo posto de cliente,{" "}
                                <Link to={"/postos-clientes"} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}
                           
                            {/* editou posto de cliente [12]*/}
                            {activity?.tipo == 12 && activity?.accao == 2 ? (
                              <span>
                                {" "}
                                editou a informação do posto de cliente{" "}
                                <Link to={"/postos-clientes"} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}

                            {/* eliminar posto de cliente */}
                            {activity?.tipo == 12 && activity?.accao == 3 ? (
                              <span>
                                {"removeu o posto de cliente " + activity?.mensagem}{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            {/* ativar posto de cliente [12] */}
                            {activity?.tipo == 12 && activity?.accao == 7 ? (
                              <span>
                                {"ativou o posto de cliente "}{" "}
                                <Link to={"/postos-clientes"} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}

                            {/* desativar posto de cliente [12] */}
                            {activity?.tipo == 12 && activity?.accao == 8 ? (
                              <span>
                                {"desativou o posto de cliente "}{" "}
                                <Link to={"/postos-clientes"} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}


                             {/* Subsidio|Descontos */}

                            {/* Adicionou Subsidio|Descontos [13] */}
                            {activity?.tipo == 13 && activity?.accao == 1 ? (
                              <span>
                                {" "}
                                adicionou novo Subsidio|Desconto,{" "}
                                <Link to={"/payroll-items"} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}
                           
                            {/* editou Subsidio|Descontos [13]*/}
                            {activity?.tipo == 13 && activity?.accao == 2 ? (
                              <span>
                                {" "}
                                editou a informação do Subsidio|Desconto{" "}
                                <Link to={"/payroll-items"} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}
                            {/* eliminar Subsidio|Descontos */}
                            {activity?.tipo == 13 && activity?.accao == 3 ? (
                              <span>
                                {"removeu o Subsidio|Desconto " + activity?.mensagem}{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            {/* ativar Subsidio|Descontos [13] */}
                            {activity?.tipo == 13 && activity?.accao == 7 ? (
                              <span>
                                {"ativou o Subsidio|Desconto "}{" "}
                                <Link to={"/payroll-items"} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}
                            {/* desativar Subsidio|Descontos [13] */}
                            {activity?.tipo == 13 && activity?.accao == 8 ? (
                              <span>
                                {"desativou o Subsidio|Desconto "}{" "}
                                <Link to={"/payroll-items"} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}


                            {/* folha salarial */}

                            {/* Adicionou folha salarial [14] */}
                            {activity?.tipo == 14 && activity?.accao == 1 ? (
                              <span>
                                {" "}
                                adicionou nova folha salarial,{" "}
                                <Link to={"/salary-sheet-employees/"+activity?.tipo_id} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}
                           
                            {/* editou folha salarial [14]*/}
                            {activity?.tipo == 14 && activity?.accao == 2 ? (
                              <span>
                                {" "}
                                editou a informação da folha salarial{" "}
                                <Link to={"/salary-sheet-employees/"+activity?.tipo_id} className="name">
                                  {activity?.mensagem}{" "}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}

                            {/* eliminar folha salarial */}
                            {activity?.tipo == 14 && activity?.accao == 3 ? (
                              <span>
                                {"removeu a folha salarial " + activity?.mensagem}{" "}
                              </span>
                            ) : (
                              ""
                            )}
                          



                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Activities;
