/* eslint-disable react/no-unescaped-entities */

import React, { useState, useEffect } from "react";
import ChatView from "./chatView";
import ChatRightsidebar from "./chatRightsidebar";
import ChatModals from "./chatModals";
import { Link, useParams, useNavigate } from "react-router-dom";

import AuthUser from "../../../../../components/AuthUser";

const Chat = () => {

  let { id } = useParams(); 

  let { tipo } = useParams();

  const [utilizador, setUtilizador] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, user, getMissao } = AuthUser();

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  
  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  const getUtilizador = async (user_id) => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/utilizador/' + id);

      console.log('utilizador',res.data.utilizador);
      setUtilizador(res.data.utilizador);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    const fetchData = async () => {

      try {
        // Chama a função assíncrona getUtilizador()       
        await getUtilizador(id);
      } catch (error) {
        console.error('Erro ao obter utilizadores:', error);
      }

    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();

    return () => {
      window.removeEventListener("resize", detectSize);
    };

  }, [windowDimension, id, tipo ]);


  useEffect(() => {
    let firstload = localStorage.getItem("minheight");
    if (firstload == "false") {
      setTimeout(function () {
        window.location.reload(1);
        localStorage.removeItem("minheight");
      }, 1000);
    }
  });

  return (
    <>
      <div
        className="page-wrapper"
        style={{ minHeight: windowDimension.winHeight }}
      >
        {/* Chat Main Row */}
        <div className="chat-main-row">
          <div className="chat-main-wrapper">

            <ChatView utilizador={utilizador} />
            <ChatRightsidebar utilizador={utilizador} />
            <ChatModals utilizador={utilizador} />


          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
