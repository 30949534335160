
import React, { useState, useEffect } from "react";
import { Table } from "antd";
import {
  User1,
  User2,
  User3,
  User4,
  User5,
  User6,
  User7,
  User8,
  User9,
  User10,
  User11,
  User12,
} from "../../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import EditUserModal from "../../../../components/Administration/Users/EditUseModal";
import DeleteModal from "../../../../components/modelpopup/DeleteModal";

import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";

import AuthUser from "../../../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../../../components/Spinner";

const UsersTable = ({ users, cargos, departamentos, missoes }) => {

  const { t } = useTranslation();

  const [utilizadores, setUtilizador] = useState([]);
  const [utilizadorSelecionado, setUtilizadorSelecionado] = useState([]);
  const [permissoes, setPermissoes] = useState([]);
  const [accoes, setAccoes] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } = AuthUser();

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [selectedUserIds, setSelectedUserIds] = useState([]);

  useEffect(() => {
    setUtilizador(users);
    getAccoes();
  }, [users]);

  const handleSubmissionSuccess = () => {
    getUtilizador();

  };

  const chamarModalEliminar = (idSelecionado) => {

    setUtilizadorSelecionado(idSelecionado);

    const editButton = document.getElementById('triggerModalEliminar');

    console.log('Utilizador selecionado:')
    console.log(utilizadorSelecionado);

    if (editButton) {
      editButton.click();
    }

  }

  const chamarModalEdit = (idSelecionado) => {

    setUtilizadorSelecionado(idSelecionado);

    const editButton = document.getElementById('triggerModalEdit');

    console.log('Utilizador selecionado:')
    console.log(utilizadorSelecionado);

    if (editButton) {
      editButton.click();
    }

  }

  const getUtilizador = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/utilizadores/'+getMissao()?.id);

      setUtilizador(res.data.utilizadores);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  /* permissoes */
  const getPermissoes = async (nivelSelecionado) => {

    try {

      setLoading(true);

      const formData = new FormData();
      formData.append('nivel_id', nivelSelecionado);

      const res = await http.post('/permissoes', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setPermissoes(res.data.permissoes);

      console.log('Permissões :');
      console.log(res.data.permissoes);

    } catch (error) {
      console.error('Ocorreu um erro ao carregar os permissões:', error);
    } finally {
      setLoading(false);
    }

  };

  const getAccoes = async () => {
    try {
      setLoading(true);

      const res = await http.get('/accoes');

      setAccoes(res.data.accoes);

      console.log('Acções');
      //console.log(res.data.accoes);

    } catch (error) {
      console.error('Ocorreu um erro ao carregar os acções:', error);
    } finally {
      setLoading(false);
    }
  };

  /* fim get permissões */


  const handleAtivarDesativar = async (id, newState) => {

    //console.log('Clicou aqui para ativar e desativar');

    if (verificarPermissao('UTILIZADORES', 'ativar')) {
      try {

        const formData = new FormData();
        formData.append('id', id);
        formData.append('estado', newState);

        const response = await http.post('/utilizador/activate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getUtilizador();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



        // Atualize a lista de utilizadores após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleResetSenha = (id) => {

    if (verificarPermissao('UTILIZADORES', 'atualizar')) {
      // Armazena o ID do usuário no estado resetSenhaUserId


      // Exibe a mensagem de confirmação
      Swal.fire({
        title: "Tem certeza que quer resetar a senha?",
        text: "Isso irá resetar a senha do usuário.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, resetar senha!",
      }).then((result) => {
        if (result.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação para resetar a senha
          setLoading(true);
          resetarSenha(id);
          setLoading(false);
        } else {
          // Se o usuário cancelar, limpe o estado resetSenhaUserId
          //setResetSenhaUserId(null);
          setLoading(false);
        }
      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const resetarSenha = async (id) => {
    if (verificarPermissao('UTILIZADORES', 'atualizar')) {
      try {
        // Gere uma senha aleatória no cliente
        const senhaAleatoria = Math.random().toString(36).slice(-8);

        // Exiba a senha antes de enviar a solicitação
        alert(`Nova Senha: ${senhaAleatoria}`);

        // Envie uma solicitação para a API para resetar a senha
        const response = await http.post(`/resetar-senha/${id}`, { novaSenha: senhaAleatoria });


        // Exiba uma mensagem de sucesso
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } catch (error) {
        // Exiba uma mensagem de erro se ocorrer um problema
        toast.error('Ocorreu um erro ao resetar a senha. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  const columns = [
    {
      title: t('Name'),
      dataIndex: "name",
      render: (text, record) => (
        <div className="table-avatar">

          <span
            onClick={() => handleResetSenha(record.id, text == 1 ? 0 : 1)}
          >
            <i className="fa fa-key"></i>
          </span>&nbsp;
          <Link to={"/profile/"+record.id} className="avatar">
            <img src={url_root + "/storage/" + record.fotografia} alt="foto" />
          </Link>
          <Link to={"/profile/"+record.id}>
            {text}
          </Link>
          

        </div>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Tel/Email",
      dataIndex: "email",
      render: (text, record) => (
        <div className="table-avatar">
          <span>
            {record.telefone}
          </span><br></br>
          <span>
            {record.email}
          </span>
        </div>
      ),
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Dept/cargo",
      dataIndex: "cargo_id",
      render: (text, record) => (
        <div className="table-avatar">
          <span>
            {'DPT : '} {record?.departamento?.designacao}
          </span><br></br>
          <span>
            {'Função : '}{record?.cargo?.designacao}
          </span>
        </div>
      ),
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Missão",
      dataIndex: "missao_id",
      render: (text, record) => (
        <div className="table-avatar">
          <span>
            {record?.missao?.designacao}
          </span>
        </div>
      ),
      sorter: (a, b) => a.missao_id.length - b.missao_id.length,
    },
    {
      title: t('Level'),
      dataIndex: "nivel_id",
      sorter: (a, b) => a.nivel.designacao.length - b.nivel.designacao.length,
      render: (text, record) => record?.nivel?.designacao,
    },
    {
      title: t('Status'),
      dataIndex: "estado",
      render: (text, record) => (
        <>
          <span
            className={
              text == 1
                ? "badge bg-inverse-success"
                : "badge bg-inverse-danger"
            }
          >
            {text == 1 ? 'Ativo' : 'Desativado'}
          </span>&nbsp;
          <span
            onClick={() => handleAtivarDesativar(record.id, text == 1 ? 0 : 1)}
          >
            <i className="fa fa-recycle"></i>
          </span>
        </>
      ),
      sorter: (a, b) => a.nivel_id.length - b.nivel_id.length,
    },

    {
      title: t('Action'),
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <span
              className="dropdown-item"
              title={record?.name}
              onClick={() => chamarModalEdit(record)}
            >
              <i className="fa fa-pencil m-r-5" /> {t('Edit')}
            </span>
            <span
              className="dropdown-item"
              title={record?.name}
              onClick={() => chamarModalEliminar(record)}
            >
              <i className="fa fa-trash m-r-5" /> {t('Delete')}
            </span>
          </div>
        </div>
      ),
    },
  ];

  if (loading) return (<><Spinner></Spinner></>); else return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">


            <Table
              className="table-striped"
              style={{ overflowX: "auto" }}
              columns={columns}
              dataSource={utilizadores}
              rowKey={(record) => record.id}
            />
            
            <EditUserModal onSubmissionSuccess={handleSubmissionSuccess} utilizador={utilizadorSelecionado} generoParam={utilizadorSelecionado?.genero} nivelParam={utilizadorSelecionado?.nivel_id} accoes={accoes} cargos={cargos} departamentos={departamentos} missoes={missoes} imagePreview={url_root + "/storage/"+utilizadorSelecionado?.fotografia} />

            <DeleteModal objectoName={t('User')} onSubmissionSuccess={handleSubmissionSuccess} id={utilizadorSelecionado?.id} endpoint={'utilizador'} />


            <span
              data-bs-toggle="modal"
              data-bs-target="#edit_user"
              id="triggerModalEdit"
            >
            </span>

            <span
              data-bs-toggle="modal"
              data-bs-target="#delete"
              id="triggerModalEliminar"
            >
            </span>

          </div>
        </div>
      </div>

      <ToastContainer />

    </>
  );
};

export default UsersTable;
