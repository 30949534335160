import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AuthUser from "../../../../../components/AuthUser";

const RecentTable = ({ projetos }) => {

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getToken, getMissao, user } = AuthUser();

  const [projetos_lista, setProjetos] = useState(projetos);

  useEffect(() => {

    const fetchData = async () => {
      try {
        // Chama a função assíncrona getUtilizador()
        setProjetos(projetos);
      } catch (error) {
        console.error('Erro ao obter utilizadores:', error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, [projetos_lista]);

  return (
    <div className="col-md-12 d-flex">
      <div className="card card-table flex-fill">
        <div className="card-header">
          <h3 className="card-title mb-0">{t('Recent projects')}</h3>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table custom-table mb-0">
              <thead>
                <tr>
                  <th>{t('Project Name')} </th>
                  <th>{t('Progress')}</th>
                  <th className="text-end">{t('Action')}</th>
                </tr>
              </thead>
              <tbody>

                {projetos_lista?.filter(project => {

                  // Excluir se a missão do projeto é diferente da missão na sessão
                  if (getMissao()?.id != project.missao_id) {
                    return false;
                  }

                  // Excluir se o projeto esta arquivado
                  if (project.estado == 2) {
                    return false;
                  }

                  // Se o tipo de usuário for 1, retorna true para todos os projetos
                  if (user.tipo == 1) {
                    return true;
                  }

                  // Se o projeto foi criado pelo usuário
                  if (project.criado_por == user.id) {
                    return true;
                  }

                  // Se o usuário é participante do projeto
                  const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                  if (participante) {
                    return true;
                  }

                  return false;

                })?.map((project) => (

                  <tr>
                    <td>

                      <h4 className="project-title">

                        {
                          project?.aprovado == 0 ? <><img width={24}
                            height={24} title={t('Pending to approve')} src={url_root + "/storage/uploads/icons/siren.png"} /></> : project?.aprovado == 1 ? <span title={t('Approved')}><i className="fa fa-thumbs-up text-success"></i></span> : <span title={t('Negated')}><i className="fa fa-thumbs-down text-danger"></i></span>
                        }

                        {
                          (project?.aprovado == 0 || project?.aprovado == 2) ? <></> :
                            project?.estado == 1 ? <><span
                              className={"badge bg-inverse-success"}
                            >
                              <i className="fa fa-check"></i>&nbsp;&nbsp; <span>{t('Closed')}</span>
                            </span>&nbsp;&nbsp;</> : ''
                        }

                        {
                          (project?.aprovado == 0 || project?.aprovado == 2) ? <></> : ((new Date(project?.data_fim) < new Date()) && project?.estado != 1) ? (<><span
                            className={"badge bg-inverse-danger"}
                          >
                            <i className="fa fa-clock"></i>&nbsp;&nbsp; <span>{t('Pending')}</span>
                          </span>&nbsp;&nbsp;</>) : (project?.estado != 1 ? (<span
                            className={"badge bg-inverse-warning"}
                          >
                            <i className="fa fa-list"></i>&nbsp;&nbsp; <span>{t('Opened')}</span>
                          </span>) : '')
                        }

                      </h4>

                      <h2>
                        <Link to={"/project-view/" + project?.id}>{project?.nome}</Link>
                      </h2>
                      <small className="block text-ellipsis">
                        <span className="text-xs">{project?.tarefas?.filter(tarefa => tarefa.estado == 0).length}</span>{" "}
                        <span className="text-warning">{t('open tasks')}, </span>

                        <span className="text-xs">
                          {project?.tarefas?.filter(tarefa => tarefa.estado == 1).length}
                        </span>{" "}
                        <span className="text-success">{t('tasks completed')}</span>{", "}

                        <span className="text-xs">
                          {project?.tarefas?.filter(tarefa => (((new Date(tarefa?.prazo) < new Date()) && tarefa?.estado != 1))).length}
                        </span>{" "}
                        <span className="text-danger">{t('Pending Tasks')}</span>

                      </small>
                    </td>
                    <td>

                    <span className="text-success float-end">
                          {Math.round(((project?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / project?.tarefas?.length) * 100) ? Math.round(((project?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / project?.tarefas?.length) * 100) : 0}%</span>
                          
                      <div className="progress progress-xs progress-striped">                       

                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          data-bs-toggle="tooltip"
                          title={(Math.round(((project?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / project?.tarefas?.length) * 100)) + '%'}
                          style={{ width: `${Math.round(((project?.tarefas?.filter(tarefa => tarefa.estado == 1).length) / project?.tarefas?.length) * 100)}%` }}
                        />

                      </div>
                    </td>
                    <td className="text-end">
                      <div className="dropdown dropdown-action">
                        <Link
                          to="#"
                          className="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="material-icons">more_vert</i>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-right">
                          <Link className="dropdown-item" to={"/project-view/" + project?.id}>
                            <i className="fa fa-pencil m-r-5" />{t('Edit')}
                          </Link>
                          {/* <Link className="dropdown-item" to="#">
                            <i className="fa fa-trash m-r-5" />{t('Delete')}
                          </Link> */}
                        </div>
                      </div>
                    </td>
                  </tr>

                ))}

              </tbody>
            </table>
          </div>
        </div>
        <div className="card-footer">
          <Link to="/projects">{t('View all projects')}</Link>
        </div>
      </div>
    </div>
  );
};

export default RecentTable;
