/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Table } from "antd";
import { User2, User3 } from "../../../Routes/ImagePath";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import TicketModelPopup from "../../../components/modelpopup/TicketModelPopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import TicketFilter from "../../../components/TicketFilter";
import { base_url } from "../../../base_urls";

import { useTranslation } from "react-i18next";
import TicketModalPopup from "../../../components/modelpopup/TicketModalPopup";

import { format } from 'date-fns';

import Swal from "sweetalert2";

import AuthUser from "../../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../../components/Spinner";

const TicketArquivado = () => {

  const [users, setUsers] = useState([]);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [tickets, setTickets] = useState([]);
  const [ticketSelecionado, setTicketSelecionado] = useState();
  const [filteredtickets, setFilteredtickets] = useState([]);

  const [numNovasSolicitacoes, setNumNovasSolicitacoes] = useState(0);
  const [numSolicitacoesResolvidas, setNumSolicitacoesResolvidas] = useState(0);
  const [numSolicitacoesAbertas, setNumSolicitacoesAbertas] = useState(0);
  const [numSolicitacoesPendentes, setNumSolicitacoesPendentes] = useState(0);

  const [utilizadores, setUtilizadores] = useState([]);
  const [missoes, setMissoes] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getToken, user, getMissao, logout } = AuthUser();
  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);


  useEffect(() => {

    axios.get(base_url + "/api/ticket.json").then((res) => setUsers(res.data));

    if (getToken() == undefined) {
      logout();
      //return <Redirect to="/signIn" />;
      //props.history.push('/trainning/system/signIn');
      //history.push('');
      navigate("/");
    } else {

      const fetchData = async () => {
        try {
          // Chama a função assíncrona getUtilizador()
          await getTickets();
          await getUtilizadores();
          await getMissoes();
        } catch (error) {
          console.error('Erro ao obter utilizadores:', error);
        }
      };

      // Chama a função fetchData() para iniciar o processo
      fetchData();

    }

  }, []);


   // Function to filter tasks based on specified conditions
   const filterTickets = (tickets) => {

    return tickets?.filter((task) => {
      // Excluir se a missão do projeto é diferente da missão na sessão
      if (getMissao()?.id != task?.missao_id) {
        return false;
      }

      // Excluir se o ticket está arquivado
      if (task?.estado != 2) {
        return false;
      }

      // Se o tipo de usuário for 1, retorna true para todos os projetos
      if (user.tipo == 1) {
        return true;
      }

      // Se o projeto foi criado pelo usuário
      if (task?.criado_por == user.id) {
        return true;
      }

      // Se o usuário é participante do projeto
      const participante = task?.colaboradores.find(
        (colaborador) => colaborador.colaborador_id == user.id
      );
      if (participante) {
        return true;
      }

      return false;
    });
  };


  const getMissoes = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/missoes_ativas');

      setMissoes(res.data.missoes);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getTickets = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/tickets/arquivados/'+getMissao()?.id);

      const allTickets = filterTickets(res.data.tickets);

      console.log('allTickets',allTickets);

      // Número de solicitações novas (solicitações feitas a menos de dois dias)
      const hoje = new Date();
      const duasDiasAtras = new Date(hoje);
      duasDiasAtras.setDate(hoje.getDate() - 2);
      const novasSolicitacoes = allTickets.filter(ticket => new Date(ticket.data_inicio) >= duasDiasAtras);
      setNumNovasSolicitacoes(novasSolicitacoes.length);

      // Número de solicitações resolvidas (solicitações cujo o estado = 1)
      const solicitacoesResolvidas = allTickets.filter(ticket => ticket.estado == 1);
      setNumSolicitacoesResolvidas(solicitacoesResolvidas.length);

      // Número de solicitações abertas (solicitações cujo o estado = 0)
      const solicitacoesAbertas = allTickets.filter(ticket => ticket.estado == 0);
      setNumSolicitacoesAbertas(solicitacoesAbertas.length);

      // Número de solicitações pendentes (solicitação cujo estado=0 e data_fim < today_date)
      const solicitacoesPendentes = allTickets.filter(ticket => ticket.estado == 0 && new Date(ticket.data_fim) < hoje);
      setNumSolicitacoesPendentes(solicitacoesPendentes.length);

      setTickets(allTickets);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getUtilizadores = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/utilizadores/ativados/'+getMissao()?.id);

      setUtilizadores(res.data.utilizadores);

      console.log('Utilizadores : ');
      console.log(res.data.utilizadores);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleSubmissionSuccess = (ticketIdSubmited) => {

    if (ticketIdSubmited)
      navigate('/task/ticket-view/' + ticketIdSubmited?.id);
    else {
      setFilteredtickets([]);
      getTickets();
    }

  };

  const chamarModalEliminar = (idSelecionado) => {

    setTicketSelecionado(idSelecionado);

    const editButton = document.getElementById('triggerModalEliminar');

    console.log('Ticket selecionado:')
    console.log(ticketSelecionado);

    if (editButton) {
      editButton.click();
    }

  }

  const chamarModalEdit = (idSelecionado) => {

    setTicketSelecionado(idSelecionado);

    const editButton = document.getElementById('triggerModalEdit');

    console.log('Ticket selecionado:')
    console.log(ticketSelecionado);

    if (editButton) {
      editButton.click();
    }

  }

  const handleFilterSubmit = (filteredtickets) => {
    setTickets(filterTickets(filteredtickets));
  };

  const stripHtmlTags = (html) => {
    const tempDiv = document.createElement("div"); 
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const aprovarTicket = async (ticket_id, estado) => {

    if (user?.tipo == 1) {

      Swal.fire({
        title: estado == 1 ? 'Aprovar ticket' : 'Reprovar ticket',
        text: "Tem a certeza que pretende " + (estado == 1 ? 'aprovar' : 'reprovar') + " este ticket ?",
        type: "confirm",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (t) {

        if (t.isConfirmed) {
          try {

            setLoading(true);

            const formData = new FormData();
            formData.append('id', ticket_id);
            formData.append('estado', estado);

            const response = await http.post('/Ticket/aprovar', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });

            setLoading(false);
            //Refresh colaboradores list
            getTickets();

            // Show a success toast
            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              onClose: () => {

                setLoading(false);
                //Refresh colaboradores list
                //getTickets();

              }
            });

          } catch (error) {

            setLoading(false);
            console.error(error);

            toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        }

      });

    } else {

      toast.error('Seu utilizador não tem permissão para aprovar ou negar aprovação de ticket', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });

    }




  };

  const arquivarTicket = async (ticket_id, estado) => {

    if (user?.tipo == 1) {

      Swal.fire({
        title: estado == 2 ? 'Arquivar ticket' : 'Desarquivar ticket',
        text: "Tem a certeza que pretende " + (estado == 2 ? 'arquivar' : 'desarquivar') + " este ticket ?",
        type: "confirm",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (t) {

        if (t.isConfirmed) {
          try {

            setLoading(true);

            const formData = new FormData();
            formData.append('id', ticket_id);
            formData.append('estado', estado);

            const response = await http.post('/ticket/arquivar', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });

            setLoading(false);
            //Refresh colaboradores list
            getTickets();

            // Show a success toast
            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              onClose: () => {

                setLoading(false);
                //Refresh colaboradores list
                //getTickets();

              }
            });

          } catch (error) {

            setLoading(false);
            console.error(error);

            toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        }

      });

    } else {

      toast.error('Seu utilizador não tem permissão para aprovar ou negar aprovação de ticket', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });

    }

  };

  const data = [
    {
      id: 1,
      image: User2,
      name: "John Doe",
      ticketid: "TKT-0001",
      ticketsubject: "Internet Issue",
      createddate: "5 Jan 2023 07:21 AM",
      lastreply: "5 Jan 2023 11.12 AM	",
      priority: t('High'),
      status: t('New'),
    },
    {
      id: 2,
      image: User3,
      name: "Catarina Manuel",
      ticketid: "TKT-0001",
      ticketsubject: "Laptop Issue",
      createddate: "5 Jan 2023 07:21 AM",
      lastreply: "5 Jan 2023 11.12 AM	",
      priority: t('High'),
      status: t('New'),
    },
  ];

  const columns = [
    {
      title: t('Ticket Id'),
      dataIndex: "ticket_code",
      render: (text, record) => (
        <Link
          onClick={() => localStorage.setItem("minheight", "true")}
          to={"/task/ticket-view/"+record?.id}
        >
          {record?.ticket_code}
        </Link>
      ),
      sorter: (a, b) => a.ticket_code.length - b.ticket_code.length,
    },

    {
      title: t('Ticket Subject'),
      dataIndex: "nome",
      sorter: (a, b) => a.nome.length - b.nome.length,
    },
    {
      title: t('Team'),
      dataIndex: "Team",
      render: (text, record) => (
        <ul className="team-members">
          {record?.colaboradores?.slice(0, 2).map(user => (
            <li>
              <Link
                to="#"
                data-bs-toggle="tooltip"
                title={user?.colaborador?.name}
              >
                <img src={url_root + "/storage/" + user?.colaborador?.fotografia} alt={user?.colaborador?.name} title={user?.colaborador?.name} />
              </Link>
            </li>
          ))}

          <li className="dropdown avatar-dropdown">



            {
              record?.colaboradores?.length > 2 ? <Link
                to="#"
                className="all-users dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >

                <span>+{record?.colaboradores?.length - 2}</span>

              </Link> : ''
            }


            <div className="dropdown-menu dropdown-menu-right">
              <div className="avatar-group">
                {record?.colaboradores?.map(user => (
                  <Link className="avatar avatar-xs" to="#">
                    <img src={url_root + "/storage/" + user?.colaborador?.fotografia} alt={user?.colaborador?.name} title={user?.colaborador?.name} />
                  </Link>

                ))}
              </div>
              <div className="avatar-pagination">
                <ul className="pagination">
                  <li className="page-item">
                    <Link
                      className="page-link"
                      to="#"
                      aria-label="Previous"
                    >
                      <span aria-hidden="true">«</span>
                      <span className="sr-only">{t('Previous')}</span>
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      1
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      2
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link
                      className="page-link"
                      to="#"
                      aria-label="Next"
                    >
                      <span aria-hidden="true">»</span>
                      <span className="sr-only">{t('Next')}</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      ),
      sorter: (a, b) => a.team.length - b.team.length,
    },
    {
      title: t('Created Date'),
      dataIndex: "data_inicio",
      render: (text, record) => (
        format(new Date(record.data_inicio), 'dd/MM/yyyy')
      ),
      sorter: (a, b) => a.data_inicio.length - b.data_inicio.length,
    },
    {
      title: t('Last Reply'),
      dataIndex: "lastreply",
      sorter: (a, b) => a.lastreply.length - b.lastreply.length,
    },
    {
      title: t('Priority'),
      dataIndex: "prioridade",
      render: (text, record) => (
        <>
          <span
            className={
              text == 1
                ? "badge bg-inverse-success"
                : text == 2 ? "badge bg-inverse-warning" : "badge bg-inverse-danger"
            }
          >
            {text == 1 ? t('Low') : text == 2 ? t('Medium') : t('High')}
          </span>
        </>
      ),
      sorter: (a, b) => a.prioridade.length - b.prioridade.length,
    },
    {
      title: t('Status'),
      dataIndex: "estado",
      render: (text, record) => (
        <>
          <span
            className={
              text == 1
                ? "badge bg-inverse-success" : ((new Date(record?.data_fim) < new Date()) && text != 1) ?
                 "badge bg-inverse-danger" : "badge bg-inverse-warning"
            }
          >
            {text == 1 ? t('Closed') : ((new Date(record?.data_fim) < new Date()) && text != 1) ? t('Pending') : t('Progress')}
          </span>
        </>
      ),
      sorter: (a, b) => a.Status.length - b.Status.length,
    },
    {
      title: t('Action'),
      className: "text-end",
      render: (record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <span
              className="dropdown-item"
              title={record.nome}
              onClick={() => chamarModalEdit(record)}
            >
              <i className="fa fa-pencil m-r-5" /> {t('Edit')}
            </span>
            <span
              className="dropdown-item"
              title={record.nome}
              onClick={() => arquivarTicket(record?.id,record?.estado!=2?2:0)}
            >
              <i className="fa fa-recycle m-r-5" /> { record?.estado!=2?t('Arquivar'):t('Desarquivar')}
            </span>
            <span
              className="dropdown-item"
              title={record.nome}
              onClick={() => chamarModalEliminar(record)}
            >
              <i className="fa fa-trash m-r-5" /> {t('Delete')}
            </span>
          </div>
        </div>
      ),
      sorter: (a, b) => a.length - b.length,
      width: "10%",
    },
  ];

  if (loading) return (<><Spinner></Spinner></>); else return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={t('Solicitações Arquivadas')}
            title="Dashboard"
            subtitle={t('Tickets Arquivados')}
            modal="#add_ticket"
            name={t('Add Ticket')}
          />

        
          <TicketFilter onFilterSubmit={handleFilterSubmit} />

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  rowKey={(record) => record.id}
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={tickets?.length > 0 ? tickets : []}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <TicketModelPopup /> */}

      <TicketModalPopup onSubmissionSuccess={handleSubmissionSuccess} ticket={ticketSelecionado} prioridadeParam={ticketSelecionado?.prioridade} tempoParam={ticketSelecionado?.tempo} utilizadores={utilizadores} missoes={missoes} />


      <DeleteModal Name={t('Delete Ticket')} objectoName={t('Ticket')} onSubmissionSuccess={handleSubmissionSuccess} id={ticketSelecionado?.id} endpoint={'ticket'} />

      <span
        data-bs-toggle="modal"
        data-bs-target="#edit_ticket"
        id="triggerModalEdit"
      >
      </span>

      <span
        data-bs-toggle="modal"
        data-bs-target="#delete"
        id="triggerModalEliminar"
      >
      </span>

    </>
  );
};

export default TicketArquivado;
