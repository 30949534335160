import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Avatar_16,
  Avatar_02,
  Avatar_05,
  Avatar_09,
  Avatar_10,
} from "../../../Routes/ImagePath/index";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import React, { useState, useEffect } from "react";

import AuthUser from "../../AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../Spinner";

import Swal from "sweetalert2";

const PlanificacaoModal = ({ onSubmissionSuccess, projetos_array }) => {

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user, user_permissoes, verificarPermissao } = AuthUser();
  const [data1, setData1] = useState('');
  const [area, setArea] = useState('');
  const [obs, setObs] = useState('');
  const [data2, setData2] = useState('');
  const [prazo, setPrazo] = useState();
  const [estado, setEstado] = useState();

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event('click'));
    }
  };

  useEffect(() => {
  }, [projetos_array]);

  //registar tarefa

  const submitFormPlanificacao = async () => {

    try {

      toggleLoading(true);

      await uploadPlano();

    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError('Utilizador não autenticado.');

        // Show a error toast
        toast.error('Utilizador não autenticado.', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } else {
        
        setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

        // Show a error toast
        toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }
    } finally {
      toggleLoading(false);
    }


  };

  const uploadPlanoInNewTab = () => {
    const newTab = window.open('', '_blank');

    if (newTab) {
      newTab.document.write('<html><head><title>Upload</title></head><body><script>(' + uploadPlano.toString() + ')();</script></body></html>');
    } else {
      console.error('Não foi possível abrir uma nova aba.');
    }
  };


  const uploadPlano = async () => {

    try {

      const formData = new FormData();

      formData.append('data1', data1);
      formData.append('data2', data2);
      formData.append('area', area);
      formData.append('obs', obs);
      formData.append('lider', user?.id);
      formData.append('projetos', projetos_array);

      //alert('Projetos array de Ids',projetos_array);

      const response = await http.post('/planificacao-colectiva', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      console.log('response',response.data.url);

      // Open the response URL in a new tab
      window.open(response?.data?.url, '_blank');

    } catch (error) {
      console.error(error);

      toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

  };


  return (
    <>

      {/* Add Plan Modal */}
      <div
        id="planifcacao_geral_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{t('Planificação PDF')}</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="closeButton30"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <form>

                <div className="input-block mb-3">
                  <label className="col-form-label">{t('Area')}</label>
                  <input type="text" className="form-control" value={area} onChange={e => setArea(e.target.value)} />
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">{t('Date Start')}</label>
                  <input type="date" className="form-control" value={data1} onChange={e => setData1(e.target.value)} />
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">{t('Date End')}</label>
                  <input type="date" className="form-control" value={data2} onChange={e => setData2(e.target.value)} />
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">{t('Observação')}</label>
                  <div className="">
                    <textarea className="form-control" value={obs} onChange={e => setObs(e.target.value)}></textarea>
                  </div>
                </div>

                <div className="submit-section m-t-20 text-center">
                  {loading ? 'A processar' : <a
                    className="btn btn-primary submit-btn"
                    onClick={submitFormPlanificacao}>
                    {t('Submit')}
                  </a>}

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Plan Modal */}

      <ToastContainer />

    </>
  );
};

export default PlanificacaoModal;
