import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Avatar_16,
  Avatar_02,
  Avatar_05,
  Avatar_09,
  Avatar_10,
} from "../../../Routes/ImagePath/index";

import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import React, { useState, useEffect } from "react";

import AuthUser from "../../AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../../Spinner";

import Swal from "sweetalert2";

const SubsidioVinculoModal = ({
  onSubmissionSuccess,
  subsidio,
  clientes,
  cargos,
  departamentos,
  postos,
  colaboradores,
  vinculos
}) => {

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user, user_permissoes, verificarPermissao, getMissao } =
    AuthUser();

  const [vinculo_clientes, setVinculoCliente] = useState([]);
  const [vinculo_colaboradores, setVinculoColaborador] = useState([]);
  const [vinculo_cargos, setVinculoCargo] = useState([]);
  const [vinculo_departamentos, setVinculoDepartamento] = useState([]);
  const [vinculo_postos, setVinculoPosto] = useState([]);


  const [vinculo_clientes_selecionados, setVinculoClienteSelecionado] = useState([]);
  const [vinculo_colaboradores_selecionados, setVinculoColaboradorSelecionado] = useState([]);
  const [vinculo_cargos_selecionados, setVinculoCargoSelecionado] = useState([]);
  const [vinculo_departamentos_selecionados, setVinculoDepartamentoSelecionado] = useState([]);
  const [vinculo_postos_selecionados, setVinculoPostoSelecionado] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState(subsidio);

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event("click"));
    }
  };

  useEffect(() => {

    console.log("Subsidio modal:");
    console.log(subsidio);
    console.log("Vinculos modal :");
    console.log(vinculos);

    setVinculoCargo(vinculos?.cargos);
    setVinculoDepartamento(vinculos?.departamentos);
    setVinculoColaborador(vinculos?.colaboradores);
    setVinculoCliente(vinculos?.clientes);
    setVinculoPosto(vinculos?.postos);

    setVinculoCargoSelecionado(vinculos?.cargos);
    setVinculoDepartamentoSelecionado(vinculos?.departamentos);
    setVinculoColaboradorSelecionado(vinculos?.colaboradores);
    setVinculoClienteSelecionado(vinculos?.clientes);
    setVinculoPostoSelecionado(vinculos?.postos);

  }, [subsidio]);


  //Vinculo Colaboradores

  const getClassForColaborador = (colaboradorId) => {
    if (vinculo_colaboradores_selecionados?.some(vc => vc.colaborador_id == colaboradorId)) {
      return 'bg-primary';
    } else if (vinculo_colaboradores?.some(vc => vc.colaborador_id == colaboradorId)) {
      return 'bg-secondary';
    } else {
      return '';
    }
  };

  const selecionarColaborador = (colaborador) => {
    const exists = vinculo_colaboradores_selecionados?.some(vc => vc.colaborador_id == colaborador.id);
    if (exists) {
      setVinculoColaboradorSelecionado(
        vinculo_colaboradores_selecionados?.filter(vc => vc.colaborador_id != colaborador.id)
      );
    } else {
      setVinculoColaboradorSelecionado([
        ...vinculo_colaboradores_selecionados,
        { id: null, subsidio_id: subsidio?.id, colaborador_id: colaborador.id }
      ]);
    }
  };

  const vincular_subsidio_colaborador = async () => {

    if (verificarPermissao("CARGO", "adicionar")) {
      try {

        setLoading(true);

        const formData = new FormData();
        formData.append("subsidio_id", subsidio?.id);
        formData.append('colaboradores', JSON.stringify(vinculo_colaboradores_selecionados));

        const response = await http.post("/subsidio/colaborador/register", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        setLoading(false);

      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  //Vinculo Cargos

  const getClassForCargo = (cargoId) => {
    if (vinculo_cargos_selecionados?.some(vc => vc.cargo_id == cargoId)) {
      return 'bg-primary';
    } else if (vinculo_cargos?.some(vc => vc.cargo_id == cargoId)) {
      return 'bg-secondary';
    } else {
      return '';
    }
  };

  const selecionarCargo = (cargo) => {
    const exists = vinculo_cargos_selecionados?.some(vc => vc.cargo_id == cargo.id);
    if (exists) {
      setVinculoCargoSelecionado(
        vinculo_cargos_selecionados?.filter(vc => vc.cargo_id != cargo.id)
      );
    } else {
      setVinculoCargoSelecionado([
        ...vinculo_cargos_selecionados,
        { id: null, subsidio_id: subsidio?.id, cargo_id: cargo.id }
      ]);
    }
  };

  const vincular_subsidio_cargo = async () => {

    if (verificarPermissao("CARGO", "adicionar")) {
      try {

        setLoading(true);

        const formData = new FormData();
        formData.append("subsidio_id", subsidio?.id);
        formData.append('cargos', JSON.stringify(vinculo_cargos_selecionados));

        const response = await http.post("/subsidio/cargo/register", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        setLoading(false);

      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


   //Vinculo Departamentos

   const getClassForDepartamento = (departamentoId) => {
    if (vinculo_departamentos_selecionados?.some(vc => vc.departamento_id == departamentoId)) {
      return 'bg-primary';
    } else if (vinculo_departamentos?.some(vc => vc.departamento_id == departamentoId)) {
      return 'bg-secondary';
    } else {
      return '';
    }
  };

  const selecionarDepartamento = (departamento) => {
    const exists = vinculo_departamentos_selecionados?.some(vc => vc.departamento_id == departamento.id);
    if (exists) {
      setVinculoDepartamentoSelecionado(
        vinculo_departamentos_selecionados?.filter(vc => vc.departamento_id != departamento.id)
      );
    } else {
      setVinculoDepartamentoSelecionado([
        ...vinculo_departamentos_selecionados,
        { id: null, subsidio_id: subsidio?.id, departamento_id: departamento.id }
      ]);
    }
  };

  const vincular_subsidio_departamento = async () => {

    if (verificarPermissao("CARGO", "adicionar")) {
      try {

        setLoading(true);

        const formData = new FormData();
        formData.append("subsidio_id", subsidio?.id);
        formData.append('departamentos', JSON.stringify(vinculo_departamentos_selecionados));

        const response = await http.post("/subsidio/departamento/register", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        setLoading(false);

      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };



  //Vinculo postos

  const getClassForPosto = (postoId) => {
    if (vinculo_postos_selecionados?.some(vc => vc.posto_id == postoId)) {
      return 'bg-primary';
    } else if (vinculo_postos?.some(vc => vc.posto_id == postoId)) {
      return 'bg-secondary';
    } else {
      return '';
    }
  };

  const selecionarPosto = (posto) => {
    const exists = vinculo_postos_selecionados?.some(vc => vc.posto_id == posto.id);
    if (exists) {
      setVinculoPostoSelecionado(
        vinculo_postos_selecionados?.filter(vc => vc.posto_id != posto.id)
      );
    } else {
      setVinculoPostoSelecionado([
        ...vinculo_postos_selecionados,
        { id: null, subsidio_id: subsidio?.id, posto_id: posto.id }
      ]);
    }
  };

  const vincular_subsidio_posto = async () => {

    if (verificarPermissao("CARGO", "adicionar")) {
      try {

        setLoading(true);

        const formData = new FormData();
        formData.append("subsidio_id", subsidio?.id);
        formData.append('postos', JSON.stringify(vinculo_postos_selecionados));

        const response = await http.post("/subsidio/posto/register", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        setLoading(false);

      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


//Vinculo clientes

const getClassForCliente = (clienteId) => {
  if (vinculo_clientes_selecionados?.some(vc => vc.cliente_id == clienteId)) {
    return 'bg-primary';
  } else if (vinculo_clientes?.some(vc => vc.cliente_id == clienteId)) {
    return 'bg-secondary';
  } else {
    return '';
  }
};

const selecionarCliente = (cliente) => {
  const exists = vinculo_clientes_selecionados?.some(vc => vc.cliente_id == cliente.id);
  if (exists) {
    setVinculoClienteSelecionado(
      vinculo_clientes_selecionados?.filter(vc => vc.cliente_id != cliente.id)
    );
  } else {
    setVinculoClienteSelecionado([
      ...vinculo_clientes_selecionados,
      { id: null, subsidio_id: subsidio?.id, cliente_id: cliente.id }
    ]);
  }
};

const vincular_subsidio_cliente = async () => {

  if (verificarPermissao("CARGO", "adicionar")) {
    try {

      setLoading(true);

      const formData = new FormData();
      formData.append('clientes', JSON.stringify(vinculo_clientes_selecionados));

      const response = await http.post("/subsidio/cliente/register", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setLoading(false);

    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  } else {
    Swal.fire({
      title: "SEM PERMISSÃO",
      text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
      type: "error",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Certo, entendi!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    });
  }
};


  

  return (
    <>
      {/* Add Task Modal */}
      <div
        id="vinculo_subsidio_modal"
        className="modal custom-modal fade modal-lg"
        role="dialog"
      >

        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{subsidio?.nome}</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="closeButton30"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              {/* start tab */}

              <div className="page-menu">
                <div className="row">
                  <div className="col-sm-12">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          data-bs-toggle="tab"
                          to="#tab_colaboradores"
                        >
                          {t("Colaboradores")}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          data-bs-toggle="tab"
                          to="#tab_cargos"
                        >
                          {t("Cargos")}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          data-bs-toggle="tab"
                          to="#tab_departamentos"
                        >
                          {t("Departamentos")}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          data-bs-toggle="tab"
                          to="#tab_clientes"
                        >
                          {t("Clientes")}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          data-bs-toggle="tab"
                          to="#tab_postos"
                        >
                          {t("Postos")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="tab-content">
                <div className="tab-pane show active" id="tab_colaboradores">
                  <div className="row">
                    <div className="col-sm-8">
                      <div className="input-block mb-3 mb-3 form-focus focused">
                        <div>
                          <input className="form-control" type="text" />
                          <label className="focus-label">
                            {t("Digite um nome para procurar")}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="input-block mb-3 mb-3 form-focus focused">
                        <a className="btn btn-primary" onClick={()=>vincular_subsidio_colaborador()}>
                          {loading ? (
                            "Carregando"
                          ) : (
                            <>
                              <i className="fa fa-save"></i> SALVAR ATRIBUIÇÕES{" "}
                            </>
                          )}
                        </a>
                      </div>
                    </div>

                    <hr className=""></hr>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-nowrap">
                      <thead>
                        <tr>
                          <th>{t("Foto")}</th>
                          <th>{t("Colaborador")}</th>
                          <th>{t("Dept/Cargo")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {colaboradores?.map((item) => (
                          <tr key={item?.id} onClick={() => selecionarColaborador(item)}>
                            <td className={getClassForColaborador(item.id)}>
                              <Link
                                to={"/profile/" + item.id}
                                className="avatar"
                              >
                                <img
                                  src={url_root + "/storage/" + item.fotografia}
                                  alt="foto"
                                />
                              </Link>
                            </td>
                            <td className={getClassForColaborador(item.id)}>
                              {item?.name}
                              <br></br>
                              {item?.email}
                              <br></br>
                              {item?.telefone}
                            </td>
                            <td className={getClassForColaborador(item.id)}>
                              {item?.departamento?.designacao}
                              <br></br>
                              {item?.cargo?.designacao}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="tab-pane" id="tab_cargos">
                <div className="row">
                    <div className="col-sm-8">
                      <div className="input-block mb-3 mb-3 form-focus focused">
                        <div>
                          <input className="form-control" type="text" />
                          <label className="focus-label">
                            {t("Digite um nome para procurar")}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="input-block mb-3 mb-3 form-focus focused">
                        <a className="btn btn-primary" onClick={()=>vincular_subsidio_cargo()}>
                          {loading ? (
                            "Carregando"
                          ) : (
                            <>
                              <i className="fa fa-save"></i> SALVAR ATRIBUIÇÕES{" "}
                            </>
                          )}
                        </a>
                      </div>
                    </div>

                    <hr className=""></hr>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-nowrap">
                      <thead>
                        <tr>
                          <th>{t("Designação")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cargos?.map((item) => (
                          <tr key={item?.id} onClick={() => selecionarCargo(item)}>
                            <td className={getClassForCargo(item.id)}>{item?.designacao}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="tab-pane" id="tab_departamentos">
                <div className="row">
                    <div className="col-sm-8">
                      <div className="input-block mb-3 mb-3 form-focus focused">
                        <div>
                          <input className="form-control" type="text" />
                          <label className="focus-label">
                            {t("Digite um nome para procurar")}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="input-block mb-3 mb-3 form-focus focused">
                        <a className="btn btn-primary" onClick={()=>vincular_subsidio_departamento()}>
                          {loading ? (
                            "Carregando"
                          ) : (
                            <>
                              <i className="fa fa-save"></i> SALVAR ATRIBUIÇÕES{" "}
                            </>
                          )}
                        </a>
                      </div>
                    </div>

                    <hr className=""></hr>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-nowrap">
                      <thead>
                        <tr>
                          <th>{t("Designação")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {departamentos?.map((item) => (
                          <tr key={item?.id} onClick={() => selecionarDepartamento(item)}>
                            <td className={getClassForDepartamento(item.id)}>{item?.designacao}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="tab-pane" id="tab_postos">
                <div className="row">
                    <div className="col-sm-8">
                      <div className="input-block mb-3 mb-3 form-focus focused">
                        <div>
                          <input className="form-control" type="text" />
                          <label className="focus-label">
                            {t("Digite um nome para procurar")}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="input-block mb-3 mb-3 form-focus focused">
                        <a className="btn btn-primary" onClick={()=>vincular_subsidio_posto()}>
                          {loading ? (
                            "Carregando"
                          ) : (
                            <>
                              <i className="fa fa-save"></i> SALVAR ATRIBUIÇÕES{" "}
                            </>
                          )}
                        </a>
                      </div>
                    </div>

                    <hr className=""></hr>
                  </div>
                  <table className="table table-nowrap">
                    <thead>
                      <tr>
                        <th>{t("Imagem")}</th>
                        <th>{t("Cliente")}</th>
                        <th>{t("Posto")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {postos?.map((item) => (
                        <tr key={item?.id} onClick={() => selecionarPosto(item)}>
                          <td className={getClassForPosto(item.id)}>
                            <Link to="#" className="avatar">
                              <img
                                src={
                                  url_root + "/storage/" + item?.cliente?.logo
                                }
                                alt="logo"
                              />
                            </Link>
                          </td>
                          <td className={getClassForPosto(item.id)}>{item?.cliente?.nome}</td>
                          <td className={getClassForPosto(item.id)}>{item?.codigo + " - " + item?.nome}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="tab-pane" id="tab_clientes">
                <div className="row">
                    <div className="col-sm-8">
                      <div className="input-block mb-3 mb-3 form-focus focused">
                        <div>
                          <input className="form-control" type="text" />
                          <label className="focus-label">
                            {t("Digite um nome para procurar")}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="input-block mb-3 mb-3 form-focus focused">
                        <a className="btn btn-primary" onClick={()=>vincular_subsidio_cliente()}>
                          {loading ? (
                            "Carregando"
                          ) : (
                            <>
                              <i className="fa fa-save"></i> SALVAR ATRIBUIÇÕES{" "}
                            </>
                          )}
                        </a>
                      </div>
                    </div>

                    <hr className=""></hr>
                  </div>
                  <table className="table table-nowrap">
                    <thead>
                      <tr>
                        <th>{t("Imagem")}</th>
                        <th>{t("Cliente")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientes?.map((item) => (
                        <tr key={item?.id} onClick={() => selecionarCliente(item)}>

                          <td className={getClassForCliente(item.id)}>
                            <Link to="#" className="avatar">
                              <img
                                src={url_root + "/storage/" + item?.logo}
                                alt="logo"
                              />
                            </Link>
                          </td>

                          <td className={getClassForCliente(item.id)}>{item?.nome}</td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* end tab */}
            </div>
          </div>
        </div>
      </div>
      {/* /Add Task Modal */}

      {loading ? <><Spinner></Spinner></>:<></>}

      <ToastContainer />
    </>
  );
};

export default SubsidioVinculoModal;
