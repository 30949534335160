import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../components/AuthUser";

import { format } from "date-fns";

import Spinner from "../../../components/Spinner";

const PainelMissao = () => {
  // eslint-disable-next-line no-unused-vars
  const {
    http,
    url_root,
    user_permissoes,
    verificarPermissao,
    getMissao,
    multipla_visibilidade,
    setMissaoSelecionada,
    user,
  } = AuthUser();

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);
  const [selecionada, setSelecionada] = useState(false);
  const [missoes, setMissoes] = useState([]);
  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    if (!multipla_visibilidade && user?.tipo != 1) navigate("/admin-dashboard");

    const fetchData = async () => {
      try {
        await getMissoes();

        setSelecionada(getMissao());
      } catch (error) {
        console.error("Erro ao obter utilizadores:", error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, []);

  const definirMissaoSelecionada = (missao) => {

    setMissaoSelecionada(missao);
    setSelecionada(missao);

    //navigate("/admin-dashboard");
  };

  const getMissoes = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/missoes_ativas");

      setMissoes(res.data.missoes);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  if (loading)
    return (
      <>
        <Spinner></Spinner>
      </>
    );
  else
    return (
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            {Array.isArray(missoes) && missoes.length > 0 ? (
              missoes.map((missao, index) => (
                <div 
                  className="col-md-4"
                  key={missao.id}
                  onClick={() => definirMissaoSelecionada(missao)}
                >
                  <span className={`job-list ${
                            selecionada?.id==missao.id
                              ? "bg-primary"
                              : ""
                          }`} to="/job-view">
                    <div className="job-list-det">
                      <div className="job-list-desc">
                        <h3 className="job-list-title">{missao.designacao}</h3>
                        <h4 className="job-department">{missao.local}</h4>
                        <h5 className="job-department">[ID] - {missao.id}</h5>
                      </div>
                      <div className="job-type-info">
                        <span className="job-types">{t("Mission")}</span>
                      </div>
                    </div>
                    <div className="job-list-footer">
                      <ul>
                        <li>
                          <i className="fa-solid fa-signs-post" />{" "}
                          {missao.endereco}
                        </li>
                      </ul>
                    </div>
                    <div className="job-list-image">
                      <img
                        src={url_root + "/storage/" + missao.imagem}
                        alt={missao.nome}
                        className="job-image"
                      />
                    </div>
                  </span>
                </div>
              ))
            ) : (
              <p>Nenhuma Missão Encontrada.</p>
            )}
          </div>
        </div>
      </div>
    );
};

export default PainelMissao;
