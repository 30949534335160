
import React, { useState, useEffect } from "react";
import { Table } from "antd";
import {
  User1,
  User2,
  User3,
  User4,
  User5,
  User6,
  User7,
  User8,
  User9,
  User10,
  User11,
  User12,
} from "../../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import MissaoModal from "../../../../components/Administration/missaos/MissaoModal";
import DeleteModal from "../../../../components/modelpopup/DeleteModal";

import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";

import AuthUser from "../../../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../../../components/Spinner";

const MissaoTable = ({ missaos }) => {

  const { t } = useTranslation();

  const [missao, setmissao] = useState(missaos);
  const [missaoSelecionado, setmissaoSelecionado] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao } = AuthUser();

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [selectedUserIds, setSelectedUserIds] = useState([]);

  useEffect(() => {
    setmissao(missaos);
  }, [missaos]);

  const handleSubmissionSuccess = (missaos) => {   
    setmissao(missaos);
  };

  const chamarModalEliminar = (idSelecionado) => {

    setmissaoSelecionado(idSelecionado);

    const editButton = document.getElementById('triggerModalEliminar');

    console.log('missao selecionado:');
    console.log(missaoSelecionado);

    if (editButton) {
      editButton.click();
    }

  }

  const chamarModalEdit = (idSelecionado) => {

    setmissaoSelecionado(idSelecionado);

    const editButton = document.getElementById('triggerModalEdit');

    console.log('missao selecionado:')
    console.log(missaoSelecionado);

    if (editButton) {
      editButton.click();
    }

  }

  const getmissao = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/missaos');

      setmissao(res.data.missaos);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };



  const handleAtivarDesativar = async (id, newState) => {

    //console.log('Clicou aqui para ativar e desativar');

    if (verificarPermissao('missao', 'ativar')) {
      try {

        setLoading(true); 

        const formData = new FormData();
        formData.append('id', id);
        formData.append('estado', newState);

        const response = await http.post('/missao/activate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        setLoading(false); 

        setmissao(response.data.data);

        // Atualize a lista de utilizadores após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };



  const columns = [
    {
      title: t('Name'),
      dataIndex: "designacao",
      render: (text, record) => (
        <div className="table-avatar">
          <Link to="/profile" className="avatar">
            <img src={url_root + "/storage/" + record.imagem} alt="foto" />
          </Link>
          <Link to="#">
            {text} 
          </Link>         
        </div>
      ),
      sorter: (a, b) => a.designacao.length - b.designacao.length,
    },
    {
      title: "Descrição",
      dataIndex: "descricao",
      sorter: (a, b) => a.descricao.length - b.descricao.length,
    },
    {
      title: t('Status'),
      dataIndex: "estado",
      render: (text, record) => (
        <>
          <span
            className={
              text == 1
                ? "badge bg-inverse-success"
                : "badge bg-inverse-danger"
            }
          >
            {text == 1 ? 'Ativo' : 'Desativado'}
          </span>&nbsp;
          <span
            onClick={() => handleAtivarDesativar(record.id, text == 1 ? 0 : 1)}
          >
            <i className="fa fa-recycle"></i>
          </span>
        </>
      ),
      sorter: (a, b) => a.nivel_id.length - b.nivel_id.length,
    },

    {
      title: t('Action'),
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <span
              className="dropdown-item"
              title={record?.name}
              onClick={() => chamarModalEdit(record)}
            >
              <i className="fa fa-pencil m-r-5" /> {t('Edit')}
            </span>
            <span
              className="dropdown-item"
              title={record?.designacao}
              onClick={() => chamarModalEliminar(record)}
            >
              <i className="fa fa-trash m-r-5" /> {t('Delete')}
            </span>
          </div>
        </div>
      ),
    },
  ];

  if (loading) return (<><Spinner><ToastContainer /></Spinner></>); else return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">


            <Table
              className="table-striped"
              style={{ overflowX: "auto" }}
              columns={columns}
              dataSource={missao}
              rowKey={(record) => record.id}
            />
            <MissaoModal onSubmissionSuccess={handleSubmissionSuccess} missao={missaoSelecionado} imagePreview={url_root + "/storage/"+missaoSelecionado?.imagem} />
            <DeleteModal objectoName={t('Mission')} onSubmissionSuccess={handleSubmissionSuccess} id={missaoSelecionado?.id} endpoint={'missao'} />


            <span
              data-bs-toggle="modal"
              data-bs-target="#edit_missao_modal"
              id="triggerModalEdit"
            >
            </span>

            <span
              data-bs-toggle="modal"
              data-bs-target="#delete"
              id="triggerModalEliminar"
            >
            </span>

          </div>
        </div>
      </div>

      <ToastContainer />

    </>
  );
};

export default MissaoTable;
