import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Avatar_16,
  Avatar_02,
  Avatar_05,
  Avatar_09,
  Avatar_10,
} from "../../../Routes/ImagePath/index";

import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import React, { useState, useEffect } from "react";

import { startOfMonth, endOfMonth, format } from "date-fns";

import AuthUser from "../../AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../../Spinner";

import Swal from "sweetalert2";

const SubsidioDoCargoModal = ({ subsidios, cargo }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const {
    http,
    url_root,
    user,
    user_permissoes,
    verificarPermissao,
    getMissao,
  } = AuthUser();

  const [subsidio_id, setSubsidioID] = useState("");
  const [cargo_subsidios, setcargosubsidios] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const toggleLoading = (value) => {
    setLoading(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getsubsidiosDocargo(cargo);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [cargo]);

  const getsubsidiosDocargo = async (cargo) => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("cargo_id", cargo?.id);

      const response = await http.post("/subsidios/do-cargo", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      // Converte o objeto em um array
      const subsidiosArray = Object.values(response.data.subsidios);
      console.log("Dados dos subsidios como array:", subsidiosArray); // Log para verificar

      setcargosubsidios(subsidiosArray);

      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // REGISTO NOVO

  const submitFormNovo = async () => {
    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {
        toggleLoading(true);

        await registar();
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else {
          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      console.log("Clicou em submeter");

      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, movimentocte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const registar = async () => {

    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {
        const formData = new FormData();

        formData.append("cargo_id", cargo?.id);
        formData.append("subsidio_id", subsidio_id);
        formData.append("missao_id", getMissao()?.id);

        const response = await http.post(
          "/vincular/cargo/subsidio",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        // Converte o objeto em um array
        const subsidiosArray = Object.values(response.data.subsidios);
        console.log("Dados dos subsidios como array:", subsidiosArray); // Log para verificar

        setcargosubsidios(subsidiosArray);
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, movimentocte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const submitFormEliminarVinculo = async (id) => {
    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {
        Swal.fire({
          title: "Remover vinculo entre o subsidio e cargo",
          text: "Tem a certeza que pretende remover este cargo deste subsidio ?",
          type: "confirm",
          showCancelButton: !0,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!",
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-danger ml-1",
          buttonsStyling: !1,
        }).then(async function (t) {
          if (t.isConfirmed) {
            toggleLoading(true);

            await removerVinculo(id);
          }
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else {
          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      console.log("Clicou em submeter");

      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, movimentocte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const removerVinculo = async (id) => {
    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {
        const formData = new FormData();

        formData.append("id", id);
        formData.append("cargo_id",cargo?.id);

        const response = await http.post(
          "/remover/cargo/subsidio",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        // Converte o objeto em um array
        const subsidiosArray = Object.values(response.data.subsidios);
        console.log("Dados dos subsidios como array:", subsidiosArray); // Log para verificar

        setcargosubsidios(subsidiosArray);
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, movimentocte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const renderOptionsubsidios = (subsidios) => {
    return subsidios?.map((subsidio) => (
      <React.Fragment key={subsidio.id}>
        <option value={subsidio.id}>
          {subsidio.natureza == 1 ? "Fixo" : "Percentagem"} - {subsidio?.nome} -{" "}
          {formatNumber(subsidio.valor)} -{" "}
          {subsidio.tipo == 1 ? "Remuneração" : "Desconto"}
        </option>
      </React.Fragment>
    ));
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat("pt-PT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  return (
    <>
      <>
        {/* Add subsidio cargo Modal */}
        <div
          id="add_subsidio_cargo_modal"
          className="modal modal-lg custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  {t("Subsidios do cargo : " + cargo?.designacao)}
                </h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  id="closeButton30"
                >
                  ×
                </button>
              </div>
              <div className="modal-body">
                <div className="card">
                  <div className="card-body">
                    <hr className="mt-4"></hr>
                    <form>
                      <div className="row mt-4">
                        <div className="col-md-8">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <select
                              className="form-control select"
                              onChange={(e) => setSubsidioID(e.target.value)}
                            >
                              <option value="-1">- Nenhum -</option>
                              {renderOptionsubsidios(subsidios)}
                            </select>

                            <label className="focus-label">
                              {t("subsidio | Desconto")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <a
                            className="btn btn-primary submit-btn"
                            onClick={submitFormNovo}
                          >
                            {loading ? "Carregando" : t("Vincular")}
                          </a>
                        </div>

                        <hr className="mt-2"></hr>

                        <div>
                          <h2>
                            <i
                              onClick={() =>
                                getsubsidiosDocargo(cargo)
                              }
                              className="fa fa-recycle"
                              title="Atualizar a lista"
                            ></i>{" "}
                            Lista de subsidios | Descontos do cargo
                          </h2>
                          <div className="table-responsive">
                            <table className="table table-nowrap">
                              <thead>
                                <tr>
                                  <th>
                                    <i className="fa fa-cogs"></i>
                                  </th>
                                  <th>Ligação</th>
                                  <th>Designação</th>
                                  <th>Valor</th>
                                  <th>Natureza</th>
                                  <th>Tipo</th>
                                </tr>
                              </thead>
                              <tbody>
                                {cargo_subsidios &&
                                cargo_subsidios.length > 0 ? (
                                  cargo_subsidios.map(
                                    (subsidio, index) => (
                                      <tr key={index}>
                                        <td>
                                          {subsidio?.tipo_relacao ==
                                          "Direta" ? (
                                            <>
                                              <i
                                                onClick={() =>
                                                  submitFormEliminarVinculo(
                                                    subsidio?.id_relacao
                                                  )
                                                }
                                                title="Remover vinculo direto"
                                                className="fa fa-trash text-danger"
                                              ></i>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </td>
                                        <td>
                                          {subsidio?.tipo_relacao}
                                        </td>
                                        <td>{subsidio?.subsidio?.nome}</td>
                                        <td>
                                          {formatNumber(
                                            subsidio?.subsidio?.valor
                                          )}
                                        </td>
                                        <td>
                                          {subsidio?.subsidio?.natureza == 1
                                            ? "Fixo"
                                            : "Percentagem"}
                                        </td>
                                        <td>
                                          {subsidio?.subsidio?.tipo == 1
                                            ? "Remuneração"
                                            : "Desconto"}
                                        </td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan="6">
                                      Nenhum subsidio encontrado
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Add subsidio cargo Modal */}

        <ToastContainer />
      </>
    </>
  );
};

export default SubsidioDoCargoModal;
