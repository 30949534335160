/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { TimePicker } from "antd";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import AuthUser from "../AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../Spinner";

import { Link, useHistory } from "react-router-dom";

import Swal from "sweetalert2";

dayjs.extend(customParseFormat);
const onChange = (time, timeString) => {
  // console.log(time, timeString);
};

const AddSalaryModal = ({ departamentos, cargos, postos, bancos, onSubmissionSuccess }) => {
  // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao, user, getMissao } = AuthUser();

  const [selectedDate1, setSelectedDate1] = useState(null);
  const [cargo_id, setCargoID] = useState(-1);
  const [banco_id, setBancoID] = useState(-1);
  const [posto_id, setPostoID] = useState(-1);
  const [departamento_id, setDepartamentoID] = useState(-1);

  const [nota, setNota] = useState("");
  const [ano, setAno] = useState(2024);
  const [mes, setMes] = useState(1);
  const [subNatal, setSubNatal] = useState(0);

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const [grupo, handleGrupoDaDFolha] = useState(6);

  const employee = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ];
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#00b0f0" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#00b0f0",
      },
    }),
  };

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event("click"));
    }
  };

  const submitForm = async () => {

    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {

        setLoading(true);      

        // Basic form validation
        if (grupo==2 && cargo_id == -1) {

        
          setLoading(false);

          // Show a error toast
          toast.error("Deve definir um cargo.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });

          return;
        }

        if (grupo==1 && departamento_id == -1) {
          setLoading(false);

          // Show a error toast
          toast.error("Deve definir um departamento.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });

          return;
        }

        if (grupo==3 && posto_id == -1) {

          setLoading(false);

          // Show a error toast
          toast.error("Deve definir um posto de cliente.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });

          return;
        }

        await uploadSalario();

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Utilizador não autenticado.");

          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else {
          setError(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado"
          );

          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );
        }
      } finally {
        setLoading(false);
      }
    } else {
      console.log("Clicou em submeter");

      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const uploadSalario = async () => {

    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {

        const formData = new FormData();

        console.log("tipo :");
        console.log(grupo);
        
        formData.append("tipo", grupo);
        formData.append("missao_id", getMissao()?.id);
        formData.append("mes", mes);
        formData.append("ano", ano);
        formData.append("banco_id", banco_id);
        formData.append("subsidio_natal", subNatal);
        formData.append("nota", nota);
        formData.append("posto_id", posto_id);
        formData.append("cargo_id", cargo_id);
        formData.append("departamento_id", departamento_id);
        formData.append("registou", user?.id);
        formData.append("atualizou", user?.id);

        const response = await http.post("/salario/coletivo/processar", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        setLoading(false);


        triggerCloseButtonClick("closeButton1");

        onSubmissionSuccess(response?.data?.folha_salarial_id);

        //console.log("folha_salarial_id");
        //console.log(response?.data?.folha_salarial_id);

      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const renderOptionCargos = (cargos) => {
    return cargos?.map((cargo) => (
      <React.Fragment key={cargo.id}>
        <option value={cargo.id}>{cargo.designacao}</option>
      </React.Fragment>
    ));
  };

  const renderOptionDepartamentos = (departamentos) => {
    return departamentos?.map((departamento) => (
      <React.Fragment key={departamento.id}>
        <option value={departamento.id}>{departamento.designacao}</option>
      </React.Fragment>
    ));
  };

  const renderOptionPostos = (postos) => {
    return postos?.map((posto) => (
      <React.Fragment key={posto.id}>
        <option value={posto.id}>
          {posto?.cliente?.nome} - {posto.codigo} {posto.nome}
        </option>
      </React.Fragment>
    ));
  };

  const renderOptionBancos= (bancos) => {
    return bancos?.map((banco) => (
      <React.Fragment key={banco.id}>
        <option value={banco.id}>
          {banco.nome}
        </option>
      </React.Fragment>
    ));
  };

  return (
    <>
      <div id="add_salary" className="modal custom-modal fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t("Add Salary")} - Individual</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <hr></hr>

              <div className="row">
                <div className="col-sm-6"></div>
                <div className="col-sm-6">
                  <Link
                    className="btn btn-primary"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#add_group_salary"
                  >
                    <i className="fa fa-users"></i>&nbsp; PROCESSAR DE FORMA
                    COLETIVA
                  </Link>
                </div>
              </div>

              <hr></hr>

              <form>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        {t("Employee Name")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="input-block mb-3">
                        <Select
                          options={employee}
                          placeholder="Select"
                          styles={customStyles}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        {t("Net Salary")} / {t("Hourly payment")}{" "}
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0.0"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">
                      {t("INSS")} ({t("According to default table")}){" "}
                    </label>
                    <Select
                      options={employee}
                      placeholder="Não incluir"
                      styles={customStyles}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">
                      {t("IRT")} ({t("According to default table")})
                    </label>
                    <Select
                      options={employee}
                      placeholder="Sim incluir"
                      styles={customStyles}
                    />
                  </div>

                  <div className="col-md-4">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        {t("Calculating type")}
                      </label>
                      <Select
                        options={employee}
                        placeholder="Por hora"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">{t("Month")}</label>
                    <Select
                      options={employee}
                      placeholder="Jan"
                      styles={customStyles}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">{t("Year")}</label>
                    <Select
                      options={employee}
                      placeholder="2024"
                      styles={customStyles}
                    />
                  </div>

                  <div className="col-md-4">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        {t("System registed time")}?
                      </label>
                      <Select
                        options={employee}
                        placeholder="Não, hora digitada"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">{t("Time")}</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="80"
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">Horas extra</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="10"
                      readOnly
                    />
                  </div>

                  <div className="col-md-4">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        {t("Absent days")}?
                      </label>
                      <Select
                        options={employee}
                        placeholder="Aplicar diretamente"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">{t("Days")}</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="3"
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">{t("Preview")}</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="10"
                      readOnly
                    />
                  </div>

                  <div className="col-md-4">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        {t("Holiday payment")}?
                      </label>
                      <Select
                        options={employee}
                        placeholder="Sim"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">{t("Percent")}(%)</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="80"
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">{t("Preview")}</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="10"
                      readOnly
                    />
                  </div>

                  <div className="col-md-4">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        {t("Christmass payment")}?
                      </label>
                      <Select
                        options={employee}
                        placeholder="Sim"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">{t("Percent")}(%)</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="50"
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">{t("Preview")}</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="10"
                      readOnly
                    />
                  </div>

                  <hr></hr>

                  <div className="col-md-12 col-sm-12">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="add-more input-block mb-3">
                          <span
                            title="Adicionar subsidios"
                            className="text-success"
                          >
                            <i className="fa-solid fa-plus-circle" /> Adicionar
                            Subsidios
                          </span>
                        </div>

                        <div className="input-block mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Descrição do subsidio"
                          />
                          <input
                            type="number"
                            className="form-control"
                            placeholder="valor"
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="add-more input-block mb-3">
                          <span
                            title="Adicionar descontos"
                            className="text-danger"
                          >
                            <i className="fa-solid fa-plus-circle" /> Adicionar
                            Descontos
                          </span>
                        </div>

                        <div className="input-block mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Descrição do desconto"
                          />
                          <input
                            type="number"
                            className="form-control"
                            placeholder="valor"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input-block mb-5">
                      <label className="col-form-label">{t("Add Note")} </label>
                      <textarea className="form-control" defaultValue={""} />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <button
                        className="btn btn-secondary submit-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        type="reset"
                      >
                        SIMULAR PROCESSAMENTO
                      </button>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="col-md-12">
                    <div className="input-block mb-3 row">
                      <div className="col-sm-6">
                        <h4>Remunerações</h4>

                        <ul className="chat-user-list">
                          <li>
                            <div className="chat-block d-flex">
                              <div className="media-body align-self-center text-nowrap">
                                <div className="user-name">290.000</div>
                                <span className="designation">
                                  Sálario base
                                </span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="chat-block d-flex">
                              <div className="media-body align-self-center text-nowrap">
                                <div className="user-name">48.000</div>
                                <span className="designation">
                                  Sub. Transporte
                                </span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="chat-block d-flex">
                              <div className="media-body align-self-center text-nowrap">
                                <div className="user-name">80.000</div>
                                <span className="designation">
                                  Sub. Projeto
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div className="col-sm-6">
                        <h4>Descontos</h4>

                        <ul className="chat-user-list">
                          <li>
                            <div className="chat-block d-flex">
                              <div className="media-body align-self-center text-nowrap">
                                <div className="user-name">30.000</div>
                                <span className="designation">
                                  Desconto de crédito
                                </span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="chat-block d-flex">
                              <div className="media-body align-self-center text-nowrap">
                                <div className="user-name">3.000</div>
                                <span className="designation">INSS</span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="chat-block d-flex">
                              <div className="media-body align-self-center text-nowrap">
                                <div className="user-name">30.000</div>
                                <span className="designation">
                                  Desvio do LapTop
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div className="col-sm-12">
                        <ul className="chat-user-list">
                          <li>
                            <div className="chat-block d-flex">
                              <div className="media-body align-self-center text-nowrap">
                                <div className="user-name text-success">
                                  380.000
                                </div>
                                <span className="designation text-success">
                                  Sálario líquido
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="reset"
                  >
                    {t("Submit")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        id="add_group_salary"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t("Group Salary Process")}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeButton1"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <hr></hr>

              <form>
                <div className="row">
                  <div className="col-md-4">
                    <div className="input-block mb-3 form-focus focused">
                      <label className="focus-label">
                        {t("Escolha o tipo de grupo")}
                      </label>
                      <select
                        className="form-control"
                        value={grupo}
                        onChange={(e) => handleGrupoDaDFolha(e.target.value)}
                      >
                        <option value="1">Por Departamento</option>
                        <option value="2">Por Cargo</option>
                        <option value="3">Por Posto</option>
                        <option value="4">Expatriados</option>
                        <option value="5">Todos os colaboradores</option>
                        <option value="6">Por Projeto</option>
                      </select>
                    </div>
                  </div>

                  {grupo == 1 ? (
                    <>
                      <div className="col-md-8">
                        <div className="input-block mb-3 form-focus focused">
                          <label className="focus-label">
                            {t("Department")}
                          </label>
                          <select
                            onChange={(e) => setDepartamentoID(e.target.value)}
                            value={departamento_id}
                            className="form-control select"
                          >
                            <option key={0} value={-1}></option>
                            {renderOptionDepartamentos(departamentos)}
                          </select>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {grupo == 2 ? (
                    <>
                      <div className="col-md-8">
                        <div className="input-block mb-3 form-focus focused">
                          <label className="focus-label">{t("Job Role")}</label>
                          <select
                            onChange={(e) => setCargoID(e.target.value)}
                            value={cargo_id}
                            className="form-control select"
                          >
                            <option key={0} value={-1}></option>
                            {renderOptionCargos(cargos)}
                          </select>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {grupo == 3 ? (
                    <>
                      <div className="col-md-8">
                        <div className="input-block mb-3 form-focus focused">
                          <label className="focus-label">
                            {t("Postos De Clientes")}
                          </label>
                          <select
                            onChange={(e) => setPostoID(e.target.value)}
                            value={posto_id}
                            className="form-control select"
                          >
                            <option key={0} value={-1}></option>
                            {renderOptionPostos(postos)}
                          </select>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="col-md-4">
                    <div className="input-block mb-3 form-focus focused">
                      <label className="focus-label">{t("Ano")}</label>
                      <select
                        onChange={(e) => setAno(e.target.value)}
                        value={ano}
                        className="form-control select"
                      >
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                      </select>
                    </div>
                  </div>

                  {(grupo!=1 && grupo!=2 && grupo!=3)?<>
                  <div className="col-md-4">
                    <div className="input-block mb-3 form-focus focused">
                      <label className="focus-label">{t("Mês")}</label>

                      <select
                        onChange={(e) => setMes(e.target.value)}
                        value={mes}
                        className="form-control select"
                      >
                        <option value="1">Janeiro</option>
                        <option value="2">Fevereiro</option>
                        <option value="3">Março</option>
                        <option value="4">Abril</option>
                        <option value="5">Maio</option>
                        <option value="6">Junho</option>
                        <option value="7">Julho</option>
                        <option value="8">Agosto</option>
                        <option value="9">Setembro</option>
                        <option value="10">Outubro</option>
                        <option value="11">Novembro</option>
                        <option value="12">Dezembro</option>
                      </select>
                    </div>
                  </div>
                  </>:<></>}

                  {(grupo==1 || grupo==2 || grupo==3)?<>
                    <div className="col-md-8">
                    <div className="input-block mb-3 form-focus focused">
                      <label className="focus-label">{t("Mês")}</label>

                      <select
                        onChange={(e) => setMes(e.target.value)}
                        value={mes}
                        className="form-control select"
                      >
                        <option value="1">Janeiro</option>
                        <option value="2">Fevereiro</option>
                        <option value="3">Março</option>
                        <option value="4">Abril</option>
                        <option value="5">Maio</option>
                        <option value="6">Junho</option>
                        <option value="7">Julho</option>
                        <option value="8">Agosto</option>
                        <option value="9">Setembro</option>
                        <option value="10">Outubro</option>
                        <option value="11">Novembro</option>
                        <option value="12">Dezembro</option>
                      </select>
                    </div>
                  </div>
                  </>:<></>}
                  


                  <div className="col-md-8">
                        <div className="input-block mb-3 form-focus focused">
                          <label className="focus-label">
                            {t("Banco")}
                          </label>
                          <select
                            onChange={(e) => setBancoID(e.target.value)}
                            value={banco_id}
                            className="form-control select"
                          >
                            <option key={0} value={-1}></option>
                            {renderOptionBancos(bancos)}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="input-block mb-3 form-focus focused">
                          <label className="focus-label">
                            {t("Incluir Subsidio De Natal ?")}
                          </label>
                          <select
                            onChange={(e) => setSubNatal(e.target.value)}
                            value={subNatal}
                            className="form-control select"
                          >
                            <option value="1">Não</option>
                            <option value="2">Sim</option>
                          </select>
                        </div>
                      </div>

                  <hr></hr>

                  <div className="col-md-12">
                    <div className="input-block mb-5">
                      <label className="col-form-label">{t("Add Note")} </label>
                      <textarea value={nota} className="form-control" onChange={(e)=>setNota(e.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="submit-section mb-5">
                  <a
                    className="btn btn-primary submit-btn"
                    onClick={submitForm}
                  >
                    {loading ? "..." : t("Submit")}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <>
          <Spinner></Spinner>
        </>
      ) : (
        <></>
      )}

    </>
  );
};

export default AddSalaryModal;
