/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthUser from "../AuthUser";

const VideoTutorialModalPopup = ({ video, titulo }) => {
  const { t } = useTranslation();
  const { http, url_root, user_permissoes, verificarPermissao, getToken, getMissao, user } = AuthUser();

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [videoError, setVideoError] = useState(null);

  useEffect(() => {
    if (video) {
      setIsVideoLoaded(false);
      setVideoError(null);
    }
  }, [video]);

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  const handleVideoError = (error) => {
    setVideoError("Erro ao carregar o vídeo. Verifique o caminho ou formato.");
    console.error("Erro ao carregar o vídeo:", error);
  };

  return (
    <div>
      <div id="modal_video" className="modal custom-modal fade modal-lg" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{titulo}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="trigger_close_modal_tarefas"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body center container">
              {videoError ? (
                <p className="text-danger">{videoError}</p>
              ) : (
                <video
                  width="520"
                  height="240"
                  controls
                  onCanPlay={handleVideoLoad}
                  onError={handleVideoError}
                >
                  <source src={video} type="video/mp4" />
                  Seu navegador não suporta o elemento de vídeo.
                </video>
              )}
              {!isVideoLoaded && !videoError && <p>Carregando vídeo...</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoTutorialModalPopup;
