import React, { useState, useEffect } from "react";

import { Link } from 'react-router-dom';
import Select from "react-select";
import { useTranslation } from "react-i18next";

import AuthUser from "./AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "./Spinner";

const ProjectsFilter = ({ onFilterSubmit }) => {

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao } = AuthUser();
  const [projetos, setProjetos] = useState([]);

  const [nomeFilter, setNomeFilter] = useState('');
  const [estadoFilter, setEstadoFilter] = useState(-1);

   // State to track whether the "checkgeral" checkbox is checked
   const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const handleFilterSubmit = async () => {
    try {

      console.log('consultar');

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append('nome', nomeFilter);
      formData.append('estado', estadoFilter);

      const response = await http.post('/projeto/pesquisar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });


      // Update the parent component state with filtered users
      onFilterSubmit(response.data.projetos);

      setLoading(false);

    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error('Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setLoading(false);

    }
  }; 

  return (
    <>
      <div className="row filter-row">
        <div className="col-sm-6 col-md-6">
          <div
            className={
              nomeFilter
                ? "input-block form-focus focused"
                : "input-block form-focus"
            }
          >
            <input
              type="text"
              className="form-control floating"
              value={nomeFilter}
              onChange={(e) => setNomeFilter(e.target.value)}
            />
            <label
              className="focus-label" 
            >
              {t('Project Name')}
            </label>
          </div>
        </div>

        <div className="col-sm-3 col-md-3">
        <div className="input-block form-focus select-focus">
          <select className="form-control" onChange={(e) => setEstadoFilter(e.target.value)}
          >
            <option value="-1">Todos estados</option>
            <option value="0">Aberto</option>
            <option value="1">Fechado</option>
          </select>
          <label className="focus-label">{t('Status')}</label>
        </div>
      </div>

        <div className="col-sm-6 col-md-3">
          <span className="btn btn-success btn-block w-100" onClick={() => handleFilterSubmit()}>
            {t('Search')}
          </span>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

export default ProjectsFilter;
