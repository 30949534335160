/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { TimePicker } from "antd";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import AuthUser from "../AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../Spinner";

import { Link, useHistory } from "react-router-dom";

import Swal from "sweetalert2";

dayjs.extend(customParseFormat);
const onChange = (time, timeString) => {
  // console.log(time, timeString);
};

const FechoDeContasModal = ({ colaborador }) => {
  // eslint-disable-next-line no-unused-vars
  const {
    http,
    url_root,
    user,
    user_permissoes,
    verificarPermissao,
    getMissao,
  } = AuthUser();

  //

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const toggleLoading = (value) => {
    setLoading(value);
  };

  // Estados para os arrays
  // Estados para os arrays
  const [salariosAtrasados, setSalariosAtrasados] = useState([]);
  const [outrosProveitos, setOutrosProveitos] = useState([]);
  const [dividas, setDividas] = useState([]);

  const [descontos, setDescontos] = useState([]);

  // Estados para os inputs de novo proveito e desconto
  const [novoProveito, setNovoProveito] = useState({ descricao: "", valor: 0 });
  const [novoDesconto, setNovoDesconto] = useState({ descricao: "", valor: 0 });

  const [indeminizacao, setIndeminizacao] = useState(0);
  const [motivo, setMotivo] = useState(1);
  const [data_fecho, setdataFecho] = useState(1);

  // Calcular o total de proveitos e descontos
  const totalProveitos =
    salariosAtrasados?.reduce((acc, curr) => acc + Number(curr.valor), 0) +
    outrosProveitos?.reduce((acc, curr) => acc + Number(curr.valor), 0);

  const totalDescontos =
    descontos.reduce((acc, curr) => acc + Number(curr.valor), 0) +
    dividas?.reduce((acc, curr) => acc + Number(curr.valor), 0);

  const resumo =
    Number(totalProveitos) - Number(totalDescontos) + Number(indeminizacao);

  const getProveitoDividas = async () => {
    try {
      setLoading(true); // Inicia o loading

      const formData = new FormData();
      formData.append("user_id", colaborador?.id);

      const response = await http.post(
        "/utilizador/fecho-conta/proveitos-dividas",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log(response.data); // Log para verificação dos dados recebidos

      // Atualiza os estados com os dados recebidos
      setSalariosAtrasados(response.data.salarios);
      setDividas(response.data.dividas);

      setLoading(false); // Finaliza o loading
    } catch (error) {
      console.error("Erro ao buscar proveitos e dívidas:", error);
      setLoading(false); // Finaliza o loading em caso de erro
    } finally {
      setLoading(false); // Garante que o loading seja finalizado em qualquer situação
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Chama a função assíncrona getUtilizador()
        //await getUtilizador();

        await getProveitoDividas();
      } catch (error) {
        console.error("Erro ao obter utilizadores:", error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, [colaborador]);

  const adicionarProveito = () => {
    if (novoProveito.descricao && novoProveito.valor > 0) {
      setOutrosProveitos([...outrosProveitos, novoProveito]); // Removi o Number() aqui, pois ele estava tentando converter o objeto inteiro para um número
      setNovoProveito({ descricao: "", valor: 0 });
    }
  };

  const adicionarDesconto = () => {
    if (novoDesconto.descricao && novoDesconto.valor > 0) {
      setDescontos([...descontos, novoDesconto]); // Removi o Number() aqui, pelo mesmo motivo
      setNovoDesconto({ descricao: "", valor: 0 });
    }
  };

  // Função para remover salário em atraso
  const removerSalarioAtrasado = (index) => {
    const novosSalariosAtrasados = salariosAtrasados?.filter(
      (_, i) => i !== index
    );
    setSalariosAtrasados(novosSalariosAtrasados); // Não converter o array para Number
  };

  // Função para remover proveitos
  const removerProveitos = (index) => {
    const novosProveitos = outrosProveitos.filter((_, i) => i !== index);
    setOutrosProveitos(novosProveitos); // Não converter o array para Number
  };

  // Função para remover descontos
  const removerDescontos = (index) => {
    const novosDescontos = descontos.filter((_, i) => i !== index);
    setDescontos(novosDescontos); // Não converter o array para Number
  };

  // Função para remover dívida
  const removerDivida = (index) => {
    const novasDividas = dividas?.filter((_, i) => i !== index);
    setDividas(novasDividas); // Não converter o array para Number
  };
  // Função para processar o fecho e unir todos os arrays

  const processarFecho = async () => {
    if (verificarPermissao("UTILIZADORES", "atualizar")) {
      Swal.fire({
        title: "Confirmar",
        text: "Tem a certeza que pretende executar este fecho de conta! caso o utilizador ja tenha um fecho de conta, sera substituido por este novo processamento, pretendo continuar ?",
        type: "confirm",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (t) {
        if (t.isConfirmed) {
          try {
            //pergunta

            toggleLoading(true);

            const salariosComTipo = salariosAtrasados?.map((item) => ({
              ...item,
              tipo: 1,
            }));
            const proveitosComTipo = outrosProveitos.map((item) => ({
              ...item,
              tipo: 1,
            }));
            const descontosComTipo = descontos.map((item) => ({
              ...item,
              tipo: -1,
            }));
            const dividasComTipo = dividas?.map((item) => ({
              ...item,
              tipo: -1,
            }));

            const resultadoFinal = [
              ...salariosComTipo,
              ...proveitosComTipo,
              ...descontosComTipo,
              ...dividasComTipo,
            ];

            console.log("Resultado Final:", resultadoFinal);
            // Convertendo resultadoFinal para JSON antes de enviar
            const formData = new FormData();
            formData.append("user_id", colaborador?.id);
            formData.append("motivo", motivo);
            formData.append("admissao", colaborador?.admissao);
            formData.append("data_fecho", data_fecho);
            formData.append("indeminizacao", indeminizacao);
            formData.append("missao_id", getMissao()?.id);
            formData.append("valores", JSON.stringify(resultadoFinal)); // Convertendo para JSON

            const response = await http.post(
              "/utilizador/processar-fecho-conta",
              formData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );

            toast.success(response?.data?.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            });
          } catch (error) {
            if (error.response && error.response.status === 401) {
              toast.error("Utilizador não autenticado.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
              });
            } else {
              toast.error(
                "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.",
                {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                }
              );
            }
          } finally {
            toggleLoading(false);
          }
        }
      });

      //fim da pergunta
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      });
    }
  };

  return (
    <>
      <div
        id="fecho_de_conta_modal"
        className="modal modal-xl custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Processar Fecho De Contas</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {/* fecho começa aqui */}

              <div className="row">
                {/* Primeira Coluna */}
                <div className="col-md-6">
                  <div className="card profile-box flex-fill">
                    <div className="card-body">
                      <hr />

                      {/* Campos de Entrada de Data e Motivo */}
                      <div className="row mb-3">
                        <div className="col-sm-4">
                          <div className="input-block form-focus">
                            <label className="focus-label">Data Admissão</label>
                            <input
                              className="form-control"
                              type="date"
                              value={colaborador?.admissao}
                            />
                          </div>
                        </div>

                        <div className="col-sm-4">
                          <div className="input-block form-focus">
                            <label className="focus-label">Data Fecho</label>
                            <input
                              className="form-control"
                              type="date"
                              value={data_fecho}
                              onChange={(e) => setdataFecho(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-sm-4">
                          <div className="input-block form-focus">
                            <label className="focus-label">Motivo</label>
                            <select
                              className="form-control"
                              value={motivo}
                              onChange={(e) => setMotivo(e.target.value)}
                            >
                              <option value={1}>Abandono de Trabalho</option>
                              <option value={2}>Despedimento</option>
                              <option value={3}>Licença</option>
                              <option value={4}>Rescisão de contrato</option>
                              <option value={5}>Suspensão de contrato</option>
                              <option value={6}>Caducidade de contrato</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <div className="submit-section">
                            <button
                              className="btn btn-primary btn-lg"
                              onClick={processarFecho}
                            >
                              <i className="fa fa-save"></i> Processar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Segunda Coluna */}
                <div className="col-md-6">
                  <div className="card profile-box flex-fill">
                    <div className="card-body">
                      <hr />

                      {/* Dados do Funcionário */}
                      <h4>Dados do Funcionário</h4>
                      <div className="mb-3 ">
                        <label>Nome: {colaborador?.name}</label>
                      </div>
                      {/* <div className="mb-3">
                        <label>Situação: Desistência</label>
                      </div> */}
                      {/* <div className="mb-3">
                        <label>Conta: Banco XXXXXXXX</label>
                      </div> */}

                      <hr></hr>

                      <div
                        className="input-group mb-3"
                        title="valor da indeminização"
                      >
                        <input
                          type="number"
                          className="form-control"
                          placeholder="indeminização"
                          value={indeminizacao}
                          onChange={(e) => setIndeminizacao(e.target.value)}
                        />
                        <button className="btn btn-primary">
                          <i className="fa fa-arrow-down"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                {/* Primeira Coluna */}
                <div className="col-md-6">
                  <div className="card profile-box flex-fill">
                    <div className="card-body">
                      <h3 className="card-title">
                        <i className="fa fa-arrow-up text-success"></i>{" "}
                        Proveitos
                      </h3>
                      <hr />

                      {/* Salários em Atraso */}
                      <h4>Salários em Atraso</h4>
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Mês/Ano</th>
                              <th>Valor</th>
                              <th>
                                <i className="fa fa-trash"></i>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {salariosAtrasados?.map((item, index) => (
                              <tr key={index}>
                                <td>{item?.descricao}</td>
                                <td>{item?.valor?.toLocaleString()}</td>
                                <td>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() =>
                                      removerSalarioAtrasado(index)
                                    }
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      {/* Outros Proveitos */}
                      <h4>Outros Proveitos</h4>
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Descrição</th>
                              <th>Valor</th>
                              <th>
                                <i className="fa fa-trash"></i>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {outrosProveitos.map((item, index) => (
                              <tr key={index}>
                                <td>{item?.descricao}</td>
                                <td>{item?.valor?.toLocaleString()}</td>
                                <td>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => removerProveitos(index)}
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      {/* Adicionar Proveito */}
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Descrição"
                          value={novoProveito.descricao}
                          onChange={(e) =>
                            setNovoProveito({
                              ...novoProveito,
                              descricao: e.target.value,
                            })
                          }
                        />
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Valor"
                          value={novoProveito.valor}
                          onChange={(e) =>
                            setNovoProveito({
                              ...novoProveito,
                              valor: Number(e.target.value),
                            })
                          }
                        />
                        <button
                          className="btn btn-primary"
                          onClick={()=>adicionarProveito()}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {/* Total Proveitos */}
                      <div className="text-end">
                        <strong>
                          Total Proveitos: {totalProveitos.toLocaleString()} Kz
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Segunda Coluna */}
                <div className="col-md-6">
                  <div className="card profile-box flex-fill">
                    <div className="card-body">
                      <h3 className="card-title">
                        <i className="fa fa-arrow-down text-danger"></i>{" "}
                        Descontos
                      </h3>
                      <hr />

                      {/* Dívidas */}
                      <h4>Dívidas</h4>
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Descrição</th>
                              <th>Valor</th>
                              <th>
                                <i className="fa fa-trash"></i>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {dividas?.map((item, index) => (
                              <tr key={index}>
                                <td>{item?.descricao}</td>
                                <td>{item?.valor?.toLocaleString()}</td>
                                <td>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => removerDivida(index)}
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      {/* Outros Descontos */}
                      <h4>Outros Descontos</h4>
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Descrição</th>
                              <th>Valor</th>
                              <th>
                                <i className="fa fa-trash"></i>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {descontos.map((item, index) => (
                              <tr key={index}>
                                <td>{item?.descricao}</td>
                                <td>{item?.valor?.toLocaleString()}</td>
                                <td>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => removerDescontos(index)}
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      {/* Adicionar Desconto */}
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Descrição"
                          value={novoDesconto.descricao}
                          onChange={(e) =>
                            setNovoDesconto({
                              ...novoDesconto,
                              descricao: e.target.value,
                            })
                          }
                        />
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Valor"
                          value={novoDesconto.valor}
                          onChange={(e) =>
                            setNovoDesconto({
                              ...novoDesconto,
                              valor: Number(e.target.value),
                            })
                          }
                        />
                        <button
                          className="btn btn-primary"
                          onClick={()=>adicionarDesconto()}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {/* Total Descontos */}
                      <div className="text-end">
                        <strong>
                          Total Descontos: {totalDescontos.toLocaleString()} Kz
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Resumo */}
              <div className="row">
                <div className="col-md-12">
                  <div className="card profile-box flex-fill">
                    <div className="card-body text-end">
                      <h3>Resumo Final: {resumo.toLocaleString()} Kz</h3>
                    </div>
                  </div>
                </div>
              </div>

              {/* fecho termina aqui */}
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

      {loading ? (
        <>
          <Spinner></Spinner>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default FechoDeContasModal;
